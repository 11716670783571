(function () {
    'use strict';

    angular
      .module('app.deliveries')
      .controller('DeliveriesDetailController', DeliveriesDetailController);

    DeliveriesDetailController.$inject = ['$scope', '$http', '$stateParams', 'DeliveriesService', 'uiGridConstants', 'Settings', 'uiGridGroupingConstants', 'FileUploader', 'localStorageService', '$location', '$anchorScroll'];

    function DeliveriesDetailController($scope, $http, $stateParams, DeliveriesService, uiGridConstants, Settings, uiGridGroupingConstants, FileUploader, localStorageService, $location, $anchorScroll) {
        var vm = this;

        vm.title = 'Teslimat Detay';

        activate();

        function activate() {
            vm.getTableHeight = function () {
                var rowHeight = 65;
                var headerHeight = 39;
                return {
                    height: (vm.gridProductsOptions.data.length * rowHeight + headerHeight) + "px"
                };
            };

            vm.deliveryFields = DeliveriesService.getDeliveryFields();

            DeliveriesService.getDelivery($stateParams.id).then(function (data) {
                vm.customer = data.Customer;
                vm.detailDelivery = data;
                vm.loading = false;
                vm.uploader.url = 'api/Delivery/' + data.Id + '/Media';
                var authData = localStorageService.get('authorizationData');
                vm.uploader.headers["Authorization"] = 'Bearer ' + authData.token;
                for (var i = 0; i < data.DeliveryMedia.length; i++) {
                    $http.get("/Uploads/" + data.DeliveryMedia[i].FileName, { responseType: "blob", Id: data.DeliveryMedia[i].Id })
                        .then(function (dataImage, status, headers, config) {
                            var mimetype = dataImage.type;
                            var file = new File([dataImage], "", { type: mimetype });
                            var dummy = new FileUploader.FileItem(vm.uploader, {});
                            dummy._file = file;
                            dummy.progress = 100;
                            dummy.isUploaded = true;
                            dummy.isSuccess = true;
                            dummy.MediaId = config.Id;
                            vm.uploader.queue.push(dummy);
                        })
                        .catch(function (dataImage, status, headers, config) {
                            var dummy = new FileUploader.FileItem(vm.uploader, {});
                            dummy.progress = 100;
                            dummy.isUploaded = true;
                            dummy.isSuccess = true;
                            //dummy.MediaId = config.Id;
                            vm.uploader.queue.push(dummy);
                            console.log("The url could not be loaded...\n (network error? non-valid url? server offline? etc?)");
                        });
                }

            });
        }

        vm.deliveryProduct = [];

        vm.printBarcode = function (deliveryProduct, TicketCount) {
            var popupWin = window.open('', '_blank', 'width=900,height=600');
            popupWin.document.open();
            for (var i = 0; i < TicketCount ; i++) {
                popupWin.document.write('<html><head></head><body onload="window.print()"><table class="table"><thead class="table-header bg-gray"><tr><th>Ürün Kodu</th><th>Ürün Adı</th><th>Kategori</th><th>Variant</th><th>Teslimat Adet</th><th>Lokasyon</th><th>Ticket</th></tr></thead><tbody class="table-body"><tr><td>' + deliveryProduct.Code + '</td><td>' + deliveryProduct.Name + '</td><td>' + deliveryProduct.Category + '</td><td>' + deliveryProduct.Variant + '</td><td>' + deliveryProduct.DeliveryQuantity + '</td><td>' + deliveryProduct.Location + '</td></tr></tbody></table></body></html>');
            }
            popupWin.document.close();
        }

        vm.uploader = new FileUploader({});

        vm.Settings = Settings;

        vm.uploader.filters.push({
            name: 'imageFilter',
            fn: function (item, options) {
                var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
            }
        });

        vm.removeFile = function (item) {
            DeliveriesService.removeDeliveryMedia(vm.detailDelivery.Id, item.MediaId).then(function (data) {
                vm.uploader.removeFromQueue(item);
            })
        }

        vm.uploader.onSuccessItem = function (fileItem, response) {
            fileItem.MediaId = response.Id;
        };

        //vm.mapoptions = {
        //    map: {
        //        center: new google.maps.LatLng(38.7133632, 35.5326646),
        //        zoom: 17,
        //        mapTypeId: google.maps.MapTypeId.ROADMAP
        //    },
        //    marker: function (house) {
        //        return {
        //            clickable: false,
        //            draggable: true,
        //            title: house.name
        //        }
        //    }
        //};
        //vm.setHouseLocation = function (house, marker) {
        //    var position = marker.getPosition();
        //    house.lat = position.lat();
        //    house.lng = position.lng();
        //};
        //vm.houses = [
        //    {
        //        id: 0,
        //        name: 'House A',
        //        lat: 38.7133632,
        //        lng: 35.5326646
        //    }
        //];


    }
})();