(function () {
    'use strict';

    angular
        .module('app.discounts')
        .controller('ContractsDetailController', DetailController);

    DetailController.$inject = ['$location', '$stateParams', '$http', 'ContractsService'];

    function DetailController($location, $stateParams, $http, Service) {
        /* jshint validthis:true */
        var vm = this;

        Service.get($stateParams.Id).then(function (data) {
            vm.entity = data;
        });

        vm.fields = Service.getFields();

        vm.delete = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                Service.del(vm.entity).then(function (data) {
                    $location.path('/contracts/list');
                });
            }
        };
    }
})();
