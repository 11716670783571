(function () {
    'use strict';

    angular
        .module('app.designMockup')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates(), "/");
    }

    function getStates() {
        return [
            {
                state: 'designMockup',
                config: {
                    url: '/designMockup',
                    templateUrl: 'app/designMockup/designMockup.html',
                    controller: 'designMockupController',
                    controllerAs: 'vm',
                    title: 'Tasarım Mockupları',
                    settings: {
                        //nav: 101,
                        //topName: 'products',
                        //name: 'designMockup',
                        //content: 'Tasarım Mockupları',
                        //breadcrumb: 'Tasarım Mockupları',
                        //breadcrumbIcon: 'format_color_fill'
                    }
                }
            }
        ];
    }
})();