(function () {
    'use strict';

    angular
        .module('app.blogPostCategory')
        .factory('BlogPostCategoryService', BlogPostCategoryService);

    BlogPostCategoryService.$inject = ['$http', '$q', 'Settings', 'ngAuthSettings', 'logger', 'config'];

    function BlogPostCategoryService($http, $q, Settings, ngAuthSettings, logger, config) {
        var service = {
            getCategories: getCategories,
            getCategory: getCategory,
            addCategory: addCategory,
            editCategory: editCategory,
            deleteCategory: deleteCategory,
            getCategoryFields: getCategoryFields
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/PostCategory/';

        var categoryFields = [
            {
                key: 'Name',
                type: 'horizontalInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Adı',
                    resource: 'form.name',
                    placeholder: 'Adı'
                }
            },
            {
                key: 'DisplayOrder',
                type: 'horizontalInput',
                templateOptions: {
                    type: 'number',
                    label: 'Sıra',
                    resource: 'form.display-order',
                    placeholder: 'Sıra'
                }
            },
            {
                key: 'Active',
                type: 'horizontalCheckbox',
                templateOptions: {
                    label: 'Aktif',
                    resource: 'form.active',
                    placeholder: 'Aktif'
                }
            },
            {
                key: 'ShowMenu',
                type: 'horizontalCheckbox',
                templateOptions: {
                    label: 'Menüde Göster',
                    resource: 'form.show-menu',
                    placeholder: 'Menüde Göster'
                }
            },
            {
                key: 'MetaKeyword',
                type: 'horizontalInput',
                templateOptions: {
                    type: 'text',
                    resource: 'form.meta-keyword',
                    label: 'Seo keywords'
                }
            },
            {
                key: 'MetaDescription',
                type: 'horizontalTextarea',
                templateOptions: {
                    label: 'Seo description',
                    resource: 'form.meta-description',
                    maxlength: 160
                }
            },
            {
                key: 'Name',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Seo title',
                    resource: 'form.meta-title',
                    type: 'text'
                }
            },
            {
                key: 'NormalizedName',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Arama Motoru Sayfa Adı',
                    resource: 'form.normalized-name',
                    type: 'text'
                }
            }
        ];

        return service;

        /////////////////////////////////////////

        function getCategoryFields() {
            return categoryFields;
        }

        function getCategories(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBase, {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCategory(Id) {
            var deferred = $q.defer();

            $http.get(serviceBase + Id)
                .then(getPostComplete)
                .catch(getPostFailed);

            function getPostComplete(response) {
                deferred.resolve(response.data);
            }

            function getPostFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function addCategory(category) {
            var deferred = $q.defer();

            $http.post(serviceBase, category)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function editCategory(category) {
            var deferred = $q.defer();

            $http.put(serviceBase + category.Id, category)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function deleteCategory(category) {
            var deferred = $q.defer();

            $http.delete(serviceBase + category.Id, category)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();