(function () {
    'use strict';

    angular
        .module('app.blogPost')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'blogpost',
                config: {
                    url: '/blogpost',
                    title: 'Blog Postları',
                    abstract: true,
                    templateUrl: 'app/blogpost/blogpost.html',
                    settings: {
                        nav: 3,
                        name: 'blogpost',
                        icon: 'fa fa-cubes',
                        content: 'Post',
                        resource: 'menu.post'
                    }
                }
            },
            {
                state: 'blogpost.list',
                config: {
                    templateUrl: 'app/blogpost/blogpost.list.html',
                    controller: 'BlogPostListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Blog Post Listesi',
                    settings: {
                        nav: 3,
                        name: 'blogpost.list',
                        topName: 'blogpost.list',
                        content: 'Blog Post Listesi',
                        breadcrumbIcon: 'local_mall',
                        breadcrumb: 'Blog Post Listesi',
                        resource: 'menu.posts.list'
                    }
                }
            },
            {
                state: 'blogpost.add',
                config: {
                    templateUrl: 'app/blogpost/blogpost.add.html',
                    controller: 'BlogPostAddController',
                    controllerAs: 'vm',
                    url: '/add',
                    title: 'Blog Post Ekle',
                    settings: {
                        nav: 3,
                        name: 'blogpost.add',
                        topName: 'blogpost.list',
                        icon: 'add',
                        content: 'Ekle',
                        breadcrumbIcon: 'add',
                        breadcrumb: 'Blog Post Ekle',
                        resource: 'menu.posts.add'
                    }
                }
            },
            {
                state: 'blogpost.edit',
                config: {
                    templateUrl: 'app/blogpost/blogpost.edit.html',
                    controller: 'BlogPostEditController',
                    controllerAs: 'vm',
                    url: '/edit/:PostId',
                    title: 'Blog Post Düzenle',
                    settings: {
                        name: 'blogpost.edit',
                        topName: 'blogpost.list',
                        breadcrumbIcon: 'edit',
                        breadcrumb: 'Blog Post Düzenle',
                        resource: 'menu.posts.edit'
                    }
                }
            },
            {
                state: 'blogpost.detail',
                config: {
                    templateUrl: 'app/blogpost/blogpost.detail.html',
                    controller: 'BlogPostDetailController',
                    controllerAs: 'vm',
                    url: '/detail/:PostId',
                    title: 'Blog Post Detay',
                    settings: {
                        name: 'blogpost.detail',
                        topName: 'blogpost.list',
                        breadcrumbIcon: 'assignment',
                        breadcrumb: 'Blog Post Detayı',
                        resource: 'menu.posts.detail'
                    }
                }
            }
        ];
    }
})();