(function () {
    'use strict';

    angular
        .module('app.resources')
        .factory('ResourcesService', Service);

    Service.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config', '$timeout', 'Settings'];

    function Service($http, $q, ngAuthSettings, logger, config, $timeout, Settings) {
        var service = {
            getAll: getAll,
            add: add,
            edit: edit,
            getResources: getResources,
            getCultures: getCultures
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri;
        var endpoint = serviceBase + 'api/resources/';

        return service;

        ///////////////////////////////

        function getAll(filterParams) {
            var deferred = $q.defer();

            $http.get(endpoint, {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();

            $http.post(endpoint, entity)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(customer) {
            var deferred = $q.defer();

            $http.put(endpoint + customer.Id, customer)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getResources() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/general/resources')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getCultures() {
            var deferred = $q.defer();

            $http.get(endpoint + 'cultures')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
    }
})();