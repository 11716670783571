(function () {
    'use strict';

    angular
        .module('app.simplemailing')
        .controller('SendSimplemailing', ListController);

    ListController.$inject = ['$location', 'SimplemailingService'];

    function ListController($location, Service) {
        /* jshint validthis:true */
        var vm = this;

        vm.entity = {};

        vm.fields = Service.getFields();

        vm.create = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                vm.message = null;
                vm.errormessage = null;
                Service.send(vm.entity).then(function (data) {
                    vm.loading = false;
                    vm.message = "Mailleriniz yollandı."
                }, function (response) {
                    vm.loading = false;
                    vm.errormessage = "Bir hata oluştu: " + response.data.message;
                });
            }
        };
    }
})();