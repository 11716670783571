(function() {
    "use strict";

    angular
        .module("app.contracts")
        .controller("ContractsEditController", EditController);

    EditController.$inject = ["$location", "$stateParams", "ContractsService"];

    function EditController($location, $stateParams, Service) {
        /* jshint validthis:true */
        var vm = this;

        vm.loading = true;
        vm.title = "";

        Service.get($stateParams.Id).then(function(data) {
            vm.entity = data;
            vm.loading = false;
        });

        vm.fields = Service.getFields();

        vm.edit = function() {
            if (vm.form.$valid) {
                vm.loading = true;
                Service.edit(vm.entity).then(function() {
                    vm.loading = false;
                    $location.path("/contracts/list");
                });
            }
        };

        vm.cancel = function() {
            $location.path("/contracts/list");
        };
    }
})();
