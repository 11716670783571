(function () {
    'use strict';

    angular
        .module('app.marketing')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [{
                state: 'marketing',
                config: {
                    url: '/marketing',
                    title: 'Pazarlama',
                    abstract: true,
                    templateUrl: 'app/marketing/marketing.html'
                }
            },
            {
                state: 'marketing.campaign',
                config: {
                    url: '/campaigns',
                    title: 'Kampanyalar',
                    abstract: true,
                    templateUrl: 'app/marketing/marketing.html'
                }
            },
            {
                state: 'marketing.campaign.list',
                config: {
                    templateUrl: 'app/marketing/campaigns/campaigns.list.html',
                    controller: 'CampaignsListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Kampanya Listesi',
                    settings: {
                        nav: 4,
                        name: 'campaign.list',
                        topName: 'marketing',
                        content: 'Kampanya Listesi',
                        resource: 'menu.campaign.list'
                    }
                }
            },
            {
                state: 'marketing.campaign.add',
                config: {
                    templateUrl: 'app/marketing/campaigns/campaigns.add.html',
                    controller: 'CampaignsAddController',
                    controllerAs: 'vm',
                    url: '/{Id}',
                    title: 'Kampanya Ekle',
                    settings: {
                        nav: 4,
                        name: 'campaign.add',
                        topName: 'marketing.campaign.list',
                        content: 'Kampanya Ekle',
                        resource: 'menu.campaign.add'
                    }
                }
            },
        ];
    }
})();