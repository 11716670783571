(function () {
    'use strict';

    angular
        .module('app.currency')
        .controller('CurrencyListController', CurrencyListController);

    CurrencyListController.$inject = ['$location', 'CurrencyService', 'NgTableParams', '$uibModal'];

    function CurrencyListController($location, CurrencyService, NgTableParams, $uibModal) {
        /* jshint validthis:true */
        var vm = this;
        vm.settings = globalSettings;
        activate();
        function activate() {
            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 10 }, $location.search()),
                {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {};
                        filterData.PageIndex = params.page(),
                            filterData.PageSize = params.count(),
                            filterData.SortProperty = Object.keys(params.sorting())[0],
                            filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }


                        return CurrencyService.GetAllCurrency(filterData).then(function (data) {
                            params.total(data.FilteredCount);
                            return data.Data;
                        });

                    }
                });


           // vm.fields = CurrencyService.CurrencyService();
        }


        vm.UpdateCurrency = function () {
            CurrencyService.UpdateCurrency().then(function (response) {
                toastr.info("Para Birimleri Güncellendi", "Para Birimleri");
                vm.tableParams.reload();
            });

        }

        vm.delete = function (data) {

            CurrencyService.DeleteCurrency(data).then(function (response) {
                toastr.info("Para Birimi Silindi", "Para Birimleri");
                vm.tableParams.reload();
            });
        }

        vm.save = function (data) {

            CurrencyService.SaveCurrency(data).then(function (response) {
                toastr.info("Para Birimi Güncellendi", "Para Birimleri");
                vm.tableParams.reload();
            });
        }




    }
    
})();