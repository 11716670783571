(function () {
    'use strict';

    angular
        .module('app.cultures')
        .controller('CulturesController', Controller);

    Controller.$inject = ['$location', 'NgTableParams', 'CulturesService', 'GeneralService'];

    function Controller($location, NgTableParams, Service, GeneralService) {
        var vm = this;

        activate();

        function activate() {
            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 10 }, $location.search()),
                {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {};
                        filterData.PageIndex = params.page(),
                            filterData.PageSize = params.count(),
                            filterData.SortProperty = Object.keys(params.sorting())[0],
                            filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return Service.getAll(filterData).then(function (data) {
                            params.total(data.FilteredCount);
                            return data.Data;
                        });
                    }
                });
        }

        Service.getSupportedCultures()
            .then(function (data) {
                vm.cultures = data;
            });

        vm.cancel = cancel;
        vm.save = save;
        vm.add = add;
        vm.del = del;

        ///////////////////////////////////

        vm.clearCache = function () {
            vm.loading = true;
            GeneralService.clearCache()
                .then(function () {
                    vm.loading = false;
                });
        };

        vm.selectLanguage = function (row) {
            var selectedCulture = vm.cultures.find(function (item) {
                return item.Name === row.Name;
            });
            row.Description = selectedCulture.DisplayName + '(' + selectedCulture.NativeName + ')';
        };

        function del(row) {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                Service.del(row).then(function (data) {
                    vm.tableParams.reload();
                });
            }
        }

        function cancel(row, rowForm) {
            vm.tableParams.reload();
        }

        function save(row, rowForm) {
            if (row.Id) {
                Service.edit(row).then(function (data) {
                    vm.tableParams.reload();
                });
            } else {
                Service.add(row).then(function (data) {
                    vm.tableParams.reload();
                });
            }
        }

        function add() {
            vm.tableParams.data.unshift({
                Name: "",
                isEditing: true
            });
        }
    }
})();