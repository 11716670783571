(function () {
    'use strict';

    angular
        .module('app.hbIntegration')
        .controller('HBEditMatchCategoryFilterValueController', HBEditMatchCategoryFilterValueController);

    HBEditMatchCategoryFilterValueController.$inject = ['$scope', '$stateParams', '$location', 'HBIntegrationService', 'NgTableParams'];

    function HBEditMatchCategoryFilterValueController($scope, $stateParams, $location, HBIntegrationService, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;

        vm.settings = globalSettings;
        activate();

        function activate() {
           
            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 10 }, $location.search()),
                {
                    getData: function () {
                        return HBIntegrationService.hbgetMatchCategoryFilter($stateParams.Id)
                            .then(function (data) {
                                return HBIntegrationService.getCategoryFilterValue(data.MatchCategory.IntegrationCategoryId, data.IntegrationCategoryAttrId)
                                    .then(function (result) {
                                        return result;
                                    });
                            });
                    }
                });

            HBIntegrationService.hbgetMatchCategoryFilter($stateParams.Id)
                .then(function (data) {
                    HBIntegrationService.getOptionValue(data.OptionId).then(function (result) {
                        vm.entity = result;
                        vm.entity.refresh = true;
                        vm.loading = false;
                    });
                });


            vm.MatchCategoryFilterId = $stateParams.Id;
        }

        vm.selectFilterValue = function (filter, option) {
            vm.loading = true;
            vm.catAttrValue = {};
            vm.catAttrValue.OptionValueId = option;
            vm.catAttrValue.MatchCategoryFilterId = $stateParams.Id;
            vm.catAttrValue.IntegrationCategoryAttrValueId = filter.id;
            vm.catAttrValue.IntegrationCategoryAttrValueName = filter.value;
            HBIntegrationService.hbeditMatchCategoryFilterValue(vm.catAttrValue).then(function (data) {
                vm.loading = false;
                $location.path('/hbIntegration/hbeditmatchcategoryfiltervalue/' + data.MatchCategoryFilterId);
            });
        };

    }
})();