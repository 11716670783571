(function () {
    'use strict';

    angular
        .module('app.pages')
        .factory('PagesService', PagesService);

    PagesService.$inject = ['$http', '$q', '$stateParams', 'Settings', 'ngAuthSettings', 'logger', 'config', '$timeout', 'PageSectionsService', 'TemplatesService', 'MediaService', 'CulturesService'];

    function PagesService($http, $q, $stateParams, Settings, ngAuthSettings, logger, config, $timeout, PageSectionsService, TemplatesService, MediaService, CulturesService) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            copy: copy,
            copyToCulture: copyToCulture,
            getFields: getFields,
            getSectionFields: getSectionFields
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/pages/';

        var fields = [{
                key: 'Name',
                type: 'horizontalInput',
                templateOptions: {
                    required: true,
                    label: 'Adı',
                    resource: 'form.name',
                    placeholder: 'Adı'
                }
            },
            {
                key: 'Url',
                type: 'horizontalInput',
                templateOptions: {
                    required: true,
                    label: 'Url',
                    resource: 'form.url',
                    placeholder: 'Url'
                }
            },
            {
                key: 'Description',
                type: 'horizontalTextarea',
                templateOptions: {
                    label: 'Açıklama',
                    resource: 'form.description',
                    placeholder: 'Açıklama'
                }
            },
            {
                key: 'Title',
                type: 'horizontalInput',
                templateOptions: {
                    required: true,
                    label: 'Başlık',
                    resource: 'form.title',
                    placeholder: 'Başlık'
                }
            },
            {
                key: 'MetaKeyword',
                type: 'horizontalInput',
                templateOptions: {
                    type: 'text',
                    resource: 'form.meta-keyword',
                    label: 'Seo keywords'
                }
            },
            {
                key: 'MetaDescription',
                type: 'horizontalTextarea',
                templateOptions: {
                    resource: 'form.meta-description',
                    label: 'Seo description'
                }
            },
            {
                key: 'CultureId',
                type: 'horizontalSelect',
                templateOptions: {
                    required: true,
                    label: 'Dil',
                    labelProp: 'Description',
                    resource: 'form.culture',
                    options: [{
                        Name: 'Yükleniyor...'
                    }]
                },
                controller: ['$scope', function ($scope) {
                    CulturesService.getAll({
                        PageSize: -1
                    }).then(function (response) {
                        $scope.to.options = response.Data;
                        if (!$scope.model.CultureId && response.Data.length <= 1) {
                            $scope.model.CultureId = response.Data[0].Id;
                        }
                    });
                }]
            },
            {
                key: 'Active',
                type: 'horizontalCheckbox',
                defaultValue: true,
                templateOptions: {
                    label: 'Yayında',
                    resource: 'form.active',
                    placeholder: 'Yayında'
                }
            },
            {
                key: 'ShowMenu',
                type: 'horizontalCheckbox',
                templateOptions: {
                    resource: 'form.show-menu',
                    label: 'Menüde Görünsün Mü?'
                }
            },
            {
                key: 'IsSpecial',
                type: 'horizontalCheckbox',
                templateOptions: {
                    resource: 'form.is-special',
                    label: 'Özel Sayfa'
                }
            },
            {
                key: 'UseHeader',
                type: 'horizontalCheckbox',
                defaultValue: true,
                templateOptions: {
                    resource: 'form.use-header',
                    label: 'Header Kulllan'
                }
            },
            {
                key: 'UseFooter',
                type: 'horizontalCheckbox',
                defaultValue: true,
                templateOptions: {
                    resource: 'form.use-footer',
                    label: 'Footer Kullan'
                }
            }
        ];

        var sectionFields = [{
                key: 'Name',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    label: 'Adı',
                    resource: 'form.name',
                    placeholder: 'Adı',
                    class: "col-xs-6"
                }
            },
            {
                key: 'Line',
                type: 'floatInput',
                templateOptions: {
                    type: 'number',
                    label: 'Sıra',
                    resource: 'form.display-order',
                    placeholder: 'Sıra',
                    class: "col-xs-3"
                }
            },
            {
                key: 'Active',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Aktif',
                    resource: 'form.active',
                    placeholder: 'Aktif',
                    class: "col-xs-3"
                }
            },
            {
                key: 'Content',
                type: 'floatTinyMCE',
                templateOptions: {
                    label: 'İçerik',
                    resource: 'form.content',
                    placeholder: 'İçerik'
                },
                hideExpression: '!model.HasContent',
            },
            {
                key: 'Size',
                defaultValue: 12,
                type: 'floatRangeInput',
                templateOptions: {
                    min: 1,
                    max: 12,
                    label: 'Boyut',
                    resource: 'form.size',
                    placeholder: 'Boyut',
                    class: "col-xs-8"
                }
            },
            {
                key: 'CustomClass',
                type: 'floatInput',
                templateOptions: {
                    label: 'Özel Sınıflar',
                    resource: 'form.custom-class',
                    placeholder: 'Özel Sınıflar',
                    class: "col-xs-4"
                }
            },
            {
                key: 'TemplateId',
                type: 'floatSelect',
                templateOptions: {
                    required: true,
                    label: 'Template',
                    resource: 'form.template',
                    options: [{
                        Name: 'Yükleniyor...'
                    }]
                },
                controller: ['$scope', function ($scope) {
                    TemplatesService.getAll({
                        PageSize: -1
                    }).then(function (response) {
                        $scope.to.options = response.Data.sort((a, b) => a.Name.localeCompare(b.Name));
                    });

                    $scope.$watch('model.Id', function (newValue, oldValue) {
                        if ($scope.model.TemplateId) {
                            var template = $scope.to.options.find(function (item) {
                                return item.Id === $scope.model.TemplateId;
                            });
                            $scope.model.HasContent = template.HasContent;
                        }
                    });

                    $scope.$watch('model.TemplateId', function (newValue, oldValue) {
                        if (newValue) {
                            var template = $scope.to.options.find(function (item) {
                                return item.Id === newValue;
                            });
                            $scope.model.Settings = {};
                            $scope.model.Settings = JSON.parse(template.Settings);
                        } else {
                            $scope.model.Settings = {};
                        }
                    });
                }]
            }
        ];

        return service;

        ///////////////////////////////

        function getAll(filterParams) {
            var deferred = $q.defer();
            $http.get(serviceBase, {
                    params: filterParams
                })
                .then(getAllComplete)
                .catch(getAllFailed);

            function getAllComplete(response) {
                deferred.resolve(response.data);
            }

            function getAllFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function get(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();

            $http.post(serviceBase, entity)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http.put(serviceBase + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function copy(Id) {
            var deferred = $q.defer();

            $http.put(serviceBase + "copy/" + Id)
                .then(complete)
                .catch(failed);

            function complete(response) {
                deferred.resolve(response.data);
            }

            function failed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function copyToCulture(Id, CultureId) {
            var deferred = $q.defer();

            $http.put(serviceBase + "copyToCulture/" + Id + "/" + CultureId)
                .then(complete)
                .catch(failed);

            function complete(response) {
                deferred.resolve(response.data);
            }

            function failed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(customer) {
            var deferred = $q.defer();

            $http.delete(serviceBase + customer.Id, customer)
                .then(delComplete)
                .catch(delFailed);

            function delComplete(response) {
                deferred.resolve(response.data);
            }

            function delFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getFields() {
            return fields;
        }

        function getSectionFields() {
            return sectionFields;
        }
    }
})();