(function() {
    "use strict";

    angular
        .module("app.contracts")
        .controller("ContractsListController", ListController);

    ListController.$inject = ["$location", "ContractsService", "$timeout"];

    function ListController($location, Service, $timeout) {
        /* jshint validthis:true */
        var vm = this;

        function activate() {
            vm.getContractList();
        }

        vm.regenerateTreeTable = function() {
            $timeout(function() {
                $(".contract-list")
                    .removeData("treetable")
                    .removeClass("treetable");
                $(".contract-list").treetable({ expandable: true });
            }, 0);
        };

        vm.getContractList = function() {
            Service.getAll({ PageSize: -1 }).then(function(data) {
                vm.contractsMixed = data.Data.sort(function(a, b) {
                    if (a.Name < b.Name) {
                        return -1;
                    }
                    if (a.Name > b.Name) {
                        return 1;
                    }
                    return 0;
                });
                vm.contracts = vm.getSubContractList(null);
                vm.regenerateTreeTable();
            });
        };

        vm.getSubContractList = function(TopContractId) {
            var contractList = [];
            var subContractsList = vm.contractsMixed.filter(function(contract) {
                return contract.TopContractId === TopContractId;
            });
            for (var i = 0; i < subContractsList.length; i++) {
                var item = subContractsList[i];
                contractList.push(item);
                var subItems = vm.getSubContractList(item.Id);
                contractList = contractList.concat(subItems);
            }
            return contractList;
        };

        activate();
    }
})();
