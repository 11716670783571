(function () {
    'use strict';

    angular
        .module('app.pages')
        .controller('PagesDetailController', PagesDetailController);

    PagesDetailController.$inject = ['$scope', '$stateParams', '$location', 'PagesService', 'PageSectionsService', 'MediaService', 'localStorageService', 'GeneralService'];

    function PagesDetailController($scope, $stateParams, $location, PagesService, PageSectionsService, MediaService, localStorageService, GeneralService) {
        var vm = this;

        function activate() {
            vm.entity = {};

            vm.selectedSection = {};

            vm.treeoptions = {
                nodeChildren: "Section",
                injectClasses: {
                    iExpanded: 'fa fa-minus-square',
                    iCollapsed: 'fa fa-plus-square'
                },
                isSelectable: function (node) {
                    return node.type !== 'main';
                }
            };

            vm.treedata = [{
                id: 1,
                type: 'main',
                text: 'Yükleniyor...',
                icon: "note",
                Section: []
            }];

            vm.fields = PagesService.getFields();

            vm.sectionFields = PagesService.getSectionFields();

            vm.loadPageData();
        }

        vm.loadPageData = function () {
            PagesService.get($stateParams.Id).then(function (data) {
                vm.entity = data;
                vm.treedata[0].Name = data.Name;
                vm.treedata[0].Section = data.Section.filter(function (item) {
                    return item.TopSectionId === null;
                });
                vm.treeExpandedNodes = vm.entity.Section;
                vm.treeExpandedNodes.push(vm.treedata[0]);
            });
        };

        vm.delete = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                PagesService.del(vm.entity).then(function (data) {
                    $location.path('/pages/list');
                });
            }
        };

        vm.editSection = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                vm.selectedSection.Data = JSON.stringify(vm.selectedSectionData);
                PageSectionsService.edit(vm.selectedSection).then(function () {
                    vm.loading = false;
                    vm.loadPageData();
                    vm.selectedSection = {};
                    vm.selectedSectionData = {};
                    vm.form.$setUntouched();
                });
            }
        };

        vm.selectSection = function (node, selected, $parentNode, $index, $first, $middle, $last, $odd, $even, $path) {
            if (selected) {
                vm.selectedSection = angular.copy(node);
                vm.selectedSectionData = {};
                vm.selectedSectionData = JSON.parse(vm.selectedSection.Data);
                vm.selectedSection.Settings = {};
                vm.selectedSection.Settings = JSON.parse(vm.selectedSection.Template.Settings);
            } else {
                vm.selectedSection = {};
            }
        };

        vm.prepareAddSection = function ($event, node) {
            vm.selectedSection = {
                PageId: vm.entity.Id,
                TopSectionId: node.Id
            };
            vm.selectedSectionData = {};
            $event.stopPropagation();
        };

        vm.saveSection = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                vm.selectedSection.Data = JSON.stringify(vm.selectedSectionData);
                PageSectionsService.add(vm.selectedSection).then(function () {
                    vm.loading = false;
                    vm.loadPageData();
                    vm.selectedSection = {};
                    vm.selectedSectionData = {};
                    vm.form.$setUntouched();
                });
            }
        };

        vm.deleteSection = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                vm.loading = true;
                PageSectionsService.del(vm.selectedSection).then(function (data) {
                    vm.loading = false;
                    vm.loadPageData();
                    vm.selectedSection = {};
                    vm.selectedSectionData = {};
                    vm.form.$setUntouched();
                });
            }
        };

        vm.clearCache = function () {
            vm.loading = true;
            GeneralService.clearCache()
                .then(function () {
                    vm.loading = false;
                });
        };

        activate();
    }
})();