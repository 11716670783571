
(function () {
    'use strict';

    angular
        .module('app.services')
        .factory('ServicesService', ServicesService);

    ServicesService.$inject = ['config'];

    function ServicesService(config) {
        var service = {
            getservices: getservices,
            getservice: getservice
        };

        var services = [
            { id: 1, Adi: "Service1", Kodu: "1256897235842" },
            { id: 2, Adi: "Service2", Kodu: "3546523235335" },
            { id: 3, Adi: "Service3", Kodu: "6265523253555" },
            { id: 4, Adi: "Service4", Kodu: "1256897235842" },
            { id: 5, Adi: "Service5", Kodu: "1256897235842" },
            { id: 6, Adi: "Service6", Kodu: "1256897235842" },
            { id: 7, Adi: "Service7", Kodu: "3856226531858" },
            { id: 8, Adi: "Service8", Kodu: "1256897235842" },
            { id: 9, Adi: "Service9", Kodu: "1256897235842" },
            { id: 10, Adi: "Service10", Kodu: "1256897235842" },
            { id: 11, Adi: "Service11", Kodu: "6866592323354" },
            { id: 12, Adi: "Service12", Kodu: "1256897235842" },
            { id: 13, Adi: "Service13", Kodu: "1256897235842" },
            { id: 14, Adi: "Service14", Kodu: "1256897235842" }

        ];

        function getservices() {
            return services;
        }

        function getservice(id) {
            return services.find(function (c) { return c.id >= id; });
        }

        return service;
    }
})();
