(function () {
    'use strict';

    angular
        .module('app.discounts')
        .controller('DiscountsAddController', AddController);

    AddController.$inject = ['$location', 'DiscountsService'];

    function AddController($location, Service) {
        /* jshint validthis:true */
        var vm = this;

        vm.entity = {};

        vm.fields = Service.getFields();

        vm.create = function () {
            if (vm.form.$valid) {
                vm.loading = true;

                vm.entity.Categories = vm.entity.Categories || [];
                vm.entity.DiscountCategory = vm.entity.Categories.map(function (item) {
                    return {
                        CategoryId: item.id
                    };
                });
                vm.entity.Categories = null;

                vm.entity.Products = vm.entity.Products || [];
                vm.entity.DiscountProduct = vm.entity.Products.map(function (item) {
                    return {
                        ProductId: item
                    };
                });
                vm.entity.Products = null;

                Service.add(vm.entity).then(function (data) {
                    vm.loading = false;
                    $location.path('/discounts/detail/' + data.Id);
                });
            }
        };
    }
})();