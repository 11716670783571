(function () {
    'use strict';

    angular
        .module('app.blogPostCategory')
        .controller('BlogPostCategoryAddController', BlogPostCategoryAddController);

    BlogPostCategoryAddController.$inject = ['$location', 'BlogPostCategoryService'];

    function BlogPostCategoryAddController($location, BlogPostCategoryService) {
        var vm = this;

        vm.title = '';

        vm.entity = {};

        vm.entityFields = BlogPostCategoryService.getCategoryFields();

        vm.create = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                BlogPostCategoryService.addCategory(vm.entity).then(function (data) {
                    vm.loading = false;
                    $location.path('/blogpostcategory/detail/' + data.Id);
                });
            }
        };
    }
})();