(function () {
    'use strict';

    angular
        .module('app.n11Integration')
        .controller('AddMatchCategoryController', AddMatchCategoryController);

    AddMatchCategoryController.$inject = ['$scope', '$stateParams','$location', 'N11IntegrationService', 'NgTableParams'];

    function AddMatchCategoryController($scope, $stateParams,$location, N11IntegrationService, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;

        vm.settings = globalSettings;
        activate();

        function activate() {
            vm.fields = N11IntegrationService.getMatchCategoryFields();
        }

        vm.addMatchCategory = function () {
            if (vm.form.$valid) {
                vm.loading = true;

                if (vm.entity.N11Categories1) {
                    vm.entity.IntegrationCategoryFullText = vm.entity.N11Categories1Name;
                    vm.entity.IntegrationCategoryId = vm.entity.N11Categories1;
                    vm.entity.IntegrationCategoryName = vm.entity.N11Categories1Name;
                }
                if (vm.entity.N11Categories2) {
                    vm.entity.IntegrationCategoryFullText = vm.entity.IntegrationCategoryFullText + "/" + vm.entity.N11Categories2Name;
                    vm.entity.IntegrationCategoryId = vm.entity.N11Categories2;
                    vm.entity.IntegrationCategoryName = vm.entity.N11Categories2Name;
                }
                if (vm.entity.N11Categories3) {
                    vm.entity.IntegrationCategoryFullText = vm.entity.IntegrationCategoryFullText + "/" + vm.entity.N11Categories3Name;
                    vm.entity.IntegrationCategoryId = vm.entity.N11Categories3;
                    vm.entity.IntegrationCategoryName = vm.entity.N11Categories3Name;
                }
                if (vm.entity.N11Categories4) {
                    vm.entity.IntegrationCategoryFullText = vm.entity.IntegrationCategoryFullText + "/" + vm.entity.N11Categories4Name;
                    vm.entity.IntegrationCategoryId = vm.entity.N11Categories4;
                    vm.entity.IntegrationCategoryName = vm.entity.N11Categories4Name;
                }
                if (vm.entity.N11Categories5) {
                    vm.entity.IntegrationCategoryFullText = vm.entity.IntegrationCategoryFullText + "/" + vm.entity.N11Categories5Name;
                    vm.entity.IntegrationCategoryId = vm.entity.N11Categories5;
                    vm.entity.IntegrationCategoryName = vm.entity.N11Categories5Name;
                }
                if (vm.entity.N11Categories6) {
                    vm.entity.IntegrationCategoryFullText = vm.entity.IntegrationCategoryFullText + "/" + vm.entity.N11Categories6Name;
                    vm.entity.IntegrationCategoryId = vm.entity.N11Categories6;
                    vm.entity.IntegrationCategoryName = vm.entity.N11Categories6Name;
                }

                vm.entity.EComCategoryId = vm.entity.EComCategoryId[0].id;

                N11IntegrationService.addMatchCategory(vm.entity).then(function (data) {
                    vm.loading = false;
                    $location.path('/n11Integration/matchcategorylist');
                },
                function (data) {
                    vm.loading = false;
                });
                vm.loading = false;
            }
        };
    }
})();
