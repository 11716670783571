(function() {
    "use strict";

    angular.module("app.orders").controller("DebtsListController", OrdersListController);

    OrdersListController.$inject = ["$scope", "$location", "$stateParams", "OrdersService", "NgTableParams"];

    function OrdersListController($scope, $location, $stateParams, OrdersService, NgTableParams) {
        var vm = this;

        activate();

        function activate() {
            vm.refresh = function() {
                vm.loading = true;
                vm.tableParams.reload();
                vm.loading = false;
            };

            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 18, sorting: { OrderDate: "desc" } }, $location.search()),
                {
                    getData: function(params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {}; // params.filter();
                        filterData.PageIndex = params.page();
                        filterData.PageSize = params.count();
                        filterData.SortProperty = Object.keys(params.sorting())[0];
                        filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        filterData.SalesOrderTypeId = "167f2802-fe76-44c3-b5c7-a6ceaa6c9428";
                        filterData.SelectFields =
                            "Id,Code,OrderDate,Customer.Name,Customer.Surname,OrderDate,Total,TotalDiscount,Status.Name,Description,Bank.Name";
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return OrdersService.getOrders(filterData).then(function(data) {
                            params.total(data.FilteredCount);
                            return data.Data;
                        });
                    }
                }
            );

            vm.totalDebts = "...";
            vm.totalExpectedDebts = "...";
            OrdersService.getDebtsDashboard().then(function(data) {
                vm.totalDebts = data.TotalDebts;
                vm.totalExpectedDebts = data.TotalExpectedDebts;
            });
        }

        vm.TotalFilter = {
            TotalMin: {
                id: "ng-table/money.html",
                placeholder: "Minimum Ücret"
            },
            TotalMax: {
                id: "ng-table/money.html",
                placeholder: "Maximum Ücret"
            }
        };
        vm.DateFilter = {
            OrderDateMin: {
                id: "ng-table/date.html",
                placeholder: "Minimum Tarih"
            },
            OrderDateMax: {
                id: "ng-table/date.html",
                placeholder: "Maximum Tarih"
            }
        };

        vm.Status = OrdersService.getOrderStatuses().then(function(response) {
            return response.map(function(item) {
                return { id: item.Id, title: item.Name };
            });
        });
    }
})();
