(function () {
    'use strict';

    angular
        .module('app.hbIntegration')
        .controller('HBAddMatchCategoryController', HBAddMatchCategoryController);

    HBAddMatchCategoryController.$inject = ['$scope', '$stateParams', '$location', 'HBIntegrationService', 'NgTableParams'];

    function HBAddMatchCategoryController($scope, $stateParams, $location, HBIntegrationService, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;

        vm.settings = globalSettings;
        activate();

        function activate() {
            vm.fields = HBIntegrationService.hbgetMatchCategoryFields();
        }

        vm.addMatchCategory = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                vm.entity.IntegrationCategoryFullText = vm.entity.HBCategories[0].name;
                vm.entity.IntegrationCategoryId = vm.entity.HBCategories[0].id;
                vm.entity.IntegrationCategoryName = vm.entity.HBCategories[0].name;


                vm.entity.EComCategoryId = vm.entity.EComCategoryId[0].id;


                HBIntegrationService.hbaddMatchCategory(vm.entity).then(function (data) {
                    vm.loading = false;
                    $location.path('/hbIntegration/hbmatchcategorylist');
                },
                    function (data) {
                        vm.loading = false;
                    });
                vm.loading = false;
            }
        };
    }
})();
