(function () {
    'use strict';

    angular.module('app.core')
        .config(toastrConfig)
        .value('config', config)
        .config(configure)
        .config(fileManagerConfigure)
        .config(translate)
        .config(interceptorsConfig)
        .run(accountServiceRun)
        .run(searchReplaceRun);

    var config = {
        appErrorPrefix: '[MazakaCore Error] ',
        appTitle: 'MazakaCore'
    };
    
    translate.$inject = ['$translateProvider'];

    function translate($translateProvider) {
        $translateProvider.useSanitizeValueStrategy('escapeParameters');
        $translateProvider.useLoader('CustomResourceLoader');
        //$translateProvider.useMissingTranslationHandler('CustomTranslateHandlerFactory');
    }

    fileManagerConfigure.$inject = ['fileManagerConfigProvider'];

    function fileManagerConfigure(config) {
        config.set({
            "appName": "mazakacore-angular-filemanager",
            "defaultLang": globalSettings.Culture,
            "listUrl": "api/FileManager/List",
            "uploadUrl": "api/FileManager/Upload",
            "renameUrl": "api/FileManager/Rename",
            "moveUrl": "api/FileManager/Move",
            "removeUrl": "api/FileManager/Delete",
            "createFolderUrl": "api/FileManager/CreateFolder",
            "downloadFileUrl": "api/FileManager/Download",
            "basePath": "/",
            "searchForm": true,
            "sidebar": true,
            "breadcrumb": false,
            "allowedActions": {
                "upload": true,
                "preview": true,
                "remove": true,
                "createFolder": true,
                "pickFiles": true,
                "rename": false,
                "move": false,
                "copy": false,
                "edit": false,
                "changePermissions": false,
                "compress": false,
                "compressChooseName": false,
                "extract": false,
                "download": false,
                "downloadMultiple": false,
                "pickFolders": false
            },
            "multipleDownloadFileName": "mazakacore-files.zip",
            "filterFileExtensions": [],
            "showExtensionIcons": true,
            "showSizeForDirectories": false,
            "useBinarySizePrefixes": false,
            "downloadFilesByAjax": true,
            "previewImagesInModal": true,
            "enablePermissionsRecursive": false,
            "compressAsync": false,
            "extractAsync": false,
            "pickCallback": function (item, currentPath) {
                globalSettings.currentPath = currentPath;
                var evt = window.document.createEvent("CustomEvent");
                evt.initCustomEvent("onPickItem", true, true, { item: item, currentPath: currentPath });
                window.dispatchEvent(evt);
                return true;
            },
            "mediaServer": globalSettings.MediaServer,
            isEditableFilePattern: /\.(txt|diff?|patch|svg|asc|cnf|cfg|conf|html?|.html|cfm|cgi|aspx?|ini|pl|py|md|css|cs|js|jsp|log|htaccess|htpasswd|gitignore|gitattributes|env|json|atom|eml|rss|markdown|sql|xml|xslt?|sh|rb|as|bat|cmd|cob|for|ftn|frm|frx|inc|lisp|scm|coffee|php[3-6]?|java|c|cbl|go|h|scala|vb|tmpl|lock|go|yml|yaml|tsv|lst)$/i,
            isImageFilePattern: /\.(jpe?g|gif|bmp|png|svg|tiff?)$/i,
            isExtractableFilePattern: /\.(gz|tar|rar|g?zip)$/i,
            "tplPath": "app/filemanager"
        });
    }

    toastrConfig.$inject = ['toastr'];

    function toastrConfig(toastr) {
        toastr.options.timeOut = 4000;
        toastr.options.positionClass = 'toast-top-right';
        toastr.options.closeButton = true;
    }

    configure.$inject = ['$logProvider', 'routerHelperProvider', 'exceptionHandlerProvider'];

    function configure($logProvider, routerHelperProvider, exceptionHandlerProvider) {
        if ($logProvider.debugEnabled) {
            $logProvider.debugEnabled(true);
        }
        exceptionHandlerProvider.configure(config.appErrorPrefix);
        routerHelperProvider.configure({ docTitle: config.appTitle + ': ' });
    }

    interceptorsConfig.$inject = ['$httpProvider'];

    function interceptorsConfig($httpProvider) {
        $httpProvider.interceptors.push('InterceptorService');
    }

    accountServiceRun.$inject = ['AccountService'];

    function accountServiceRun(AccountService) {
        AccountService.fillAuthData();
    }

    searchReplaceRun.$inject = ["$location", "$route", "$rootScope"];

    function searchReplaceRun($location, $route, $rootScope) {
        $location.searchReplace = function () {
            var lastRoute = $route.current;
            var un = $rootScope.$on("$locationChangeSuccess", function () {
                $route.current = lastRoute;
                un();
            });
            return $location.search.apply($location, arguments);
        };
    }
})();