(function() {
    "use strict";

    angular.module("app.contracts").factory("ContractsService", Service);

    Service.$inject = ["$http", "$q", "ngAuthSettings", 'GeneralService'];

    function Service($http, $q, ngAuthSettings, GeneralService) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            getFields: getFields
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + "api/contracts/";

        var fields = [
            {
                key: "Name",
                type: "horizontalInput",
                templateOptions: {
                    required: true,
                    type: "text",
                    label: "Adı",
                    resource: "form.name",
                    placeholder: "Adı"
                }
            },
            {
                key: "ContractTypeId",
                type: 'horizontalSelect',
                templateOptions: {
                    required: true,
                    label: "Kontrat Tipi",
                    resource: "form.contract-type",
                    placeholder: "Kontrat Tipi",
                    options: [{ Name: '...' }]
                },
                controller: ['$scope', function ($scope) {
                    GeneralService.getActiveContractType({ PageSize: -1 })
                        .then(function (response) {
                            $scope.to.options = response;
                        });
                }]
            },
            {
                key: "CultureId",
                type: 'horizontalSelect',
                templateOptions: {
                    required: true,
                    label: "Dil",
                    resource: "form.culture",
                    placeholder: "Dil",
                    options: [{ Name: '...' }]
                },
                controller: ['$scope', function ($scope) {
                    GeneralService.getActiveCultures({ PageSize: -1 })
                        .then(function (response) {
                            $scope.to.options = response;
                        });
                }]
            },
            {
                key: "AvailableVariables",
                type: "horizontalTextarea",
                defaultValue:'#SaticiBilgileri#  #AliciBilgileri#  #Tarih#  #SaticiAdi#  #AliciAdi#  #FaturaBilgileri#  #SiparisBilgileri#  #Tutar#',
                templateOptions: {
                    type: "text",
                    label: "Varolan Değişkenler",
                    resource: "form.available-variables",
                    placeholder: "Varolan Değişkenler",
                    readonly: true
                }
            },
            {
                key: "Content",
                type: "horizontalTinyMCE",
                templateOptions: {
                    label: "İçerik",
                    placeholder: "İçerik",
                    resource: "form.content"
                }
            }
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();

            $http
                .get(serviceBase, {
                    params: filterParams
                })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(Id) {
            var deferred = $q.defer();

            $http
                .get(serviceBase + Id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();

            $http
                .post(serviceBase, entity)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http
                .put(serviceBase + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function del(entity) {
            var deferred = $q.defer();

            $http
                .delete(serviceBase + entity.Id, entity)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();
