(function () {
	"use strict";

	angular.module("app.production")
		.factory("ProductsService", ProductsService);

	ProductsService.$inject = [
		"$http",
		"$q",
		"ngAuthSettings",
		"ProductsGeneralService",
		"CategoriesService",
		"CollectionsService",
		"OptionValuesService",
		"CulturesService",
		"CurrencyService",
		"ShipmentProviderService",
		"RolesService",
		"ProductListsService",
		"SharedData"
	];

	function ProductsService(
		$http,
		$q,
		ngAuthSettings,
		ProductsGeneralService,
		CategoriesService,
		CollectionsService,
		OptionValuesService,
		CulturesService,
		CurrencyService,
		ShipmentProviderService,
		RolesService,
		ProductListsService,
		SharedData
	) {
		var service = {
			getProducts: getProducts,
			getProduct: getProduct,
			addProduct: addProduct,
			editProduct: editProduct,
			activeProduct: activeProduct,
			passiveProduct: passiveProduct,
			deleteProduct: deleteProduct,
			getProductFields: getProductFields,
			getProductOtherFields: getProductOtherFields,
			getSeoFields: getSeoFields,
			getAttrFields: getAttrFields,
			getAllProductMedia: getAllProductMedia,
			removeProductMedia: removeProductMedia,
			getProductVariants: getProductVariants,
			regenerateAllVariants: regenerateAllVariants,
			addVariantProduct: addVariantProduct,
			copyToCulture: copyToCulture,
			copy: copy,
			getN11Fields: getN11Fields,
			getGGFields: getGGFields,
			getHBFields: getHBFields,
			getTRFields: getTRFields,
			getMatchCategory: getMatchCategory,
			prepareAllProducts: prepareAllProducts,
			ProductCombining: ProductCombining
		};

		var serviceBase = ngAuthSettings.apiServiceBaseUri;

		var productFields = [{
			key: "Name",
			type: "floatInput",
			templateOptions: {
				required: true,
				type: "text",
				label: "Adı",
				placeholder: "Adı",
				resource: "form.name"
			}
		},
		{
			key: "Price",
			type: "floatInput",
			templateOptions: {
				required: true,
				resource: "form.price",
				label: "Fiyat",
				placeholder: "Fiyat ",
				numberMask: 2,
				class: "col-xs-4"
			}
		},
		{
			key: "Tax",
			type: "floatInput",
			templateOptions: {
				required: true,
				label: "Vergi",
				resource: "form.tax",
				placeholder: "Vergi ",
				numberMask: 2,
				class: "col-xs-4"
			}
		},
		{
			key: "CurrencyId",
			type: "floatSelect",
			templateOptions: {
				required: true,
				label: "Para Birimi",
				labelProp: "ShortName",
				valueProp: 'Id',
				resource: "form.currency",
				options: [{
					Name: "Yükleniyor..."
				}],
				class: "col-xs-4"
			},
			controller: [
				"$scope",
				function ($scope) {
					CurrencyService.GetAllCurrency({
						PageSize: -1
					}).then(function (response) {
						$scope.to.options = response.Data;
						if ((!$scope.model.Id || ($scope.model.Id && !$scope.model.CurrencyId)) && response.Data.length > 0) {
							var defaultCurrencuId = jQuery.grep(response.Data, function (item, index) {
								return (item.Default == true);
							});
							$scope.model.CurrencyId = defaultCurrencuId[0].Id;
						}
					});
				}
			]
		},
		{
			key: 'MainCategoryId',
			type: 'floatTreeSelect',
			templateOptions: {
				label: 'Kategori',
				resource: 'form.category',
				required: false,
				placeholder: 'Kategori Seçiniz ',
				topProp: 'TopCategoryId',
				options: [{ Name: 'Yükleniyor...' }]
			},
			controller: ['$scope', function ($scope) {
				var filterParams = {
					PageSize: -1,
					SelectFields: 'Id,Name,TopCategoryId'
				};
				CategoriesService.getCategories(filterParams)
					.then(function (response) {
						$scope.to.options = response.Data;
					});

				$scope.$watch('model.MainCategory', function (newValue, oldValue) {
					if (newValue != undefined && newValue.length > 0) {
						$scope.model.MainCategoryId = newValue[0].id;
					}
				});
			}]
		},
		{
			key: "Active",
			type: "floatCheckbox",
			defaultValue: true,
			templateOptions: {
				label: "Yayında",
				resource: "form.active",
				placeholder: "Yayında",
				class: "col-xs-3"
			}
		},
		{
			key: "DisplayOrder",
			type: "floatInput",
			defaultValue: 99,
			templateOptions: {
				type: "number",
				resource: "form.display-order",
				label: "Sıra",
				placeholder: "Sıra",
				class: "col-xs-3"
			}
		},
		{
			key: "CultureId",
			type: "floatSelect",
			defaultValue: SharedData.Settings.CurrentCultureId,
			templateOptions: {
				required: true,
				label: "Dil",
				labelProp: "Description",
				resource: "form.culture",
				options: [{
					Name: "Yükleniyor..."
				}],
				class: "col-xs-6"
			},
			controller: [
				"$scope",
				function ($scope) {
					CulturesService.getAll({
						PageSize: -1
					}).then(function (response) {
						$scope.to.options = response.Data;
						if (!$scope.model.CultureId && response.Data.length <= 1) {
							$scope.model.CultureId = response.Data[0].Id;
						}
					});
				}
			]
		},
		{
			key: "Description",
			type: "floatTinyMCE",
			templateOptions: {
				label: "İçerik",
				placeholder: "İçerik",
				resource: "form.description"
			}
		}
		];
		var productOtherFields = [{
			key: "Code",
			type: "floatInput",
			templateOptions: {
				type: "text",
				label: "Kod",
				resource: "form.code",
				class: "col-xs-3"
			}
		},
		{
			key: "IsVisibleIndividually",
			type: "floatCheckbox",
			defaultValue: true,
			templateOptions: {
				label: "Tekli Yayınlanda",
				resource: "form.is-visible-individually",
				placeholder: "Tekli Yayında",
				class: "col-xs-3"
			}
		},
		{
			key: "ClosedForSale",
			type: "floatCheckbox",
			defaultValue: false,
			templateOptions: {
				label: "Yayında",
				resource: "form.closed-for-sale",
				placeholder: "Yayında",
				class: "col-xs-3"
			}
		},
		{
			key: "HidePrice",
			type: "floatCheckbox",
			defaultValue: false,
			templateOptions: {
				label: "Yayında",
				resource: "form.hide-price",
				placeholder: "Yayında",
				class: "col-xs-3"
			}
		},
		{
			key: "Categories",
			type: "floatTreeMultiSelect",
			templateOptions: {
				label: "Kategori",
				resource: "form.categories",
				topProp: 'TopCategoryId',
				addTop: true,
				required: true,
				placeholder: "Kategori Seçiniz ",
				options: [{
					Name: "Yükleniyor..."
				}]
			},
			controller: [
				"$scope",
				function ($scope) {
					$scope.first = true;
					$scope.$watch("model.Categories", function (newValue, oldValue) {
						if ($scope.first && newValue) {
							$scope.first = false;
							var filterParams = {
								PageSize: -1,
								SelectFields: 'Id,Name,TopCategoryId'
							};
							CategoriesService.getCategories(filterParams)
								.then(function (response) {
									$scope.to.options = response.Data;
								});
						}
					});
				}
			]
		},
		{
			key: "ShortDescription",
			type: "floatTextarea",
			templateOptions: {
				label: "Kısa Açıklama",
				placeholder: "Kısa Açıklama",
				resource: "form.short-description"
			}
		},
		{
			key: "Description2",
			type: "floatTinyMCE",
			templateOptions: {
				label: "İçerik 2",
				placeholder: "İçerik 2",
				resource: "form.description2"
			}
		},
		{
			key: 'Roles',
			type: 'float-async-ui-select-multiple',
			templateOptions: {
				label: 'Ürün Rolü',
				resource: 'form.product-role',
				labelProp: 'Name',
				valueProp: 'Id',
				options: [],
				placeholder: 'Ürün Rolü',
				refresh: function (text, options, model) {
					var params = {
						Name: text,
						PageSize: 25
					};
					if (model) {
						params.Id = model.Id;
					}
					return RolesService.getAll(params)
						.then(function (response) {
							options.templateOptions.options = response.Data;
						});
				},
				refreshDelay: 2
			}
		},
		{
			key: 'ProductLists',
			type: 'float-async-ui-select-multiple',
			templateOptions: {
				label: 'Listeler',
				resource: 'form.product-list',
				labelProp: 'Name',
				valueProp: 'Id',
				options: [],
				placeholder: 'Listeler',
				refresh: function (text, options, model) {
					var params = {
						Name: text,
						PageSize: 25
					};
					if (model) {
						params.Id = model.Id;
					}
					return ProductListsService.getAll(params)
						.then(function (response) {
							options.templateOptions.options = response.Data;
						});
				},
				refreshDelay: 2
			}
		},
		{
			key: "TaxIncluded",
			type: "floatCheckbox",
			defaultValue: true,
			templateOptions: {
				label: "Vergiler Dahil",
				resource: "form.tax-include",
				placeholder: "Vergiler Dahil",
				class: "col-xs-4"
			}
		},
		{
			key: "TaxWithHoldingCode",
			type: "floatInput",
			templateOptions: {
				type: "text",
				placeholder: "Faaliyet Alan Kodu",
				resource: "form.tax-with-holding-code",
				label: "Faaliyet Alan Kodu",
				class: "col-xs-4"
			}
		},
		{
			key: "TaxExemptionCode",
			type: "floatInput",
			templateOptions: {
				type: "text",
				placeholder: "Vergi Muafiyet Kodu",
				resource: "form.tax-exemption-code",
				label: "Vergi Muafiyet Kodu",
				class: "col-xs-4"
			}
		},
		{
			key: "Barcode",
			type: "floatInput",
			templateOptions: {
				type: "text",
				placeholder: "Barkod",
				resource: "form.barcode",
				label: "Barkod",
				class: "col-xs-4"
			}
		},
		{
			key: "Barcode2",
			type: "floatInput",
			templateOptions: {
				type: "text",
				resource: "form.barcode2",
				label: "Barkod2",
				class: "col-xs-4"
			}
		},
		{
			key: "OEM",
			type: "floatInput",
			templateOptions: {
				type: "text",
				resource: "form.oem",
				label: "OEM",
				class: "col-xs-4"
			}
		},
		{
			key: "DefaultIncrement",
			type: "floatInput",
			defaultValue: 1,
			templateOptions: {
				label: "Varsayılan Artış",
				resource: "form.default-increment",
				placeholder: "Varsayılan Artış ",
				numberMask: 2,
				class: "col-xs-4"
			}
		},
		{
			key: "DefaultQuantity",
			type: "floatInput",
			defaultValue: 1,
			templateOptions: {
				label: "Varsayılan Miktar",
				resource: "form.default-quantity",
				placeholder: "Varsayılan Miktar ",
				numberMask: 2,
				class: "col-xs-4"
			}
		},
		{
			key: "DefaultShippingPrice",
			type: "floatInput",
			templateOptions: {
				label: "Varsayılan Kargo Tutarı",
				resource: "form.default-cargo",
				placeholder: "Varsayılan Kargo Tutarı ",
				numberMask: 2,
				class: "col-xs-4"
			}
		},
		{
			key: "Width",
			type: "floatInput",
			templateOptions: {
				label: "Genişlik",
				resource: "form.width",
				placeholder: "Genişlik ",
				numberMask: 2,
				class: "col-xs-3"
			}
		},
		{
			key: "Height",
			type: "floatInput",
			templateOptions: {
				label: "Yükseklik",
				resource: "form.height",
				placeholder: "Yükseklik ",
				numberMask: 2,
				class: "col-xs-3"
			}
		},
		{
			key: "Depth",
			type: "floatInput",
			templateOptions: {
				label: "Derinlik",
				resource: "form.depth",
				placeholder: "Derinlik ",
				numberMask: 2,
				class: "col-xs-3"
			}
		},
		{
			key: "Weight",
			type: "floatInput",
			templateOptions: {
				label: "Ağırlık",
				resource: "form.weight",
				placeholder: "Ağırlık ",
				numberMask: 2,
				class: "col-xs-3"
			}
		},
		{
			key: "ProductBrandId",
			type: "floatSelect",
			templateOptions: {
				label: "Marka",
				resource: "form.brand",
				options: [{
					Name: "..."
				}],
				class: "col-xs-6"
			},
			controller: [
				"$scope",
				function ($scope) {
					ProductsGeneralService.getProductBrands().then(function (response) {
						$scope.to.options = response;
					});
				}
			]
		},
		{
			key: "BaseTypeId",
			type: "floatSelect",
			defaultValue: "8db4aba4-2145-48d2-9bdc-1e07db7cfc91",
			templateOptions: {
				required: true,
				label: "Temel Tip",
				resource: "form.base-type",
				options: [{
					Name: "..."
				}],
				class: "col-xs-6"
			},
			controller: [
				"$scope",
				function ($scope) {
					ProductsGeneralService.getBaseTypes().then(function (response) {
						$scope.to.options = response;
					});
				}
			]
		},
		{
			key: "Cost",
			type: "floatInput",
			templateOptions: {
				resource: "form.cost",
				label: "Maliyet",
				placeholder: "Maliyet ",
				numberMask: 2,
				class: "col-xs-4"
			}
		},
		{
			key: "DeliveryDate",
			type: "floatInput",
			templateOptions: {
				type: "number",
				resource: "form.delivery-date",
				label: "Teslimat Süresi (Gün)",
				placeholder: "Teslimat Süresi (Gün)",
				class: "col-xs-4"
			}
		},
		{
			key: "DeliveryDateWithoutStock",
			type: "floatInput",
			templateOptions: {
				type: "number",
				resource: "form.delivery-date-without-stock",
				label: "Stoksuz Teslimat Süresi (Gün)",
				placeholder: "Stoksuz Teslimat Süresi (Gün)",
				class: "col-xs-4"
			}
		},
		{
			key: "ReviewTypeId",
			type: "floatSelect",
			templateOptions: {
				label: "Yorum Tipi",
				resource: "form.review-type",
				options: [{
					Name: "Yükleniyor..."
				}],
				class: "col-xs-6"
			},
			controller: [
				"$scope",
				function ($scope) {
					ProductsGeneralService.getReviewTypes().then(function (response) {
						var reviewTypes = response.map(x => {
							var obj = {};
							obj["Id"] = x.Id;
							obj["Name"] = x.Type;
							return obj;
						});
						$scope.to.options = reviewTypes;
					});
				}
			]
		},
		{
			key: "ShowOldReview",
			type: "floatCheckbox",
			defaultValue: true,
			templateOptions: {
				label: "Önceki Yorumları Göster",
				resource: "form.show-old-review",
				placeholder: "Önceki Yorumları Göster",
				class: "col-xs-6"
			}
		},
		{
			key: 'ShipmentProviders',
			type: 'float-async-ui-select-multiple',
			templateOptions: {
				label: 'Gönderim Seçenekleri',
				resource: 'form.shipment-providers',
				labelProp: 'Name',
				valueProp: 'Id',
				options: [],
				placeholder: 'Gönderim Seçenekleri',
				refresh: function (text, options, model) {
					var params = {
						Name: text,
						PageSize: 25
					};
					if (model) {
						params.Id = model.Id;
					}
					return ShipmentProviderService.getAll(params)
						.then(function (response) {
							options.templateOptions.options = response.Data;
						});
				},
				refreshDelay: 2
			}
		},
		{
			key: "CatalogMediaId",
			type: "floatSingleMedia",
			templateOptions: {
				label: "Katalog",
				placeholder: "Katalog",
				resource: "form.catalog",
				valueKey: "Id",
				valueModel: "CatalogMedia.Name",
				class: "col-xs-6"
			}
		},
		{
			key: "CollectionId",
			type: "floatSelect",
			templateOptions: {
				label: "Koleksiyon",
				resource: "form.collection",
				options: [{
					Name: "..."
				}],
				class: "col-xs-6"
			},
			controller: [
				"$scope",
				function ($scope) {
					CollectionsService.getCollections({
						PageSize: -1
					}).then(function (response) {
						var opt = [{
							Name: ""
						}];
						$scope.to.options = opt.concat(response.Data);
					});
				}
			]
		},
		{
			key: "ModelId",
			type: "floatSelect",
			templateOptions: {
				label: "Model",
				resource: "form.model",
				options: [{
					Name: "..."
				}],
				class: "col-xs-4"
			},
			controller: [
				"$scope",
				function ($scope) {
					ProductsGeneralService.getModels().then(function (response) {
						$scope.to.options = response;
					});
				}
			]
		},
		{
			key: "TypeId",
			type: "floatSelect",
			templateOptions: {
				label: "Tip",
				resource: "form.type",
				options: [{
					Name: "..."
				}],
				class: "col-xs-4"
			},
			controller: [
				"$scope",
				function ($scope) {
					ProductsGeneralService.getTypes().then(function (response) {
						$scope.to.options = response;
					});
				}
			]
		},
		{
			key: "UnitMeasureId",
			type: "floatSelect",
			templateOptions: {
				label: "Birim",
				resource: "form.unit-measure",
				options: [{
					Name: "..."
				}],
				class: "col-xs-4"
			},
			controller: [
				"$scope",
				function ($scope) {
					ProductsGeneralService.getUnitMeasures().then(function (response) {
						$scope.to.options = response;
					});
				}
			]
		}
		];
		var n11Fields = [

			{
				key: "N11Transfer",
				type: "floatCheckbox",
				defaultValue: true,
				templateOptions: {
					label: "Aktif",
					resource: "form.n11-active",
					placeholder: "Aktif",
					class: "col-xs-3"
				}
			},
			{
				key: "N11Description",
				type: "floatTinyMCE",
				templateOptions: {
					label: "İçerik",
					placeholder: "İçerik",
					resource: "form.n11-description"
				}
			},
			{
				key: "n11Cost",
				type: "floatInput",
				templateOptions: {
					resource: "form.cost",
					label: "Maliyet",
					placeholder: "Maliyet ",
					numberMask: 2,
					class: "col-xs-2"
				}
			},
			{
				key: "n11Commision",
				type: "floatInput",
				templateOptions: {
					resource: "form.n11Commision",
					label: "N11 komisyon",
					placeholder: "N11 Komisyon",
					numberMask: 2,
					class: "col-xs-2"
				}
			},
			{
				key: 'n11CargoPriceType',
				type: 'floatSelect',
				templateOptions: {
					type: 'text',
					label: 'Kargo Ödeme Yöntemi',
					resource: 'form.n11cargopricetype',
					placeholder: 'Kargo Ödeme Yöntemi',
					class: 'col-md-3',
					options: [{
						Name: 'Alıcı Öder',
						Id: '1'
					},
					{
						Name: 'Satıcı Öder',
						Id: '2'
					}
					]
				}
			},
			{
				key: "n11CargoPrice",
				type: "floatInput",
				templateOptions: {
					resource: "form.n11CargoPrice",
					label: "N11 Kargo Fiyatı",
					placeholder: "N11 Kargo Fiyatı ",
					numberMask: 2,
					class: "col-xs-2"
				},
				hideExpression: 'model.n11CargoPriceType ==="1"',
			},
			{
				key: "N11Price",
				type: "floatInput",
				templateOptions: {
					resource: "form.n11Price",
					label: "N11 Fiyatı",
					placeholder: "N11 Fiyatı ",
					numberMask: 2,
					class: "col-xs-3"
				},
				controller: ['$scope', function ($scope) {
					var cost = 0.0;
					var n11Comm = 0.0;
					var n11CargoPrice = 0.0;
					var n11CargoType = 1;
					$scope.$watch('model.n11Cost', function (newValue, oldValue) {
						cost = newValue;
						if (n11CargoType == "2") {
							var result = (cost * n11Comm) + n11CargoPrice;
							$scope.model.n11Price = result;
						} else {
							var result = (cost * n11Comm);
							$scope.model.n11Price = result;
						}
					});


					$scope.$watch('model.n11Commision', function (newValue, oldValue) {
						n11Comm = newValue;
						if (n11CargoType == "2") {
							var result = (cost * n11Comm) + n11CargoPrice;
							$scope.model.n11Price = result;
						} else {
							var result = (cost * n11Comm);
							$scope.model.n11Price = result;
						}
					});


					$scope.$watch('model.n11CargoPrice', function (newValue, oldValue) {
						n11CargoPrice = newValue;
						if (n11CargoType == "2") {
							var result = (cost * n11Comm) + n11CargoPrice;
							$scope.model.n11Price = result;
						} else {
							var result = (cost * n11Comm);
							$scope.model.n11Price = result;
						}
					});

					$scope.$watch('model.n11CargoPriceType', function (newValue, oldValue) {
						n11CargoType = newValue;
						if (n11CargoType == "2") {
							var result = (cost * n11Comm) + n11CargoPrice;
							$scope.model.n11Price = result;
						} else {
							var result = (cost * n11Comm);
							$scope.model.n11Price = result;
						}
					});


				}],
			}
		];
		var ggFields = [{
			key: "GGTransfer",
			type: "floatCheckbox",
			defaultValue: true,
			templateOptions: {
				label: "Aktif",
				resource: "form.gg-active",
				placeholder: "Aktif",
				class: "col-xs-3"
			}
		},
		{
			key: "GGDescription",
			type: "floatTinyMCE",
			templateOptions: {
				label: "İçerik",
				placeholder: "İçerik",
				resource: "form.gg-description"
			}
		},
		{
			key: "ggCost",
			type: "floatInput",
			templateOptions: {
				resource: "form.cost",
				label: "Maliyet",
				placeholder: "Maliyet ",
				numberMask: 2,
				class: "col-xs-2"
			}
		},
		{
			key: "ggCommision",
			type: "floatInput",
			templateOptions: {
				resource: "form.ggCommision",
				label: "GittiGidiyor komisyon",
				placeholder: "GittiGidiyor Komisyon",
				numberMask: 2,
				class: "col-xs-2"
			}
		},
		{
			key: 'ggCargoPriceType',
			type: 'floatSelect',
			templateOptions: {
				type: 'text',
				label: 'Kargo Ödeme Yöntemi',
				resource: 'form.ggcargopricetype',
				placeholder: 'Kargo Ödeme Yöntemi',
				class: 'col-md-3',
				options: [{
					Name: 'Alıcı Öder',
					Id: '1'
				},
				{
					Name: 'Satıcı Öder',
					Id: '2'
				}
				]
			}
		},
		{
			key: "ggCargoPrice",
			type: "floatInput",
			templateOptions: {
				resource: "form.ggCargoPrice",
				label: "GittiGidiyor Kargo Fiyatı",
				placeholder: "GittiGidiyor Kargo Fiyatı ",
				numberMask: 2,
				class: "col-xs-2"
			},
			hideExpression: 'model.ggCargoPriceType ==="1"',
		},
		{
			key: "GGPrice",
			type: "floatInput",
			templateOptions: {
				resource: "form.ggPrice",
				label: "GittiGidiyor Fiyatı",
				placeholder: "GittiGidiyor Fiyatı",
				numberMask: 2,
				class: "col-xs-3"
			},
			controller: ['$scope', function ($scope) {
				var cost = 0.0;
				var ggComm = 0.0;
				var ggCargoPrice = 0.0;
				var ggCargoType = 1;
				$scope.$watch('model.ggCost', function (newValue, oldValue) {
					cost = newValue;
					if (ggCargoType == "2") {
						var result = (cost * ggComm) + ggCargoPrice;
						$scope.model.ggPrice = result;
					} else {
						var result = (cost * ggComm);
						$scope.model.ggPrice = result;
					}
				});


				$scope.$watch('model.ggCommision', function (newValue, oldValue) {
					ggComm = newValue;
					if (ggCargoType == "2") {
						var result = (cost * ggComm) + ggCargoPrice;
						$scope.model.ggPrice = result;
					} else {
						var result = (cost * ggComm);
						$scope.model.ggPrice = result;
					}
				});


				$scope.$watch('model.ggCargoPrice', function (newValue, oldValue) {
					ggCargoPrice = newValue;
					if (ggCargoType == "2") {
						var result = (cost * ggComm) + ggCargoPrice;
						$scope.model.ggPrice = result;
					} else {
						var result = (cost * ggComm);
						$scope.model.ggPrice = result;
					}
				});

				$scope.$watch('model.ggCargoPriceType', function (newValue, oldValue) {
					ggCargoType = newValue;
					if (ggCargoType == "2") {
						var result = (cost * ggComm) + ggCargoPrice;
						$scope.model.ggPrice = result;
					} else {
						var result = (cost * ggComm);
						$scope.model.ggPrice = result;
					}
				});


			}]
		}
		];
		var hbFields = [{
			key: "HBTransfer",
			type: "floatCheckbox",
			defaultValue: true,
			templateOptions: {
				label: "Aktif",
				resource: "form.hb-active",
				placeholder: "Aktif",
				class: "col-xs-3"
			}
		},
		{
			key: "hbShortDescription",
			type: "floatTextarea",
			templateOptions: {
				label: "Kısa Açıklama",
				placeholder: "Kısa Açıklama",
				resource: "form.hb-short-description",
				class: "col-xs-12"
			}
		},
		{
			key: "hbCost",
			type: "floatInput",
			templateOptions: {
				resource: "form.cost",
				label: "Maliyet",
				placeholder: "Maliyet ",
				numberMask: 2,
				class: "col-xs-2"
			}
		},
		{
			key: "hbCommision",
			type: "floatInput",
			templateOptions: {
				resource: "form.hbCommision",
				label: "Hepsiburada komisyon",
				placeholder: "Hepsiburada Komisyon",
				numberMask: 2,
				class: "col-xs-2"
			}
		},
		{
			key: 'hbCargoPriceType',
			type: 'floatSelect',
			templateOptions: {
				type: 'text',
				label: 'Kargo Ödeme Yöntemi',
				resource: 'form.hbcargopricetype',
				placeholder: 'Kargo Ödeme Yöntemi',
				class: 'col-md-3',
				options: [{
					Name: 'Alıcı Öder',
					Id: '1'
				},
				{
					Name: 'Satıcı Öder',
					Id: '2'
				}
				]
			}
		},
		{
			key: "hbCargoPrice",
			type: "floatInput",
			templateOptions: {
				resource: "form.hbCargoPrice",
				label: "Hepsiburada Kargo Fiyatı",
				placeholder: "Hepsiburada Kargo Fiyatı ",
				numberMask: 2,
				class: "col-xs-2"
			},
			hideExpression: 'model.hbCargoPriceType ==="1"',
		},
		{
			key: "hbPrice",
			type: "floatInput",
			templateOptions: {
				resource: "form.hbPrice",
				label: "Hepsiburada Fiyatı",
				placeholder: "Hepsiburada Fiyatı",
				numberMask: 2,
				class: "col-xs-3"
			},
			controller: ['$scope', function ($scope) {
				var cost = 0.0;
				var hbComm = 0.0;
				var hbCargoPrice = 0.0;
				var hbCargoType = 1;
				$scope.$watch('model.hbCost', function (newValue, oldValue) {
					cost = newValue;
					if (hbCargoType == "2") {
						var result = (cost * hbComm) + hbCargoPrice;
						$scope.model.hbPrice = result;
					} else {
						var result = (cost * hbComm);
						$scope.model.hbPrice = result;
					}
				});


				$scope.$watch('model.hbCommision', function (newValue, oldValue) {
					hbComm = newValue;
					if (hbCargoType == "2") {
						var result = (cost * hbComm) + hbCargoPrice;
						$scope.model.hbPrice = result;
					} else {
						var result = (cost * hbComm);
						$scope.model.hbPrice = result;
					}
				});


				$scope.$watch('model.hbCargoPrice', function (newValue, oldValue) {
					hbCargoPrice = newValue;
					if (hbCargoType == "2") {
						var result = (cost * hbComm) + hbCargoPrice;
						$scope.model.hbPrice = result;
					} else {
						var result = (cost * hbComm);
						$scope.model.hbPrice = result;
					}
				});

				$scope.$watch('model.hbCargoPriceType', function (newValue, oldValue) {
					hbCargoType = newValue;
					if (hbCargoType == "2") {
						var result = (cost * hbComm) + hbCargoPrice;
						$scope.model.hbPrice = result;
					} else {
						var result = (cost * hbComm);
						$scope.model.hbPrice = result;
					}
				});


			}],
		},
		{
			key: "HBSKU",
			type: "floatInput",
			templateOptions: {
				type: "text",
				placeholder: "Hepsiburada SKU",
				resource: "form.hb-SKU",
				label: "Hepsiburada SKU",
				class: "col-xs-4"
			}
		}
		];
		var trFields = [{
			key: "TrendyolTransfer",
			type: "floatCheckbox",
			defaultValue: true,
			templateOptions: {
				label: "Aktif",
				resource: "form.tr-active",
				placeholder: "Aktif",
				class: "col-xs-3"
			}
		},
		{
			key: "TRDescription",
			type: "floatTinyMCE",
			templateOptions: {
				label: "İçerik",
				placeholder: "İçerik",
				resource: "form.tr-description"
			}
		},
		{
			key: "trCost",
			type: "floatInput",
			templateOptions: {
				resource: "form.cost",
				label: "Maliyet",
				placeholder: "Maliyet ",
				numberMask: 2,
				class: "col-xs-2"
			}
		},
		{
			key: "trCommision",
			type: "floatInput",
			templateOptions: {
				resource: "form.trCommision",
				label: "Trendyol komisyon",
				placeholder: "Trendyol Komisyon",
				numberMask: 2,
				class: "col-xs-2"
			}
		},
		{
			key: 'trCargoPriceType',
			type: 'floatSelect',
			templateOptions: {
				type: 'text',
				label: 'Kargo Ödeme Yöntemi',
				resource: 'form.trcargopricetype',
				placeholder: 'Kargo Ödeme Yöntemi',
				class: 'col-md-3',
				options: [{
					Name: 'Alıcı Öder',
					Id: '1'
				},
				{
					Name: 'Satıcı Öder',
					Id: '2'
				}
				]
			}
		},
		{
			key: "trCargoPrice",
			type: "floatInput",
			templateOptions: {
				resource: "form.trCargoPrice",
				label: "Trendyol Kargo Fiyatı",
				placeholder: "Trendyol Kargo Fiyatı ",
				numberMask: 2,
				class: "col-xs-2"
			},
			hideExpression: 'model.trCargoPriceType ==="1"',
		},
		{
			key: "TrendyolPrice",
			type: "floatInput",
			templateOptions: {
				resource: "form.trPrice",
				label: "Trendyol Fiyatı",
				placeholder: "Trendyol Fiyatı",
				numberMask: 2,
				class: "col-xs-3"
			},
			controller: ['$scope', function ($scope) {
				var cost = 0.0;
				var trComm = 0.0;
				var trCargoPrice = 0.0;
				var trCargoType = 1;
				$scope.$watch('model.trCost', function (newValue, oldValue) {
					cost = newValue;
					if (trCargoType == "2") {
						var result = (cost * trComm) + trCargoPrice;
						$scope.model.trPrice = result;
					} else {
						var result = (cost * trComm);
						$scope.model.trPrice = result;
					}
				});


				$scope.$watch('model.trCommision', function (newValue, oldValue) {
					trComm = newValue;
					if (trCargoType == "2") {
						var result = (cost * trComm) + trCargoPrice;
						$scope.model.trPrice = result;
					} else {
						var result = (cost * trComm);
						$scope.model.trPrice = result;
					}
				});


				$scope.$watch('model.trCargoPrice', function (newValue, oldValue) {
					trCargoPrice = newValue;
					if (trCargoType == "2") {
						var result = (cost * trComm) + trCargoPrice;
						$scope.model.trPrice = result;
					} else {
						var result = (cost * trComm);
						$scope.model.trPrice = result;
					}
				});

				$scope.$watch('model.trCargoPriceType', function (newValue, oldValue) {
					trCargoType = newValue;
					if (trCargoType == "2") {
						var result = (cost * trComm) + trCargoPrice;
						$scope.model.trPrice = result;
					} else {
						var result = (cost * trComm);
						$scope.model.trPrice = result;
					}
				});


			}],
		}
		];
		var seoFields = [{
			key: "MetaKeyword",
			type: "floatInput",
			templateOptions: {
				type: "text",
				resource: "form.meta-keyword",
				label: "Seo keywords"
			}
		},
		{
			key: "MetaDescription",
			type: "floatTextarea",
			templateOptions: {
				label: "Seo description",
				resource: "form.meta-description",
				maxlength: 160
			}
		},
		{
			key: "MetaTitle",
			type: "floatInput",
			templateOptions: {
				label: "Seo title",
				resource: "form.meta-title",
				type: "text"
			}
		},
		{
			key: "NormalizedName",
			type: "floatInput",
			templateOptions: {
				label: "Arama Motoru Sayfa Adı",
				resource: "form.normalized-name",
				type: "text"
			}
		}
		];
		var attrFields = [{
			key: "OptionId",
			type: "floatSelect",
			templateOptions: {
				label: "Özellik",
				resource: "form.option",
				options: [{
					Name: "..."
				}],
				myCustomValue: true
			},
			controller: [
				"$scope",
				function ($scope) {
					ProductsGeneralService.getOptions().then(function (response) {
						$scope.to.options = response;
					});
				}
			]
		},
		{
			key: "OptionValueId",
			type: "floatSelect",
			templateOptions: {
				label: "Değer",
				resource: "form.option-value",
				labelProp: "Value",
				options: [{
					Value: "Önce Özellik Seçmelisiniz..."
				}]
			},
			controller: [
				"$scope",
				function ($scope) {
					$scope.$watch("model.OptionId", function (newValue, oldValue) {
						if (newValue) {
							OptionValuesService.getOptionValue(newValue).then(function (response) {
								$scope.to.options = response;
							});
						}
					});
				}
			]
		},
		{
			key: 'SaveSubOptions',
			type: 'floatCheckbox',
			templateOptions: {
				label: 'Alt Özellikleri Kaydet',
				resource: 'form.save-sub-options',
				placeholder: 'Alt Özellikleri Kaydet'
			}
		}
		];

		return service;

		/////////////////////////////////////////

		function getProductFields() {
			return productFields;
		}

		function getProductOtherFields() {
			return productOtherFields;
		}

		function getSeoFields() {
			return seoFields;
		}

		function getAttrFields() {
			return attrFields;
		}

		function getN11Fields() {
			return n11Fields;
		}

		function getGGFields() {
			return ggFields;
		}

		function getHBFields() {
			return hbFields;
		}

		function getTRFields() {
			return trFields;
		}

		function getProducts(filterParams) {
			var deferred = $q.defer();

			$http
				.get(serviceBase + "api/Products/", {
					params: filterParams
				})
				.then(getProductsComplete)
				.catch(getProductsFailed);

			function getProductsComplete(response) {
				deferred.resolve(response.data);
			}

			function getProductsFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}


		function getMatchCategory(filterParams) {
			var deferred = $q.defer();
			$http
				.get(serviceBase + "api/Categories/GetMatchCategory/" + filterParams)
				.then(getProductsComplete)
				.catch(getProductsFailed);

			function getProductsComplete(response) {
				deferred.resolve(response.data);
			}

			function getProductsFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}


		function getProduct(Id) {
			var deferred = $q.defer();

			$http
				.get(serviceBase + "api/Products/" + Id)
				.then(getProductComplete)
				.catch(getProductFailed);

			function getProductComplete(response) {
				deferred.resolve(response.data);
			}

			function getProductFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}

		function addProduct(product) {
			var deferred = $q.defer();

			$http
				.post(serviceBase + "api/Products", product)
				.then(addComplete)
				.catch(addFailed);

			function addComplete(response) {
				deferred.resolve(response.data);
			}

			function addFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}

		function editProduct(entity) {
			var deferred = $q.defer();

			$http
				.put(serviceBase + "api/Products/" + entity.Id, entity)
				.then(editComplete)
				.catch(editFailed);

			function editComplete(response) {
				deferred.resolve(response.data);
			}

			function editFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}

		function deleteProduct(entity) {
			var deferred = $q.defer();

			$http
				.delete(serviceBase + "api/Products/" + entity.Id, entity)
				.then(deleteComplete)
				.catch(deleteFailed);

			function deleteComplete(response) {
				deferred.resolve(response.data);
			}

			function deleteFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}

		function getProductVariants(Id) {
			var deferred = $q.defer();

			$http
				.get(serviceBase + "api/Products/" + Id + "/Variant")
				.then(getProductComplete)
				.catch(getProductFailed);

			function getProductComplete(response) {
				deferred.resolve(response.data);
			}

			function getProductFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}

		function getAllProductMedia(Id) {
			var deferred = $q.defer();

			$http
				.get(serviceBase + "api/Products/" + Id + "/Media")
				.then(getProductComplete)
				.catch(getProductFailed);

			function getProductComplete(response) {
				deferred.resolve(response.data);
			}

			function getProductFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}

		function removeProductMedia(productId, MediaId) {
			var deferred = $q.defer();

			$http
				.delete(serviceBase + "api/Products/" + productId + "/Media/" + MediaId)
				.then(removeProductMediaComplete)
				.catch(removeProductMediaFailed);

			function removeProductMediaComplete(response) {
				deferred.resolve(response.data);
			}

			function removeProductMediaFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}

		function regenerateAllVariants(Id) {
			var deferred = $q.defer();

			$http
				.get(serviceBase + "api/Products/" + Id + "/Generate")
				.then(regenerateAllVariantsComplete)
				.catch(regenerateAllVariantsFailed);

			function regenerateAllVariantsComplete(response) {
				deferred.resolve(response.data);
			}

			function regenerateAllVariantsFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}

		function addVariantProduct(Id, ProductOptionValueList) {
			var deferred = $q.defer();

			$http
				.post(serviceBase + "api/Products/" + Id + "/AddVariantProduct", ProductOptionValueList)
				.then(complete)
				.catch(failed);

			function complete(response) {
				deferred.resolve(response.data);
			}

			function failed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}

		function activeProduct(Id) {
			var deferred = $q.defer();

			$http
				.put(serviceBase + "api/Products/Active/" + Id)
				.then(addComplete)
				.catch(addFailed);

			function addComplete(response) {
				deferred.resolve(response.data);
			}

			function addFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}

		function passiveProduct(Id) {
			var deferred = $q.defer();
			$http
				.put(serviceBase + "api/Products/Passive/" + Id)
				.then(addComplete)
				.catch(addFailed);

			function addComplete(response) {
				deferred.resolve(response.data);
			}

			function addFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}

		function copyToCulture(Id, CultureId) {
			var deferred = $q.defer();

			$http
				.put(serviceBase + "api/Products/CopyToCulture/" + Id + "/" + CultureId)
				.then(complete)
				.catch(failed);

			function complete(response) {
				deferred.resolve(response.data);
			}

			function failed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}

		function copy(Id) {
			var deferred = $q.defer();

			$http
				.put(serviceBase + "api/Products/Copy/" + Id)
				.then(complete)
				.catch(failed);

			function complete(response) {
				deferred.resolve(response.data);
			}

			function failed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}

		function prepareAllProducts(onlyEmpty) {
			var deferred = $q.defer();
			var url = serviceBase + "api/Products/PrepareAllProduct";
			if (onlyEmpty) {
				url += "?onlyEmpty=true";
			}
			$http
				.put(url)
				.then(complete)
				.catch(failed);

			function complete(response) {
				deferred.resolve(response.data);
			}

			function failed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}

		function ProductCombining(ProductList) {

			var deferred = $q.defer();

			$http.post(serviceBase + "api/Products/Combining", JSON.stringify(ProductList))
				.then(addComplete)
				.catch(addFailed);

			function addComplete(response) {
				deferred.resolve(response.data);
			}

			function addFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}

	}
})();