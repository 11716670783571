(function () {
    'use strict';

    angular
        .module('app.subscriptions')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'subscriptions',
                config: {
                    url: '/subscriptions',
                    title: 'Abonelikler',
                    abstract: true,
                    templateUrl: 'app/subscriptions/subscriptions.html'
                }
            },
            {
                state: 'subscriptions.list',
                config: {
                    templateUrl: 'app/subscriptions/subscriptions.list.html',
                    controller: 'SubscriptionsController',
                    controllerAs: 'vm',
                    url: '/subscriptions',
                    title: 'Abonelikler',
                    settings: {
                        nav: 4,
                        name: 'subscriptions.list',
                        topName: 'system',
                        content: 'Abonelikler',
                        resource: 'menu.subscriptions'
                    }
                }
            }
        ];
    }
})();