(function () {
    'use strict';

    angular
        .module('app.categories')
        .controller('CategoriesAddController', categories);

    categories.$inject = ['$location', 'CategoriesService'];

    function categories($location, CategoriesService) {
        /* jshint validthis:true */
        var vm = this;

        vm.title = '';

        vm.entity = {};

        vm.entityFields = CategoriesService.getCategoryFields();

        vm.create = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                vm.entity.CategoryMedia = null;
                vm.entity.Options = vm.entity.Options || [];
                vm.entity.CategoryOption = vm.entity.Options.map(function (item) {
                    return { OptionId: item };
                });
                vm.entity.Options = null;
                CategoriesService.addCategory(vm.entity).then(function (data) {
                    vm.loading = false;
                    $location.path('/categories/detail/' + data.Id);
                });
            }
        };
    }
})();