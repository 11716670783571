(function() {
    "use strict";

    angular.module("app.dashboard").component("newCustomer", {
        templateUrl: "app/dashboard/dashboard.newcustomer.html",
        controller: NewCustomer
    });

    NewCustomer.$inject = ["$scope", "CustomersService"];

    function NewCustomer($scope, CustomersService) {
        /*jshint validthis: true */
        var vm = this;

        vm.loading = true;

        CustomersService.getCustomers({ PageSize: 10, PageIndex: 1, SortProperty: "InsertDate", SortType: "desc" }).then(
            function(data) {
                vm.newCustomerList = data.Data;
                vm.newCustomerCount = data.FilteredCount;
                vm.loading = false;
            }
        );

        CustomersService.getCustomers({
            PageSize: -1,
            SortProperty: "InsertDate",
            SortType: "desc",
            IsApproved: false
        }).then(function(data) {
            vm.pendingCustomerList = data.Data;
            vm.pendingCustomerCount = data.FilteredCount;
            vm.loading = false;
        });
    }
})();
