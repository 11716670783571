(function () {
    'use strict';

    angular
        .module('app.settings')
        .factory('SettingsService', Service);

    Service.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'SharedData', '$timeout', 'Settings'];

    function Service($http, $q, ngAuthSettings, logger, SharedData, $timeout, Settings) {
        var service = {
            getAll: getAll,
            getInvoiceAll: getInvoiceAll,
            get: get,
            edit: edit,
            getSettings: getSettings,
            getAddressSettings: getAddressSettings,
            refreshSettingsValue: refreshSettingsValue,
            getIntegrationSettings: getIntegrationSettings,
            resreshIntegrationSettingsValue: resreshIntegrationSettingsValue,
            getAllProviders: getAllProviders,
            getEInvoiceSettings: getEInvoiceSettings,
            editInvoiceProvider: editInvoiceProvider,
            editInvoiceGlobalSett: editInvoiceGlobalSett,
            addImageByFileManager: addImageByFileManager,
            getSettingAddImageFields: getSettingAddImageFields,
            saveXsltConfig: saveXsltConfig,
            getEInvoiceTemplates: getEInvoiceTemplates,
            saveXsltDefault: saveXsltDefault,
            getDefaultProvider: getDefaultProvider

        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri;
        var endpoint = serviceBase + 'api/setting/';
        var settings = null;

        var settingAddImageFields = [
            {
                key: "File",
                type: "horizontalSingleMedia",
                templateOptions: {
                    label: "Fotoğraf Yükle",
                    placeholder: "Fotoğraf Yükle",
                    valueKey: "Id"
                }
            }
        ];


        return service;

        ///////////////////////////////

        function getAll(filterParams) {
            var deferred = $q.defer();
            $http.get(endpoint + "all", {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getInvoiceAll(filterParams) {
            var deferred = $q.defer();
            $http.get(endpoint + "all", {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {

                function getVeriban (response){

                    const dataReverse = response.data.Data.reverse();
                    var veribanArr = [];

                    dataReverse.map(x => {
                        
                        let veriban = x.Key.includes('Veriban');
                        let QNB = x.Key.includes('Qnb');
                        let global = x.Key.includes('Globalnvoice');
                        if (veriban || QNB || global) {
                            
                            veribanArr.push(x);
                            return x;
                        }
                        else {
                            return;
                        }

                    });

                    return veribanArr;
                }

                deferred.resolve(getVeriban(response));
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getAllProviders() {

            var deferred = $q.defer();
                $http.get(endpoint + "getAllProviders")
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getDefaultProvider() {

            var deferred = $q.defer();
            $http.get(endpoint + "getDefaultProvider")
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(id) {
            var deferred = $q.defer();

            $http.get(endpoint + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                logger.error('Failed in addRole() function' + error.data);
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(customer) {
            var deferred = $q.defer();

            $http.put(endpoint + customer.Id, customer)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function editInvoiceProvider(invoice) {
            var deferred = $q.defer();

            $http.put(endpoint + "einvoiceprovider", invoice)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function editInvoiceGlobalSett(invoice){

            var deferred = $q.defer();

            $http.put(endpoint + "einvoiceglobal", invoice)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getSettings() {
            var deferred = $q.defer();

            if (settings) {
                deferred.resolve(settings);
            } else {
                $http.get(serviceBase + 'api/setting')
                    .then(getSettingsComplete)
                    .catch(getSettingsFailed);
            }

            function getSettingsComplete(response) {
                settings = response;
                deferred.resolve(response.data);
            }

            function getSettingsFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getAddressSettings() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/setting/addresssettings')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getIntegrationSettings() {
            var deferred = $q.defer();
            if (settings) {
                deferred.resolve(settings);
            } else {
                $http.get(serviceBase + 'api/setting/IntegrationSettings')
                    .then(getSettingsComplete)
                    .catch(getSettingsFailed);
            }

            function getSettingsComplete(response) {
                settings = response;
                deferred.resolve(response.data);
            }

            function getSettingsFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getEInvoiceSettings() {
            var deferred = $q.defer();

                $http.get(serviceBase + 'api/setting/EInvoiceSettings')
                    .then(getSettingsComplete)
                    .catch(getSettingsFailed);
            

            function getSettingsComplete(response) {
                settings = response;
                deferred.resolve(response.data);
            }

            function getSettingsFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getEInvoiceTemplates() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/setting/EInvoiceTemplates')
                .then(getSettingsComplete)
                .catch(getSettingsFailed);


            function getSettingsComplete(response) {
                settings = response;
                deferred.resolve(response.data);
            }

            function getSettingsFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function resreshIntegrationSettingsValue() {
            getIntegrationSettings()
                .then(function (integrationsettings) {
                    SharedData.IntegrationSettings = integrationsettings;
                    var dataa = integrationsettings;

                    var keys = Object.keys(dataa);
                    for (var i = 0; i < keys.length; i++) {
                        SharedData.IntegrationSettings[keys[i]] = integrationsettings[keys[i]];
                    }
                });
        }

        function refreshSettingsValue() {
            getSettings()
                .then(function (settings) {
                    SharedData.Settings = settings;
                    var dataa = settings;
                    var keys = Object.keys(dataa);
                    for (var i = 0; i < keys.length; i++) {
                        Settings[keys[i]] = settings[keys[i]];
                    }
                });
        }

        function addImageByFileManager() {
            var deferred = $q.defer();

            $http.post(endpoint + 'UploadImageByFileManager')
                .then(addImageByFileManagerComplete)
                .catch(addImageByFileManagerFailed);

            function addImageByFileManagerComplete(response) {
                deferred.resolve(response.data);
            }

            function addImageByFileManagerFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function saveXsltConfig(json) {
            var deferred = $q.defer();

            $http.post(endpoint + 'xsltconfig', JSON.stringify(json))
                .then(saveXsltConfigComplete)
                .catch(saveXsltConfigFailed);

            function saveXsltConfigComplete(response) {
                deferred.resolve(response.data);
            }

            function saveXsltConfigFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function saveXsltDefault(data) {
            var deferred = $q.defer();

            $http.post(endpoint + 'xsltdefault', data)
                .then(saveXsltConfigComplete)
                .catch(saveXsltConfigFailed);

            function saveXsltConfigComplete(response) {
                deferred.resolve(response.data);
            }

            function saveXsltConfigFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }


        function getSettingAddImageFields() {
            return settingAddImageFields;
        }

    }
})();