(function () {
    'use strict';

    angular
        .module('app.markettemplates')
        .controller('ShipmentTemplateListController', ShipmentTemplateListController);

    ShipmentTemplateListController.$inject = ['$location', 'MarketTemplatesService', 'NgTableParams'];

    function ShipmentTemplateListController($location, MarketTemplatesService, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;

        vm.settings = globalSettings;
        activate();

        function activate() {
            vm.firstLoad = true;
            vm.tableParamsGetShipment = new NgTableParams(
                angular.extend({ page: 1, count: 10 }, $location.search()),
                {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {};
                        filterData.PageIndex = params.page(),
                            filterData.PageSize = params.count(),
                            filterData.SortProperty = Object.keys(params.sorting())[0],
                            filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return MarketTemplatesService.getAllTemplate(filterData).then(function (data) {
                            params.total(data.FilteredCount);
                            return data.Data;
                        });
                    }
                });

            vm.fields = MarketTemplatesService.getShipmentTemplateFields();
        }

        vm.RefreshList = function () {
            MarketTemplatesService.refreshList().then(function (data) {
                vm.loading = false;

            },
                function (data) {
                    vm.loading = false;
                });
        }

        vm.delete = function (entity) {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                MarketTemplatesService.delShipmentTemplate(entity).then(function (data) {
                    vm.tableParamsGetShipment.reload();
                });
            }
        };
    }
})();