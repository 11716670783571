(function () {
    "use strict";

    angular
        .module("app.stockItems")
        .factory("StockItemsService", Service);

    Service.$inject = [
        "$http",
        "$q",
        "ngAuthSettings",
        "ProductsService",
        "WarehousesService",
        "WarehouseLocationsService"
    ];

    function Service(
        $http,
        $q,
        ngAuthSettings,
        ProductsService,
        WarehousesService,
        WarehouseLocationsService
    ) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            getFields: getFields,
            getStockItemStatus: getStockItemStatus,
            calculateStockItemAverage: calculateStockItemAverage,
            paymentStockItem: paymentStockItem
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + "api/stockItems/";

        var fields = [{
                key: "Code",
                type: "floatInput",
                templateOptions: {
                    resource: "form.code",
                    label: "Kodu",
                    placeholder: "Kodu ",
                    class: "col-xs-6"
                }
            },
            {
                key: "Cost",
                type: "floatInput",
                templateOptions: {
                    resource: "form.cost",
                    label: "Maliyet",
                    placeholder: "Maliyet ",
                    numberMask: 2,
                    class: "col-xs-6"
                }
            },
            {
                key: "RecommendedPrice",
                type: "floatInput",
                templateOptions: {
                    resource: "form.recommended-price",
                    label: "Tavsiye Edilen Fiyat",
                    placeholder: "Tavsiye Edilen Fiyat ",
                    numberMask: 2,
                    class: "col-xs-6"
                }
            },
            {
                key: "Quantity",
                type: "floatInput",
                templateOptions: {
                    resource: "form.quantity",
                    label: "Miktar",
                    placeholder: "Miktar",
                    numberMask: 2,
                    class: "col-xs-6"
                }
            },
            {
                key: 'ProductId',
                type: 'float-async-ui-select',
                templateOptions: {
                    required: true,
                    label: 'Ürün',
                    resource: 'form.product',
                    options: [],
                    placeholder: '...',
                    valueProp: 'Id',
                    labelProp: 'Name',
                    refresh: function (text, options, model) {
                        var params = {
                            Name: text
                        };
                        if (model.ProductId) {
                            params.Id = model.ProductId
                        }
                        return ProductsService.getProducts(params)
                            .then(function (response) {
                                if (response.Data.length > 0) {
                                    options.templateOptions.options = response.Data;
                                } else {
                                    options.templateOptions.options = [{
                                        Id: '',
                                        Name: 'Bulunamadı (' + text + ')'
                                    }];
                                }
                            });
                    },
                    refreshDelay: 2
                }
            },
            {
                key: "WarehouseId",
                type: "floatSelect",
                templateOptions: {
                    label: "Depo ",
                    options: [{
                        Name: "Yükleniyor..."
                    }],
                    class: "col-xs-6"
                },
                controller: [
                    "$scope",
                    function ($scope) {
                        WarehousesService.getAll({
                            PageSize: -1
                        }).then(function (response) {
                            $scope.to.options = response.Data;
                        });
                    }
                ]
            },
            {
                key: "WarehouseLocationId",
                type: "floatSelect",
                templateOptions: {
                    label: "Depo Lokasyonu",
                    options: [{
                        Name: "Yükleniyor..."
                    }],
                    class: "col-xs-6"
                },
                controller: [
                    "$scope",
                    function ($scope) {
                        $scope.$watch("model.WarehouseId", function (newValue, oldValue) {
                            if (newValue) {
                                if ($scope.model.WarehouseId) {
                                    WarehouseLocationsService.getAll({
                                        PageSize: -1,
                                        WarehouseId: newValue
                                    }).then(function (response) {
                                        $scope.to.options = response.Data;
                                    });
                                } else {
                                    WarehouseLocationsService.getAll({
                                        PageSize: -1
                                    }).then(function (response) {
                                        $scope.to.options = response.Data;
                                    });
                                }
                            }
                        });
                    }
                ]
            },
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();

            $http
                .get(serviceBase, {
                    params: filterParams
                })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(Id) {
            var deferred = $q.defer();

            $http
                .get(serviceBase + Id)
                .then(getProductComplete)
                .catch(getProductFailed);

            function getProductComplete(response) {
                deferred.resolve(response.data);
            }

            function getProductFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();

            $http
                .post(serviceBase, entity)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http
                .put(serviceBase + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(entity) {
            var deferred = $q.defer();

            $http
                .delete(serviceBase + entity.Id, entity)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getStockItemStatus(order) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'StockItemStatus')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function calculateStockItemAverage(productId) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'StockItemAverage/' + productId)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function paymentStockItem(selectedStockItems) {
            var deferred = $q.defer();

            $http
                .put(serviceBase + "Payment", selectedStockItems)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();