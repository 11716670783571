(function () {
    'use strict';

    angular
        .module('app.stockItems')
        .controller('StockItemsListController', ListController);

    ListController.$inject = [
        '$location',
        'StockItemsService',
        'NgTableParams',
        'WarehousesService',
        'VendorsService',
        'ProductsService',
        'CategoriesService',
        'ProductsGeneralService'
    ];

    function ListController(
        $location,
        Service,
        NgTableParams,
        WarehousesService,
        VendorsService,
        ProductsService,
        CategoriesService,
        ProductsGeneralService) {
        /* jshint validthis:true */
        var vm = this;
        vm.showFilter = true;
        vm.filterModel = {};

        activate();

        function activate() {

            vm.refresh = function () {
                vm.loading = true;
                vm.tableParams.reload();
                vm.loading = false;
            };

            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({
                        page: 1,
                        count: 10,
                        sorting: {
                            InsertDate: "desc"
                        }
                    },
                    $location.search()), {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {};
                        filterData.PageIndex = params.page();
                        filterData.PageSize = params.count();
                        filterData.SortProperty = Object.keys(params.sorting())[0];
                        filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        var filterModelKeys = Object.keys(vm.filterModel);
                        for (var i = 0; i < filterModelKeys.length; i++) {
                            filterData[filterModelKeys[i]] = vm.filterModel[filterModelKeys[i]];
                        }
                        return Service.getAll(filterData).then(function (data) {
                            params.total(data.FilteredCount);
                            return data.Data;
                        });
                    }
                });
        }

        ProductsGeneralService.getCategories()
            .then(function (data) {
                vm.Categories = getTreeData(data, null);
            });

        function getTreeData(options, topProp) {
            var data = options.filter(obj => obj["TopCategoryId"] === topProp);
            if (data.length <= 0) {
                return [];
            }
            return data
                .map(obj => {
                    var rObj = {};
                    rObj.id = obj["Id"];
                    rObj.name = obj["Name"];
                    rObj.children = getTreeData(options, obj.Id);
                    return rObj;
                });
        }

        Service.getStockItemStatus()
            .then(function (data) {
                vm.StockItemStatus = data;
            });

        vm.Vendors = VendorsService.getAll().then(function (data) {
            return [{
                id: '',
                title: 'Seçiniz...'
            }].concat(data.Data.map(function (item) {
                return {
                    id: item.Id,
                    title: item.Name
                };
            }));
        });

        WarehousesService.getAll()
            .then(function (data) {
                vm.Warehouses = data.Data;
            });

        vm.SalesOrderCodeFilter = {
            IsAddedSalesOrder: {
                id: 'ng-table/headers/select-is-added-order.html',
                placeholder: 'Minimum Fiyat'
            },
            SalesOrderCode: {
                id: 'text',
                placeholder: 'Sipariş Kodu'
            }
        };
        vm.ProductFilter = {
            CategoryId: {
                id: 'ng-table/headers/filterCategory.html',
                placeholder: 'Kategori Ara...'
            },
            ProductId: {
                id: 'ng-table/headers/filterProduct.html',
                placeholder: 'Ürün Ara...'
            }
        };
        vm.filteredProductList = [];
        vm.filteredCategoryList = [];
        vm.refreshFilterProduct = function (search) {
            var params = {
                SearchKey: search,
                PageSize: 18,
                PageIndex: 1,
                SortProperty: 'Name',
                SortType: 'asc',
                SelectFields: 'Id,Name'
            };
            vm.filteredProductList = [{
                Id: '',
                Name: 'Yükleniyor...'
            }];
            return ProductsService.getProducts(params)
                .then(function (response) {
                    if (response.Data.length > 0) {
                        vm.filteredProductList = response.Data;
                    } else {
                        vm.filteredProductList = [{
                            Id: '',
                            Name: 'Bulunamadı (' + text + ')'
                        }];
                    }
                });
        }
        vm.refreshFilterCategory = function (search) {
            var params = {
                Name: search,
                PageSize: 18,
                PageIndex: 1,
                SortProperty: 'Name',
                SortType: 'asc'
            };
            vm.filteredCategoryList = [{
                Id: '',
                Name: 'Yükleniyor...'
            }];
            return CategoriesService.getCategories(params)
                .then(function (response) {
                    if (response.Data.length > 0) {
                        vm.filteredCategoryList = response.Data;
                    } else {
                        vm.filteredCategoryList = [{
                            Id: '',
                            Name: 'Bulunamadı (' + text + ')'
                        }];
                    }
                });
        }
    }
})();