(function () {
    'use strict';

    angular
        .module('app.excel')
        .controller('ExcelStockExportController', ExcelStockExportController);

    ExcelStockExportController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'ExcelService'];

    function ExcelStockExportController($scope, $stateParams, $filter, $location, logger, ExcelService) {
        var vm = this;

        activate();

        function activate() {
            vm.excelStockExport = {};
        }

        vm.excelStockExportFields = ExcelService.getExcelStockExportFields();
        vm.excelStockFilterFields = ExcelService.getExcelStockFilterFields();


        vm.exportStockExcel = function () {
            console.log("vm.exportStockExcel", vm.exportStockExcel);
            vm.loading = true;
            ExcelService.exportStockFile(vm.excelStockExport).then(function (result, a, b, c) {
                console.log('excel result', result, a, b, c);
                vm.loading = false;
            });
        };

        vm.exportStock = function () {
            console.log("vm.exportStockExcel", vm.exportStockExcel);
            vm.loading = true;
            setTimeout(function () {
                $("#exportStockForm").submit();
            }, 1000);
            vm.loading = false;
        };

    }
})();

