(function () {
    'use strict';

    angular
        .module('app.customers')
        .factory('CustomerAddressService', CustomersService);

    CustomersService.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config', 'GeneralService', 'CustomerGeneralService', 'SettingsService', '$timeout'];

    function CustomersService($http, $q, ngAuthSettings, logger, config, GeneralService, CustomerGeneralService, SettingsService, $timeout) {
        var service = {
            addCustomerAddress: addCustomerAddress,
            addLocation: addLocation,
            editCustomerAddress: editCustomerAddress,
            deleteCustomerAddress: deleteCustomerAddress,
            getCustomerAddresses: getCustomerAddresses,
            getCustomerAddress: getCustomerAddress,
            getCustomerAddressFields: getCustomerAddressFields,            
            prepareAllAddress: prepareAllAddress,
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri;

        var customerAddressFields = [
            {
                key: 'Name',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    resource: 'form.address-title',
                    label: 'Adres Başlığı'
                },
                controller: ['$scope', function ($scope) {
                    setTimeout(function () {
                        SettingsService.getAddressSettings()
                            .then(data => {
                                $scope.model.AddressSettings = data;
                            });
                    }, 1000);
                }]
            },
            {
                key: "Phone1",
                type: "floatPhone",
                templateOptions: {
                    label: 'Telefon',
                    resource: 'form.phone'
                },
                hideExpression: '!model.AddressSettings.IsPhone1Enabled',
                expressionProperties: { 'templateOptions.required': 'model.AddressSettings.IsPhone1Required' }
            },
            {
                key: "Phone2",
                type: "floatPhone",
                templateOptions: {
                    resource: 'form.phone2',
                    label: 'Telefon 2'
                },
                hideExpression: '!model.AddressSettings.IsPhone2Enabled',
                expressionProperties: { 'templateOptions.required': 'model.AddressSettings.IsPhone2Required' }
            },
            {
                key: "FaxNumber",
                type: "floatPhone",
                templateOptions: {
                    resource: 'form.fax-number',
                    label: 'Fax'
                },
                hideExpression: '!model.AddressSettings.IsFaxNumberEnabled',
                expressionProperties: { 'templateOptions.required': 'model.AddressSettings.IsPhone1Required' }
            },
            {
                key: 'CountryId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Ülke',
                    resource: 'form.country',
                    options: [{ name: 'Seçiniz...' }]
                },
                controller: ['$scope', function ($scope) {
                    GeneralService.getCountry().then(function (data) {
                        $scope.to.options = data.sort(function (a, b) {
                            return a.Name.localeCompare(b.Name);
                        });
                    });
                }],
                hideExpression: 'model.AddressSettings.CountryType !== 1',
                expressionProperties: { 'templateOptions.required': 'model.AddressSettings.IsCountryRequired' }
            },
            {
                key: "CountryName",
                type: "floatInput",
                templateOptions: {
                    resource: 'form.country',
                    label: 'Ülke'
                },
                hideExpression: 'model.AddressSettings.CountryType !== 2',
                expressionProperties: { 'templateOptions.required': 'model.AddressSettings.IsCountryRequired' }
            },
            {
                key: 'StateProvinceId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'İl',
                    resource: 'form.state-province',
                    required: true,
                    options: [{ Name: 'Önce Ülke Seçiniz...' }]
                },
                controller: ['$scope', function ($scope) {
                    $scope.$watch('model.CountryId', function (newValue, oldValue) {
                        if ($scope.model.AddressSettings.CountryType === 1) {
                            if (newValue) {
                                GeneralService.getStateProvince($scope.model.CountryId).then(function (data) {
                                    $scope.to.options = data.sort(function (a, b) {
                                        return a.Name.localeCompare(b.Name);
                                    });
                                });
                            } else {
                                $scope.to.options = [{ Name: 'Önce Ülke Seçiniz...' }];
                            }
                        } else {
                            GeneralService.getStateProvince()
                                .then(function (data) {
                                    $scope.to.options = data.sort(function (a, b) {
                                        return a.Name.localeCompare(b.Name);
                                    });
                                });
                        }
                    });
                }],
                hideExpression: 'model.AddressSettings.StateProvinceType !== 1',
                expressionProperties: { 'templateOptions.required': 'model.AddressSettings.IsStateProvinceRequired' }
            },
            {
                key: "StateProvinceName",
                type: "floatInput",
                templateOptions: {
                    resource: 'form.state-province',
                    label: 'İl'
                },
                hideExpression: 'model.AddressSettings.StateProvinceType !== 2',
                expressionProperties: { 'templateOptions.required': 'model.AddressSettings.IsStateProvinceRequired' }
            },
            {
                key: 'DistrictId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'İlçe',
                    resource: 'form.district',
                    required: true,
                    options: [{ Name: 'Önce İl Seçiniz...' }]
                },
                controller: ['$scope', function ($scope) {
                    $scope.$watch('model.StateProvinceId', function (newValue, oldValue) {
                        if ($scope.model.AddressSettings.StateProvinceType === 1) {
                            if (newValue) {
                                GeneralService.getDistrict($scope.model.StateProvinceId)
                                    .then(function (data) {
                                        $scope.to.options = data.sort(function (a, b) {
                                            return a.Name.localeCompare(b.Name);
                                        });
                                    });
                            } else {
                                $scope.to.options = [{ Name: 'Önce İl Seçiniz...' }];
                            }
                        }
                        else {
                            GeneralService.getDistrict()
                                .then(function (data) {
                                    $scope.to.options = data.sort(function (a, b) {
                                        return a.Name.localeCompare(b.Name);
                                    });
                                });
                        }
                    });
                }],
                hideExpression: 'model.AddressSettings.DistrictType !== 1',
                expressionProperties: { 'templateOptions.required': 'model.AddressSettings.IsDistrictRequired' }
            },
            {
                key: "DistrictName",
                type: "floatInput",
                templateOptions: {
                    resource: 'form.district',
                    label: 'İlçe'
                },
                hideExpression: 'model.AddressSettings.DistrictType !== 2',
                expressionProperties: { 'templateOptions.required': 'model.AddressSettings.IsDistrictRequired' }
            },
            {
                key: 'CityId',
                type: 'floatSelect',
                templateOptions: {
                    label: '/Semt / Mahalle',
                    resource: 'form.city',
                    options: [{ Name: 'Önce İlçe Seçiniz...' }]
                },
                controller: ['$scope', function ($scope) {
                    $scope.$watch('model.DistrictId', function (newValue, oldValue) {
                        if (newValue) {
                            GeneralService.getCity($scope.model.DistrictId).then(function (data) {
                                $scope.to.options = data.sort(function (a, b) {
                                    return a.Name.localeCompare(b.Name);
                                });
                            });
                        } else {
                            $scope.to.options = [{ Name: 'Önce İl Seçiniz...' }];
                        }
                    });
                }],
                hideExpression: 'model.AddressSettings.CityType !== 1',
                expressionProperties: { 'templateOptions.required': 'model.AddressSettings.IsCityRequired' }
            },
            {
                key: "CityName",
                type: "floatInput",
                templateOptions: {
                    resource: 'form.city',
                    label: 'Semt / Mahalle'
                },
                hideExpression: 'model.AddressSettings.CityType !== 2',
                expressionProperties: { 'templateOptions.required': 'model.AddressSettings.IsCityRequired' }
            },
            {
                key: 'Street1',
                type: 'floatInput',
                templateOptions: {
                    type: 'text',
                    resource: 'form.street',
                    label: 'Sokak'
                },
                hideExpression: '!model.AddressSettings.IsStreet1Enabled',
                expressionProperties: { 'templateOptions.required': 'model.AddressSettings.IsStreet1Required' }
            },
            {
                key: 'ApartmentNo',
                type: 'floatInput',
                templateOptions: {
                    type: 'text',
                    resource: 'form.apartment-no',
                    label: 'Daire No:'
                },
                hideExpression: '!model.AddressSettings.IsApartmentNoEnabled',
                expressionProperties: { 'templateOptions.required': 'model.AddressSettings.IsApartmentNoRequired' }
            },
            {
                key: 'ZipPostalCode',
                type: 'floatInput',
                templateOptions: {
                    type: 'text',
                    resource: 'form.zip-postal-code',
                    label: 'Posta Kodu'
                },
                hideExpression: '!model.AddressSettings.IsZipPostalCodeEnabled',
                expressionProperties: { 'templateOptions.required': 'model.AddressSettings.IsZipPostalCodeRequired' }
            },
            {
                key: 'Description',
                type: 'floatTextarea',
                templateOptions: {
                    type: 'text',
                    resource: 'form.description',
                    label: 'Adres'
                },
                hideExpression: '!model.AddressSettings.IsDescriptionEnabled',
                expressionProperties: { 'templateOptions.required': 'model.AddressSettings.IsDescriptionRequired' }
            },
            {
                key: 'Location',
                type: 'floatInput',
                templateOptions: {
                    type: 'text',
                    resource: 'form.map-location',
                    label: 'Harita Konumu(long,lat)'
                }
            }
        ];

        var customerAddressList = [];
        var customerAddressLocationList = [];

        return service;

        function addLocation(CustomerId, AddressId, AdressLocation) {
            var deferred = $q.defer();

            $http.post(serviceBase + 'api/User/' + CustomerId + '/Address/' + AddressId + '/Location', AdressLocation)
                .then(addLocationComplete)
                .catch(addCustomerAddressLocationFailed);

            function addLocationComplete(response) {
                customerAddressLocationList.push(response);
                deferred.resolve(response.data);
            }

            function addCustomerAddressLocationFailed(error) {
                logger.error('Failed in addCustomerAddressLocation() function' + error.data);
                deferred.reject(error);
            }

            return deferred.promise;
        }

        //function savedLocation(userId, addressId) {
        //    var deferred = $q.defer();

        //    $http.get(serviceBase + 'api/User/' + userId + '/Address/' + addressId + '/Location')
        //    .then(SavedLocationComplete)
        //    .catch(SavedLocationFailed);

        //    function SavedLocationComplete(response) {
        //        customerAddressLocationList = response;
        //        deferred.resolve(customerAddressLocationList);
        //    }

        //    function SavedLocationFailed(error) {
        //        logger.error('Failed in addCustomerAddressLocation() function' + error.data);
        //        deferred.reject(error);
        //    }
        //    return deferred.promise;
        //}

        function addCustomerAddress(address) {
            var deferred = $q.defer();

            $http.post(serviceBase + 'api/User/' + address.UserId + '/Address', address)
                .then(addCustomerAddressComplete)
                .catch(addCustomerAddressFailed);

            function addCustomerAddressComplete(response) {
                customerAddressList.push(response);
                deferred.resolve(response.data);
            }

            function addCustomerAddressFailed(error) {
                logger.error('Failed in addCustomerAddress() function' + error.data);
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function editCustomerAddress(address) {
            var deferred = $q.defer();

            $http.put(serviceBase + 'api/User/' + address.UserId + '/Address/' + address.Id, address)
                .then(editCustomerAddressComplete)
                .catch(editCustomerAddressFailed);

            function editCustomerAddressComplete(response) {
                var address = customerAddressList.find(function (data) { return data.Id === response.Id; });
                var index = customerAddressList.indexOf(address);
                customerAddressList[index] = response;
                deferred.resolve(response.data);
            }

            function editCustomerAddressFailed(error) {
                logger.error('Failed in editCustomerAddress() function' + error.data);
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function deleteCustomerAddress(address) {
            var deferred = $q.defer();

            $http.delete(serviceBase + 'api/User/' + address.UserId + '/Address/' + address.Id, address)
                .then(deleteCustomerAddressComplete)
                .catch(deleteCustomerAddressFailed);

            function deleteCustomerAddressComplete(response) {
                var index = customerAddressList.indexOf(address);
                customerAddressList.splice(index, 1);
                deferred.resolve(response.data);
            }

            function deleteCustomerAddressFailed(error) {
                logger.error('Failed in deleteCustomerAddress() function' + error.data);
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCustomerAddresses(userId) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/User/' + userId + '/Address')
                .then(getCustomerAddressesComplete)
                .catch(getCustomerAddressesFailed);

            function getCustomerAddressesComplete(response) {
                customerAddressList = response.data;
                deferred.resolve(customerAddressList);
            }

            function getCustomerAddressesFailed(error) {
                logger.error('Failed in addCustomerAddress() function' + error.data);
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCustomerAddress(userId, addressId) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/User/' + userId + '/Address/' + addressId)
                .then(getCustomerAddressesComplete)
                .catch(getCustomerAddressesFailed);

            function getCustomerAddressesComplete(response) {
                var address = customerAddressList.find(function (data) { return data.Id === response.data.Id; });
                if (address) {
                    var index = customerAddressList.indexOf(address);
                    customerAddressList[index] = response.data;
                } else {
                    customerAddressList.push(response.data);
                }
                deferred.resolve(response.data);
            }

            function getCustomerAddressesFailed(error) {
                logger.error('Failed in addCustomerAddress() function' + error.data);
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCustomerAddressFields() {
            return customerAddressFields;
        }

        function prepareAllAddress() {
            var deferred = $q.defer();

            $http.put(serviceBase + 'api/User/00000000-0000-0000-0000-000000000000/Address/PrepareAllAddress')
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();