(function () {
    'use strict';

    angular
        .module('app.subscriptions')
        .controller('SubscriptionsController', Controller);

    Controller.$inject = ['$location', 'NgTableParams', 'SubscriptionsService', 'GeneralService'];

    function Controller($location, NgTableParams, Service, GeneralService) {
        var vm = this;

        activate();

        function activate() {
            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 10 }, $location.search()),
                {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {};
                        filterData.PageIndex = params.page(),
                            filterData.PageSize = params.count(),
                            filterData.SortProperty = Object.keys(params.sorting())[0],
                            filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return Service.getAll(filterData).then(function (data) {
                            params.total(data.FilteredCount);
                            return data.Data;
                        });
                    }
                });
        }

        vm.cancel = cancel;
        vm.save = save;
        vm.add = add;
        vm.del = del;

        ///////////////////////////////////

        vm.refresh = function () {
            vm.loading = true;
            vm.tableParams.reload();
            vm.loading = false;
        };

        vm.exportFile = function () {
            vm.loading = true;
            Service.exportFile().then(function (result, a, b, c) {
                vm.loading = false;
                var headers = result.headers();
                var blob = new Blob([result.data], { type: headers['content-type'] });
                var windowUrl = (window.URL || window.webkitURL);
                var downloadUrl = windowUrl.createObjectURL(blob);
                var anchor = document.createElement("a");
                anchor.href = downloadUrl;
                var fileNamePattern = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                anchor.download = fileNamePattern.exec(headers['content-disposition'])[1];
                document.body.appendChild(anchor);
                anchor.click();
                windowUrl.revokeObjectURL(blob);
            });
        };

        function del(row) {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                Service.del(row).then(function (data) {
                    vm.tableParams.reload();
                });
            }
        }

        function cancel(row, rowForm) {
            vm.tableParams.reload();
        }

        function save(row, rowForm) {
            if (row.Id) {
                Service.edit(row).then(function (data) {
                    vm.tableParams.reload();
                });
            } else {
                Service.add(row).then(function (data) {
                    vm.tableParams.reload();
                });
            }
        }

        function add() {
            vm.tableParams.data.unshift({
                Name: "",
                isEditing: true
            });
        }
    }
})();