(function () {
    'use strict';

    angular
        .module('app.account')
        .factory('InterceptorService', InterceptorService);

    InterceptorService.$inject = ['$q', '$injector', '$location', 'logger', 'ngAuthSettings', 'localStorageService'];

    function InterceptorService($q, $injector, $location, logger, ngAuthSettings, localStorageService) {
        var service = {
            request: request,
            responseError: responseError
        };

        var inflightAuthRequest = null;

        function request(config) {
            config.headers = config.headers || {};

            var authData = localStorageService.get('authorizationData');
            if (authData) {
                config.headers.Authorization = 'Bearer ' + authData.token;
            }

            return config;
        }

        function responseError(rejection) {
            var deferred = $q.defer();
            if (rejection.status === 401) {
                var AccountService = $injector.get('AccountService');
                AccountService.refreshToken().then(function (response) {
                    retryHttpRequest(rejection.config, deferred);
                }, function () {
                    AccountService.logOut();
                    $location.path('/account/loginorsignup');
                    deferred.reject(rejection);
                });
            } else if (rejection.status === 403) {
                console.log('rejection :>> ', rejection);
                toastr.error("Bu işlemi yapmaya yetkiniz bulunmamaktadır","Uyarı")
                deferred.reject(rejection);
            } else {
                deferred.reject(rejection);
            }
            return deferred.promise;
        }

        function retryHttpRequest(config, deferred) {
            var $http = $injector.get('$http');
            $http(config).then(function (response) {
                deferred.resolve(response.data);
            }, function (response) {
                deferred.reject(response);
            });
        }

        return service;
    }
})();