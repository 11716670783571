(function () {
    'use strict';

    angular
        .module('app.galleries')
        .controller('GalleriesDetailController', DetailController);

    DetailController.$inject = ['$location', '$stateParams', '$http', 'GalleriesService', 'FileUploader', 'localStorageService', 'Settings', 'ProductsService'];

    function DetailController($location, $stateParams, $http, Service, FileUploader, localStorageService, Settings, ProductsService) {
        /* jshint validthis:true */
        var vm = this;
        vm.Settings = Settings;

        vm.uploader = new FileUploader({});

        vm.uploader.filters.push({
            name: 'imageFilter',
            fn: function (item, options) {
                var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
            }
        });

        vm.uploader.onSuccessItem = function (fileItem, response) {
            fileItem.MediaId = response.Id;
        };

        Service.get($stateParams.GalleryId).then(function (data) {
            vm.entity = data;
            vm.uploader.url = 'api/gallery/' + data.Id + '/Media';
            var authData = localStorageService.get('authorizationData');
            vm.uploader.headers["Authorization"] = 'Bearer ' + authData.token;
            for (var i = 0; i < data.GalleryMedia.length; i++) {
                $http.get(Settings.imageServe + "/" + data.GalleryMedia[i].Media.FileName, { responseType: "blob", Id: data.GalleryMedia[i].Id }).
                    then(function (response) {
                        var mimetype = response.data.type;
                        var file = new File([response.data], "", { type: mimetype });
                        var dummy = new FileUploader.FileItem(vm.uploader, {});
                        dummy._file = file;
                        dummy.progress = 100;
                        dummy.isUploaded = true;
                        dummy.isSuccess = true;
                        dummy.MediaId = response.config.Id;
                        vm.uploader.queue.push(dummy);
                    }, function (error) {
                        var dummy = new FileUploader.FileItem(vm.uploader, {});
                        dummy.progress = 100;
                        dummy.isUploaded = true;
                        dummy.isSuccess = true;
                        dummy.MediaId = error.config.Id;
                        vm.uploader.queue.push(dummy);
                    });
            }
        });

        vm.removeFile = function (item, Id) {
            Service.removeMedia(vm.entity.Id, Id).then(function (data) {
                vm.uploader.removeFromQueue(item);
            })
        }

        vm.fields = Service.getFields();

        vm.delete = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                Service.delete(vm.entity).then(function (data) {
                    $location.path('/categories/list');
                });
            }
        }

        vm.getProduct = function (val) {
            return ProductsService.getProducts({ Code: val }).then(function (response) {
                return response.Data;
            });
        };

        vm.setProduct = function ($item, galleryMedia) {
            Service.addMediaProduct(vm.entity.Id, galleryMedia.Id, $item.Id).then(function (data) {
                galleryMedia.GalleryMediaProduct.push({ Product: { Name: $item.Name } });
                vm.selectedProduct = null;
            });
        };

        vm.removeMediaProduct = function (galleryMedia, productId) {
            Service.removeMediaProduct(vm.entity.Id, galleryMedia.Id, productId).then(function (data) {
                var index = galleryMedia.GalleryMediaProduct.indexOf(function (product) {
                    return product.Product.Id === productId;
                });
                galleryMedia.GalleryMediaProduct.splice(index, 1);
                vm.selectedProduct = null;
            });
        };
    }
})();
