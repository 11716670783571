(function () {
    'use strict';

    angular.module('app.shipmentPrice', [
        // Angular modules 
        'ngRoute'

        // Custom modules 
        // 3rd Party Modules
        
    ]);
})();