(function () {
    'use strict';

    angular
        .module('app.stockHistories')
        .controller('StockHistoriesListController', ListController);

    ListController.$inject = [
        '$location',
        'StockHistoriesService',
        'NgTableParams',
        'WarehousesService',
        'VendorsService',
        'ProductsService',
        'CategoriesService'
    ];

    function ListController(
        $location,
        Service,
        NgTableParams,
        WarehousesService,
        VendorsService,
        ProductsService,
        CategoriesService) {

        /* jshint validthis:true */
        var vm = this;

        activate();

        function activate() {

            vm.refresh = function () {
                vm.loading = true;
                vm.tableParams.reload();
                vm.loading = false;
            };
            vm.Products = ProductsService.getProducts().then(function (data) {
                return [{
                    id: '',
                    title: 'Seçiniz...'
                }].concat(data.Data.map(function (item) {
                    return {
                        id: item.Id,
                        title: item.Name
                    };
                }));
            });
            vm.Vendors = VendorsService.getAll().then(function (data) {
                return [{
                    id: '',
                    title: 'Seçiniz...'
                }].concat(data.Data.map(function (item) {
                    return {
                        id: item.Id,
                        title: item.Name
                    };
                }));
            });
            vm.Warehouses = WarehousesService.getAll().then(function (data) {
                return [{
                    id: '',
                    title: 'Seçiniz...'
                }].concat(data.Data.map(function (item) {
                    return {
                        id: item.Id,
                        title: item.Name
                    };
                }));
            });
            vm.ProductFilter = {
                ProductId: {
                    id: 'ng-table/headers/filterProduct.html',
                    placeholder: 'Ürün Ara...'
                }
            };
            vm.filteredProductList = [];
            vm.refreshFilterProduct = function (search) {
                var params = {
                    SearchKey: search,
                    PageSize: 18,
                    PageIndex: 1,
                    SortProperty: 'Name',
                    SortType: 'asc',
                    SelectFields: 'Id,Name'
                };
                vm.filteredProductList = [{
                    Id: '',
                    Name: 'Yükleniyor...'
                }];
                return ProductsService.getProducts(params)
                    .then(function (response) {
                        if (response.Data.length > 0) {
                            vm.filteredProductList = response.Data;
                        } else {
                            vm.filteredProductList = [{
                                Id: '',
                                Name: 'Bulunamadı (' + search + ')'
                            }];
                        }
                    });
            }
            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({
                    page: 1,
                    count: 10,
                    sorting: {
                        Date: "desc"
                    }
                }, $location.search()), {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {};
                        filterData.PageIndex = params.page();
                        filterData.PageSize = params.count();
                        filterData.SortProperty = Object.keys(params.sorting())[0];
                        filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return Service.getAll(filterData).then(function (data) {
                            params.total(data.FilteredCount);
                            return data.Data;
                        });
                    }
                });
        }
    }
})();