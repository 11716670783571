(function () {
    'use strict';

    angular
        .module('app.galleries')
        .factory('GalleriesService', Service);

    Service.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config'];

    function Service($http, $q, ngAuthSettings, logger, config) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            getFields: getFields,
            removeMedia: removeMedia,
            addMediaProduct: addMediaProduct,
            removeMediaProduct: removeMediaProduct
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri;
        var endpoint = serviceBase + 'api/gallery/';

        var customerFields = [
            {
                key: 'Name',
                type: 'horizontalInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Adı',
                    placeholder: 'Adı'
                }
            }];

        return service;

        ///////////////////////////////

        function getAll(filterParams) {
            var deferred = $q.defer();
            $http.get(endpoint, {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(id) {
            var deferred = $q.defer();

            $http.get(endpoint + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(customer) {
            var deferred = $q.defer();

            $http.post(endpoint, customer)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(customer) {
            var deferred = $q.defer();

            $http.put(endpoint + customer.Id, customer)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(customer) {
            var deferred = $q.defer();

            $http.delete(endpoint + customer.Id, customer)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getFields() {
            return customerFields;
        }

        function removeMedia(Id, GalleryMediaId) {
            var deferred = $q.defer();

            $http.delete(endpoint + Id + "/Media/" + GalleryMediaId)
                .then(removeComplete)
                .catch(removeFailed);

            function removeComplete(response) {
                deferred.resolve(response.data);
            }

            function removeFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function addMediaProduct(Id, GalleryMediaId, ProductId) {
            var deferred = $q.defer();

            $http.post(endpoint + Id + "/Media/" + GalleryMediaId + "/Product/" + ProductId)
                .then(removeComplete)
                .catch(removeFailed);

            function removeComplete(response) {
                deferred.resolve(response.data);
            }

            function removeFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function removeMediaProduct(Id, GalleryMediaId, ProductId) {
            var deferred = $q.defer();

            $http.delete(endpoint + Id + "/Media/" + GalleryMediaId + "/Product/" + ProductId)
                .then(removeComplete)
                .catch(removeFailed);

            function removeComplete(response) {
                deferred.resolve(response.data);
            }

            function removeFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();