(function () {
    'use strict';

    angular
        .module('app.consulting')
        .controller('ConsultantsEditController', EditController);

    EditController.$inject = ['$location', '$stateParams', 'ConsultantsService', 'ConsultantSchedulesService'];

    function EditController($location, $stateParams, Service, ConsultantSchedulesService) {
        /* jshint validthis:true */
        var vm = this;

        vm.loading = true;
        vm.title = '';

        Service.get($stateParams.Id)
            .then(function (data) {
                vm.entity = data;
                vm.entity.Expertises = vm.entity.ConsultantExpertise.map(function (item) {
                    return item.ExpertiseId;
                });
                vm.loading = false;
                vm.getConsultantSchedules();
            });

        vm.fields = Service.getFields();

        vm.edit = function () {
            if (vm.form.$valid) {
                vm.loading = true;

                vm.entity.Expertises = vm.entity.Expertises || [];
                vm.entity.ConsultantExpertise = vm.entity.Expertises.map(function (item) {
                    return {
                        ExpertiseId: item
                    };
                });
                vm.entity.Expertises = null;

                Service.edit(vm.entity)
                    .then(function () {
                        vm.loading = false;
                        $location.path('/consulting/consultants/list');
                    });
            }
        };

        vm.delete = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                Service.del(vm.entity).then(function (data) {
                    $location.path("/consulting/consultants/list");
                });
            }
        }

        vm.cancel = function () {
            $location.path('/consulting/consultants/list');
        };

        vm.getConsultantSchedules = function () {
            vm.loading = true;
            vm.events.length = 0;
            ConsultantSchedulesService.getAll({
                    PageSize: -1,
                    ConsultantId: vm.entity.Id,
                    Today: new Date()
                })
                .then(function (response) {
                    for (const consultantSchedule of response.Data) {
                        vm.events.push({
                            title: moment(consultantSchedule.StartDate).format('DD.MM.YYYY') + " - " + moment(consultantSchedule.EndDate).format('DD.MM.YYYY'),
                            start: consultantSchedule.StartDate,
                            end: consultantSchedule.EndDate,
                            id: consultantSchedule.Id,
                            overlap: true
                        })
                    }
                    vm.loading = false;
                })
        }

        vm.events = [];
        vm.eventSources = [vm.events];
        vm.uiConfig = {
            calendar: {
                height: 450,
                editable: true,
                header: {
                    left: 'month agendaWeek agendaDay',
                    center: 'title',
                    right: 'today prev,next'
                },
                selectable: true,
                editable: true,
                navLinks: true, // can click day/week names to navigate views
                businessHours: true, // display business hours
                eventClick: function (event, jsEvent, view) {
                    if (confirm("Silmek istediğinize emin misiniz?")) {
                        ConsultantSchedulesService.del({
                                Id: event.id
                            })
                            .then(function (data) {
                                vm.getConsultantSchedules();
                            });
                    }
                },
                eventDrop: function (event, delta, revertFunc, jsEvent, ui, view) {
                    ConsultantSchedulesService.edit({
                            Id: event.id,
                            StartDate: event.start,
                            EndDate: event.end,
                            ConsultantId: vm.entity.Id
                        })
                        .then(function (data) {
                            vm.getConsultantSchedules();
                        });
                },
                eventResize: function (event, delta, revertFunc, jsEvent, ui, view) {
                    console.log('eventResize :>> ', event, delta, revertFunc, jsEvent, ui, view);
                },
                select: function (start, end) {
                    if (start && end) {
                        vm.addEvent({
                            title: moment(start).format('DD.MM.YYYY') + " - " + moment(end).format('DD.MM.YYYY'),
                            start: start,
                            end: end
                        });
                    }
                }
            }
        };

        vm.addEvent = function (event) {
            vm.loading = true;
            ConsultantSchedulesService.add({
                    StartDate: event.start,
                    EndDate: event.end,
                    ConsultantId: vm.entity.Id
                })
                .then(function (response) {
                    vm.loading = false;
                    vm.getConsultantSchedules();
                })
        };
    }
})();