(function () {
    'use strict';

    angular
      .module('app.filter')
      .filter('add', addFilter);

    function addFilter() {
        return function (data, key1, key2) {
            if (typeof (data) === 'undefined' || typeof (key1) === 'undefined' || typeof (key2) === 'undefined') {
                return 0;
            }

            return Number(key1) + Number(key2);
        };
    }
})();

