(function () {
    'use strict';

    angular
        .module('app.layout')
        .controller('SidebarController', SidebarController);

    SidebarController.$inject = ['$state', '$timeout', 'AccountService', 'Settings', '$sce', 'SharedData', 'routerHelper'];

    function SidebarController($state, $timeout, AccountService, Settings, $sce, SharedData, routerHelper) {
        /*jshint validthis: true */
        var vm = this;
        vm.SharedData = SharedData;

        activate();

        function activate() {

            vm.entity = {};
            vm.menuLoading = true;
            vm.treedata = [];

            $timeout(function () {
                $('[data-widget="tree"]').tree();
            }, 1000);

            AccountService.getUserMenu()
                .then(function (data) {
                    vm.entity = data;
                    vm.treedata = data.filter(function (item) {
                        return item.UstYetkiId === null;
                    });
                    vm.prepareAllMenus(vm.treedata);
                    vm.treeExpandedNodes = vm.entity.AltYetkiler;
                    vm.loading = false;
                    vm.menuLoading = false;
                });
        }

        vm.trustSrc = function (src) {
            return $sce.trustAsResourceUrl(src);
        };

        vm.prepareAllMenus = function (menuList) {
            for (var menu of menuList) {
                menu.Menus = vm.entity.filter(function (item) {
                    return item.UstYetkiId === menu.Id;
                });
                vm.prepareAllMenus(menu.Menus);
            }
        };


        vm.getTopMenus = function () {
            if (!vm.UserMenu) {
                return;
            }
            return vm.UserMenu.filter(function (item) {
                return item.UstYetkiId === null;
            });
        };

        vm.getSubMenus = function (TopMenuId) {
            if (!vm.UserMenu) {
                return;
            }
            return vm.UserMenu.filter(function (item) {
                return item.UstYetkiId === TopMenuId;
            });
        };

        vm.IsSelected = function (yetki) {
            if (!yetki.Controller) {
                for (var subMenu of yetki.Menus) {
                    if (vm.IsSelected(subMenu)) {
                        return true;
                    }
                }
                return false;
            } else {
                var state = $state.href($state.current.name, $state.params, {
                    absolute: true
                });
                if (state) {
                    return state.includes(yetki.Controller);
                } else {
                    return false;
                }
            }
        };
    }
})();