(function () {
    'use strict';

    angular
        .module('app.collections')
        .controller('CollectionsDetailController', Collections);

    Collections.$inject = ['$location', '$stateParams', '$http', 'CollectionsService', 'FileUploader', 'localStorageService', 'Settings'];

    function Collections($location, $stateParams, $http, CollectionsService, FileUploader, localStorageService, Settings) {
        /* jshint validthis:true */
        var vm = this;
        vm.Settings = Settings;
        
        CollectionsService.getCollection($stateParams.CollectionId).then(function (data) {
            vm.entity = data;
        });
        
        vm.fields = CollectionsService.getCollectionFields();

        vm.delete = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                CollectionsService.deleteCollection(vm.entity).then(function (data) {
                    $location.path('/collections/list');
                });
            }
        };
    }
})();
