(function () {
    'use strict';

    angular.module('app.shipmentPrice')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'shipmentPrice',
                config: {
                    url: '/shipmentPrice',
                    title: 'Kargo Fiyatları',
                    abstract: true,
                    templateUrl: 'app/shipment/shipmentPrice.html'
                }
            },
            {
                state: 'shipmentPrice.list',
                config: {
                    templateUrl: 'app/shipment/shipmentPrice.list.html',
                    controller: 'ListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Kargo Fiyatları Listesi',
                    settings: {
                        nav: 22,
                        name: 'shipmentPrice.list',
                        topName: 'sales',
                        content: 'Kargo Fiyatları Listesi',
                        resource: 'menu.shipmentPrice.list'
                    }
                }
            },
            {
                state: 'shipmentPrice.add',
                config: {
                    templateUrl: 'app/shipment/shipmentPrice.add.html',
                    controller: 'AddController',
                    controllerAs: 'vm',
                    url: '/add',
                    title: 'Kargo Fiyatı Ekle',
                    settings: {
                        topName: 'shipmentPrice.list',
                        resource: 'menu.shipmentPrice.add'
                    }
                }
            },
            {
                state: 'shipmentPrice.edit',
                config: {
                    templateUrl: 'app/shipment/shipmentPrice.edit.html',
                    controller: 'EditController',
                    controllerAs: 'vm',
                    url: '/edit/:Id',
                    title: 'Kargo Fiyatı Düzenle',
                    settings: {
                        topName: 'shipmentPrice.list',
                        resource: 'menu.shipmentPrice.edit'
                    }
                }
            },
            {
                state: 'shipmentPrice.detail',
                config: {
                    templateUrl: 'app/shipment/shipmentPrice.detail.html',
                    controller: 'DetailController',
                    controllerAs: 'vm',
                    url: '/detail/:Id',
                    title: 'Kargo Fiyatı Detay',
                    settings: {
                        topName: 'shipmentPrice.list',
                        resource: 'menu.shipmentPrice.detail'
                    }
                }
            }
        ];
    }
})();