(function () {
    'use strict';

    var unique = function (data, key) {
        var result = [];
        for (var i = 0; i < data.length; i++) {
            var value = data[i][key];
            if (result.indexOf(value) == -1) {
                result.push(value);
            }
        }
        return result;
    };


    angular
        .module('app.orders')
        .controller('OrdersDetailController', OrdersDetailController);

    OrdersDetailController.$inject = [
        '$scope',
        '$q',
        '$stateParams',
        'logger',
        '$location',
        'OrdersService',
        'OrderProductsService',
        '$translate',
        'Settings',
        'CustomerAddressService',
        'StockItemsService',
        'CartsService',
        'SharedData',
        'SettingsService',
        '$uibModal',
        '$filter',
        'InvoiceService'];

    function OrdersDetailController(
        $scope,
        $q,
        $stateParams,
        logger,
        $location,
        OrdersService,
        OrderProductsService,
        $translate,
        Settings,
        CustomerAddressService,
        StockItemsService,
        CartsService,
        SharedData,
        SettingsService,
        $uibModal,
        $filter,
        InvoiceService) {
        var vm = this;
        vm.SharedData = SharedData;

        activate();

        function activate() {
            vm.loading = true;
            vm.defaultProvider = null;
            vm.settings = Settings;
            vm.orderFields = OrdersService.getOrderFields();
            vm.paymentFields = OrdersService.getPaymentFields();
            //console.log(vm.SharedData);

            //console.log("svm.SharedData-int ", vm.SharedData.IntegrationSettings.ParasutUserNumber.length);
            //console.log("svm.SharedData-set", vm.SharedData.Settings);

            SettingsService.getAllProviders().then(function (data) {
                vm.defaultProvider = data.filter(prov => prov.Default === true);
                vm.defaultProvider = vm.defaultProvider[0];
                console.log(vm.defaultProvider);
            });

            OrdersService.getOrder($stateParams.OrderId).then(function (data) {
                vm.priceCurrency = data.Currency;
                vm.order = data;
                vm.orderProductData = data.OrderProduct;


                var netsimdata = vm.SharedData.IntegrationSettings.NetsimUsername;
                if (netsimdata != null) {
                    if (vm.order.Customer.NetsimUserCode == null) {
                        toastr.info("Muhasebeleştirme yapıldığı zaman Yeni Kullanıcı olarak Netsim'e kayıt edilecektir.", "Muhasebeleştirme");
                    }
                }

                vm.orderLastPayment = data.Payment.sort(function (a, b) {
                    x = new Date(a.InsertDate);
                    y = new Date(b.InsertDate);
                    return x > y ? -1 : x < y ? 1 : 0;
                })[0];
                vm.customer = vm.order.Customer;
                vm.calculateTotal();
                vm.loading = false;
                vm.ShipmentCode = vm.order.ShipmentCode;
                CustomerAddressService.getCustomerAddress(data.CustomerId, data.DeliveryAddressId)
                    .then(function (data) {
                        vm.address = data;
                    });
            });
            OrdersService.getOrderStatuses()
                .then(function (data) {
                    vm.OrderStatus = data.filter(function (x) {
                        if (x.Id == '1efca84d-a006-40f5-aa8e-e5f278e9b03a' ||
                            x.Id == '59158dcb-c607-487f-9712-898e08858c90' ||
                            x.Id == 'a9752b35-21be-4f12-9ba3-41f0acdd8a04' ||
                            x.Id == '654ef3ff-1fca-431f-821d-c073aea6fa73') {
                            return false
                        } else {
                            return true;
                        }
                    });
                });

            StockItemsService.getStockItemStatus()
                .then(function (data) {
                    vm.StockItemStatus = data;
                });
            OrderProductsService.getSalesOrderProductStatus()
                .then(function (data) {
                    vm.SalesOrderProductStatus = data;
                });
            $translate('orders.detail.moneySign')
                .then(function (sign) {
                    vm.moneySign = sign;
                });

        }
        vm.jsonParse = function (objString) {
            return JSON.parse(objString);
        }
        vm.changeCurrency = function (currency) {
            for (var i = 0; i < vm.order.OrderProduct.length; i++) {
                var item = vm.order.OrderProduct[i];
                if (item.CurrencyId != currency.Id) {
                    var productPrice = item.Product.Price;
                    productPrice = (item.Product.Price) * (item.Currency.Rate);
                    productPrice = productPrice / currency.Rate;
                    item.Product.Price = productPrice
                }
            }
        }
        vm.calculateTotal = function () {
            vm.subTotal = 0;
            vm.taxTotal = 0;
            vm.total = 0;
            if (vm.order.OrderProduct && vm.order.OrderProduct.length > 0) {
                for (var i = 0; i < vm.order.OrderProduct.length; i++) {
                    var product = vm.order.OrderProduct[i];
                    vm.subTotal += product.Quantity * (product.UnitPriceWithDiscount || product.UnitPrice);
                    vm.taxTotal += product.Quantity * (product.UnitPriceWithDiscount || product.UnitPrice) * product.Tax;
                    vm.total += product.Quantity * (product.UnitPriceWithDiscount || product.UnitPrice) * (parseFloat(product.Tax) + 1.00);
                }
            }
        };

        vm.deleteOrder = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                OrdersService.deleteOrder($stateParams.OrderId).then(function (data) {
                    $location.path('/orders/list');
                });
            }
        };


        vm.OrderAccept = function () {

            OrdersService.OrderAccept(vm.order)
                .then(function () {
                    console.log("Ok");
                })
                .catch(function () {
                    vm.loading = false;
                });
        }

        vm.OrderReject = function () {
            OrdersService.OrderReject(vm.order.Id)
                .then(function () {
                    console.log("Ok");
                })
                .catch(function () {
                    vm.loading = false;
                });
        }

        vm.changeStatus = function (StatusId) {
            if (StatusId && StatusId !== vm.order.StatusId) {
                vm.loading = true;
                OrdersService.changeStatus(vm.order.Id, StatusId)
                    .then(function () {
                        OrdersService.getOrder($stateParams.OrderId)
                            .then(function (data) {
                                vm.order = data;
                                vm.customer = vm.order.Customer;
                                vm.calculateTotal();
                                vm.loading = false;
                            })
                            .catch(function (response) {
                                alert(response.data.message);
                                vm.loading = false;
                            });
                    })
                    .catch(function (response) {
                        alert(response.data.message);
                        vm.loading = false;
                    });
            }
        };

        vm.changePaymentStatus = function (PaymentId) {
            vm.loading = true;
            OrdersService.changePaymentStatus(PaymentId, '4121ba40-3e2b-496e-b0de-b8da95b9f5a9')
                .then(function () {
                    OrdersService.getOrder($stateParams.OrderId)
                        .then(function (data) {
                            vm.order = data;
                            vm.customer = vm.order.Customer;
                            vm.calculateTotal();
                            vm.loading = false;
                        })
                        .catch(function () {
                            vm.loading = false;
                        });
                })
                .catch(function () {
                    vm.loading = false;
                });
        };
        vm.changeOrderProductStatus = function (orderProduct, StatusId) {
            if (StatusId && StatusId !== orderProduct.SalesOrderProductStatusId) {
                vm.loading = true;
                orderProduct.SalesOrderProductStatusId = StatusId;
                orderProduct.SalesOrderProductStatus = null;
                OrderProductsService.edit(orderProduct)
                    .then(function () {
                        OrdersService.getOrder($stateParams.OrderId)
                            .then(function (data) {
                                vm.order = data;
                                vm.customer = vm.order.Customer;
                                vm.calculateTotal();
                                vm.loading = false;
                            })
                            .catch(function (response) {
                                vm.loading = false;
                            });
                    })
                    .catch(function (response) {
                        vm.loading = false;
                    });
            }
        };

        vm.changeStockItemStatus = function (stockItem, StatusId) {
            if (StatusId && StatusId !== stockItem.StockItemStatusId) {
                vm.loading = true;
                stockItem.StockItemStatusId = StatusId;
                stockItem.StockItemStatus = null;
                StockItemsService.edit(stockItem)
                    .then(function () {
                        OrdersService.getOrder($stateParams.OrderId)
                            .then(function (data) {
                                vm.order = data;
                                vm.customer = vm.order.Customer;
                                vm.calculateTotal();
                                vm.loading = false;
                            })
                            .catch(function () {
                                vm.loading = false;
                            });
                    })
                    .catch(function () {
                        vm.loading = false;
                    });
            }
        };

        vm.setShipmentCode = function (ShipmentCode) {
            if (ShipmentCode && ShipmentCode !== vm.order.ShipmentCode) {
                vm.loading = true;
                OrdersService.setShipmentCode(vm.order.Id, ShipmentCode)
                    .then(function () {
                        activate();
                    })
                    .catch(function () {
                        vm.loading = false;
                    });
            }
        };

        vm.setOrderProductShipmentCode = function (orderProduct, ShipmentCode) {
            if (ShipmentCode) {
                vm.loading = true;
                OrdersService.setOrderProductShipmentCode(orderProduct.Id, ShipmentCode)
                    .then(function () {
                        activate();
                    })
                    .catch(function () {
                        vm.loading = false;
                    });
            }
        };

        vm.CargoProductChange = function (orderProduct, cargocompany) {
            if (cargocompany) {
                vm.loading = true;
                OrdersService.CargoProductChange(orderProduct.Id, cargocompany)
                    .then(function () {
                        activate();
                    })
                    .catch(function () {
                        vm.loading = false;
                    });
            }
        };

        vm.setInvoiceNumber = function (InvoiceSerial, InvoiceNumber) {
            if (InvoiceSerial && InvoiceNumber) {
                vm.loading = true;
                OrdersService.setInvoiceNumber(vm.order.Id, InvoiceSerial, InvoiceNumber)
                    .then(function () {
                        activate();
                    })
                    .catch(function () {
                        vm.loading = false;
                    });
            }
        }

        vm.accounting = function () {
            vm.loading = true;
            logger.showToasts = true;
            OrdersService.accounting(vm.order.Id)
                .then(function () {
                    OrdersService.getOrder($stateParams.OrderId)
                        .then(function (data) {
                            vm.order = data;
                            vm.customer = vm.order.Customer;
                            vm.calculateTotal();
                            vm.loading = false;
                        })
                        .catch(function () {
                            vm.loading = false;
                        });
                })
                .catch(function (error) {
                    vm.loading = false;
                });
        };

        vm.refreshStockItem = function (text, orderProduct) {
            var params = {
                Code: text,
                PageSize: 10,
                PageIndex: 1,
                SortProperty: 'Code',
                SortType: 'asc',
                ProductId: orderProduct.ProductId,
                SalesOrderProduct: false
            };
            vm.refreshStockItemList[orderProduct.Id] = [{
                Id: '',
                Name: 'Yükleniyor...'
            }];
            return StockItemsService.getAll(params)
                .then(function (response) {
                    if (response.Data.length > 0) {
                        vm.refreshStockItemList[orderProduct.Id] = response.Data;
                    } else {
                        vm.refreshStockItemList[orderProduct.Id] = [{
                            Id: '',
                            Name: 'Bulunamadı (' + text + ')'
                        }];
                    }
                });
        }

        vm.deleteOrderProductStockItem = function (stockItem, orderProduct) {
            vm.loading = true;
            stockItem.SalesOrderProductId = null;
            StockItemsService.edit(stockItem)
                .then(function () {
                    vm.getSalesOrderStockItem(orderProduct);
                });
        }

        vm.onSelectStockItemCallback = function ($item, orderProduct) {
            vm.loading = true;
            $item.SalesOrderProductId = orderProduct.Id;
            StockItemsService.edit($item)
                .then(function () {
                    vm.getSalesOrderStockItem(orderProduct);
                });
        }

        vm.getSalesOrderStockItem = function (orderProduct) {
            vm.loading = true;
            var params = {
                PageSize: -1,
                SortProperty: 'Code',
                SortType: 'asc',
                SalesOrderProductId: orderProduct.Id
            };
            StockItemsService.getAll(params)
                .then(function (response) {
                    orderProduct.StockItem = response.Data;
                    vm.loading = false;
                });
        }

        vm.print = function (divName) {
            var printContents = document.getElementById(divName).innerHTML;
            var popupWin = window.open('', '_blank', '');
            popupWin.document.open();
            var printContent = `<html>
                                    <body style="padding:0px; width:550px;height:595px;" onload="window.print()">
                                        ${printContents}
                                    </body>
                                    <link href="/dist/css/mazakacore.css"  rel="stylesheet" />
                                    <script src="/dist/js/mazakacore-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-angular-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-app.js" ></script>
                                    <style>
                                        table {
                                            font-size:10px;
                                            table-layout:auto;
                                        }
                                        .dd {
                                            display:inline-block;
                                            font-size:10px;
                                            font-weight:bold;
                                        }
                                    </style>
                                </html>`;
            popupWin.document.write(printContent);
            popupWin.document.close();
        };

        vm.ProductShipmentCodePrint = function (Code, ShipmentProviderMame) {
            vm.ShipmentProviderName = ShipmentProviderMame;
            $('.productcargo-print').reload();
        }
        vm.getMainImage = function (product) {
            if (!product.ProductMedia) {
                return 'no-image.png';
            }
            var mainMedia =
                product.ProductMedia.find(function (media) {
                    return media.IsMain === true;
                }) ||
                product.ProductMedia[0];
            if (mainMedia) {
                return mainMedia.Media.FullPath;
            } else {
                return 'no-image.png';
            }
        };

        //////////////////////////////////////

        vm.getInstallmentWithBankInstallment = function (installmentList, bankInstallment) {
            if (!installmentList) {
                return;
            }
            return installmentList.find(function (x) {
                return x.BankInstallment == bankInstallment;
            });
        };

        vm.getTotalInstallmentAverageDay = function (bank) {
            if (!vm.pay) {
                return 0;
            }
            var dd = vm.pay.Total / vm.general.AmountPassedByCreditCard;
            var total = 0;
            for (let i = 2; i < bank.Installment.length; i++) {
                const item = bank.Installment[i];
                total +=
                    dd * bank.CollectionDaysInstallmentFirstPaymentDay +
                    bank.CollectionDaysInstallmentRepaymentPeriod * (i - 1);
            }
            return total / vm.pay.Total;
        };

        ////////////////////////////////////////

        vm.createCart = function () {
            var cart = {
                Active: true,
                CartTypeId: "355c5e8a-1a5e-46e2-8b84-fa8d6d0d66b3",
                UserId: vm.order.CustomerId,
                DeliveryAddressId: vm.order.DeliveryAddressId,
                InvoiceAddressId: vm.order.InvoiceAddressId,
                Note: vm.order.Description
            }
            CartsService
                .add(cart)
                .then(function (data) {
                    var promiseList = [];
                    for (const orderProduct of vm.order.OrderProduct) {
                        var cartProduct = {
                            ProductId: orderProduct.ProductId,
                            Quantity: orderProduct.Quantity,
                            CartId: data.Id
                        }
                        var promise = CartsService.InsertProduct(cartProduct);
                        promiseList.push(promise);
                    }
                    $q.all(promiseList)
                        .then(function (result) {
                            $location.path('/carts/edit/' + data.Id);
                        });
                });
        }

        ///////////////////////////////////////////

        vm.Netsim = function (data) {
            OrdersService.NetsimAccounting(data)
                .then(function (response) {
                    if (response === "Ok") {
                        toastr.info("Başarıyla İşlendi.", "Muhasebeleştirme");
                    } else {
                        toastr.warning(response, "Muhasebeleştirme");
                    }
                    vm.loading = false;
                })
                .catch(function () {
                    vm.loading = false;
                });
        }

        vm.EInvoice = function (data) {
            //console.log("order", data);
             //console.log("shared data", vm.SharedData);
            InvoiceService.sendEInvoice(data)
                .then(function (response) {
                    //window.location.reload();
                    if (response === "Ok") {
                        toastr.info("Başarıyla İşlendi.", "Muhasebeleştirme");
                    }
                    else {
                        toastr.warning(response, "Muhasebeleştirme");
                    }
                    vm.loading = false;
                })
                .catch(function () {
                    vm.loading = false;
                });

        }
        vm.ShipmentProviderCreateCargoNumber = function (data, CargoData) {
            OrdersService.ShipmentProviderCreateCargoNumber(data, CargoData)
                .then(function (response) {
                    console.log("response ", response);
                    if (response.shipmentProviderViewModel.NormalizedName == "yurtici-kargo" && response.ResultCode == "0") {
                        toastr.info("Başarıyla Kargo Numarası Oluşturuldu.", "Yurtiçi Kargo");
                    } else if (response.shipmentProviderViewModel.NormalizedName == "aras-kargo" && response.ShipmentCode != null) {
                        toastr.info("Başarıyla Kargo Numarası Oluşturuldu.", "Aras Kargo");
                    } else if (response.shipmentProviderViewModel.NormalizedName == "ptt-kargo" && response.ShipmentCode != null) {
                        toastr.info("Başarıyla Kargo Numarası Oluşturuldu.", "Ptt Kargo");
                    } else {
                        toastr.warning(response.ResultMessage, "Mazaka Eticaret");
                    }
                    vm.loading = false;
                })
                .catch(function () {
                    vm.loading = false;
                });
        }

        vm.CreateCargoNumber = function (data) {
            OrdersService.CreateCargoNumber(data)
                .then(function (response) {
                    window.location.reload();

                    vm.loading = false;
                })
                .catch(function () {
                    vm.loading = false;
                });
        }

        vm.CargoStatus = function (data) {
            OrdersService.CargoStatus(data)
                .then(function (response) {
                    vm.openModal(response);
                    vm.loading = false;
                })
                .catch(function () {
                    vm.loading = false;
                });
        }

        vm.CargoStatusAndMail = function (data) {
            OrdersService.CargoStatusAndMail(data)
                .then(function (response) {
                    var title = "Kargo İşlemleri";
                    if (response.Result != "Ok") {
                        toastr.error("Kargo İşlem Görmemiş!", title);
                    } else {
                        toastr.info("Kargo Takip Numarası Gönderildi!", title);
                    }
                    vm.loading = false;
                })
                .catch(function () {
                    vm.loading = false;
                });
        }

        vm.modalTitle = "Kargo Durumu";
        vm.modalInstance = null;
        vm.openModal = function (data) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'ModalContent.html',
                controller: 'ModalController',
                controllerAs: 'vm',
                resolve: {
                    items: function () {
                        console.log(data);
                        return {
                            data: data
                        };
                    }
                }
            });
        }
    }

    angular
        .module('app.excel')
        .controller('ModalController', ModalController);

    ModalController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'ExcelService', '$uibModal', '$uibModalStack', '$uibModalInstance', 'items'];

    function ModalController($scope, $stateParams, $filter, $location, logger, ExcelService, $uibModal, $uibModalStack, $uibModalInstance, items) {
        var vm = this;
        vm.content = items;
        activate();
        function activate() {
            vm.data = vm.content.data;
        }
    }
})();