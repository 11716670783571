(function () {
    'use strict';

    angular
        .module('app.collections')
        .controller('CollectionsEditController', Collections);

    Collections.$inject = ['$location', '$stateParams', 'CollectionsService', 'Upload'];

    function Collections($location, $stateParams, CollectionsService,Upload) {
        /* jshint validthis:true */
        var vm = this;

        vm.loading = true;
        vm.title = '';

        CollectionsService.getCollection($stateParams.CollectionId).then(function (data) {
            vm.entity = data;
            vm.loading = false;
        });

        vm.fields = CollectionsService.getCollectionFields();

        vm.edit = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                CollectionsService.editCollection(vm.entity).then(function () {
                    vm.loading = false;
                    $location.path('/collections/detail/' + vm.entity.Id);
                });
            }
        };
    }
})();