(function () {
    'use strict';

    angular
        .module('app.customers')
        .controller('CustomersDeleteController', CustomersDeleteController);

    CustomersDeleteController.$inject = ['$scope', '$stateParams', '$location', 'CustomersService', 'CustomerAddressService', 'CustomerGeneralService', 'logger'];

    function CustomersDeleteController($scope, $stateParams, $location, CustomersService, CustomerAddressService, CustomerGeneralService, logger) {
        var vm = this;

        vm.loading = true;

        CustomersService.getCustomer($stateParams.Id).then(function (data) {
            vm.customer = data;
            vm.loading = false;
        });

        vm.customerFields = CustomersService.getCustomerFields();

        vm.deleteCustomer = function () {
            if (vm.customer) {
                vm.loading = true;
                CustomersService.deleteCustomer(vm.customer).then(function (data) {
                    vm.loading = false;
                    logger.success("Kullanıcı silme işlemi başarıyla tamamlanmıştır.");
                    $location.path('/customers/list');
                });
            }
        };

        vm.Cancel = function () {
            $location.path('/customers/' + vm.customer.Id);
        };
    }
})();