(function () {
    'use strict';

    angular
        .module('app.account')
        .controller('AccountDetailController', AccountDetailController);

    AccountDetailController.$inject = ['$scope', '$stateParams', '$location', '$timeout', 'AccountService'];

    function AccountDetailController($scope, $stateParams, $location, $timeout, AccountService) {
        var vm = this;

        vm.title = '';

        AccountService.getUser($stateParams.id).then(function (data) {
            vm.detailuser = data;
            vm.detailuser.mode = "detail";
        });

        vm.delete = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                vm.loading = true;
                AccountService.removeUser(vm.detailuser).then(function () {
                    vm.loading = false;
                    $location.path('/account/list');
                });
            }
        };

        vm.userFields = AccountService.getUserFields();
    }
})();