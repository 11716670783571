(function() {
    "use strict";

    angular.module("app.account", [
        "LocalStorageModule",
        "smart-table",
        "treeControl",
        "ui.bootstrap",
        "ui.grid",
        "ui.grid.pagination",
        "ui.mask",
        "formly",
        "formlyBootstrap",
        "ngMap",
        "ui.select",
        "ngTable",
        "ngSanitize"
    ]);
})();
