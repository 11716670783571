(function () {
    'use strict';

    angular.module('app.blogPostCategory')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'blogpostcategory',
                config: {
                    url: '/blogpostcategory',
                    title: 'Kategoriler',
                    abstract: true,
                    templateUrl: 'app/blogpostcategory/blogpostcategory.html'
                }
            },
            {
                state: 'blogpostcategory.list',
                config: {
                    templateUrl: 'app/blogpostcategory/blogpostcategory.list.html',
                    controller: 'BlogPostCategoryListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Kategori Listesi',
                    settings: {
                        nav: 3,
                        name: 'blogpostcategory.list',
                        topName: 'blogpostcategory',
                        content: 'Kategori Listesi',
                        resource: 'menu.blogpostcategory.list'
                    }
                }
            },
            {
                state: 'blogpostcategory.add',
                config: {
                    templateUrl: 'app/blogpostcategory/blogpostcategory.add.html',
                    controller: 'BlogPostCategoryAddController',
                    controllerAs: 'vm',
                    url: '/add',
                    title: 'Kategori Ekle',
                    settings: {
                        topName: 'blogpostcategory.list',
                        name: 'blogpostcategory.add',
                        content: 'Kategori Ekle',
                        resource: 'menu.blogpostcategory.add'
                    }
                }
            },
            {
                state: 'blogpostcategory.edit',
                config: {
                    templateUrl: 'app/blogpostcategory/blogpostcategory.edit.html',
                    controller: 'BlogPostCategoryEditController',
                    controllerAs: 'vm',
                    url: '/edit/:CategoryId',
                    title: 'Kategori Düzenle',
                    settings: {
                        topName: 'blogpostcategory.list',
                        name: 'blogpostcategory.edit',
                        content: 'Kategori Düzenle',
                        resource: 'menu.blogpostcategory.edit'
                    }
                }
            },
            {
                state: 'blogpostcategory.detail',
                config: {
                    templateUrl: 'app/blogpostcategory/blogpostcategory.detail.html',
                    controller: 'BlogPostCategoryDetailController',
                    controllerAs: 'vm',
                    url: '/detail/:CategoryId',
                    title: 'Kategori Detay',
                    settings: {
                        topName: 'blogpostcategory.list',
                        name: 'blogpostcategory.detail',
                        content: 'Kategori Detay',
                        resource: 'menu.blogpostcategory.detail'
                    }
                }
            }
        ];
    }
})();