(function () {
    'use strict';

    angular.module('app.vendortypes')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'vendortypes',
                config: {
                    url: '/vendortypes',
                    title: 'Bayi Tipleri',
                    abstract: true,
                    templateUrl: 'app/vendortypes/vendortypes.html'
                }
            },
            {
                state: 'vendortypes.list',
                config: {
                    templateUrl: 'app/vendortypes/vendortypes.list.html',
                    controller: 'VendortypesListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Bayi Tip Listesi',
                    settings: {
                        //nav: 33,
                        //name:'vendortypes.list',
                        topName: 'sales',
                        content: 'Bayi Tip Listesi'
                    }
                }
            },
            {
                state: 'vendortypes.add',
                config: {
                    templateUrl: 'app/vendortypes/vendortypes.add.html',
                    controller: 'VendortypesAddController',
                    controllerAs: 'vm',
                    url: '/add',
                    title: 'Bayi Tipi Ekle'
                }
            },
            {
                state: 'vendortypes.edit',
                config: {
                    templateUrl: 'app/vendortypes/vendortypes.edit.html',
                    controller: 'VendortypesEditController',
                    controllerAs: 'vm',
                    url: '/edit/:VendorTypeId',
                    title: 'Bayi Tipi Düzenle'
                }
            },
            {
                state: 'vendortypes.detail',
                config: {
                    templateUrl: 'app/vendortypes/vendortypes.detail.html',
                    controller: 'VendortypesDetailController',
                    controllerAs: 'vm',
                    url: '/detail/:VendorTypeId',
                    title: 'Bayi Tipi Detay'
                }
            }
        ];
    }
})();