(function () {
    'use strict';

    angular
        .module('app.vendors')
        .factory('VendorsService', Service);

    Service.$inject = ['$http', '$q', 'ngAuthSettings', 'CustomerAddressService'];

    function Service($http, $q, ngAuthSettings, CustomerAddressService) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            getFields: getFields
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/vendors/';

        var fields = [
            {
                wrapper: "fieldset",
                templateOptions: {
                    label: "Genel Özellikler",
                    classes: 'col-md-6 no-padding',
                    resource: "form.general-options"
                },
                fieldGroup: [
                    {
                        key: 'Name',
                        type: 'floatInput',
                        templateOptions: {
                            required: true,
                            type: 'text',
                            label: 'Adı',
                            resource: 'form.name',
                            placeholder: 'Adı'
                        }
                    },
                    {
                        key: 'Email',
                        type: 'floatEmail',
                        templateOptions: {
                            resource: 'form.email',
                            label: 'Eposta'
                        }
                    },
                    {
                        key: "Description",
                        type: "floatTinyMCE",
                        templateOptions: {
                            label: "Açıklama",
                            placeholder: "İçerik",
                            resource: "form.description"
                        }
                    },
                    {
                        key: 'AuthorizedPerson',
                        type: 'floatInput',
                        templateOptions: {
                            label: 'Yetkili Kişi',
                            resource: 'form.authorized-person'
                        }
                    },
                    {
                        key: 'AccountingAuthorizedPerson',
                        type: 'floatInput',
                        templateOptions: {
                            label: 'Yetkili Kişi(Muhasebe)',
                            resource: 'form.accounting-authorized-person'
                        }
                    },
                    {
                        key: "Phone",
                        type: "floatPhone",
                        templateOptions: {
                            label: 'Telefon',
                            resource: 'form.phone',
                            required: true
                        }
                    },
        
                    {
                        key: "Fax",
                        type: "floatPhone",
                        templateOptions: {
                            label: 'Fax',
                            resource: 'form.fax'
                        }
                    },
                    {
                        key: 'TaxOffice',
                        type: 'floatInput',
                        hideExpression: 'model.UserType=="INDIVIDUAL"',
                        templateOptions: {
                            type: 'text',
                            label: 'Vergi Dairesi',
                            resource: 'form.tax-office',
                            placeholder: 'Vergi Dairesi'
                        }
                    },
                    {
                        key: 'TaxNumber',
                        type: 'floatInput',
                        hideExpression: 'model.UserType=="INDIVIDUAL"',
                        templateOptions: {
                            type: 'text',
                            label: 'Vergi No',
                            resource: 'form.tax-number',
                            placeholder: 'Vergi No'
                        }
                    },
                    {
                        key: 'Active',
                        type: 'floatCheckbox',
                        defaultValue: true,
                        templateOptions: {
                            label: 'Yayında',
                            resource: 'form.active',
                            placeholder: 'Yayında'
                        }
                    },
                ]
            },
            {
                key: 'UserAddress',
                wrapper: 'fieldset',
                templateOptions: {
                    label: 'Adres',
                    resource: 'form.address',
                    classes: 'col-md-6 no-padding'
                },
                fieldGroup: CustomerAddressService.getCustomerAddressFields()
            }
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBase, {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(Id) {
            var deferred = $q.defer();

            $http.get(serviceBase + Id)
                .then(getProductComplete)
                .catch(getProductFailed);

            function getProductComplete(response) {
                deferred.resolve(response.data);
            }

            function getProductFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();

            $http.post(serviceBase, entity)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http.put(serviceBase + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(entity) {
            var deferred = $q.defer();

            $http.delete(serviceBase + entity.Id, entity)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();