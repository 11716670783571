(function () {
    "use strict";

    angular
        .module("app.orders")
        .controller("PaymentsListController", PaymentsListController);

    PaymentsListController.$inject = [
        "$scope",
        "$location",
        "$stateParams",
        "OrdersService",
        "NgTableParams"
    ];

    function PaymentsListController(
        $scope,
        $location,
        $stateParams,
        OrdersService,
        NgTableParams
    ) {
        var vm = this;

        activate();

        function activate() {
            vm.refresh = function () {
                vm.loading = true;
                vm.tableParams.reload();
                vm.loading = false;

            };

            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({
                        page: 1,
                        count: 18,
                        sorting: {
                            InsertDate: "desc"
                        }
                    },
                    $location.search()
                ), {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {}; // params.filter();
                        filterData.PageIndex = params.page();
                        filterData.PageSize = params.count();
                        filterData.SortProperty = Object.keys(
                            params.sorting()
                        )[0];
                        filterData.SortType = params.sorting()[
                            Object.keys(params.sorting())[0]
                        ];
                        filterData.SelectFields =
                            "Id,InsertDate,Bank.Name,Bank.NormalizeName,SalesOrder.Id,SalesOrder.OrderDate,SalesOrder.Code,ExpectedTotal,PaidTotal,PaymentStatusId,PaymentStatus.Name,LastPaymentDate";
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return OrdersService.getPayments(filterData).then(
                            function (data) {
                                params.total(data.FilteredCount);
                                return data.Data;
                            }
                        );
                    }
                }
            );
        }

        vm.Status = OrdersService.getPaymentStatuses()
            .then(function (response) {
                return response.map(function (item) {
                    return {
                        id: item.Id,
                        title: item.Name
                    };
                });
            });


        vm.changePaymentStatus = function (PaymentId) {
            vm.loading = true;
            OrdersService.changePaymentStatus(PaymentId, '4121ba40-3e2b-496e-b0de-b8da95b9f5a9')
                .then(function () {
                    vm.tableParams.reload();
                })
                .catch(function () {
                    vm.loading = false;
                });
        };
    }
})();