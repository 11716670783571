(function () {
    'use strict';

    angular
        .module('app.warehouses')
        .factory('WarehousesService', Service);

    Service.$inject = ['$http', '$q','ngAuthSettings', 'CustomerAddressService', 'VendorsService'];

    function Service($http, $q, ngAuthSettings, CustomerAddressService, VendorsService) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            getFields: getFields
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/warehouses/';

        var fields = [
            {
                key: 'Name',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Adı',
                    placeholder: 'Adı'
                }
            },
            {
                key: 'AuthorizedPerson',
                type: 'floatInput',
                hideExpression: 'model.UserType=="INDIVIDUAL"',
                templateOptions: {
                    type: 'text',
                    label: 'Yetkili Adı',
                    resource: 'form.authorized-person',
                    placeholder: 'Yetkili Adı'
                }
            },
            {
                key: 'Note',
                type: 'floatTextarea',
                templateOptions: {
                    type: 'text',
                    label: 'Not',
                    resource: 'form.note',
                    placeholder: 'Not'
                }
            },
            {
                key: 'VendorId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Satıcı',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    VendorsService.getAll({ PageSize: -1 }).then(function (response) {
                        $scope.to.options = response.Data;
                    });
                }]
            },
            {
                key: 'Address',
                wrapper: 'fieldset',
                templateOptions: {
                    label: 'Adres',
                    resource: 'form.address',
                    classes: 'col-md-12 no-padding'
                },
                fieldGroup: CustomerAddressService.getCustomerAddressFields()
            }
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBase, {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(Id) {
            var deferred = $q.defer();

            $http.get(serviceBase + Id)
                .then(getProductComplete)
                .catch(getProductFailed);

            function getProductComplete(response) {
                deferred.resolve(response.data);
            }

            function getProductFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();

            $http.post(serviceBase, entity)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http.put(serviceBase + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(entity) {
            var deferred = $q.defer();

            $http.delete(serviceBase + entity.Id, entity)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();