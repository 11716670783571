(function () {
    'use strict';

    angular
        .module('app.ggIntegration')
        .controller('GGEditMatchCategoryFilterController', GGEditMatchCategoryFilterController);

    GGEditMatchCategoryFilterController.$inject = ['$scope', '$stateParams', '$location', 'GGIntegrationService', 'NgTableParams'];

    function GGEditMatchCategoryFilterController($scope, $stateParams, $location, GGIntegrationService, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;

        vm.settings = globalSettings;
        activate();

        function activate() {
            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 10 }, $location.search()),
                {
                    getData: function () {
                        return GGIntegrationService.gggetMatchCategory($stateParams.Id)
                            .then(function (data) {
                                console.log("data1", data);
                                return GGIntegrationService.getCategoryFilter(data.GGCategoryCode)
                                    .then(function (result) {
                                        console.log("data2", result);
                                        return result;
                                    });
                            });
                    }
                });

            GGIntegrationService.getOption().then(function (data) {
                vm.entity = data;
                console.log("data", data);
                vm.entity.refresh = true;
                vm.loading = false;
            });

            //GGIntegrationService.getMatchCategoryFilterListByCategory($stateParams.Id).then(function (data) {

            //});

            vm.MatchCategoryId = $stateParams.Id;
        }

        vm.selectFilter = function (filter) {
            vm.loading = true;
            console.log("filter", filter, filter.item);
            vm.catAttr = {};
            vm.catAttr.OptionId = filter.item.Id;
            vm.catAttr.MatchCategoryId = $stateParams.Id;
            vm.catAttr.IntegrationCategoryAttrId = filter.Id;
            vm.catAttr.IntegrationCategoryAttrName = filter.Name;
            console.log("vm.catAttr", vm.catAttr);
            GGIntegrationService.ggeditMatchCategoryFilter(vm.catAttr).then(function (data) {
                vm.loading = false;
                console.log("data", data);
                $location.path('/ggIntegration/editmatchcategoryfiltervalue/' + data.Id);
            });
        };
    }
})();