(function() {
    "use strict";

    angular.module("app.account").controller("AccountAuthenticationController", AccountAuthenticationController);

    AccountAuthenticationController.$inject = [
        "$scope",
        "$stateParams",
        "$location",
        "$timeout",
        "AccountService",
        "AccountAuthenticationService"
    ];

    function AccountAuthenticationController(
        $scope,
        $stateParams,
        $location,
        $timeout,
        AccountService,
        AccountAuthenticationService
    ) {
        var vm = this;

        vm.title = "";
        vm.UserAuthentications = [];
        vm.checkboxes = [];

        activate();

        function activate() {
            AccountService.getUsers({ Role : "EMPLOYEE" }).then(function(data) {
                vm.Users = data.Data;
                AccountAuthenticationService.getAuthentications().then(function(data) {
                    vm.AuthenticationsTemp = data;
                    vm.Authentications = vm.getAuthList(null);

                    AccountAuthenticationService.getUserAuthentications().then(function(data) {
                        vm.UserAuthentications = data;

                        angular.forEach(vm.UserAuthentications, function(item, i) {
                            vm.checkboxes[item.YetkiId + "_" + item.UserId] = true;
                        });

                        $(".auth-list").treetable({ expandable: true });
                        $(".auth-list").treetable("expandAll");
                    });
                });
            });
        }

        vm.changeCheckbox = function($event, authenticationId, userId) {
            var value = $event.target.checked;
            var item = vm.UserAuthentications.find(function(data) {
                return data.YetkiId === authenticationId && data.UserId === userId;
            });
            if (value && !item) {
                vm.UserAuthentications.push({ YetkiId: authenticationId, UserId: userId });
            } else if (!value && item) {
                var index = vm.UserAuthentications.indexOf(item);
                vm.UserAuthentications.splice(index, 1);
            }
        };

        vm.AuthenticateUser = function() {
            AccountAuthenticationService.setUserAuthentications(vm.UserAuthentications).then(function(data) {
                $location.path("/account/list");
            });
        };

        vm.getAuthList = function(TopId) {
            var authList = [];
            var subAutList = vm.AuthenticationsTemp.filter(function(auth) {
                return auth.UstYetkiId === TopId;
            });
            for (var i = 0; i < subAutList.length; i++) {
                var item = subAutList[i];
                authList.push(item);
                var subItems = vm.getAuthList(item.Id);
                authList = authList.concat(subItems);
            }
            return authList;
        };

        //angular.forEach(data, function (value, key) {
        //    vm.gridAuthOptions.columnDefs.push({
        //        name: value.Id,
        //        displayName: value.Name + " " + value.Surname,
        //        cellTemplate: '<div class="ui-grid-cell-contents"><input type="checkbox" /></div>'
        //    });
        //})

        //vm.gridAuthOptions.data = data;

        //AccountService.addAuthentication()
        //vm.gridAuthOptions = {
        //    data: vm.Authentications,
        //    rowHeight: 39,
        //    enableVerticalScrollbar: 2,
        //    enableHorizontalScrollbar: 2,
        //    enableFullRowSelection: true,
        //    enableGrouping: true,
        //    columnDefs: [
        //        { name: 'Ad', displayName: 'Yetki' }
        //    ]
        //}
    }
})();
