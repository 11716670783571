(function () {
    'use strict';

    angular
        .module('app.tags')
        .controller('PostTagsAddController', PostTagsAddController);

    PostTagsAddController.$inject = ['$location', 'BlogPostTagService'];

    function PostTagsAddController($location, BlogPostTagService) {

        var vm = this;

        vm.entity = {};

        vm.fields = BlogPostTagService.getFields();

        vm.create = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                BlogPostTagService.add(vm.entity)
                    .then(function (data) {
                        vm.loading = false;
                        $location.path('/blogposttag/detail/' + data.Id);
                    });
            }
        };
    }
})();