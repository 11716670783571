(function () {
    'use strict';

    angular
        .module('app.orders')
        .factory('OrderProductsService', OrderProductsService);

    OrderProductsService.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config', '$timeout', 'CustomerAddressService'];

    function OrderProductsService($http, $q, ngAuthSettings, logger, config, $timeout, CustomerAddressService) {
        var service = {
            getSalesOrderProducts: getSalesOrderProducts,
            edit: edit,
            deleteOrderProduct: deleteOrderProduct,
            getSalesOrderProductStatus: getSalesOrderProductStatus
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/SalesOrderProducts/';

        return service;

        ///////////////////////////////////

        function getSalesOrderProducts(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBase, {
                    params: filterParams
                })
                .then(getOrdersComplete)
                .catch(getOrdersFailed);

            function getOrdersComplete(response) {
                deferred.resolve(response.data);
            }

            function getOrdersFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function deleteOrderProduct(orderProductId) {

            var deferred = $q.defer();

            $http.delete(serviceBase + orderProductId)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;

        }

        function getSalesOrderProductStatus(order) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'SalesOrderProductStatus')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http
                .put(serviceBase + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

    }
})();