(function () {
    'use strict';

    angular
        .module('app.n11Integration')
        .factory('N11IntegrationService', N11IntegrationService);

    N11IntegrationService.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config', 'ProductsService', 'ProductsGeneralService'];

    function N11IntegrationService($http, $q, ngAuthSettings, logger, config, ProductsService, ProductsGeneralService) {
        var service = {

            //Option

            getOption: getOption,
            getOptionValue: getOptionValue,

            //Template

            getTemplateFields: getTemplateFields,
            getAllTemplate: getAllTemplate,
            getTemplate: getTemplate,
            addTemplate: addTemplate,
            editTemplate: editTemplate,
            delTemplate: delTemplate,

            //MatchCategory

            getMatchCategoryFields: getMatchCategoryFields,
            getAllMatchCategory: getAllMatchCategory,
            getMatchCategory: getMatchCategory,
            addMatchCategory: addMatchCategory,
            editMatchCategory: editMatchCategory,
            delMatchCategory: delMatchCategory,

            //MatchCategory Filter

            getMatchCategoryFilterFields: getMatchCategoryFilterFields,
            getMatchCategoryFilter: getMatchCategoryFilter,
            editMatchCategoryFilter: editMatchCategoryFilter,

            //MatchCategory Filter Value

            getMatchCategoryFilterValueFields: getMatchCategoryFilterValueFields,
            getMatchCategoryFilterValue: getMatchCategoryFilterValue,
            editMatchCategoryFilterValue: editMatchCategoryFilterValue,

            //N11 Integration

            getShipmentTemplate: getShipmentTemplate,
            getTopCategories: getTopCategories,
            getSubCategories: getSubCategories,
            getCategoryFilter: getCategoryFilter,
            getCategoryFilterValue: getCategoryFilterValue,
            getMatchCategoryFilterListByCategory: getMatchCategoryFilterListByCategory,
            getMatchCategoryFilterValueList: getMatchCategoryFilterValueList,
            transferCategory: transferCategory,
            transferAllCategory: transferAllCategory

        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/n11Integration/';
        var serviceBaseTemplate = ngAuthSettings.apiServiceBaseUri + 'api/templatesIntegration/';

        var templateFields = [
            {
                key: 'Name',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Adı',
                    resource: 'form.name',
                    placeholder: 'Adı'
                }
            },
            {
                key: 'SalesPrice',
                type: 'floatSelect',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Satış Fiyatı',
                    resource: 'form.sales-price',
                    placeholder: 'Satış Fiyatı',
                    class: 'col-md-2',
                    options: [
                        {
                            Name: 'Ürün Fiyatı',
                            Id: 'Ürün Fiyatı'
                        },
                        {
                            Name: 'Pazaryeri Fiyatı',
                            Id: 'Pazaryeri Fiyatı'
                        }
                    ]
                }
            },
            {
                key: 'SalesPriceCalcType',
                type: 'floatSelect',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Satış Fiyatı Hesap Biçimi',
                    class: 'col-md-2',
                    options: [
                        {
                            Name: 'X',
                            Id: 'X',
                        },
                        {
                            Name: '+',
                            Id: '+',
                        }
                    ]
                }
            },
            {
                key: 'SalesPriceQuantity',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Satış Fiyat Ayarlama Miktarı',
                    placeholder: 'Satış Fiyat Ayarlama Miktarı',
                    class: 'col-md-2',
                    numberMask: 2
                }
            },
            {
                key: 'constantPriceQuantity',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Sabit Artış Tutarı',
                    placeholder: 'Sabit Artış Tutarı',
                    class: 'col-md-2',
                    numberMask: 2
                }
            },
            {
                key: 'DiscountRate',
                type: 'floatInput',
                templateOptions: {
                    type: 'text',
                    label: '% İndirim Oranı',
                    resource: 'form.discount-rate',
                    placeholder: '% İndirim Oranı'
                }
            },
            {
                key: 'SalesStartDate',
                type: 'floatDatepicker',
                templateOptions: {
                    type: 'text',
                    label: 'Satışa Başlama Tarihi',
                    resource: 'form.sales-start-date',
                    placeholder: 'Satışa Başlama Tarihi'
                }
            },
            {
                key: 'SalesEndDate',
                type: 'floatDatepicker',
                templateOptions: {
                    type: 'text',
                    label: 'Satışın Bitiş Tarihi',
                    resource: 'form.sales-end-date',
                    placeholder: 'Satışın Bitiş Tarihi'
                }
            },
            {
                key: 'SubTitle',
                type: 'floatInput',
                templateOptions: {
                    type: 'text',
                    label: 'Alt Başlık',
                    resource: 'form.subtitle',
                    placeholder: 'Alt Başlık'
                }
            },
            {
                key: 'CargoTime',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'number',
                    label: 'Kargo Süresi',
                    resource: 'form.cargo-time',
                    placeholder: 'Kargo Süresi'
                }
            },
            {
                key: 'IntegrationShipmentTemplateName',
                type: 'floatSelect',
                templateOptions: {
                    type: 'text',
                    label: 'Teslimat Şablonu',
                    resource: 'form.deliveries-template',
                    placeholder: 'Teslimat Şablonu'
                },
                controller: ['$scope', function ($scope) {
                    getShipmentTemplate()
                        .then(function (response) {
                            var templates = response.map(x => {
                                var obj = {};
                                obj["Id"] = x.Id;
                                obj["Name"] = x.Name;
                                return obj;
                            });
                            $scope.to.options = templates;
                        });
                }]
            },
        ];
        var matchcategoryFields = [
            {
                key: 'IntegrationTemplateId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Şablonlar',
                    resource: 'form.templates',
                    required: true,
                    placeholder: 'Şablon Seçiniz ',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    ProductsGeneralService.getIntegrationTemplates()
                        .then(function (response) {
                            $scope.to.options = response;
                        });
                }]
            },
            {
                key: 'ShipmentIntegrationTemplateId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Teslimat Şablonu Seçiniz',
                    resource: 'form.shipment.templates',
                    required: true,
                    placeholder: 'Şablon Seçiniz ',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    ProductsGeneralService.getShipmentTemplates()
                        .then(function (response) {
                            $scope.to.options = response;
                        });
                }]
            },
            {
                key: 'EComCategoryId',
                type: 'floatTreeSelect',
                templateOptions: {
                    label: 'E-Ticaret Kategorileri',
                    resource: 'form.ecommerce-categories',
                    required: true,
                    placeholder: 'Kategori Seçiniz ',
                    topProp: 'TopCategoryId',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    ProductsGeneralService.getCategories()
                        .then(function (response) {
                            $scope.to.options = response;
                        });
                }]
            },
            {
                key: 'N11Categories1',
                type: 'floatSelect',
                templateOptions: {
                    label: 'N11 Kategorileri',
                    resource: 'form.n11-topcategories',
                    required: true,
                    placeholder: 'Kategori Seçiniz ',
                    class: 'col-md-3',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    getTopCategories().then(function (data) {
                        $scope.$watch('model.N11Categories1', function (newValue, oldValue) {
                            if (newValue) {
                                var found = $scope.to.options.find(function (data) {
                                    return data.Id == newValue;
                                });
                                $scope.model.N11Categories1Name = found.Name;
                            }
                        });
                        if (data.length > 0) {
                            $scope.model.showN11Categories2 =true
                        }

                        $scope.to.options = data;
                    });
                }]
            },
            {
                key: 'N11Categories2',
                hideExpression: '!(model.N11Categories1 && model.showN11Categories2)',
                type: 'floatSelect',
                templateOptions: {
                    label: 'N11 Kategorileri',
                    resource: 'form.n11-subcategories',
                    required: true,
                    placeholder: 'Kategori Seçiniz ',
                    class: 'col-md-3',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    $scope.$watch('model.N11Categories1', function (newValue, oldValue) {
                        if (newValue) {
                            getSubCategories(newValue).then(function (data) {
                                if (data.length > 0) {
                                    $scope.model.showN11Categories3 = true
                                    $scope.to.options = data;
                                }
                                else {
                                    $scope.model.showN11Categories2 = false;
                                }
                            });
                        }
                    });
                    $scope.$watch('model.N11Categories2', function (newValue, oldValue) {
                        if (newValue) {
                            var found = $scope.to.options.find(function (data) {
                                return data.Id == newValue;
                            });
                            $scope.model.N11Categories2Name = found.Name;
                        }
                    });
                }]
            },
            {
                key: 'N11Categories3',
                hideExpression: '!(model.N11Categories2 && model.showN11Categories3)',
                type: 'floatSelect',
                templateOptions: {
                    label: 'N11 Kategorileri',
                    resource: 'form.n11-subcategories',
                    required: true,
                    placeholder: 'Kategori Seçiniz ',
                    class: 'col-md-3',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    $scope.$watch('model.N11Categories2', function (newValue, oldValue) {
                        if (newValue) {
                            getSubCategories(newValue).then(function (data) {
                                if (data.length > 0) {
                                    $scope.model.showN11Categories4 = true
                                    $scope.to.options = data;
                                }
                                else {
                                    $scope.model.showN11Categories3 = false;
                                }
                            });
                        }
                    });
                    $scope.$watch('model.N11Categories3', function (newValue, oldValue) {
                        if (newValue) {
                            var found = $scope.to.options.find(function (data) {
                                return data.Id == newValue;
                            });
                            $scope.model.N11Categories3Name = found.Name;
                        }
                    });
                }]
            },
            {
                key: 'N11Categories4',
                hideExpression: '!(model.N11Categories3 && model.showN11Categories4)',
                type: 'floatSelect',
                templateOptions: {
                    label: 'N11 Kategorileri',
                    resource: 'form.n11-subcategories',
                    required: true,
                    placeholder: 'Kategori Seçiniz ',
                    class: 'col-md-3',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    $scope.$watch('model.N11Categories3', function (newValue, oldValue) {
                        if (newValue) {
                            getSubCategories(newValue).then(function (data) {
                                if (data.length > 0) {
                                    $scope.model.showN11Categories5 = true
                                    $scope.to.options = data;
                                }
                                else {
                                    $scope.model.showN11Categories4 = false;
                                }
                            });
                        }
                    });
                    $scope.$watch('model.N11Categories4', function (newValue, oldValue) {
                        if (newValue) {
                            var found = $scope.to.options.find(function (data) {
                                return data.Id == newValue;
                            });
                            $scope.model.N11Categories4Name = found.Name;
                        }
                    });
                }]
            },
            {
                key: 'N11Categories5',
                hideExpression: '!(model.N11Categories4 && model.showN11Categories5)',
                type: 'floatSelect',
                templateOptions: {
                    label: 'N11 Kategorileri',
                    resource: 'form.n11-subcategories',
                    required: true,
                    placeholder: 'Kategori Seçiniz ',
                    class: 'col-md-3',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    $scope.$watch('model.N11Categories4', function (newValue, oldValue) {
                        if (newValue) {
                            getSubCategories(newValue).then(function (data) {
                                if (data.length > 0) {
                                    $scope.model.showN11Categories6 = true
                                    $scope.to.options = data;
                                }
                                else {
                                    $scope.model.showN11Categories5 = false;
                                }
                            });
                        }
                    });
                    $scope.$watch('model.N11Categories5', function (newValue, oldValue) {
                        if (newValue) {
                            var found = $scope.to.options.find(function (data) {
                                return data.Id == newValue;
                            });
                            $scope.model.N11Categories5Name = found.Name;
                        }
                    });
                }]
            },
            {
                key: 'N11Categories6',
                hideExpression: '!(model.N11Categories5 && model.showN11Categories6)',
                type: 'floatSelect',
                templateOptions: {
                    label: 'N11 Kategorileri',
                    resource: 'form.n11-subcategories',
                    required: true,
                    placeholder: 'Kategori Seçiniz ',
                    class: 'col-md-3',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    $scope.$watch('model.N11Categories5', function (newValue, oldValue) {
                        if (newValue) {
                            getSubCategories(newValue).then(function (data) {
                                if (data.length > 0) {
                                    $scope.to.options = data;
                                }
                                else {
                                    $scope.model.showN11Categories6 = false;
                                }
                            });
                        }
                    });
                    $scope.$watch('model.N11Categories6', function (newValue, oldValue) {
                        if (newValue) {
                            var found = $scope.to.options.find(function (data) {
                                return data.Id == newValue;
                            });
                            $scope.model.N11Categories6Name = found.Name;
                        }
                    });
                }]
            },
        ]; 
        var matchcategoryFilterFields = [
            {
                key: 'EComCategoryFilterId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'E-Ticaret Kategori Filtre',
                    resource: 'form.ecommerce-categories-filter',
                    required: true,
                    placeholder: 'Kategori Seçiniz ',
                    topProp: 'TopCategoryId',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    ProductsGeneralService.getCategories()
                        .then(function (response) {
                            $scope.to.options = response;
                        });
                }]
            },
            {
                key: 'N11CategoriesFilter',
                type: 'floatSelect',
                templateOptions: {
                    label: 'N11 Kategorileri',
                    resource: 'form.n11-topcategories',
                    required: true,
                    placeholder: 'Kategori Seçiniz ',
                    class: 'col-md-3',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    getTopCategories().then(function (data) {
                        $scope.$watch('model.N11Categories1', function (newValue, oldValue) {
                            if (newValue) {
                                var found = $scope.to.options.find(function (data) {
                                    return data.Id == newValue;
                                });
                                $scope.model.N11Categories1Name = found.Name;
                            }
                        });
                        if (data.length > 0) {
                            $scope.model.showN11Categories2 = true
                        }
                        $scope.to.options = data;
                    });
                }]
            }
        ];

        return service;

        /////////////////////////////////////////


        //Option

        function getOption() {
            var deferred = $q.defer();

            $http.get(serviceBase + "option")
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getOptionValue(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "optionvalue/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        //

        //Template

        function getTemplateFields() {
            return templateFields;
        }

        function getAllTemplate(filterParams) {
            var deferred = $q.defer();
            $http.get(serviceBaseTemplate + "template", {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getTemplate(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "template/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function addTemplate(template) {
            var deferred = $q.defer();

            $http.post(serviceBaseTemplate + "template", template)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function editTemplate(template) {
            var deferred = $q.defer();

            $http.put(serviceBase + "template/" + template.Id, template)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function delTemplate(template) {
            var deferred = $q.defer();

            $http.delete(serviceBase + "template/" + template.Id)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        //

        //Match Category

        function getMatchCategoryFields() {
            return matchcategoryFields;
        }

        function getAllMatchCategory(filterParams) {
            var deferred = $q.defer();
            $http.get(serviceBase + "matchcategory", {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getMatchCategory(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "matchcategory/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function addMatchCategory(matchcategory) {
            var deferred = $q.defer();

            $http.post(serviceBase + "matchcategory/add", matchcategory)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function editMatchCategory(matchcategory) {
            var deferred = $q.defer();

            $http.put(serviceBase + "matchcategory/" + matchcategory.Id, matchcategory)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function delMatchCategory(matchcategory) {
            var deferred = $q.defer();

            $http.delete(serviceBase + "matchcategory/" + matchcategory.Id)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        //

        //Match Category Filter

        function getMatchCategoryFilterFields() {
            return matchcategoryFilterFields;
        }

        function getMatchCategoryFilter(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "n11matchcategoryfilter/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getMatchCategoryFilterListByCategory(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "matchcategoryfilterlistbycategory/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function editMatchCategoryFilter(matchcategory) {
            var deferred = $q.defer();

            $http.put(serviceBase + "matchcategoryfilter/" + matchcategory.Id, matchcategory)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        //

        //Match Category Filter Value

        function getMatchCategoryFilterValueFields() {
            return matchcategoryFilterFields;
        }

        function getMatchCategoryFilterValue(id) {
            var deferred = $q.defer();
            $http.get(serviceBase + "matchcategoryfiltervalue/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getMatchCategoryFilterValueList(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "matchcategoryfiltervaluelist/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function editMatchCategoryFilterValue(matchcategory) {
            var deferred = $q.defer();

            $http.put(serviceBase + "matchcategoryfiltervalue/" + matchcategory.Id, matchcategory)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        //

        //N11 Categories

        function getShipmentTemplate() {
            var deferred = $q.defer();

            $http.get(serviceBase + "N11TemplateList")
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getTopCategories() {
            var deferred = $q.defer();
            $http.get(serviceBase + "N11TopCategories")
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getSubCategories(CatId) {
            var deferred = $q.defer();

            $http.get(serviceBase + "N11SubCategories/" + CatId)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCategoryFilter(CatId) {
            var deferred = $q.defer();
            $http.get(serviceBase + "CategoryFilter/" + CatId)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCategoryFilterValue(FilterId) {
            var deferred = $q.defer();
            $http.get(serviceBase + "CategoryFilterValue/" + FilterId)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function transferCategory(matchcategory) {
            var deferred = $q.defer();

            $http.post(serviceBase + "TransferCategory/", matchcategory)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function transferAllCategory() {
            var deferred = $q.defer();

            $http.post(serviceBase + "TransferAllCategory/")
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        //
    }
})();