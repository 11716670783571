(function () {
    'use strict';

    angular
        .module('app.customers')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'customers',
                config: {
                    url: '/customers',
                    title: 'Müşteriler',
                    abstract: true,
                    templateUrl: 'app/customers/customers.html',
                    settings: {
                        name: 'customers',
                        nav: 4,
                        icon: 'fa fa-users',
                        content: 'Müşteri',
                        resource: 'menu.customers'
                    }
                }
            },
            {
                state: 'customers.list',
                config: {
                    templateUrl: 'app/customers/customers.list.html',
                    controller: 'CustomersListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Müşteri Listesi',
                    settings: {
                        name: 'customers.list',
                        topName: 'customers',
                        nav: 4,
                        content: 'Müşteri Listesi',
                        breadcrumbIcon: 'group',
                        breadcrumb: 'Müşteri Listesi',
                        resource: 'menu.customers.list'
                    }
                }
            },
            {
                state: 'customers.add',
                config: {
                    templateUrl: 'app/customers/customers.add.html',
                    controller: 'CustomersAddController',
                    controllerAs: 'vm',
                    url: '/add',
                    title: 'Müşteri Ekle',
                    settings: {
                        topName: 'customers.list',
                        nav: 4,
                        breadcrumbIcon: 'add',
                        breadcrumb: 'Ekle',
                        resource: 'menu.customers.add'
                    }
                }
            },
            {
                state: 'customers.edit',
                config: {
                    templateUrl: 'app/customers/customers.edit.html',
                    controller: 'CustomersEditController',
                    controllerAs: 'vm',
                    url: '/edit/:Id',
                    title: 'Müşteri Düzenle',
                    settings: {
                        topName: 'customers.list',
                        nav: 4,
                        breadcrumbIcon: 'edit',
                        breadcrumb: 'Düzenle',
                        resource: 'menu.customers.edit'
                    }
                }
            },
            {
                state: 'customers.delete',
                config: {
                    templateUrl: 'app/customers/customers.delete.html',
                    controller: 'CustomersDeleteController',
                    controllerAs: 'vm',
                    url: '/delete/:Id',
                    title: 'Müşteri Sil'
                }
            },
            {
                state: 'customers.detail',
                config: {
                    templateUrl: 'app/customers/customers.detail.html',
                    controller: 'CustomersDetailController',
                    controllerAs: 'vm',
                    url: '/:UserId',
                    title: 'Müşteri Detayı',
                    settings: {
                        topName: 'customers.list',
                        nav: 4,
                        breadcrumbIcon: 'face',
                        breadcrumb: 'Detay',
                        resource: 'menu.customers.detail'
                    }
                }
            },
            {
                state: 'customers.detail.debts',
                config: {
                    templateUrl: 'app/customers/customers.debts.html',
                    url: '/debts',
                    title: 'Müşteri Borçları',
                    controller: 'CustomerDebtListController',
                    controllerAs: 'vm'
                }
            },
            {
                state: 'customers.detail.debtadd',
                config: {
                    templateUrl: 'app/customers/customers.debtadd.html',
                    url: '/debts/add',
                    title: 'Yeni Borç Ekle',
                    controller: 'CustomerDebtAddController',
                    controllerAs: 'vm'
                }
            },
            {
                state: 'customers.detail.directs',
                config: {
                    templateUrl: 'app/customers/customers.directs.html',
                    url: '/directs',
                    title: 'Müşteri Direkt Ödemeleri',
                    controller: 'CustomerDirectListController',
                    controllerAs: 'vm'
                }
            },
            {
                state: 'customers.detail.notes',
                config: {
                    templateUrl: 'app/customers/customers.notes.html',
                    url: '/notes',
                    title: 'Müşteri Notları',
                    controller: 'CustomerNotesController',
                    controllerAs: 'vm'
                }
            },
            {
                state: 'customers.detail.orders',
                config: {
                    templateUrl: 'app/customers/customers.orders.html',
                    url: '/orders',
                    title: 'Müşteri Siparişleri',
                    controller: 'CustomerOrderListController',
                    controllerAs: 'vm'
                }
            },
            {
                state: 'customers.detail.orderdetail',
                config: {
                    templateUrl: 'app/customers/customers.orderdetail.html',
                    url: '/orders/:orderid',
                    title: 'Müşteri Sipariş Detayı',
                    controller: 'CustomerOrderDetailController',
                    controllerAs: 'vm'
                }
            },
            {
                state: 'customers.detail.productdetail',
                config: {
                    templateUrl: 'app/customers/customers.productdetail.html',
                    url: '/orders/:orderid/products/:productid',
                    title: 'Müşteri Ürün Detayı'
                }
            },
            {
                state: 'customers.detail.errordetail',
                config: {
                    templateUrl: 'app/customers/customers.errordetail.html',
                    url: '/orders/:orderid/products/:productid/errors/:errorid',
                    title: 'Ürün Hata Detayı'
                }
            },
            {
                state: 'customers.detail.sparedetail',
                config: {
                    templateUrl: 'app/customers/customers.sparedetail.html',
                    url: '/orders/:orderid/products/:productid/errors/:errorid/spares/:spareid',
                    title: 'Yedek Parça Detayı'
                }
            },
            {
                state: 'customers.detail.deliveries',
                config: {
                    templateUrl: 'app/customers/customers.deliveries.html',
                    url: '/deliveries',
                    title: 'Müşteri Teslimatları'
                }
            },
            {
                state: 'customers.detail.deliverydetail',
                config: {
                    templateUrl: 'app/customers/customers.deliverydetail.html',
                    url: '/deliveries/:deliveryid',
                    title: 'Teslimat Detayı'
                }
            },
            {
                state: 'customers.detail.deliverygroupdetail',
                config: {
                    templateUrl: 'app/customers/customers.deliverygroupdetail.html',
                    url: '/deliveries/:deliveryid/groups/:groupid',
                    title: 'Teslimat Grup Detayı'
                }
            },
            {
                state: 'customers.detail.services',
                config: {
                    templateUrl: 'app/customers/customers.services.html',
                    url: '/services',
                    title: 'Müşteri Servisleri'
                }
            },
            {
                state: 'customers.detail.servicedetail',
                config: {
                    templateUrl: 'app/customers/customers.servicedetail.html',
                    url: '/services/:serviceid',
                    title: 'Müşteri Servis Detayı'
                }
            },
            {
                state: 'customers.detail.addresses',
                config: {
                    templateUrl: 'app/customers/customers.addresses.html',
                    url: '/addresses',
                    title: 'Müşteri Adresleri',
                    controller: 'CustomerAddressesController',
                    controllerAs: 'vm'
                }
            },
            {
                state: 'customers.detail.addressadd',
                config: {
                    templateUrl: 'app/customers/customers.addressadd.html',
                    url: '/addresses/add',
                    title: 'Müşteri Adres Ekle',
                    controller: 'CustomerAddressAddController',
                    controllerAs: 'vm'
                }
            },
            {
                state: 'customers.detail.addressedit',
                config: {
                    templateUrl: 'app/customers/customers.addressedit.html',
                    url: '/addresses/edit/:AddressId',
                    title: 'Müşteri Adres Düzenle',
                    controller: 'CustomerAddressEditController',
                    controllerAs: 'vm'
                }
            },
            {
                state: 'customers.detail.addressdetail',
                config: {
                    templateUrl: 'app/customers/customers.addressdetail.html',
                    url: '/addresses/:AddressId',
                    title: 'Müşteri Adres Detayı',
                    controller: 'CustomerAddressDetailController',
                    controllerAs: 'vm'
                }
            }
        ];
    }
})();