(function () {
    'use strict';

    angular
        .module('app.trendyolIntegration')
        .controller('TrendyolMatchCategoryFilterListController', TrendyolMatchCategoryFilterListController);

    TrendyolMatchCategoryFilterListController.$inject = ['$location', '$stateParams', 'TrendyolIntegrationService', 'NgTableParams', '$uibModal'];

    function TrendyolMatchCategoryFilterListController($location, $stateParams, TrendyolIntegrationService, NgTableParams, $uibModal) {
        /* jshint validthis:true */
        var vm = this;
        vm.settings = globalSettings;
        activate();
        function activate() {
            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 10 }, $location.search()),
                {
                    getData: function () {
                        return TrendyolIntegrationService.getMatchCategoryFilterListByCategory($stateParams.Id).then(function (data) {
                            return data;
                        });
                    }
                });
        }

        vm.delete = function (row) {
            console.log("row" , row);
            TrendyolIntegrationService.deleteFilter(row).then(function (data) {
                vm.tableParams.reload();
            });
        }
    }
})();