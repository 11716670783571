(function () {
    'use strict';

    angular
        .module('app.customers')
        .controller('CustomersDetailController', CustomersDetailController);

    CustomersDetailController.$inject = ['$scope', '$location', '$stateParams', '$filter', 'CustomersService', 'CustomerAddressService', 'OrdersService'];

    function CustomersDetailController($scope, $location, $stateParams, $filter, CustomersService, CustomerAddressService, OrdersService) {
        var vm = this;

        vm.customer = {};

        vm.customerFields = CustomersService.getCustomerFields();

        vm.treeoptions = {
            injectClasses: {
                iExpanded: 'fa fa-minus',
                iCollapsed: 'fa fa-plus'
            }
        };

        vm.treedata = [{
            id: 1,
            type: 'main',
            text: "Yükleniyor...",
            icon: "face",
            children: []
        }];

        vm.showToggle = function (node, expanded, $parentNode, $index, $first, $middle, $last, $odd, $even, $path) {
            if (expanded) {
                if (node.type === 'address_list') {
                    CustomerAddressService.getCustomerAddresses(vm.customer.Id)
                        .then(function (data) {
                            node.children = data.map(function (address) {
                                var obj = {};
                                obj["icon"] = "location_on";
                                obj["text"] = address.Title || '' + address.Name || '' + address.Surname || '';
                                obj["link"] = '/customers/' + address.UserId + '/addresses/' + address.Id;
                                obj["type"] = "address_detail";
                                return obj;
                            });
                        });
                } else if (node.type === 'order_list') {
                    OrdersService.getCustomerOrders(vm.customer.Id)
                        .then(function (data) {
                            node.children = data
                                .sort(function (a, b) {
                                    return new Date(b.OrderDate) - new Date(a.OrderDate);
                                })
                                .map(function (order) {
                                    var obj = {};
                                    obj["icon"] = "location_on";
                                    obj["text"] = order.Code + ' - ' + $filter('date')(order.OrderDate, 'dd.MM.yyyy');
                                    obj["link"] = '/customers/' + order.CustomerId + '/orders/' + order.Id;
                                    obj["type"] = "order_detail";
                                    return obj;
                                });
                        });
                } else if (node.type === 'debt_list') {
                    OrdersService.getCustomerDebts(vm.customer.Id)
                        .then(function (data) {
                            node.children = data
                                .sort(function (a, b) {
                                    return new Date(b.OrderDate) - new Date(a.OrderDate);
                                })
                                .map(function (order) {
                                    var obj = {};
                                    obj["icon"] = "location_on";
                                    obj["text"] = order.Code + ' - ' + $filter('date')(order.OrderDate, 'dd.MM.yyyy');
                                    obj["link"] = '/customers/' + order.CustomerId + '/orders/' + order.Id;
                                    obj["type"] = "debt_detail";
                                    return obj;
                                });
                        });
                } else if (node.type === 'direct_list') {
                    OrdersService.getCustomerDirects(vm.customer.Id)
                        .then(function (data) {
                            node.children = data
                                .sort(function (a, b) {
                                    return new Date(b.OrderDate) - new Date(a.OrderDate);
                                })
                                .map(function (order) {
                                    var obj = {};
                                    obj["icon"] = "location_on";
                                    obj["text"] = order.Code + ' - ' + $filter('date')(order.OrderDate, 'dd.MM.yyyy');
                                    obj["link"] = '/customers/' + order.CustomerId + '/orders/' + order.Id;
                                    obj["type"] = "direct_detail";
                                    return obj;
                                });
                        });
                }
            }
        };

        activate();

        function activate() {
            var h = angular.element(window).height() - 40 - 40 - 30 - 20;
            angular.element('#customer-info').css("height", h);
            angular.element('#customer-detail').css("height", h);

            CustomersService.getCustomer($stateParams.UserId)
                .then(function (data) {
                    vm.customer = data;
                    vm.treedata[0].text = data.Name + " " + data.Surname;
                    vm.treedata[0].children.push({
                        id: 10,
                        text: "Adresler",
                        type: 'address_list',
                        icon: "location_on",
                        link: '/customers/' + data.Id + '/addresses',
                        children: [{
                            text: "Yükleniyor..."
                        }]
                    });
                    vm.treedata[0].children.push({
                        id: 20,
                        text: "Siparişler",
                        type: 'order_list',
                        icon: "add_shopping_cart",
                        link: '/customers/' + data.Id + '/orders',
                        children: [{
                            text: "Yükleniyor..."
                        }]
                    });
                    vm.treedata[0].children.push({
                        id: 20,
                        text: "Borçlar",
                        type: 'debt_list',
                        icon: "add_shopping_cart",
                        link: '/customers/' + data.Id + '/debts',
                        children: [{
                            text: "Yükleniyor..."
                        }]
                    });
                    vm.treedata[0].children.push({
                        id: 20,
                        text: "Direct Ödemeler",
                        type: 'direct_list',
                        icon: "add_shopping_cart",
                        link: '/customers/' + data.Id + '/directs',
                        children: [{
                            text: "Yükleniyor..."
                        }]
                    });
                    vm.treedata[0].children.push({
                        id: 20,
                        text: "Görüşme Notlar",
                        type: 'notes',
                        icon: "event_note",
                        link: '/customers/' + data.Id + '/notes'
                    });
                    vm.treeExpandedNodes = [vm.treedata[0]];
                });
        }
        vm.delete = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                vm.loading = true;
                CustomersService.deleteCustomer(vm.customer)
                    .then(function (response) {
                            vm.loading = false;
                            $location.path('/customers/list');
                        },
                        function (err) {
                            vm.loading = false;
                            alert(err.data.message);
                        });
            }
        }
    }
})();