(function () {
    'use strict';

    angular.module('app.messageTemplates')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'messageTemplate',
                config: {
                    url: '/messageTemplate',
                    title: 'Mesaj Şablonları',
                    abstract: true,
                    templateUrl: 'app/messageTemplates/messageTemplates.html'
                }
            },
            {
                state: 'messageTemplate.list',
                config: {
                    templateUrl: 'app/messageTemplates/messageTemplates.list.html',
                    controller: 'MessageTemplatesListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Mesaj Şablon Listesi',
                    settings: {
                        nav: 10,
                        name: 'messageTemplate.list',
                        topName: 'system',
                        content: 'Mesaj Şablon Listesi',
                        resource: 'menu.messageTemplate.list'
                    }
                }
            }
        ];
    }
})();