(function () {
    'use strict';

    angular.module('app.vendors', [
        // Angular modules 
        'ngRoute',
        'ngFileUpload'

        // Custom modules 

        // 3rd Party Modules
        
    ]);
})();