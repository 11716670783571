(function () {
    'use strict';

    angular
        .module('app.salespointTypes')
        .factory('SalespointTypesService', Service);

    Service.$inject = ['$http', '$q', 'Settings', 'ngAuthSettings', 'logger', 'config', 'ProductsGeneralService', 'GeneralService', 'CustomerAddressService', 'MediaService'];

    function Service($http, $q, Settings, ngAuthSettings, logger, config, ProductsGeneralService, GeneralService, CustomerAddressService, MediaService) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            getFields: getFields
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/salespointTypes/';

        var fields = [
            {
                key: 'Name',
                type: 'horizontalInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Adı',
                    placeholder: 'Adı'
                }
            }
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBase, {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(Id) {
            var deferred = $q.defer();

            $http.get(serviceBase + Id)
                .then(getProductComplete)
                .catch(getProductFailed);

            function getProductComplete(response) {
                deferred.resolve(response.data);
            }

            function getProductFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();

            $http.post(serviceBase, entity)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http.put(serviceBase + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(entity) {
            var deferred = $q.defer();

            $http.delete(serviceBase + entity.Id, entity)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();