(function () {
    'use strict';

    angular
        .module('app.customers')
        .controller('CustomersEditController', CustomersEditController);

    CustomersEditController.$inject = ['$scope', '$stateParams', '$location', 'CustomersService', 'CustomerAddressService', 'CustomerGeneralService', 'logger', 'uibDateParser'];

    function CustomersEditController($scope, $stateParams, $location, CustomersService, CustomerAddressService, CustomerGeneralService, logger, uibDateParser) {
        var vm = this;

        vm.loading = true;

        vm.dt = "2016-11-25T08:18:42.117Z";
        vm.format = "yy/MM/dd";

        vm.open1 = function () {
            vm.popup1.opened = true;
        };

        vm.popup1 = {
            opened: false
        };

        CustomersService.getCustomer($stateParams.Id).then(function (data) {
            vm.customer = data;
            vm.loading = false;
        });

        vm.customerFields = CustomersService.getCustomerFields();

        vm.editCustomer = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                CustomersService.editCustomer(vm.customer).then(function (data) {
                    vm.loading = false;
                    logger.success("Kullanıcı düzenleme işlemi başarıyla tamamlanmıştır.");
                    $location.path('/customers/' + vm.customer.Id);
                });
            }
        };

        vm.Cancel = function () {
            $location.path('/customers/' + vm.customer.Id);
        };
    }
})();