(function () {
    "use strict";

    angular
        .module("app.orders")
        .controller("OrdersListController", OrdersListController);

    OrdersListController.$inject = [
        "$scope",
        "$location",
        "$stateParams",
        "OrdersService",
        "NgTableParams",
        "SharedData",
        "$uibModal",
        "InvoiceService",
        "SettingsService"
    ];

    function OrdersListController(
        $scope,
        $location,
        $stateParams,
        OrdersService,
        NgTableParams,
        SharedData,
        $uibModal,
        InvoiceService,
        SettingsService
    ) {
        var vm = this;
        vm.SharedData = SharedData;
        vm.defaultProvider = null;
        activate();

        function activate() {
            vm.refresh = function () {
                vm.loading = true;
                vm.tableParams.reload();
                vm.loading = false;

            };

            SettingsService.getAllProviders().then(function (data) {
                vm.defaultProvider = data.filter(prov => prov.Default === true);
                vm.defaultProvider = vm.defaultProvider[0];
                console.log(vm.defaultProvider);
            });


            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend(
                    { page: 1, count: 18, sorting: { OrderDate: "desc" } },
                    $location.search()
                ),
                {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {}; // params.filter();
                        filterData.PageIndex = params.page();
                        filterData.PageSize = params.count();
                        filterData.SortProperty = Object.keys(
                            params.sorting()
                        )[0];
                        filterData.SortType = params.sorting()[
                            Object.keys(params.sorting())[0]
                        ];

                        //filterData.SelectFields = "Id,Code,OrderDate,Customer.Name,Customer.Surname,OrderDate,Total,TotalDiscount,Status.Name,Description,Bank.Name,OrderProduct,OrderProduct.Product";

                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return OrdersService.getOrders(filterData).then(
                            function (data) {
                                params.total(data.FilteredCount);
                                return data.Data;
                            }
                        );
                    }
                }
            );
            OrdersService.getOrderStatuses()
                .then(function (data) {
                    vm.OrderStatus = data.filter(function (x) {
                        if (x.Id == '1efca84d-a006-40f5-aa8e-e5f278e9b03a' ||
                            x.Id == '59158dcb-c607-487f-9712-898e08858c90' ||
                            x.Id == 'a9752b35-21be-4f12-9ba3-41f0acdd8a04' ||
                            x.Id == '654ef3ff-1fca-431f-821d-c073aea6fa73') {
                            return false
                        } else {
                            return true;
                        }
                    });
                });
        }

        vm.jsonParse = function (objString) {
            return JSON.parse(objString);
        }

        var clicked = false;
        vm.SelectAll = function () {
            if (clicked != true) {
                var nonChecked = vm.tableParams.data.filter(x => x.IsSelected != true);
                for (var i = 0; i < nonChecked.length; i++) {
                    nonChecked[i].IsSelected = true;
                }
                clicked = true;
            }
            else {
                var nonChecked = vm.tableParams.data.filter(x => x.IsSelected == true);
                for (var i = 0; i < nonChecked.length; i++) {
                    nonChecked[i].IsSelected = undefined;
                }
                clicked = false;
            }
        }
        vm.SelectAllFilter = {
            IsSelected: { id: 'ng-table/check.html', placeholder: 'Tümünü Seç' }
        }

        vm.MarketOrderRefresh = function () {
            OrdersService.getMarketOrders().then(
                function (data) {
                    params.total(data.FilteredCount);
                    // return data.Data;
                }
            );
        };

        //vm.getMarketOrders();

        vm.TotalFilter = {
            TotalMin: {
                id: "ng-table/money.html",
                placeholder: "Minimum Ücret"
            },
            TotalMax: {
                id: "ng-table/money.html",
                placeholder: "Maximum Ücret"
            }
        };
        vm.DateFilter = {
            OrderDateMin: {
                id: "ng-table/date.html",
                placeholder: "Minimum Tarih"
            },
            OrderDateMax: {
                id: "ng-table/date.html",
                placeholder: "Maximum Tarih"
            }
        };

        vm.Status = OrdersService.getOrderStatuses().then(function (response) {
            return response.map(function (item) {
                return { id: item.Id, title: item.Name };
            });
        });


        vm.getColor = function (statusId) {
            if (statusId.toUpperCase() === "6FAB9E8B-E3EF-4336-8F98-BBA4607193D1") {
                return "5cb8a0";
            }
            else if (statusId.toUpperCase() === "02F5383E-753E-4F0C-945A-D6FBBEFFBF56") {
                return "000";
            } else if (statusId.toUpperCase() === "1EFCA84D-A006-40F5-AA8E-E5F278E9B03A") {
                return "ff6b6b";
            }
            else if (statusId.toUpperCase() === "02F5383E-753E-4F0C-945A-D6FBBEFFBF56") {
                return "efbb29";
            }
            else if (statusId.toUpperCase() === "DCC9D370-5182-47A7-B0C3-9C65A745D712") {
                return "0c98e3";
            }
            else if (statusId.toUpperCase() === "9537CD7E-8665-4533-8247-80B1DA78CB06") {
                return "fcd316";
            }
            else if (statusId.toUpperCase() === "29BE5037-642A-4854-AE7D-772D0BE8367E") {
                return "757472";
            }
            else if (statusId.toUpperCase() === "654EF3FF-1FCA-431F-821D-C073AEA6FA73") {
                return "c12b1c";
            } else {
                return "5cb8a0";
            }
        }

        vm.findInvoiceId = function (orderData) {
            var orderProductData = orderData.OrderProduct;
            const result = Object.values(orderProductData).every((value) => value.InvoiceId != null);
            return result;
        }
        vm.CreateCargoNumber = function (data) {
            OrdersService.CreateCargoNumber(data)
                .then(function (response) {
                    window.location.reload();

                    vm.loading = false;
                })
                .catch(function () {
                    vm.loading = false;
                });
        }

        vm.getEInvoice = function (orderData) {

            var findInvoice = vm.findInvoiceId(orderData);

            vm.modalTitle = "Fatura Görüntüleme";
            $uibModal.open({
                templateUrl: 'ModalContent.html',
                controller: 'IncomeModalOrderController',
                controllerAs: 'vm',
                windowClass: 'app-modal-window',
                resolve: {
                    items: function () {
                        return {
                            data: orderData.OrderProduct[0].InvoiceId
                        };
                    }
                }
            }).then(function () {
                vm.RefreshInvoice();
            });
        }

        vm.createShipmentCodes = function () {
            var selectedData = vm.tableParams.data.filter(x => x.IsSelected == true && x.ShipmentCode == null);
            OrdersService.CreateCargoNumberSelected(selectedData)
                .then(function (response) {
                    window.location.reload();
                    vm.loading = false;
                })
                .catch(function () {
                    vm.loading = false;
                });

        }

        vm.createInvoices = function () {
            var selectedData = vm.tableParams.data.filter(x => x.IsSelected == true);
            InvoiceService.sendSelectedEInvoice(selectedData)
                .then(function (response) {
                    window.location.reload();
                    vm.loading = false;
                })
                .catch(function () {
                    vm.loading = false;
                });

        }

        vm.showOrder = function (order) {
            vm.openModal(order)
        }

        vm.modalTitle = "Sipariş Özeti";
        vm.modalInstance = null;
        vm.openModal = function (data) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'ModalOrderContent.html',
                controller: 'ModalOrderController',
                controllerAs: 'vm',
                resolve: {
                    items: function () {
                        return {
                            data: data
                        };
                    }
                }
            });
        }

        vm.changeStatus = function (StatusId) {
            if (StatusId && StatusId !== vm.order.StatusId) {
                vm.loading = true;
                OrdersService.changeStatus(vm.order.Id, StatusId)
                    .then(function () {
                        OrdersService.getOrder($stateParams.OrderId)
                            .then(function (data) {
                                vm.order = data;
                                vm.customer = vm.order.Customer;
                                vm.calculateTotal();
                                vm.loading = false;
                            })
                            .catch(function (response) {
                                alert(response.data.message);
                                vm.loading = false;
                            });
                    })
                    .catch(function (response) {
                        alert(response.data.message);
                        vm.loading = false;
                    });
            }
        };

        vm.printOrdersCargo = function () {

            var selectedData = vm.tableParams.data.filter(x => x.IsSelected == true);
            var printContents = "";
            for (var i = 0; i < selectedData.length; i++) {
                if (selectedData[i].CargoJobId == null) {
                    selectedData[i].CargoJobId = "";
                }
                if (selectedData[i].CargoReferenceCode == null) {
                    selectedData[i].CargoReferenceCode = selectedData[i].ShipmentCode;
                }
                if (selectedData[i].DeliveryAddress.TcNo != null) {
                    console.log(selectedData[i].DeliveryAddress.TcNo);
                    selectedData[i].DeliveryAddress.TcNo = selectedData[i].DeliveryAddress.TcNo.substring(0, 5) + "******";
                }
                printContents = printContents + ` <div id="cargo-print"  style="padding:0px; min-width:148mm;min-height:215mm;">
                                        <table class="table" style="padding:0px; min-width:148mm;min-height:210mm;">
                                            <tr>
                                                <th colspan="6" style="border-top:none;">
                                                    <div>
                                                        <div class="col-xs-6">
                                                            <img class="img-responsive" style= "height:50p"
                                                                 src="${vm.SharedData.Settings.WebServer + '/themes/' + vm.SharedData.Settings.ThemeName + '/images/logo.png'}" />
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td colspan="3" ng-if="vm.SharedData.Settings">
                                                    <h5><b translate="orders.sender-addressInfo">Gönderici Adres Bilgileri</b></h5>
                                                    <address>
                                                        <strong>
                                                            ${vm.SharedData.Settings.CompanyName}
                                                        </strong>
                                                        <p>
                                                            ${vm.SharedData.Settings.CompanyPhone}
                                                        </p>
                                                        <p>
                                                            ${vm.SharedData.Settings.CompanyAddress}
                                                        </p>
                                                        <p>
                                                            ${vm.SharedData.Settings.CompanyDistrict}/${vm.SharedData.Settings.CompanyCity}
                                                        </p>
                                                    </address>
                                                </td>
                                                <td colspan="3" ng-if="vm.order.DeliveryAddressId">
                                                    <h5><b translate="orders.buyer-addressInfo">Alıcı Adres Bilgileri</b></h5>
                                                    <address>
                                                        <strong>
                                                            ${selectedData[i].DeliveryAddress.Title} - ${selectedData[i].DeliveryAddress.User}
                                                            ${(selectedData[i].DeliveryAddress.Name || '') + ' ' + (selectedData[i].DeliveryAddress.Surname || '')}
                                                        </strong>
                                                        <p>
                                                            ${selectedData[i].DeliveryAddress.Phone1}
                                                            ${" / " + selectedData[i].DeliveryAddress.Phone2 ? selectedData[i].DeliveryAddress.Phone2 : ""}
                                                            ${selectedData[i].DeliveryAddress.FaxNumber ? " / " + selectedData[i].DeliveryAddress.FaxNumber : ""}
                                                        </p>
                                                        <p ng-if="selectedData[i].DeliveryAddress.TcNo">
                                                            TcNo:${selectedData[i].DeliveryAddress.TcNo}
                                                        </p>
                                                        <p>
                                                            ${selectedData[i].DeliveryAddress.Description}
                                                            ${selectedData[i].DeliveryAddress.ZipPostalCode}
                                                            ${selectedData[i].DeliveryAddress.ApartmentNo} ${selectedData[i].DeliveryAddress.Street1}
                                                            ${selectedData[i].DeliveryAddress.City}${selectedData[i].DeliveryAddress.CityName}
                                                            ${selectedData[i].DeliveryAddress.District}${selectedData[i].DeliveryAddress.DistrictName}
                                                            ${selectedData[i].DeliveryAddress.StateProvince}${selectedData[i].DeliveryAddress.StateProvinceName}
                                                            ${selectedData[i].DeliveryAddress.Country}${selectedData[i].DeliveryAddress.CountryName}
                                                        </p>
                                                    </address>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="border-top:none; text-align:center" ng-if="selectedData[i].CargoJobId">
                                         
                                                    <div class="col-xs-12" align="center">
                                                        <img class="img-responsive" style="border-top:none; text-align:center; height:50px;"
                                                             src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${selectedData[i].CargoJobId}'" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="border-top:none; text-align:center">
                                                    <div class="col-xs-12" align="center">
                                                        <img class="img-responsive" style="border-top:none; text-align:center;height:100px;"
                                                             src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${selectedData[i].CargoReferenceCode}'" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="border-top:none; text-align:center">
                                                    <strong>
                                                        <h2>Barkod No:${selectedData[i].CargoReferenceCode}</h2>
                                                    </strong>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                        </table>
                                    </div>`;
            }

            //var printContents = document.getElementById(divName).innerHTML;
            var popupWin = window.open('', '_blank', '');
            popupWin.document.open();
            var printContent = `<html>
                                    <body onload="window.print()">
                                   ${printContents}
                                    </body>
                                    <link href="/dist/css/mazakacore.css"  rel="stylesheet" />
                                    <script src="/dist/js/mazakacore-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-angular-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-app.js" ></script>
                                    <style>
                                        table {
                                            font-size:10px;
                                            table-layout:auto;
                                        }
                                        .dd {
                                            display:inline-block;
                                            font-size:10px;
                                            font-weight:bold;
                                        }
                                    </style>
                                </html>`;


            popupWin.document.write(printContent);
            popupWin.document.close();
        };
        vm.printOrders = function () {

            var selectedData = vm.tableParams.data.filter(x => x.IsSelected == true);
            console.log("selectedData", selectedData);
            var printContents = "";
            for (var i = 0; i < selectedData.length; i++) {
                if (selectedData[i].CargoJobId == null) {
                    selectedData[i].CargoJobId = "";
                }
                if (selectedData[i].CargoReferenceCode == null) {
                    selectedData[i].CargoReferenceCode = selectedData[i].ShipmentCode;
                }
                var tax = "";
                if (selectedData[i].Customer.TaxNumber || selectedData[i].Customer.TaxOffice) {
                    tax = `<p><span class="dd">Vergi Dairesi / Vergi No:</span>
                               <span ng-if=" selectedData[i].Customer.TaxOffice">${ selectedData[i].Customer.TaxOffice}</span> /
                               <span ng-if=" selectedData[i].Customer.TaxNumber">${ selectedData[i].Customer.TaxNumber}</span>
                           </p>`;
                }
                var transferBank = "";
                if (selectedData[i].TransferEFTBankId) {
                    transferBank = `<span style="color:red">
                                        ${ selectedData[i].TransferEFTBank.Name}
                                    </span>`;
                }
                var currency = "";
                if (selectedData[i].SalesOrderCurrency.length != 0) {
                    var curr = "";
                    for (var i = 0; i < selectedData[i].SalesOrderCurrency.length; i++) {
                        curr = curr + `<div class="pull-left" >
                                                               <b>'${curr.Currency.Symbol}</b> - ${formatter.format(selectedData[i].SalesOrderCurrency.Currency.Rate)}</div>`;
                    }
                    currency = `<tr>
                                                    <td colspan="7">
                                                        <p>
                                                            <div class="col-md-12">
                                                                <div class="pull-left"><b>Döviz Kurları:</b></div>
                                                                ${curr}
                                                            </div>
                                                        </p>
                                                    </td>
                                                </tr>`;
                }

                var productList = "";
                for (var j = 0; j < selectedData[i].OrderProduct.length; j++) {
                    productList = productList +
                        `
                                                    <tr>
                                                                <td>${ selectedData[i].OrderProduct[j].Code}</td>
                                                                <td>${selectedData[i].OrderProduct[j].Code}</td>
                                                                <td>${selectedData[i].OrderProduct[j].Name}</td>
                                                                <td>
                                                                    <span>
                                                                            ${formatter.format((selectedData[i].OrderProduct[j].UnitPrice) * (selectedData[i].OrderProduct[j].Currency.Rate))}     
                                                                    </span>
                                                                    
                                                                </td>
                                                                <td>${selectedData[i].OrderProduct[j].Quantity}</td>
                                                                <td>
                                                                    %${selectedData[i].OrderProduct[j].Tax}KDV
                                                                </td>
                                                                <td>
                                                                        ${formatter.format(selectedData[i].OrderProduct[j].Quantity * (selectedData[i].OrderProduct[j].UnitPriceWithDiscount || selectedData[i].OrderProduct[j].UnitPrice) * (selectedData[i].OrderProduct[j].TaxIncluded ? 1.00 : ((selectedData[i].OrderProduct[j].Tax / 100) + 1.00)))}

                                                                </td>
                                                            </tr>
                         `;
                }

                printContents = printContents + `<div id="order-print" >
                                                        <table class="table" style="line-height:2px;table-layout:auto;min-width:148mm;min-height:213mm;">
                                                            <tr>
                                                                <th colspan="7" style="border-top:none;">
                                                                    <div>
                                                                        <div class="col-xs-4">
                                                                            <img class="img-responsive" style="max-height:50px;"
                                                                                 src="${vm.SharedData.Settings.WebServer + '/themes/' + vm.SharedData.Settings.ThemeName + '/images/logo.png'}" />
                                                                        </div>
                                                                        <div class="col-xs-4" align="center">
                                                                            <img 
                                                                                 style="border-top:none; text-align:center;max-height:50px;"
                                                                                 src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${ selectedData[i].CargoJobId}'" />
                                                                        </div>
                                                                        <div class="col-xs-4" align="center">
                                                                            <img
                                                                                 style="border-top:none; text-align:center;max-height:50px;"
                                                                                 src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${ selectedData[i].CargoReferenceCode}'" />
                                                                           
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="${selectedData[i].DeliveryAddressId ? 2 : 2}">
                                                                    <p>
                                                                        <b class="dd">
                                                                            <span translate="orders.detail.orderNumber">Sipariş Numarası : </span>
                                                                            : ${selectedData[i].Code}
                                                                        </b>&nbsp;
                                                                        <span style="color:red">${ selectedData[i].Bank.Name}</span>
                                                                        ${transferBank}                                                                       
                                                                        &nbsp;
                                                                        ${selectedData[i].OrderDate}
                                                                    </p>
                                                                    <p>Ad Soyad/Ünvan:${ selectedData[i].Customer.Name} ${selectedData[i].Customer.Surname}</p>
                                                                    <p>
                                                                        Telefon: ${ selectedData[i].DeliveryAddress.Phone1}
                                                                        ${" / " + selectedData[i].DeliveryAddress.Phone2 ? selectedData[i].DeliveryAddress.Phone2 : ""}
                                                                        ${ selectedData[i].DeliveryAddress.FaxNumber ? " / " + selectedData[i].DeliveryAddress.FaxNumber : ""}
                                                                    </p>
                                                                   ${tax}
                                                                </td>
                                                                <td colspan="2">
                                                                    <p>
                                                                        <b>Adres Başlığı:</b> ${ selectedData[i].DeliveryAddress.Title}
                                                                    </p>
                                                                    <p>
                                                                        Şehir:
                                                                        ${ selectedData[i].DeliveryAddress.StateProvince}${selectedData[i].DeliveryAddress.StateProvinceName}
                                                                    </p>
                                                                    <p>
                                                                        Adres: ${ selectedData[i].DeliveryAddress.Description}
                                                                        ${ selectedData[i].DeliveryAddress.ZipPostalCode}
                                                                        ${ selectedData[i].DeliveryAddress.ApartmentNo} ${selectedData[i].DeliveryAddress.Street1}
                                                                        ${ selectedData[i].DeliveryAddress.City}${selectedData[i].DeliveryAddress.CityName}
                                                                        ${ selectedData[i].DeliveryAddress.District}${selectedData[i].DeliveryAddress.DistrictName}
                                                                        ${ selectedData[i].DeliveryAddress.StateProvince}${selectedData[i].DeliveryAddress.StateProvinceName}
                                                                        ${ selectedData[i].DeliveryAddress.Country}${selectedData[i].DeliveryAddress.CountryName}
                                                                    </p>
                                                                </td>
                                                                <td colspan="2">
                                                                    <p>
                                                                        <b>Sipariş Notu:</b> ${ selectedData[i].Description}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                           ${currency}
                                                            <tr >
                                                                <th style="width:2%;">#</th>
                                                                <th style="width:10%;" >Ürün Kodu</th>
                                                                <th style="width:30%;" >Ürün Adı</th>
                                                                <th style="width:14%;" >Birim Fiyat</th>
                                                                <th style="width:4%;" >Miktar</th>
                                                                <th style="width:18%;" >Vergi</th>
                                                                <th style="width:22%;" >Toplam Fiyat</th>
                                                            </tr>
                                                            ${productList}
                                                            <tr>
                                                                <td colspan="7">
                                                                </td>
                                                            </tr>
                                                            <style>
                                                                .totalList {
                                                                    font-size: 8px;
                                                                    display: block;
                                                                    float: left;
                                                                    width: 100%;
                                                                }

                                                                    .totalList td {
                                                                        display: block;
                                                                        float: left;
                                                                        width: 20%;
                                                                    }

                                                                        .totalList td div {
                                                                            display: block;
                                                                            float: left;
                                                                            width: 100%;
                                                                        }
                                                            </style>
                                                            <tr>
                                                                <td colspan="7">
                                                                    <table style="line-height:2px;  width:100%; table-layout:auto;">
                                                                        <tr class="totalList">
                                                                            <td>
                                                                                <div>
                                                                                         <b>Ara Toplam:</b>
                                                                                    ${formatter.format(selectedData[i].SubTotal)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <b>Vergiler Toplamı:</b>
                                                                                    ${formatter.format(selectedData[i].TotalTax)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <b>Kargo Toplamı:</b>
                                                                                    ${formatter.format(selectedData[i].ShippingPrice)}
                                                                                </div>
                                                                              
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                <b>Genel İndirim:</b>
                                                                                ${formatter.format(selectedData[i].GeneralDiscount)}
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <b>Genel Toplam:</b>
                                                                                    ${formatter.format(selectedData[i].Total)}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr></tr>
                                                            <tr>
                                                                <th colspan="7">
                                                                    <div style="font-size:8px;text-align:center">
                                                                        Mazaka Yazılım E-Ticaret Paketleri Aracılığıyla
                                                                        Hazırlanmıştır.
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </table>
                                                    </div>`;
            }
            var popupWin = window.open('', '_blank', '');
            popupWin.document.open();
            var printContent = `<html>
                                    <body onload="window.print()">
                                   ${printContents}
                                    </body>
                                    <link href="/dist/css/mazakacore.css"  rel="stylesheet" />
                                    <script src="/dist/js/mazakacore-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-angular-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-app.js" ></script>
                                    <style>
                                        table {
                                            font-size:10px;
                                            table-layout:auto;
                                        }
                                        .dd {
                                            display:inline-block;
                                            font-size:10px;
                                            font-weight:bold;
                                        }
                                    </style>
                                </html>`;


            popupWin.document.write(printContent);
            popupWin.document.close();
        };

        vm.printOrderShipmentCode= function (selectedData) {
            console.log("selecaaat", selectedData);
            var printContents = "";
            
                if (selectedData.CargoJobId == null) {
                    selectedData.CargoJobId = "";
                }
                if (selectedData.CargoReferenceCode == null) {
                    selectedData.CargoReferenceCode = selectedData.ShipmentCode;
                }
                if (selectedData.DeliveryAddress.TcNo != null) {
                    selectedData.DeliveryAddress.TcNo = selectedData.DeliveryAddress.TcNo.substring(0, 5) + "******";
                }
                printContents = printContents + `<div id="cargo-print"  style="padding:0px; min-width:148mm;min-height:215mm;">
                                        <table class="table" style="padding:0px; min-width:148mm;min-height:210mm;">
                                            <tr>
                                                <th colspan="6" style="border-top:none;">
                                                    <div>
                                                        <div class="col-xs-6">
                                                            <img class="img-responsive" style= "height:50p"
                                                                 src="${vm.SharedData.Settings.WebServer + '/themes/' + vm.SharedData.Settings.ThemeName + '/images/logo.png'}" />
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td colspan="3" ng-if="vm.SharedData.Settings">
                                                    <h5><b translate="orders.sender-addressInfo">Gönderici Adres Bilgileri</b></h5>
                                                    <address>
                                                        <strong>
                                                            ${vm.SharedData.Settings.CompanyName}
                                                        </strong>
                                                        <p>
                                                            ${vm.SharedData.Settings.CompanyPhone}
                                                        </p>
                                                        <p>
                                                            ${vm.SharedData.Settings.CompanyAddress}
                                                        </p>
                                                        <p>
                                                            ${vm.SharedData.Settings.CompanyDistrict}/${vm.SharedData.Settings.CompanyCity}
                                                        </p>
                                                    </address>
                                                </td>
                                                <td colspan="3" ng-if="vm.order.DeliveryAddressId">
                                                    <h5><b translate="orders.buyer-addressInfo">Alıcı Adres Bilgileri</b></h5>
                                                    <address>
                                                        <strong>
                                                            ${selectedData.DeliveryAddress.Title} - ${selectedData.DeliveryAddress.User}
                                                            ${(selectedData.DeliveryAddress.Name || '') + ' ' + (selectedData.DeliveryAddress.Surname || '')}
                                                        </strong>
                                                        <p>
                                                            ${selectedData.DeliveryAddress.Phone1}
                                                            ${" / " + selectedData.DeliveryAddress.Phone2 ? selectedData.DeliveryAddress.Phone2 : ""}
                                                            ${selectedData.DeliveryAddress.FaxNumber ? " / " + selectedData.DeliveryAddress.FaxNumber : ""}
                                                        </p>
                                                        <p >
                                                            TcNo:${selectedData.DeliveryAddress.TcNo}
                                                        </p>
                                                        <p>
                                                            ${selectedData.DeliveryAddress.Description}
                                                            ${selectedData.DeliveryAddress.ZipPostalCode}
                                                            ${selectedData.DeliveryAddress.ApartmentNo} ${selectedData.DeliveryAddress.Street1}
                                                            ${selectedData.DeliveryAddress.City}${selectedData.DeliveryAddress.CityName}
                                                            ${selectedData.DeliveryAddress.District}${selectedData.DeliveryAddress.DistrictName}
                                                            ${selectedData.DeliveryAddress.StateProvince}${selectedData.DeliveryAddress.StateProvinceName}
                                                            ${selectedData.DeliveryAddress.Country}${selectedData.DeliveryAddress.CountryName}
                                                        </p>
                                                    </address>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="border-top:none; text-align:center" ng-if="selectedData[i].CargoJobId">
                                         
                                                    <div class="col-xs-12" align="center">
                                                        <img class="img-responsive" style="border-top:none; text-align:center; height:50px;"
                                                             src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${selectedData.CargoJobId}'" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="border-top:none; text-align:center">
                                                    <div class="col-xs-12" align="center">
                                                        <img class="img-responsive" style="border-top:none; text-align:center;height:100px;"
                                                             src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${selectedData.CargoReferenceCode}'" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="border-top:none; text-align:center">
                                                    <strong>
                                                        <h2>Barkod No:${selectedData.CargoReferenceCode}</h2>
                                                    </strong>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                        </table>
                                    </div>`;
            

            //var printContents = document.getElementById(divName).innerHTML;
            console.log("asasdasd", printContents);
            var popupWin = window.open('', '_blank', '');
            popupWin.document.open();
            var printContent = `<html>
                                    <body onload="window.print()">
                                   ${printContents}
                                    </body>
                                    <link href="/dist/css/mazakacore.css"  rel="stylesheet" />
                                    <script src="/dist/js/mazakacore-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-angular-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-app.js" ></script>
                                    <style>
                                        table {
                                            font-size:10px;
                                            table-layout:auto;
                                        }
                                        .dd {
                                            display:inline-block;
                                            font-size:10px;
                                            font-weight:bold;
                                        }
                                    </style>
                                </html>`;


            popupWin.document.write(printContent);
            popupWin.document.close();
        };

        var formatter = new Intl.NumberFormat('tr-TR', {
            style: 'currency',
            currency: 'TRY',
        });

       
        vm.printOrderDetails = function (selectedData) {

            var printContents = "";
                if (selectedData.CargoJobId == null) {
                    selectedData.CargoJobId = "";
                }
                if (selectedData.CargoReferenceCode == null) {
                    selectedData.CargoReferenceCode = selectedData.ShipmentCode;
                }
                var tax = "";
                if (selectedData.Customer.TaxNumber || selectedData.Customer.TaxOffice) {
                    tax = `<p><span class="dd">Vergi Dairesi / Vergi No:</span>
                               <span ng-if=" selectedData[i].Customer.TaxOffice">${ selectedData.Customer.TaxOffice}</span> /
                               <span ng-if=" selectedData[i].Customer.TaxNumber">${ selectedData.Customer.TaxNumber}</span>
                           </p>`;
                }
                var transferBank = "";
                if (selectedData.TransferEFTBankId) {
                    transferBank = `<span style="color:red">
                                        ${ selectedData.TransferEFTBank.Name}
                                    </span>`;
                }
                var currency = "";
                if (selectedData.SalesOrderCurrency.length != 0) {
                    var curr = "";
                    for (var i = 0; i < selectedData.SalesOrderCurrency.length; i++) {
                        curr = curr + `<div class="pull-left" >
                                                               <b>'${curr.Currency.Symbol}</b> - ${formatter.format(selectedData.SalesOrderCurrency.Currency.Rate)}</div>`;
                    }
                    currency = `<tr>
                                                    <td colspan="7">
                                                        <p>
                                                            <div class="col-md-12">
                                                                <div class="pull-left"><b>Döviz Kurları:</b></div>
                                                                ${curr}
                                                            </div>
                                                        </p>
                                                    </td>
                                                </tr>`;
                }

                var productList = "";
                for (var j = 0; j < selectedData.OrderProduct.length; j++) {
                    productList = productList +
                        `
                                                    <tr>
                                                                <td>${ selectedData.OrderProduct[j].Code}</td>
                                                                <td>${selectedData.OrderProduct[j].Code}</td>
                                                                <td>${selectedData.OrderProduct[j].Name}</td>
                                                                <td>
                                                                    <span>
                                                                            ${formatter.format((selectedData.OrderProduct[j].UnitPrice) * (selectedData.OrderProduct[j].Currency.Rate))}     
                                                                    </span>
                                                                    
                                                                </td>
                                                                <td>${selectedData.OrderProduct[j].Quantity}</td>
                                                                <td>
                                                                    %${selectedData.OrderProduct[j].Tax}KDV
                                                                </td>
                                                                <td>
                                                                        ${formatter.format(selectedData.OrderProduct[j].Quantity * (selectedData.OrderProduct[j].UnitPriceWithDiscount || selectedData.OrderProduct[j].UnitPrice) * (selectedData.OrderProduct[j].TaxIncluded ? 1.00 : ((selectedData.OrderProduct[j].Tax / 100) + 1.00)))}

                                                                </td>
                                                            </tr>
                         `;
                }

                printContents = printContents + `<div id="order-print" >
                                                        <table class="table" style="line-height:2px;table-layout:auto;min-width:148mm;min-height:213mm;">
                                                            <tr>
                                                                <th colspan="7" style="border-top:none;">
                                                                    <div>
                                                                        <div class="col-xs-4">
                                                                            <img class="img-responsive" style="max-height:50px;"
                                                                                 src="${vm.SharedData.Settings.WebServer + '/themes/' + vm.SharedData.Settings.ThemeName + '/images/logo.png'}" />
                                                                        </div>
                                                                        <div class="col-xs-4" align="center">
                                                                            <img 
                                                                                 style="border-top:none; text-align:center;max-height:50px;"
                                                                                 src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${ selectedData.CargoJobId}'" />
                                                                        </div>
                                                                        <div class="col-xs-4" align="center">
                                                                            <img
                                                                                 style="border-top:none; text-align:center;max-height:50px;"
                                                                                 src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${ selectedData.CargoReferenceCode}'" />
                                                                           
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="${selectedData.DeliveryAddressId ? 2 : 2}">
                                                                    <p>
                                                                        <b class="dd">
                                                                            <span translate="orders.detail.orderNumber">Sipariş Numarası : </span>
                                                                            : ${selectedData.Code}
                                                                        </b>&nbsp;
                                                                        <span style="color:red">${ selectedData.Bank.Name}</span>
                                                                        ${transferBank}                                                                       
                                                                        &nbsp;
                                                                        ${selectedData.OrderDate}
                                                                    </p>
                                                                    <p>Ad Soyad/Ünvan:${ selectedData.Customer.Name} ${selectedData.Customer.Surname}</p>
                                                                    <p>
                                                                        Telefon: ${ selectedData.DeliveryAddress.Phone1}
                                                                        ${" / " + selectedData.DeliveryAddress.Phone2 ? selectedData.DeliveryAddress.Phone2 : ""}
                                                                        ${ selectedData.DeliveryAddress.FaxNumber ? " / " + selectedData.DeliveryAddress.FaxNumber : ""}
                                                                    </p>
                                                                   ${tax}
                                                                </td>
                                                                <td colspan="2">
                                                                    <p>
                                                                        <b>Adres Başlığı:</b> ${ selectedData.DeliveryAddress.Title}
                                                                    </p>
                                                                    <p>
                                                                        Şehir:
                                                                        ${ selectedData.DeliveryAddress.StateProvince}${selectedData.DeliveryAddress.StateProvinceName}
                                                                    </p>
                                                                    <p>
                                                                        Adres: ${ selectedData.DeliveryAddress.Description}
                                                                        ${ selectedData.DeliveryAddress.ZipPostalCode}
                                                                        ${ selectedData.DeliveryAddress.ApartmentNo} ${selectedData.DeliveryAddress.Street1}
                                                                        ${ selectedData.DeliveryAddress.City}${selectedData.DeliveryAddress.CityName}
                                                                        ${ selectedData.DeliveryAddress.District}${selectedData.DeliveryAddress.DistrictName}
                                                                        ${ selectedData.DeliveryAddress.StateProvince}${selectedData.DeliveryAddress.StateProvinceName}
                                                                        ${ selectedData.DeliveryAddress.Country}${selectedData.DeliveryAddress.CountryName}
                                                                    </p>
                                                                </td>
                                                                <td colspan="2">
                                                                    <p>
                                                                        <b>Sipariş Notu:</b> ${ selectedData.Description}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                           ${currency}
                                                            <tr >
                                                                <th style="width:2%;">#</th>
                                                                <th style="width:10%;" >Ürün Kodu</th>
                                                                <th style="width:30%;" >Ürün Adı</th>
                                                                <th style="width:14%;" >Birim Fiyat</th>
                                                                <th style="width:4%;" >Miktar</th>
                                                                <th style="width:18%;" >Vergi</th>
                                                                <th style="width:22%;" >Toplam Fiyat</th>
                                                            </tr>
                                                            ${productList}
                                                            <tr>
                                                                <td colspan="7">
                                                                </td>
                                                            </tr>
                                                            <style>
                                                                .totalList {
                                                                    font-size: 8px;
                                                                    display: block;
                                                                    float: left;
                                                                    width: 100%;
                                                                }

                                                                    .totalList td {
                                                                        display: block;
                                                                        float: left;
                                                                        width: 20%;
                                                                    }

                                                                        .totalList td div {
                                                                            display: block;
                                                                            float: left;
                                                                            width: 100%;
                                                                        }
                                                            </style>
                                                            <tr>
                                                                <td colspan="7">
                                                                    <table style="line-height:2px;  width:100%; table-layout:auto;">
                                                                        <tr class="totalList">
                                                                            <td>
                                                                                <div>
                                                                                         <b>Ara Toplam:</b>
                                                                                    ${formatter.format(selectedData.SubTotal)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <b>Vergiler Toplamı:</b>
                                                                                    ${formatter.format(selectedData.TotalTax)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <b>Kargo Toplamı:</b>
                                                                                    ${formatter.format(selectedData.ShippingPrice)}
                                                                                </div>
                                                                              
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                <b>Genel İndirim:</b>
                                                                                ${formatter.format(selectedData.GeneralDiscount)}
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <b>Genel Toplam:</b>
                                                                                    ${formatter.format(selectedData.Total)}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr></tr>
                                                            <tr>
                                                                <th colspan="7">
                                                                    <div style="font-size:8px;text-align:center">
                                                                        Mazaka Yazılım E-Ticaret Paketleri Aracılığıyla
                                                                        Hazırlanmıştır.
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </table>
                                                    </div>`;
            
            var popupWin = window.open('', '_blank', '');
            popupWin.document.open();
            var printContent = `<html>
                                    <body onload="window.print()">
                                   ${printContents}
                                    </body>
                                    <link href="/dist/css/mazakacore.css"  rel="stylesheet" />
                                    <script src="/dist/js/mazakacore-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-angular-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-app.js" ></script>
                                    <style>
                                        table {
                                            font-size:10px;
                                            table-layout:auto;
                                        }
                                        .dd {
                                            display:inline-block;
                                            font-size:10px;
                                            font-weight:bold;
                                        }
                                    </style>
                                </html>`;


            popupWin.document.write(printContent);
            popupWin.document.close();
        };


    }



    angular
        .module('app.invoices')
        .controller('IncomeModalOrderController', InvoiceModalController);

    InvoiceModalController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'InvoiceService', '$sce', 'items'];

    function InvoiceModalController($scope, $stateParams, $filter, $location, logger, InvoiceService, $sce, items) {

        var vm = this;

        vm.jsonAnswer = null;
        selectedData[i] = items;
        vm.content = null;

        activate();
        vm.loading = true;

        function activate() {

            InvoiceService.getPreviewInvoice(vm.order.data).then(function (response) {

                vm.content = $sce.trustAsHtml(response);
                vm.loading = false;

            });
        }

        vm.download = function () {
            InvoiceService.getDownloadInvoice(vm.order.data).then(function (response) {
                window.open("data:application/pdf;base64," + response);
                return data;

            });

        }

    }
    angular
        .module('app.excel')
        .controller('ModalOrderController', ModalOrderController);

    ModalOrderController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', '$uibModal', '$uibModalStack', '$uibModalInstance', 'items', 'SharedData'];

    function ModalOrderController($scope, $stateParams, $filter, $location, logger, $uibModal, $uibModalStack, $uibModalInstance, items, SharedData) {
        var vm = this;
        vm.SharedData = SharedData;
        vm.content = items;
        activate();
        function activate() {
            vm.data = vm.content.data;
            vm.modalTitle = "Sipariş Detayı";
        }
        vm.printOrderShipmentCode = function (selectedData) {
            console.log("selecaaat", selectedData);
            var printContents = "";

            if (selectedData.CargoJobId == null) {
                selectedData.CargoJobId = "";
            }
            if (selectedData.CargoReferenceCode == null) {
                selectedData.CargoReferenceCode = selectedData.ShipmentCode;
            }
            if (selectedData.DeliveryAddress.TcNo != null) {
                selectedData.DeliveryAddress.TcNo = selectedData.DeliveryAddress.TcNo.substring(0, 5) + "******";
            }
            printContents = printContents + `<div id="cargo-print"  style="padding:0px; min-width:148mm;min-height:215mm;">
                                        <table class="table" style="padding:0px; min-width:148mm;min-height:210mm;">
                                            <tr>
                                                <th colspan="6" style="border-top:none;">
                                                    <div>
                                                        <div class="col-xs-6">
                                                            <img class="img-responsive" style= "height:50p"
                                                                 src="${vm.SharedData.Settings.WebServer + '/themes/' + vm.SharedData.Settings.ThemeName + '/images/logo.png'}" />
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td colspan="3" ng-if="vm.SharedData.Settings">
                                                    <h5><b translate="orders.sender-addressInfo">Gönderici Adres Bilgileri</b></h5>
                                                    <address>
                                                        <strong>
                                                            ${vm.SharedData.Settings.CompanyName}
                                                        </strong>
                                                        <p>
                                                            ${vm.SharedData.Settings.CompanyPhone}
                                                        </p>
                                                        <p>
                                                            ${vm.SharedData.Settings.CompanyAddress}
                                                        </p>
                                                        <p>
                                                            ${vm.SharedData.Settings.CompanyDistrict}/${vm.SharedData.Settings.CompanyCity}
                                                        </p>
                                                    </address>
                                                </td>
                                                <td colspan="3" ng-if="vm.order.DeliveryAddressId">
                                                    <h5><b translate="orders.buyer-addressInfo">Alıcı Adres Bilgileri</b></h5>
                                                    <address>
                                                        <strong>
                                                            ${selectedData.DeliveryAddress.Title} - ${selectedData.DeliveryAddress.User}
                                                            ${(selectedData.DeliveryAddress.Name || '') + ' ' + (selectedData.DeliveryAddress.Surname || '')}
                                                        </strong>
                                                        <p>
                                                            ${selectedData.DeliveryAddress.Phone1}
                                                            ${" / " + selectedData.DeliveryAddress.Phone2 ? selectedData.DeliveryAddress.Phone2 : ""}
                                                            ${selectedData.DeliveryAddress.FaxNumber ? " / " + selectedData.DeliveryAddress.FaxNumber : ""}
                                                        </p>
                                                        <p >
                                                            TcNo:${selectedData.DeliveryAddress.TcNo}
                                                        </p>
                                                        <p>
                                                            ${selectedData.DeliveryAddress.Description}
                                                            ${selectedData.DeliveryAddress.ZipPostalCode}
                                                            ${selectedData.DeliveryAddress.ApartmentNo} ${selectedData.DeliveryAddress.Street1}
                                                            ${selectedData.DeliveryAddress.City}${selectedData.DeliveryAddress.CityName}
                                                            ${selectedData.DeliveryAddress.District}${selectedData.DeliveryAddress.DistrictName}
                                                            ${selectedData.DeliveryAddress.StateProvince}${selectedData.DeliveryAddress.StateProvinceName}
                                                            ${selectedData.DeliveryAddress.Country}${selectedData.DeliveryAddress.CountryName}
                                                        </p>
                                                    </address>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="border-top:none; text-align:center" ng-if="selectedData[i].CargoJobId">
                                         
                                                    <div class="col-xs-12" align="center">
                                                        <img class="img-responsive" style="border-top:none; text-align:center; height:50px;"
                                                             src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${selectedData.CargoJobId}'" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="border-top:none; text-align:center">
                                                    <div class="col-xs-12" align="center">
                                                        <img class="img-responsive" style="border-top:none; text-align:center;height:100px;"
                                                             src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${selectedData.CargoReferenceCode}'" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" style="border-top:none; text-align:center">
                                                    <strong>
                                                        <h2>Barkod No:${selectedData.CargoReferenceCode}</h2>
                                                    </strong>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                        </table>
                                    </div>`;


            //var printContents = document.getElementById(divName).innerHTML;
            console.log("asasdasd", printContents);
            var popupWin = window.open('', '_blank', '');
            popupWin.document.open();
            var printContent = `<html>
                                    <body onload="window.print()">
                                   ${printContents}
                                    </body>
                                    <link href="/dist/css/mazakacore.css"  rel="stylesheet" />
                                    <script src="/dist/js/mazakacore-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-angular-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-app.js" ></script>
                                    <style>
                                        table {
                                            font-size:10px;
                                            table-layout:auto;
                                        }
                                        .dd {
                                            display:inline-block;
                                            font-size:10px;
                                            font-weight:bold;
                                        }
                                    </style>
                                </html>`;


            popupWin.document.write(printContent);
            popupWin.document.close();
        };

        var formatter = new Intl.NumberFormat('tr-TR', {
            style: 'currency',
            currency: 'TRY',
        });


        vm.printOrderDetails = function (selectedData) {

            var printContents = "";
            if (selectedData.CargoJobId == null) {
                selectedData.CargoJobId = "";
            }
            if (selectedData.CargoReferenceCode == null) {
                selectedData.CargoReferenceCode = selectedData.ShipmentCode;
            }
            var tax = "";
            if (selectedData.Customer.TaxNumber || selectedData.Customer.TaxOffice) {
                tax = `<p><span class="dd">Vergi Dairesi / Vergi No:</span>
                               <span ng-if=" selectedData[i].Customer.TaxOffice">${ selectedData.Customer.TaxOffice}</span> /
                               <span ng-if=" selectedData[i].Customer.TaxNumber">${ selectedData.Customer.TaxNumber}</span>
                           </p>`;
            }
            var transferBank = "";
            if (selectedData.TransferEFTBankId) {
                transferBank = `<span style="color:red">
                                        ${ selectedData.TransferEFTBank.Name}
                                    </span>`;
            }
            var currency = "";
            if (selectedData.SalesOrderCurrency.length != 0) {
                var curr = "";
                for (var i = 0; i < selectedData.SalesOrderCurrency.length; i++) {
                    curr = curr + `<div class="pull-left" >
                                                               <b>'${curr.Currency.Symbol}</b> - ${formatter.format(selectedData.SalesOrderCurrency.Currency.Rate)}</div>`;
                }
                currency = `<tr>
                                                    <td colspan="7">
                                                        <p>
                                                            <div class="col-md-12">
                                                                <div class="pull-left"><b>Döviz Kurları:</b></div>
                                                                ${curr}
                                                            </div>
                                                        </p>
                                                    </td>
                                                </tr>`;
            }

            var productList = "";
            for (var j = 0; j < selectedData.OrderProduct.length; j++) {
                productList = productList +
                    `
                                                    <tr>
                                                                <td>${ selectedData.OrderProduct[j].Code}</td>
                                                                <td>${selectedData.OrderProduct[j].Code}</td>
                                                                <td>${selectedData.OrderProduct[j].Name}</td>
                                                                <td>
                                                                    <span>
                                                                            ${formatter.format((selectedData.OrderProduct[j].UnitPrice) * (selectedData.OrderProduct[j].Currency.Rate))}     
                                                                    </span>
                                                                    
                                                                </td>
                                                                <td>${selectedData.OrderProduct[j].Quantity}</td>
                                                                <td>
                                                                    %${selectedData.OrderProduct[j].Tax}KDV
                                                                </td>
                                                                <td>
                                                                        ${formatter.format(selectedData.OrderProduct[j].Quantity * (selectedData.OrderProduct[j].UnitPriceWithDiscount || selectedData.OrderProduct[j].UnitPrice) * (selectedData.OrderProduct[j].TaxIncluded ? 1.00 : ((selectedData.OrderProduct[j].Tax / 100) + 1.00)))}

                                                                </td>
                                                            </tr>
                         `;
            }

            printContents = printContents + `<div id="order-print" >
                                                        <table class="table" style="line-height:2px;table-layout:auto;min-width:148mm;min-height:213mm;">
                                                            <tr>
                                                                <th colspan="7" style="border-top:none;">
                                                                    <div>
                                                                        <div class="col-xs-4">
                                                                            <img class="img-responsive" style="max-height:50px;"
                                                                                 src="${vm.SharedData.Settings.WebServer + '/themes/' + vm.SharedData.Settings.ThemeName + '/images/logo.png'}" />
                                                                        </div>
                                                                        <div class="col-xs-4" align="center">
                                                                            <img 
                                                                                 style="border-top:none; text-align:center;max-height:50px;"
                                                                                 src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${ selectedData.CargoJobId}'" />
                                                                        </div>
                                                                        <div class="col-xs-4" align="center">
                                                                            <img
                                                                                 style="border-top:none; text-align:center;max-height:50px;"
                                                                                 src="https://barcode.tec-it.com/barcode.ashx?code=Code128&modulewidth=fit&data='${ selectedData.CargoReferenceCode}'" />
                                                                           
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="${selectedData.DeliveryAddressId ? 2 : 2}">
                                                                    <p>
                                                                        <b class="dd">
                                                                            <span translate="orders.detail.orderNumber">Sipariş Numarası : </span>
                                                                            : ${selectedData.Code}
                                                                        </b>&nbsp;
                                                                        <span style="color:red">${ selectedData.Bank.Name}</span>
                                                                        ${transferBank}                                                                       
                                                                        &nbsp;
                                                                        ${selectedData.OrderDate}
                                                                    </p>
                                                                    <p>Ad Soyad/Ünvan:${ selectedData.Customer.Name} ${selectedData.Customer.Surname}</p>
                                                                    <p>
                                                                        Telefon: ${ selectedData.DeliveryAddress.Phone1}
                                                                        ${" / " + selectedData.DeliveryAddress.Phone2 ? selectedData.DeliveryAddress.Phone2 : ""}
                                                                        ${ selectedData.DeliveryAddress.FaxNumber ? " / " + selectedData.DeliveryAddress.FaxNumber : ""}
                                                                    </p>
                                                                   ${tax}
                                                                </td>
                                                                <td colspan="2">
                                                                    <p>
                                                                        <b>Adres Başlığı:</b> ${ selectedData.DeliveryAddress.Title}
                                                                    </p>
                                                                    <p>
                                                                        Şehir:
                                                                        ${ selectedData.DeliveryAddress.StateProvince}${selectedData.DeliveryAddress.StateProvinceName}
                                                                    </p>
                                                                    <p>
                                                                        Adres: ${ selectedData.DeliveryAddress.Description}
                                                                        ${ selectedData.DeliveryAddress.ZipPostalCode}
                                                                        ${ selectedData.DeliveryAddress.ApartmentNo} ${selectedData.DeliveryAddress.Street1}
                                                                        ${ selectedData.DeliveryAddress.City}${selectedData.DeliveryAddress.CityName}
                                                                        ${ selectedData.DeliveryAddress.District}${selectedData.DeliveryAddress.DistrictName}
                                                                        ${ selectedData.DeliveryAddress.StateProvince}${selectedData.DeliveryAddress.StateProvinceName}
                                                                        ${ selectedData.DeliveryAddress.Country}${selectedData.DeliveryAddress.CountryName}
                                                                    </p>
                                                                </td>
                                                                <td colspan="2">
                                                                    <p>
                                                                        <b>Sipariş Notu:</b> ${ selectedData.Description}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                           ${currency}
                                                            <tr >
                                                                <th style="width:2%;">#</th>
                                                                <th style="width:10%;" >Ürün Kodu</th>
                                                                <th style="width:30%;" >Ürün Adı</th>
                                                                <th style="width:14%;" >Birim Fiyat</th>
                                                                <th style="width:4%;" >Miktar</th>
                                                                <th style="width:18%;" >Vergi</th>
                                                                <th style="width:22%;" >Toplam Fiyat</th>
                                                            </tr>
                                                            ${productList}
                                                            <tr>
                                                                <td colspan="7">
                                                                </td>
                                                            </tr>
                                                            <style>
                                                                .totalList {
                                                                    font-size: 8px;
                                                                    display: block;
                                                                    float: left;
                                                                    width: 100%;
                                                                }

                                                                    .totalList td {
                                                                        display: block;
                                                                        float: left;
                                                                        width: 20%;
                                                                    }

                                                                        .totalList td div {
                                                                            display: block;
                                                                            float: left;
                                                                            width: 100%;
                                                                        }
                                                            </style>
                                                            <tr>
                                                                <td colspan="7">
                                                                    <table style="line-height:2px;  width:100%; table-layout:auto;">
                                                                        <tr class="totalList">
                                                                            <td>
                                                                                <div>
                                                                                         <b>Ara Toplam:</b>
                                                                                    ${formatter.format(selectedData.SubTotal)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <b>Vergiler Toplamı:</b>
                                                                                    ${formatter.format(selectedData.TotalTax)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <b>Kargo Toplamı:</b>
                                                                                    ${formatter.format(selectedData.ShippingPrice)}
                                                                                </div>
                                                                              
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                <b>Genel İndirim:</b>
                                                                                ${formatter.format(selectedData.GeneralDiscount)}
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <b>Genel Toplam:</b>
                                                                                    ${formatter.format(selectedData.Total)}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr></tr>
                                                            <tr>
                                                                <th colspan="7">
                                                                    <div style="font-size:8px;text-align:center">
                                                                        Mazaka Yazılım E-Ticaret Paketleri Aracılığıyla
                                                                        Hazırlanmıştır.
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </table>
                                                    </div>`;

            var popupWin = window.open('', '_blank', '');
            popupWin.document.open();
            var printContent = `<html>
                                    <body onload="window.print()">
                                   ${printContents}
                                    </body>
                                    <link href="/dist/css/mazakacore.css"  rel="stylesheet" />
                                    <script src="/dist/js/mazakacore-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-angular-lib.js" ></script>
                                    <script src="/dist/js/mazakacore-app.js" ></script>
                                    <style>
                                        table {
                                            font-size:10px;
                                            table-layout:auto;
                                        }
                                        .dd {
                                            display:inline-block;
                                            font-size:10px;
                                            font-weight:bold;
                                        }
                                    </style>
                                </html>`;


            popupWin.document.write(printContent);
            popupWin.document.close();
        };

        vm.findInvoiceId = function (orderData) {
            var orderProductData = orderData.OrderProduct;
            const result = Object.values(orderProductData).every((value) => value.InvoiceId != null);
            return result;
        }

    }

})();
