(function () {
    'use strict';

    angular
        .module('app.excel')
        .controller('ExcelSalesPointImportController', ExcelSalesPointImportController);

    ExcelSalesPointImportController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'ExcelService', '$uibModal', '$uibModalStack'];

    function ExcelSalesPointImportController($scope, $stateParams, $filter, $location, logger, ExcelService, $uibModal, $uibModalStack) {
        var vm = this;

        activate();

        function activate() {
        }

        vm.excelSalesPointImportFields = ExcelService.getExcelSalesPointImportFields();

        vm.saveSalesPointImport = function () {
            console.log("log", vm.excelSalesPointImport);
            vm.openModal(vm.excelSalesPointImport.File);
        }

        // Modal

        vm.modalTitle = "Excel Bayi Import";
        vm.modalInstance = null;
        vm.openModal = function (File) {
            console.log("File", File);
            vm.modalInstance = $uibModal.open({
                templateUrl: 'ModalContent.html',
                controller: 'ModalSalesPointController',
                controllerAs: 'vm',
                resolve: {
                    items: function () {
                        return {
                            File: File
                        };
                    }
                }
            });
        }
    }

    angular
        .module('app.excel')
        .controller('ModalSalesPointController', ModalSalesPointController);

    ModalSalesPointController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'ExcelService', '$uibModal', '$uibModalStack', '$uibModalInstance', 'items'];

    function ModalSalesPointController($scope, $stateParams, $filter, $location, logger, ExcelService, $uibModal, $uibModalStack, $uibModalInstance, items) {

        var vm = this;
        vm.content = items;
        activate();

        function activate() {
            ExcelService.analysisSalesPointFile(vm.content.File).then(function (data) {
                vm.file = vm.content.File;
                vm.SalesPointCount = data.data.SalesPointCount;
                vm.NotMatchField = data.data.NotMatchField;

                vm.loading = false;
            });
        }


        vm.modalCancel = function () {
            $uibModalStack.dismissAll();
        }

        vm.approveSalesPointImport = function () {
            vm.loading = true;
            ExcelService.importSalesPointFile(vm.file, vm.filedelete).then(function () {
                vm.loading = false;
                vm.modalCancel();
                $location.path('/salespoints/list');
            });
        }

    }
})();