(function () {
    'use strict';

    angular
        .module('app.option')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'option',
                config: {
                    url: '/option',
                    title: 'Varyantlar',
                    abstract: true,
                    templateUrl: 'app/options/options.html'
                }
            },
            {
                state: 'option.list',
                config: {
                    templateUrl: 'app/options/options.list.html',
                    controller: 'OptionListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Varyant Listesi',
                    settings: {
                        nav: 4,
                        name: 'option.list',
                        topName: 'products',
                        content: 'Varyant Listesi',
                        resource: 'menu.option.list'
                    }
                }
            },
            {
                state: 'option.detail',
                config: {
                    templateUrl: 'app/option/option.detail.html',
                    controller: 'OptionDetailController',
                    controllerAs: 'vm',
                    url: '/detail/:OptionId',
                    title: 'Option Detayı'
                }
            },
        ];
    }
})();