(function () {
    "use strict";

    angular
        .module("app.banks")
        .factory("BanksService", Service);

    Service.$inject = ["$http", "$q", "ngAuthSettings", "logger", "config", "Settings", "MediaService"];

    function Service($http, $q, ngAuthSettings, logger, config, Settings, MediaService) {
        var service = {
            getAll: getAll,
            get: get,
            edit: edit,
            del: del,
            copyToRole: copyToRole,
            getFields: getFields,
            getBanktype: getBanktype
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + "api/bank/";

        var fields = [
            {
                key: "Name",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    label: "Adı",
                    resource: "form.name",
                    placeholder: "Adı",
                    readonly: true,
                    class: "col-xs-8"
                }
            },
            {
                key: "DisplayOrder",
                type: "floatInput",
                templateOptions: {
                    type: "number",
                    resource: "form.display-order",
                    label: "Sıra",
                    placeholder: "Sıra",
                    class: "col-xs-4"
                }
            },
            {
                key: "Active",
                type: "floatCheckbox",
                templateOptions: {
                    label: "Aktif",
                    resource: "form.active",
                    placeholder: "Aktif",
                    class: "col-xs-3"
                }
            },
            {
                key: "Default",
                type: "floatCheckbox",
                templateOptions: {
                    label: "Varsayılan",
                    resource: "form.default",
                    placeholder: "Varsayılan",
                    class: "col-xs-3"
                }
            },
            {
                key: "Test",
                type: "floatCheckbox",
                templateOptions: {
                    label: "Test",
                    resource: "form.test",
                    placeholder: "Test",
                    class: "col-xs-3"
                }
            },
            {
                key: "TransferEFT",
                type: "floatCheckbox",
                templateOptions: {
                    label: "Havale/EFT",
                    resource: "form.transfer-eft",
                    placeholder: "Havale/EFT",
                    class: "col-xs-3"
                }
            },
            {
                key: "BusinessNo",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    label: "Business No",
                    resource: "form.business-no",
                    placeholder: "Business No",
                    class: "col-xs-6"
                }
            },
            {
                key: "BusinessPass",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    label: "Business Pass",
                    resource: "form.business-pass",
                    placeholder: "Business Pass",
                    class: "col-xs-6"
                }
            },
            {
                key: "BusinessType",
                type: "floatSelect",
                templateOptions: {
                    label: "Business Type",
                    resource: "form.business-type",
                    placeholder: "Business Type",
                    class: "col-xs-4",
                    options: [
                        { Name: "..." },
                        { Id: "NO3D", Name: "3Dsiz" },
                        { Id: "Pay_Hosting", Name: "Pay_Hosting" },
                        { Id: "3D", Name: "3D" },
                        { Id: "3D_Hosting", Name: "3D_Hosting" },
                        { Id: "3D_Pay", Name: "3D_Pay" },
                        { Id: "3D_Pay_Hosting", Name: "3D_Pay_Hosting" }
                    ]
                }
            },
            {
                key: "BusinessId",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    label: "Business Id",
                    resource: "form.business-id",
                    placeholder: "Business Id",
                    class: "col-xs-4"
                }
            },
            {
                key: "BusinessKey",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    label: "Business Key",
                    resource: "form.business-key",
                    placeholder: "Business Key",
                    class: "col-xs-4"
                }
            },
            {
                key: "MinPrice",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    label: "Minimum Tutar",
                    resource: "form.minprice",
                    placeholder: "Minimum Tutar",
                    class: "col-xs-6",
                    numberMask: 2
                }
            },
            {
                key: "MaxPrice",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    label: "Maximum Tutar",
                    resource: "form.maxprice",
                    placeholder: "Maximum Tutar",
                    class: "col-xs-6",
                    numberMask: 2
                }
            },
            {
                key: "IBAN",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    label: "IBAN",
                    resource: "form.iban",
                    placeholder: "IBAN",
                    class: "col-xs-6"
                }
            },
            {
                key: "IBANDescription",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    label: "IBAN Açıklaması",
                    resource: "form.iban-description",
                    placeholder: "IBAN Açıklaması",
                    class: "col-xs-6"
                }
            },
            {
                key: 'UsePercentageForServicePrice',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Hizmet Bedeli için Yüzde Kullan',
                    resource: 'form.use-percentage-for-service-price',
                    placeholder: 'Hizmet Bedeli için Yüzde Kullan',
                    class: "col-xs-6"
                }
            },
            {
                key: 'ServicePrice',
                type: 'floatInput',
                templateOptions: {
                    type: 'text',
                    label: 'Hizmet Bedeli',
                    resource: 'form.service-price',
                    placeholder: 'Hizmet Bedeli',
                    numberMask: 2,
                    class: "col-xs-6"
                }
            },
            {
                key: "Description",
                type: "floatTinyMCE",
                templateOptions: {
                    label: "Açıklama",
                    resource: "form.description",
                    placeholder: "Açıklama"
                }
            },
            {
                wrapper: "accordion",
                templateOptions: {
                    label: "Genel Özellikler",
                    resource: "form.general-options",
                    classes: "col-xs-12"
                },
                fieldGroup: [
                    {
                        key: "PaymentType",
                        type: "floatSelect",
                        templateOptions: {
                            label: "Web Pos Anlaşma Şekli",
                            resource: "form.payment-type",
                            placeholder: "Web Pos Anlaşma Şekli",
                            class: "col-xs-12",
                            options: [{ Id: "EGO", Name: "Ertesi Gün Ödeme" }, { Id: "BLK", Name: "Blokeli" }]
                        }
                    },
                    {
                        key: "CollectionDaysOneShot",
                        type: "floatInput",
                        templateOptions: {
                            type: "text",
                            label: "Tek Çekim Tahsilat Günü",
                            resource: "form.collection-days-one-shot",
                            placeholder: "Tek Çekim Tahsilat Günü",
                            class: "col-xs-4",
                            numberMask: 2
                        }
                    },
                    {
                        key: "CollectionDaysInstallmentFirstPaymentDay",
                        type: "floatInput",
                        templateOptions: {
                            type: "text",
                            label: "Taksitli İlk Ödeme Tahsilat Günü",
                            resource: "form.collection-days-installment-first-payment-day",
                            placeholder: "Taksitli İlk Ödeme Tahsilat Günü",
                            class: "col-xs-4",
                            numberMask: 2
                        }
                    },
                    {
                        key: "CollectionDaysInstallmentRepaymentPeriod",
                        type: "floatInput",
                        templateOptions: {
                            type: "text",
                            label: "Taksitli Geri Ödeme Periyodu",
                            resource: "form.collection-days-installment-repayment-period",
                            placeholder: "Taksitli Geri Ödeme Periyodu",
                            class: "col-xs-4",
                            numberMask: 2
                        }
                    },
                    {
                        key: "OneShotCommission",
                        type: "floatInput",
                        templateOptions: {
                            type: "text",
                            label: "Tek Çekim Komisyonu",
                            resource: "form.one-shot-commission",
                            placeholder: "Tek Çekim Komisyonu",
                            class: "col-xs-4",
                            numberMask: 2
                        }
                    },
                    {
                        key: "OneShotCustomerRating",
                        type: "floatInput",
                        templateOptions: {
                            type: "text",
                            label: "Tek Çekim Müşteri Puanı",
                            resource: "form.one-shot-customer-rating",
                            placeholder: "Tek Çekim Müşteri Puanı",
                            class: "col-xs-4",
                            numberMask: 2
                        }
                    },
                    {
                        key: "OneShotNextDayPaymentCommission",
                        type: "floatInput",
                        templateOptions: {
                            type: "text",
                            label: "Tek Çekim Ertesi Gün Ödeme İskontosu",
                            resource: "form.one-shot-next-day-payment-commission",
                            placeholder: "Tek Çekim Ertesi Gün Ödeme İskontosu",
                            class: "col-xs-4",
                            numberMask: 2
                        }
                    },
                    {
                        key: "BlockedCommission",
                        type: "floatInput",
                        templateOptions: {
                            type: "text",
                            label: "Blokeli Komisyon",
                            resource: "form.blocked-commission",
                            placeholder: "Blokeli Komisyon",
                            class: "col-xs-6",
                            numberMask: 2
                        }
                    },
                    {
                        key: "BlockedCustomerRating",
                        type: "floatInput",
                        templateOptions: {
                            type: "text",
                            label: "Blokeli Müşteri Puan",
                            resource: "form.blocked-commission",
                            placeholder: "Blokeli Müşteri Puan",
                            class: "col-xs-6",
                            numberMask: 2
                        }
                    },
                    {
                        key: "ExtraInstallmentPossibility",
                        type: "floatInput",
                        templateOptions: {
                            type: "text",
                            label: "Ekstra Taksit İmkanı",
                            resource: "form.extra-installment-possibility",
                            placeholder: "Ekstra Taksit İmkanı",
                            class: "col-xs-4",
                            numberMask: 2
                        }
                    },
                    {
                        key: "InstallmentCommission",
                        type: "floatInput",
                        templateOptions: {
                            type: "text",
                            label: "Taksit Komisyonu",
                            resource: "form.installment-commission",
                            placeholder: "Taksit Komisyonu",
                            class: "col-xs-4",
                            numberMask: 2
                        }
                    },
                    {
                        key: "InstallmentCustomerRating",
                        type: "floatInput",
                        templateOptions: {
                            type: "text",
                            label: "Taksit Müşteri Puanı",
                            resource: "form.installment-customer-rating",
                            placeholder: "Taksit Müşteri Puanı",
                            class: "col-xs-4",
                            numberMask: 2
                        }
                    }
                ]
            }
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();

            $http
                .get(serviceBase + "all", {
                    params: filterParams
                })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(Id) {
            var deferred = $q.defer();

            $http
                .get(serviceBase + Id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http
                .put(serviceBase + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function copyToRole(bankId, roleId) {
            var deferred = $q.defer();

            $http
                .put(serviceBase + "CopyToRole/" + bankId + "/" + roleId)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(entity) {
            var deferred = $q.defer();

            $http
                .delete(serviceBase + entity.Id, entity)
                .then(delComplete)
                .catch(delFailed);

            function delComplete(response) {
                deferred.resolve(response.data);
            }

            function delFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }



        function getBanktype(cardNumber) {
            var deferred = $q.defer();
            $http.get(serviceBase + 'BankType/' + cardNumber)
                .then(getBanktypeComplete)
                .catch(getBanktypeFailed);

            function getBanktypeComplete(response) {
                deferred.resolve(response.data);
            }

            function getBanktypeFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

    }
})();
