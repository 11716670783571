(function () {
    'use strict';

    angular
        .module('app.markettemplates')
        .controller('AddShipmentTemplateController', AddShipmentTemplateController);

    AddShipmentTemplateController.$inject = ['$scope', '$stateParams', '$location', 'MarketTemplatesService', 'NgTableParams'];

    function AddShipmentTemplateController($scope, $stateParams, $location, MarketTemplatesService, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;

        vm.settings = globalSettings;
        activate();

        function activate() {
            vm.fields = MarketTemplatesService.getShipmentTemplateFields();
        }


        vm.addTemplate = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                MarketTemplatesService.addShipmentTemplate(vm.entity).then(function (data) {
                    vm.loading = false;
                    $location.path('/markettemplates/shipmenttemplates');
                },
                    function (data) {
                        vm.loading = false;
                    });
                vm.loading = false;
            }
        };
    }
})();