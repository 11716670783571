(function () {
    'use strict';

    angular
        .module('app.account')
        .controller('AccountAddRoleController', AccountAddRoleController);

    AccountAddRoleController.$inject = ['$location', '$stateParams', '$timeout', 'AccountService', 'RolesService'];

    function AccountAddRoleController($location, $stateParams, $timeout, AccountService, RolesService) {
        var vm = this;

        vm.title = '';
        AccountService.getUser($stateParams.id)
            .then(function (data) {
                vm.user = data;
                vm.selectedRoles = vm.user.Roles.map(function (item) {
                    return item.RoleId;
                });
                RolesService.getAll()
                    .then(function (data) {
                        vm.roles = data.Data;
                        vm.loading = false;
                    });
            })
            .catch(function () {
                vm.loading = false;
            });

        vm.Cancel = function () {
            $location.path('/account/list');
        };

        vm.addRoleToUser = function () {
            vm.loading = true;
            AccountService.addRoleToUser(vm.user.Id, vm.selectedRoles).then(function (data) {
                vm.loading = false;
                $location.path('/account/list');
            });
        };
    }
})();