(function () {
    'use strict';

    angular
        .module('app.simplemailing')
        .factory('SimplemailingService', Service);

    Service.$inject = ['$http', '$q', 'ngAuthSettings'];

    function Service($http, $q, ngAuthSettings) {
        var service = {
            send: send,
            getFields: getFields
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/simplemailing/';

        var fields = [{
                key: 'CustomerType',
                type: 'floatSelect',
                templateOptions: {
                    required: true,
                    label: 'İndirim Tipi',
                    resource: 'form.discount-type',
                    options: [{
                            Id: 'Son1AySiparisVerenUyeler',
                            Name: 'Son 1 Ay Sipariş Veren Üyeler'
                        },
                        {
                            Id: 'HicAlisverisYapmamisUyeler',
                            Name: 'Hiç Alışveriş Yapmamış Üyeler'
                        },
                        {
                            Id: 'Uyeler',
                            Name: 'Tüm Üyeler'
                        },
                        {
                            Id: 'Aboneler',
                            Name: 'Tüm Aboneler'
                        }
                    ]
                }
            },
            {
                key: "Title",
                type: "floatInput",
                templateOptions: {
                    required: true,
                    type: "text",
                    label: "Başlık",
                    placeholder: "Başlık",
                    resource: "form.title"
                }
            },
            {
                key: "Body",
                type: "floatTinyMCE",
                templateOptions: {
                    required: true,
                    label: "İçerik",
                    placeholder: "İçerik",
                    resource: "form.description"
                }
            }
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function send(entity) {
            var deferred = $q.defer();

            $http.post(serviceBase, entity)
                .then(sendComplete)
                .catch(sendFailed);

            function sendComplete(response) {
                deferred.resolve(response.data);
            }

            function sendFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();