(function () {
    'use strict';

    angular
        .module('app.customers')
        .controller('CustomersAddController', CustomersAddController);

    CustomersAddController.$inject = ['$scope', '$location', 'CustomersService', 'CustomerAddressService', 'CustomerGeneralService', 'logger'];

    function CustomersAddController($scope, $location, CustomersService, CustomerAddressService, CustomerGeneralService, logger) {
        var vm = this;

        activate();
        function activate() {
            var h = angular.element(window).height() - 40 - 40 - 30 - 20;
            angular.element('#customer-info').css("height", h);
            angular.element('#customer-detail').css("height", h);
        }

        vm.newCustomer = { MusteriTip: 0 };

        vm.customerFields = CustomersService.getCustomerFields();

        vm.customerSaved = false;

        vm.saveUser = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                CustomersService.addCustomer(vm.newCustomer)
                    .then(function (data) {
                        vm.customerSaved = true;
                        vm.loading = false;
                        vm.newCustomer.Id = data.Id;
                        vm.newCustomerAddress.UserId = data.Id;
                        logger.success("Kullanıcı Kayıt işlemi başarıyla tamamlanmıştır.");
                    })
                    .catch(function () {
                        vm.loading = false;
                    });
            }
        };

        vm.newCustomerAddress = {};

        vm.customerAddressFields = CustomerAddressService.getCustomerAddressFields();

        vm.saveUserAddress = function () {
            if (vm.formAddress.$valid) {
                vm.addressLoading = true;
                CustomerAddressService.addCustomerAddress(vm.newCustomerAddress).then(function () {
                    vm.customerAddressSaved = true;
                    vm.addressLoading = false;
                    logger.success("Adres Kayıt işlemi başarıyla tamamlanmıştır.");
                    $location.path('/customers/' + vm.newCustomer.Id);
                });
            }
        };
    }
})();