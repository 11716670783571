(function () {
    'use strict';

    angular
        .module('app.consulting')
        .controller('ConsultantsAddController', AddController);

    AddController.$inject = ['$location', 'ConsultantsService'];

    function AddController($location, Service) {
        /* jshint validthis:true */
        var vm = this;

        vm.entity = {};

        vm.fields = Service.getFields();

        vm.create = function () {
            if (vm.form.$valid) {
                vm.loading = true;

                vm.entity.Expertises = vm.entity.Expertises || [];
                vm.entity.ConsultantExpertise = vm.entity.Expertises.map(function (item) {
                    return {
                        ExpertiseId: item
                    };
                });
                vm.entity.Expertises = null;

                Service.add(vm.entity).then(function (data) {
                    vm.loading = false;
                    $location.path('/consulting/consultants/edit/' + data.Id);
                });
            }
        };
    }
})();