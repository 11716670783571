(function () {
    'use strict';

    angular
        .module('app.shipmentProvider')
        .controller('ShipmentProviderListController', ListController);

    //ListController.$inject = ['$scope', '$location', 'ShipmentProviderService', 'NgTableParams', '$uibModal', 'Settings'];

    ListController.$inject = [
        "$scope",
        "$location",
        "ShipmentProviderService",
        "$timeout",
        "RolesService",
        "NgTableParams"
    ];

    function ListController($scope, $location, Service, $timeout, RolesService, NgTableParams, $uibModal, Settings) {
        var vm = this;
        vm.settings = globalSettings;

        function activate() {
            vm.entity = null;
            vm.getShipmentProviderList();

            vm.fields = Service.getFields();

            RolesService.getAll({ PageSize: -1, IsBase: false }).then(function (data) {
                vm.roles = data.Data;
            });
        }

        vm.regenerateTreeTable = function () {
            $timeout(function () {
                $(".shipment-provider-list")
                    .removeData("treetable")
                    .removeClass("treetable");
                $(".shipment-provider-list").treetable({ expandable: true });
                $(".shipment-provider-list").treetable("expandAll");
            }, 0);
        };

        vm.getOtherRoles = function (shipmentProvider) {
            if (!vm.shipmentProviders || vm.shipmentProviders.length === 0) {
                return;
            }
            var subRoleIds = vm.getSubShipmentProviderList(shipmentProvider.Id).map(function (item) {
                return item.RoleId;
            });
            if (!vm.roles) {
                return;
            }
            var otherRoles = vm.roles.filter(function (item) {
                return !subRoleIds.includes(item.Id);
            });
            return otherRoles;
        };

        vm.copyShipmentProviderToRole = function (shipmentProviderId, roleId) {
            Service.copyToRole(shipmentProviderId, roleId).then(function () {
                vm.getShipmentProviderList();
            });
        };

        vm.getShipmentProviderList = function () {
            Service.getAll({ PageSize: -1 }).then(function (data) {
                vm.shipmentProvidersMixed = data.Data.sort(function (a, b) {
                    if (a.Name < b.Name) {
                        return -1;
                    }
                    if (a.Name > b.Name) {
                        return 1;
                    }
                    return 0;
                });
                vm.shipmentProviders = vm.getSubShipmentProviderList(null);
                vm.regenerateTreeTable();
            });
        };

        vm.getSubShipmentProviderList = function (TopShipmentProviderId) {
            var shipmentProviderList = [];
            var subShipmentProvidersList = vm.shipmentProvidersMixed.filter(function (shipmentProvider) {
                return shipmentProvider.TopShipmentProviderId === TopShipmentProviderId;
            });
            for (var i = 0; i < subShipmentProvidersList.length; i++) {
                var item = subShipmentProvidersList[i];
                shipmentProviderList.push(item);
                var subItems = vm.getSubShipmentProviderList(item.Id);
                shipmentProviderList = shipmentProviderList.concat(subItems);
            }
            return shipmentProviderList;
        };

        vm.prepareEdit = function (data) {
            vm.entity = null;
            vm.entity = angular.copy(data);
        };

        vm.cancel = function () {
            vm.entity = {};
        };

        vm.edit = function () {
            vm.loading = true;
            Service.edit(vm.entity)
                .then(function () {
                    vm.entity = {};
                    vm.loading = false;
                    vm.getShipmentProviderList();
                })
                .catch(function () {
                    vm.entity = {};
                    vm.loading = false;
                    vm.getShipmentProviderList();
                });
        };

        vm.delete = function (entity) {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                vm.loading = true;
                Service.del(entity)
                    .then(function () {
                        vm.entity = {};
                        vm.loading = false;
                        vm.getShipmentProviderList();
                    })
                    .catch(function () {
                        vm.entity = {};
                        vm.loading = false;
                        vm.getShipmentProviderList();
                    });
            }
        };

        activate();

        ////Eski

        //activate();

        //function activate() {
        //    vm.Settings = Settings;
        //    vm.refresh = function () {
        //        vm.loading = true;
        //        vm.tableParams.reload();
        //        vm.loading = false;
        //    };

        //    vm.firstLoad = true;
        //    vm.tableParams = new NgTableParams(
        //        angular.extend({
        //            page: 1,
        //            count: 10
        //        }, $location.search()), {
        //            getData: function (params) {
        //                if (!vm.firstLoad) {
        //                    $location.search(params.url());
        //                }
        //                vm.firstLoad = false;
        //                var filterData2 = params.filter();
        //                var filterData = {};
        //                filterData.PageIndex = params.page(),
        //                    filterData.PageSize = params.count(),
        //                    filterData.SortProperty = Object.keys(params.sorting())[0],
        //                    filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
        //                var keys = Object.keys(filterData2);
        //                for (var i = 0; i < keys.length; i++) {
        //                    filterData[keys[i]] = filterData2[keys[i]];
        //                }
        //                return Service.getAll(filterData).then(function (data) {
        //                    params.total(data.FilteredCount);
        //                    return data.Data;
        //                });
        //            }
        //        });
        //}

        //vm.cancel = function (row, rowForm) {
        //    vm.tableParams.reload();
        //}

        //vm.del = function (row) {
        //    if (confirm("Silmek istediğinize emin misiniz?")) {
        //        Service.del(row).then(function (data) {
        //            vm.tableParams.reload();
        //        });
        //    }
        //}

        //vm.save = function (row, rowForm) {
        //    row.Icon = null;
        //    if (row.Id) {
        //        Service.edit(row).then(function (data) {
        //            vm.tableParams.reload();
        //        });
        //    } else {
        //        Service.add(row).then(function (data) {
        //            vm.tableParams.reload();
        //        });
        //    }
        //}

        //vm.add = function () {
        //    vm.tableParams.data.unshift({
        //        Name: "",
        //        isEditing: true
        //    });
        //}

        //var $uibModalInstance;
        //vm.selectFile = function (row) {
        //    $uibModalInstance = $uibModal.open({
        //        windowClass: 'filemanager-modal',
        //        template: '<div class="modal-body" id="modal-body-{{name}}">' +
        //            '<angular-filemanager></angular-filemanager>' +
        //            '</div>'
        //    });

        //    function pickItem(evt) {
        //        if (!row.Icon) {
        //            row.Icon = {};
        //        }
        //        row.Icon.FullPath = evt.detail.item.name;
        //        row.IconId = evt.detail.item.content;
        //        $uibModalInstance.dismiss();
        //        window.removeEventListener('onPickItem', pickItem);
        //    }
        //    window.addEventListener("onPickItem", pickItem, false);
        //};

        //vm.cancelImage = function (row) {
        //    row.Icon = null;
        //    row.IconId = null;
        //};



    }
})();