(function () {
    'use strict';

    angular
        .module('app.customers')
        .controller('CustomerDirectListController', Controller);

    Controller.$inject = ['$stateParams', 'OrdersService'];

    function Controller($stateparams, OrdersService) {
        var vm = this;

        vm.customerDirects = [{ Name: "Yükleniyor..." }];
        vm.UserId = $stateparams.UserId;

        activate();

        function activate() {
            OrdersService.getCustomerDirects(vm.UserId)
                .then(function (data) {
                    vm.customerDirects = data;
                });
        }
    }
})();