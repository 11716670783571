(function () {
    'use strict';

    angular
        .module('app.consulting')
        .factory('ConsultantsService', Service);

    Service.$inject = ['$http', '$q', 'ngAuthSettings', 'ExpertiseService', 'ProductsService'];

    function Service($http, $q, ngAuthSettings, ExpertiseService, ProductsService) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            getFields: getFields,
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/consultants/';

        var fields = [
            {
                key: 'Name',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    label: 'Adı',
                    resource: 'form.name',
                    placeholder: 'Adı'
                }
            },
            {
                key: 'Phone',
                type: 'floatPhone',
                templateOptions: {
                    required: true,
                    label: 'Telefon',
                    resource: 'form.phone',
                    placeholder: 'Telefon',
                    class: "col-xs-6"
                }
            },
            {
                key: "MediaId",
                type: "floatSingleImage",
                templateOptions: {
                    label: "Resim",
                    placeholder: "Katalog",
                    resource: "form.catalog",
                    valueKey: "Id",
                    valueModel: "Media.FullPath",
                    class: "col-xs-6"
                }
            },
            {
                key: "Expertises",
                type: "floatMultiSelect",
                templateOptions: {
                    label: "Uzmanlıklar",
                    resource: "form.expertises",
                    options: [{
                        Name: "Yükleniyor..."
                    }]
                },
                controller: [
                    "$scope",
                    function ($scope) {
                        ExpertiseService.getAll({
                            PageSize: -1
                        }).then(function (response) {
                            $scope.to.options = response.Data;
                        });
                    }
                ]
            },
            {
                key: "Description",
                type: "floatTinyMCE",
                templateOptions: {
                    label: "Açıklama",
                    resource: "form.description",
                    placeholder: "Açıklama"
                }
            }
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBase, {
                    params: filterParams
                })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(Id) {
            var deferred = $q.defer();

            $http.get(serviceBase + Id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();

            $http.post(serviceBase, entity)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http.put(serviceBase + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function del(entity) {
            var deferred = $q.defer();

            $http.delete(serviceBase + entity.Id, entity)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();