(function () {
    'use strict';

    angular
        .module('app.excel')
        .controller('ExcelSalesPointExportController', ExcelSalesPointExportController);

    ExcelSalesPointExportController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'ExcelService'];

    function ExcelSalesPointExportController($scope, $stateParams, $filter, $location, logger, ExcelService) {
        var vm = this;

        activate();

        function activate() {
            vm.excelSalesPointExport = {};
        }

        vm.excelSalesPointExportFields = ExcelService.getExcelSalesPointExportFields();
        vm.excelSalesPointFilterFields = ExcelService.getExcelSalesPointFilterFields();


        vm.exportSalesPointExcel = function () {
            console.log("vm.exportSalesPointExcel", vm.exportSalesPointExcel);
            vm.loading = true;
            ExcelService.exportSalesPointFile(vm.excelSalesPointExport).then(function (result, a, b, c) {
                console.log('excel result', result, a, b, c);
                vm.loading = false;
            });
        };

        vm.exportSalesPoint = function () {
            console.log("vm.exportSalesPointExcel", vm.exportSalesPointExcel);
            vm.loading = true;
            setTimeout(function () {
                $("#exportSalesPointForm").submit();
            }, 1000);
            vm.loading = false;
        };

    }
})();

