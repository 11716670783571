(function () {
    'use strict';

    angular
        .module('app.categories')
        .controller('CategoriesEditController', categories);

    categories.$inject = ['$location', '$stateParams', 'CategoriesService', 'Settings', '$uibModal'];

    function categories($location, $stateParams, CategoriesService, Settings, $uibModal) {
        /* jshint validthis:true */
        var vm = this;
        vm.Settings = Settings;

        vm.loading = true;
        vm.title = '';

        CategoriesService.getCategory($stateParams.CategoryId).then(function (data) {
            vm.entity = data;
            vm.entity.Options = vm.entity.CategoryOption.map(function (item) {
                return item.OptionId;
            });
            vm.entity.Roles = vm.entity.CategoryRoles.map(function (item) {
                return item.Role.Id;
            });
            vm.loading = false;
            vm.getMedia();
        });

        vm.fields = CategoriesService.getCategoryFields();
        vm.categoryMediaFields = CategoriesService.getCategoryMediaFields();
        vm.additionalFields = CategoriesService.getCategoryAdditionalFields();

        vm.edit = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                vm.entity.CategoryMedia = null;
                vm.entity.Options = vm.entity.Options || [];
                vm.entity.CategoryOption = vm.entity.Options.map(function (item) {
                    return {
                        OptionId: item
                    };
                });
                vm.entity.Options = null;
                vm.entity.CategoryRoles = vm.entity.Roles.map(function (item) {
                    if (item.id) {
                        return {
                            Id: item.id
                        };
                    } else {
                        return {
                            Id: item
                        };
                    }
                });
                vm.entity.Roles = null;
                CategoriesService.editCategory(vm.entity).then(function () {
                    vm.loading = false;
                    $location.path('/categories/detail/' + vm.entity.Id);
                });
            }
        };

        vm.options = {
            formState: {
                upload: function (fs) {
                    vm.files = fs;
                }
            }
        };

        vm.cancel = function () {
            $location.path('/categories/detail/' + vm.entity.Id);
        };

        vm.removeFile = function (entity) {
            CategoriesService.removeCategoryMedia(entity)
                .then(function (data) {
                    vm.getMedia();
                });
        };

        // vm.getMedia = function () {
        //     CategoriesService.getCategoryMedia(vm.entity.Id).then(function (data) {
        //         vm.categoryMedia = data;
        //     });
        // };

        // vm.addMedia = function () {
        //     var $uibModalInstance = $uibModal.open({
        //         windowClass: "filemanager-modal",
        //         template: '<div class="modal-body" id="modal-body-{{name}}">' +
        //             "<angular-filemanager></angular-filemanager>" +
        //             "</div>"
        //     });

        //     function pickItem(evt) {
        //         CategoriesService.addCategoryMedia(vm.entity.Id, evt.detail.item.content).then(function () {
        //             vm.getMedia();
        //         });
        //         $uibModalInstance.dismiss();
        //         window.removeEventListener("onPickItem", pickItem);
        //     }
        //     window.addEventListener("onPickItem", pickItem, false);
        // };

        vm.getMedia = function () {
            CategoriesService.getCategoryMedia(vm.entity.Id)
                .then(function (data) {
                    vm.categoryMedia = data;
                });
        };

        vm.editMedia = function (categoryMedia) {
            vm.categoryMediaEntity = categoryMedia;
        };

        vm.addMedia = function () {
            vm.categoryMediaEntity = {
                CategoryId: vm.entity.Id
            };
        };

        vm.categoryMediaSubmit = function () {
            if (vm.categoryMediaForm.$valid) {
                if (Array.isArray(vm.categoryMediaEntity.MediaList)) {
                    console.log("1");
                    var orjinalEntity = angular.copy(vm.categoryMediaEntity);
                    for (var media of orjinalEntity.MediaList) {
                        var entity = angular.copy(orjinalEntity);
                        entity.MediaList = null;
                        entity.MediaId = media.id;
                        CategoriesService.addCategoryMedia(entity)
                            .then(function () {
                                vm.loading = false;
                                vm.categoryMediaEntity = null;
                                vm.getMedia();
                            });
                    }
                } else if (vm.categoryMediaEntity.Id) {
                    CategoriesService.editCategoryMedia(vm.categoryMediaEntity)
                        .then(function () {
                            vm.loading = false;
                            vm.categoryMediaEntity = null;
                            vm.getCategoryMedia();
                        });
                } else {
                    console.log("2");
                    CategoriesService.addCategoryMedia(vm.categoryMediaEntity)
                        .then(function () {
                            vm.loading = false;
                            vm.categoryMediaEntity = null;
                            vm.getMedia();
                        });
                }
            }
        };

        vm.categoryMediaCancel = function () {
            vm.categoryMediaEntity = null;
        };
    }
})();