(function () {
    'use strict';

    angular
        .module('app.trendyolIntegration')
        .controller('HBEditMatchCategoryController', HBEditMatchCategoryController);

    HBEditMatchCategoryController.$inject = ['$scope', '$stateParams', '$location', 'HBIntegrationService', 'NgTableParams'];

    function HBEditMatchCategoryController($scope, $stateParams, $location, HBIntegrationService, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;
        vm.settings = globalSettings;
        activate();

        function activate() {
            vm.fields = HBIntegrationService.hbgetMatchCategoryFields();
            HBIntegrationService.hbgetMatchCategory($stateParams.Id)
                .then(function (data) {
                    vm.entity = data;
                    vm.entity.refresh = true;
                    vm.loading = false;
                });
        }

        vm.editMatchCategory = function () {
            if (vm.form.$valid) {
                vm.loading = true;

                vm.entity.IntegrationCategoryFullText = vm.entity.HBCategories[0].name;
                vm.entity.IntegrationCategoryId = vm.entity.HBCategories[0].id;
                vm.entity.IntegrationCategoryName = vm.entity.HBCategories[0].name;

                vm.entity.EComCategoryId = vm.entity.EComCategoryId[0].id;

                HBIntegrationService.hbeditMatchCategory(vm.entity).then(function () {
                    vm.loading = false;
                    activate();
                });
            }
        };

    }
})();