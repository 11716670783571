(function () {
    'use strict';

    angular
        .module('app.carts')
        .controller('CartsAddController', AddController);

    AddController.$inject = ['$location', 'CartsService'];

    function AddController($location, Service) {

        /* jshint validthis:true */
        var vm = this;

        vm.title = '';

        vm.entity = {
            Active: true
        };
        if ($location.search().UserId) {
            vm.entity.UserId = $location.search().UserId;
        }

        vm.fields = Service.getFields();

        vm.create = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                Service.add(vm.entity).then(function (data) {
                    vm.loading = false;
                    $location.path('/carts/edit/' + data.Id);
                });
            }
        };
    }
})();