(function() {
    "use strict";

    angular.module("app.account").controller("AccountAddController", AccountAddController);

    AccountAddController.$inject = ["$location", "$timeout", "AccountService"];

    function AccountAddController($location, $timeout, AccountService) {
        var vm = this;

        vm.title = "";

        vm.createuser = {};

        vm.userFields = AccountService.getUserFields();

        vm.userCreate = function() {
            if (vm.form.$valid) {
                vm.loading = true;
                vm.errorMessage = null;
                AccountService.addUser(vm.createuser).then(
                    function(data) {
                        vm.loading = false;
                        $location.path("/account/detail/" + data.Id);
                    },
                    function(data) {
                        vm.loading = false;
                        vm.errorMessage = data.data.message;
                    }
                );
            }
        };
    }
})();
