(function () {
    'use strict';

    angular
        .module('app.stockHistories')
        .controller('StockHistoriesAddController', AddController);

    AddController.$inject = ['$location', '$scope', 'StockHistoriesService', 'ProductsService', 'StockItemsService'];

    function AddController($location, $scope, Service, ProductsService, StockItemsService) {

        /* jshint validthis:true */
        var vm = this;

        vm.title = '';

        vm.entity = {
            Date: new Date(),
            AutoGenerateStockItem: false
        };

        vm.fields = Service.getFields();

        vm.productFields = Service.getProductFields()

        $scope.$watch('vm.entity.ProductId', function (newValue, oldValue) {
            if (newValue) {
                vm.costMessage = null;
                vm.selectedProduct = null;
                ProductsService.getProduct(newValue)
                    .then(function (data) {
                        vm.selectedProduct = data;
                        StockItemsService.getAll({
                                SortProperty: "Cost",
                                PageSize: -1,
                                ProductId: data.Id,
                                StockItemStatusId: '26fdb071-fe3e-4813-9a0b-e2f2a794ce7a'
                            })
                            .then(function (item) {
                                vm.costMessage = "Tavsiye edilen Sisteme Satış Fiyatı: " + item.Data[0].Cost;
                            })
                    })
            }
        });

        vm.create = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                Service.add(vm.entity).then(function (data) {
                    vm.loading = false;
                    $location.path('/stockHistories/detail/' + data.Id);
                });
            }
        };
    }
})();