(function() {
    'use strict';

    angular
        .module('app.sellers')
        .controller('SellersListController', SellersListController);

    SellersListController.$inject = ['$scope', '$location', 'SellersService', 'NgTableParams'];

    function SellersListController($scope, $location, SellersService, NgTableParams) {
        var vm = this;

        activate();

        function activate() {

            vm.refresh = function () {
                vm.loading = true;
                vm.tableParams.reload();
                vm.loading = false;
            };

            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 10 }, $location.search()),
                {
                    getData: function(params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {};
                        filterData.PageIndex = params.page(),
                            filterData.PageSize = params.count(),
                            filterData.SortProperty = Object.keys(params.sorting())[0],
                            filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return SellersService.getAll(filterData).then(function(data) {
                            params.total(data.FilteredCount);
                            return data.Data;
                        });
                    }
                });
        }
    }
})();