(function () {
    'use strict';

    angular
        .module('app.dashboard')
        .component('newOrder', {
            templateUrl: 'app/dashboard/dashboard.neworder.html',
            controller: NewOrder
        });

    NewOrder.$inject = ['OrdersService'];

    function NewOrder(OrdersService) {
        /*jshint validthis: true */
        var vm = this;

        vm.loading = true;

        var expectedtoPayOrderParams = {
            PageSize: -1,
            StatusId: '9537cd7e-8665-4533-8247-80b1da78cb06'
        };
        OrdersService.getOrders(expectedtoPayOrderParams)
            .then(function (data) {
                vm.expectedtoPayOrderList = data.Data;
                vm.loading = false;
            });

        var pendingOrderParams = {
            PageSize: -1,
            StatusId: '29be5037-642a-4854-ae7d-772d0be8367e'
        };
        OrdersService.getOrders(pendingOrderParams)
            .then(function (data) {
                vm.pendingOrderList = data.Data;
                vm.loading = false;
            });

        var processOrderParams = {
            PageSize: -1,
            StatusId: '6fab9e8b-e3ef-4336-8f98-bba4607193d1'
        };
        OrdersService.getOrders(processOrderParams)
            .then(function (data) {
                vm.processOrderList = data.Data;
                vm.loading = false;
            });

        var shippedOrderParams = {
            PageSize: -1,
            StatusId: 'dcc9d370-5182-47a7-b0c3-9c65a745d712'
        };
        OrdersService.getOrders(shippedOrderParams)
            .then(function (data) {
                vm.shippedOrderList = data.Data;
                vm.loading = false;
            });

        var refundOrderParams = {
            PageSize: -1,
            StatusId: 'a9752b35-21be-4f12-9ba3-41f0acdd8a04'
        };
        OrdersService.getOrders(refundOrderParams)
            .then(function (data) {
                vm.refundOrderList = data.Data;
                vm.loading = false;
            });
    }
})();