(function() {
    "use strict";

    angular.module("app.carts").factory("CartsService", Service);

    Service.$inject = ["$http", "$q", "ngAuthSettings", "CustomerAddressService", "CustomersService", "BanksService"];

    function Service($http, $q, ngAuthSettings, CustomerAddressService, CustomersService, BanksService) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            getFields: getFields,
            getCartTypes: getCartTypes,
            InsertProduct: InsertProduct,
            IncrementProduct: IncrementProduct,
            DecrementProduct: DecrementProduct,
            DeleteProduct: DeleteProduct,
            getCreditCardFields: getCreditCardFields,
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + "api/carts/";

        var fields = [
            {
                key: "CartTypeId",
                type: "floatSelect",
                defaultValue: "355c5e8a-1a5e-46e2-8b84-fa8d6d0d66b3",
                templateOptions: {
                    disabled: "disabled",
                    label: "Sepet Türü",
                    resource: "form.cart-type",
                    options: [
                        {
                            Name: "...",
                        },
                    ],
                },
                controller: [
                    "$scope",
                    function($scope) {
                        getCartTypes().then(function(response) {
                            $scope.to.options = response;
                        });
                    },
                ],
            },
            {
                key: "UserId",
                type: "float-async-ui-select",
                templateOptions: {
                    required: true,
                    label: "Müşteri",
                    resource: "form.customer",
                    options: [],
                    placeholder: "...",
                    valueProp: "Id",
                    labelProp: "FullName",
                    refresh: function(text, options, model) {
                        var params = {
                            Fullname: text,
                            SelectFields: "Id,Name,Surname",
                            TrackStockQuantity: true,
                        };
                        if (model.UserId) {
                            params.Id = model.UserId;
                        }
                        return CustomersService.getCustomers(params).then(function(response) {
                            if (response.Data.length > 0) {
                                options.templateOptions.options = response.Data;
                            } else {
                                options.templateOptions.options = [
                                    {
                                        Id: "",
                                        Name: "Bulunamadı (" + text + ")",
                                    },
                                ];
                            }
                        });
                    },
                    refreshDelay: 2,
                },
            },
            {
                key: "Title",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    resource: "form.title",
                    label: "Başlık",
                },
            },
            {
                key: "DeliveryAddressId",
                type: "floatSelect",
                templateOptions: {
                    resource: "form.delivery-address",
                    label: "Teslimat Adresi",
                    options: [
                        {
                            Name: "Müşteri Seçiniz...",
                        },
                    ],
                },
                hideExpression:
                    '!((model.CartTypeId=="3d608d3a-a806-4a72-8ee0-bee9fbdb3d11" || model.CartTypeId=="355c5e8a-1a5e-46e2-8b84-fa8d6d0d66b3") && model.Active == true)',
                controller: [
                    "$scope",
                    function($scope) {
                        $scope.$watch("model.UserId", function(newValue, oldValue) {
                            if (newValue) {
                                CustomerAddressService.getCustomerAddresses(newValue).then(function(data) {
                                    $scope.to.options = data.map(function(select) {
                                        return {
                                            Id: select.Id,
                                            Name:
                                                select.Title +
                                                " / " +
                                                (select.Country ? select.Country + "-" : "") +
                                                (select.CountryName ? select.CountryName + "-" : "") +
                                                (select.StateProvince ? select.StateProvince + "-" : "") +
                                                (select.StateProvinceName ? select.StateProvinceName + "-" : "") +
                                                (select.District ? select.District + "-" : "") +
                                                (select.DistrictName ? select.DistrictName + "-" : "") +
                                                (select.City ? select.City + "-" : "") +
                                                (select.CityName ? select.CityName + "-" : "") +
                                                (select.Street1 ? select.Street1 + "-" : "") +
                                                (select.ApartmentNo ? select.ApartmentNo + "-" : "") +
                                                (select.ZipPostalCode ? select.ZipPostalCode + "-" : "") +
                                                (select.Description || ""),
                                        };
                                    });
                                });
                            }
                        });
                    },
                ],
            },
            {
                key: "InvoiceAddressId",
                type: "floatSelect",
                templateOptions: {
                    resource: "form.invoice-address",
                    label: "Fatura Adresi",
                    options: [
                        {
                            Name: "Müşteri Seçiniz...",
                        },
                    ],
                },
                hideExpression:
                    '!((model.CartTypeId=="3d608d3a-a806-4a72-8ee0-bee9fbdb3d11" || model.CartTypeId=="355c5e8a-1a5e-46e2-8b84-fa8d6d0d66b3") && model.Active == true)',
                controller: [
                    "$scope",
                    function($scope) {
                        $scope.$watch("model.UserId", function(newValue, oldValue) {
                            if (newValue) {
                                CustomerAddressService.getCustomerAddresses(newValue).then(function(data) {
                                    $scope.to.options = data.map(function(select) {
                                        return {
                                            Id: select.Id,
                                            Name:
                                                select.Title +
                                                " / " +
                                                (select.Country ? select.Country + "-" : "") +
                                                (select.CountryName ? select.CountryName + "-" : "") +
                                                (select.StateProvince ? select.StateProvince + "-" : "") +
                                                (select.StateProvinceName ? select.StateProvinceName + "-" : "") +
                                                (select.District ? select.District + "-" : "") +
                                                (select.DistrictName ? select.DistrictName + "-" : "") +
                                                (select.City ? select.City + "-" : "") +
                                                (select.CityName ? select.CityName + "-" : "") +
                                                (select.Street1 ? select.Street1 + "-" : "") +
                                                (select.ApartmentNo ? select.ApartmentNo + "-" : "") +
                                                (select.ZipPostalCode ? select.ZipPostalCode + "-" : "") +
                                                (select.Description || ""),
                                        };
                                    });
                                });
                            }
                        });
                    },
                ],
            },
            {
                key: "Note",
                type: "floatTextarea",
                templateOptions: {
                    type: "text",
                    label: "Not",
                    resource: "form.note",
                    placeholder: "Not",
                },
                hideExpression:
                    '!((model.CartTypeId=="3d608d3a-a806-4a72-8ee0-bee9fbdb3d11" || model.CartTypeId=="355c5e8a-1a5e-46e2-8b84-fa8d6d0d66b3") && model.Active == true)',
            },
            {
                key: "CustomDiscount",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    numberMask: 2,
                    resource: "form.custom-discount",
                    label: "Özel İndirim",
                },
                hideExpression:
                    '!((model.CartTypeId=="3d608d3a-a806-4a72-8ee0-bee9fbdb3d11" || model.CartTypeId=="355c5e8a-1a5e-46e2-8b84-fa8d6d0d66b3") && model.Active == true)',
            },
        ];

        var CreditCardfields = [
            {
                key: "CardholderName",
                type: "floatInput",
                templateOptions: {
                    required: true,
                    type: "text",
                    label: "Adı Soyadı",
                    resource: "form.cardholdername",
                    class: "col-xs-12",
                },
            },
            {
                key: "CardNumber",
                type: "floatInput",
                templateOptions: {
                    required: true,
                    number: "",
                    type: "text",
                    mask: "9999 9999 9999 9999",
                    label: "Kart Numarası",
                    resource: "form.cardnumber",
                    class: "col-xs-12",
                },
            },
            {
                key: "ExpirationMonth",
                type: "floatSelect",
                templateOptions: {
                    required: true,
                    type: "text",
                    label: "Ay",
                    resource: "form.expirationmonth",
                    placeholder: "Ay",
                    class: "col-md-3",
                    options: [
                        {
                            Name: "1",
                            Id: "1",
                        },
                        {
                            Name: "2",
                            Id: "2",
                        },
                        {
                            Name: "3",
                            Id: "3",
                        },
                        {
                            Name: "4",
                            Id: "4",
                        },
                        {
                            Name: "5",
                            Id: "5",
                        },
                        {
                            Name: "6",
                            Id: "6",
                        },
                        {
                            Name: "7",
                            Id: "7",
                        },
                        {
                            Name: "8",
                            Id: "8",
                        },
                        {
                            Name: "9",
                            Id: "9",
                        },
                        {
                            Name: "10",
                            Id: "10",
                        },
                        {
                            Name: "11",
                            Id: "11",
                        },
                        {
                            Name: "12",
                            Id: "12",
                        },
                    ],
                },
            },
            {
                key: "ExpirationYear",
                type: "floatSelect",
                templateOptions: {
                    required: true,
                    type: "text",
                    label: "Yıl",
                    resource: "form.expirationyear",
                    placeholder: "Yıl",
                    class: "col-md-3",
                    options: [
                        {
                            Name: "2020",
                            Id: "2020",
                        },
                        {
                            Name: "2021",
                            Id: "2021",
                        },
                        {
                            Name: "2022",
                            Id: "2022",
                        },
                        {
                            Name: "2023",
                            Id: "2023",
                        },
                        {
                            Name: "2024",
                            Id: "2024",
                        },
                        {
                            Name: "2025",
                            Id: "2025",
                        },
                        {
                            Name: "2026",
                            Id: "2026",
                        },
                        {
                            Name: "2027",
                            Id: "2027",
                        },
                        {
                            Name: "2028",
                            Id: "2028",
                        },
                        {
                            Name: "2029",
                            Id: "2029",
                        },
                        {
                            Name: "2030",
                            Id: "2030",
                        },
                    ],
                },
            },
            {
                key: "CVV2",
                type: "floatInput",
                templateOptions: {
                    required: true,
                    type: "text",
                    label: "Cvv",
                    resource: "form.cvv2",
                    class: "col-xs-6",
                },
            },
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getCreditCardFields() {
            return CreditCardfields;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();

            $http
                .get(serviceBase, {
                    params: filterParams,
                })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(Id) {
            var deferred = $q.defer();

            $http
                .get(serviceBase + Id)
                .then(getProductComplete)
                .catch(getProductFailed);

            function getProductComplete(response) {
                deferred.resolve(response.data);
            }

            function getProductFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();

            $http
                .post(serviceBase, entity)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http
                .put(serviceBase + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(entity) {
            var deferred = $q.defer();

            $http
                .delete(serviceBase + entity.Id, entity)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCartTypes() {
            var deferred = $q.defer();

            $http
                .get(serviceBase + "CartTypes")
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function InsertProduct(cartProduct) {
            var deferred = $q.defer();

            $http
                .post("api/Carts/Product/" + cartProduct.ProductId + "/" + cartProduct.Quantity, cartProduct)
                .then(complete)
                .catch(failed);

            function complete(response) {
                deferred.resolve(response.data);
            }

            function failed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function IncrementProduct(cartProduct) {
            var deferred = $q.defer();

            $http
                .put("api/Carts/Product/" + cartProduct.ProductId + "/" + (cartProduct.Quantity + 1), {
                    Id: cartProduct.Id,
                    ProductId: cartProduct.ProductId,
                    Quantity: cartProduct.Quantity + 1,
                })
                .then(complete)
                .catch(failed);

            function complete(response) {
                deferred.resolve(response.data);
            }

            function failed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function DecrementProduct(cartProduct) {
            var deferred = $q.defer();

            $http
                .put("api/Carts/Product/" + cartProduct.ProductId + "/" + (cartProduct.Quantity - 1), {
                    Id: cartProduct.Id,
                    ProductId: cartProduct.ProductId,
                    Quantity: cartProduct.Quantity - 1,
                })
                .then(complete)
                .catch(failed);

            function complete(response) {
                deferred.resolve(response.data);
            }

            function failed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function DeleteProduct(cartProduct) {
            var deferred = $q.defer();

            $http
                .delete("api/Carts/Product/" + cartProduct.Id)
                .then(complete)
                .catch(failed);

            function complete(response) {
                deferred.resolve(response.data);
            }

            function failed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
    }
})();
