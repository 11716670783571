(function () {
    'use strict';

    angular
        .module('app.trendyolIntegration')
        .controller('TrendyolAddMatchCategoryController', TrendyolAddMatchCategoryController);

    TrendyolAddMatchCategoryController.$inject = ['$scope', '$stateParams', '$location', 'TrendyolIntegrationService', 'NgTableParams'];

    function TrendyolAddMatchCategoryController($scope, $stateParams, $location, TrendyolIntegrationService, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;

        vm.settings = globalSettings;
        activate();


        function activate() {
            vm.fields = TrendyolIntegrationService.getMatchCategoryFields();
           
        }

        vm.addMatchCategory = function () {
            if (vm.form.$valid) {
                vm.loading = true;

                if (vm.entity.TRCategories1) {
                    vm.entity.IntegrationCategoryFullText = vm.entity.TRCategories1Name;
                    vm.entity.IntegrationCategoryId = vm.entity.TRCategories1Id;
                    vm.entity.IntegrationCategoryName = vm.entity.TRCategories1Name;
                }
                if (vm.entity.TRCategories2) {
                    vm.entity.IntegrationCategoryFullText = vm.entity.IntegrationCategoryFullText + "/" + vm.entity.TRCategories2Name;
                    vm.entity.IntegrationCategoryId = vm.entity.TRCategories2Id;
                    vm.entity.IntegrationCategoryName = vm.entity.TRCategories2Name;
                }
                if (vm.entity.TRCategories3) {
                    vm.entity.IntegrationCategoryFullText = vm.entity.IntegrationCategoryFullText + "/" + vm.entity.TRCategories3Name;
                    vm.entity.IntegrationCategoryId = vm.entity.TRCategories3Id;
                    vm.entity.IntegrationCategoryName = vm.entity.TRCategories3Name;
                }
                if (vm.entity.TRCategories4) {
                    vm.entity.IntegrationCategoryFullText = vm.entity.IntegrationCategoryFullText + "/" + vm.entity.TRCategories4Name;
                    vm.entity.IntegrationCategoryId = vm.entity.TRCategories4Id;
                    vm.entity.IntegrationCategoryName = vm.entity.TRCategories4Name;
                }
                if (vm.entity.TRCategories5) {
                    vm.entity.IntegrationCategoryFullText = vm.entity.IntegrationCategoryFullText + "/" + vm.entity.TRCategories5Name;
                    vm.entity.IntegrationCategoryId = vm.entity.TRCategories5Id;
                    vm.entity.IntegrationCategoryName = vm.entity.TRCategories5Name;
                }
                if (vm.entity.TRCategories6) {
                    vm.entity.IntegrationCategoryFullText = vm.entity.IntegrationCategoryFullText + "/" + vm.entity.TRCategories6Name;
                    vm.entity.IntegrationCategoryId = vm.entity.TRCategories6Id;
                    vm.entity.IntegrationCategoryName = vm.entity.TRCategories6Name;
                }

                vm.entity.EComCategoryId = vm.entity.EComCategoryId[0].id;

                TrendyolIntegrationService.addMatchCategory(vm.entity).then(function (data) {
                    vm.loading = false;
                    $location.path('/trendyolIntegration/trendyolmatchcategorylist');
                },
                    function (data) {
                        vm.loading = false;
                    });
                vm.loading = false;
            }
        };
    }
})();
