(function () {
    'use strict';

    angular
        .module('app.pages')
        .factory('PageSectionsService', PageSectionsService);

    PageSectionsService.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config', '$timeout'];

    function PageSectionsService($http, $q, ngAuthSettings, logger, config, $timeout) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            copy: copy,
            copyToPage: copyToPage,
            getFields: getFields,
            getPageSections: getPageSections
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/pagesections/';

        var fields = [
        ];

        return service;

        ///////////////////////////////

        function getAll(filterParams) {
            var deferred = $q.defer();
            $http.get(serviceBase, {
                params: filterParams
            })
                .then(getAllComplete)
                .catch(getAllFailed);

            function getAllComplete(response) {
                deferred.resolve(response.data);
            }

            function getAllFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getPageSections(PageId, TemplateId) {
            var deferred = $q.defer();
            $http.get(serviceBase + PageId + "/Page/" + TemplateId)
                .then(getAllComplete)
                .catch(getAllFailed);

            function getAllComplete(response) {
                deferred.resolve(response.data);
            }

            function getAllFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function get(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();

            $http.post(serviceBase, entity)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http.put(serviceBase + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function copy(Id) {
            var deferred = $q.defer();

            $http.put(serviceBase + "copy/" + Id)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function copyToPage(Id, PageId) {
            var deferred = $q.defer();

            $http.put(serviceBase + "copyToPage/" + Id + "/" + PageId)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(customer) {
            var deferred = $q.defer();

            $http.delete(serviceBase + customer.Id, customer)
                .then(delComplete)
                .catch(delFailed);

            function delComplete(response) {
                deferred.resolve(response.data);
            }

            function delFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getFields() {
            return fields;
        }
    }
})();