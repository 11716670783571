(function () {
    'use strict';

    angular
        .module('app.users')
        .factory('UsersService', Service);

    Service.$inject = ['$http', '$q', 'ngAuthSettings'];

    function Service($http, $q, ngAuthSettings) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            getFields: getFields
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/users/';

        var fields = [
            {
                key: 'Name',
                type: 'horizontalInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Adı',
                    resource: 'form.name',
                    placeholder: 'Adı'
                }
            },
            {
                key: 'Active',
                type: 'horizontalCheckbox',
                templateOptions: {
                    label: 'Aktif',
                    resource: 'form.active',
                    placeholder: 'Aktif'
                }
            },
            {
                key: "MediaId",
                type: "horizontalSingleImage",
                templateOptions: {
                    label: "İkon",
                    resource: 'form.icon',
                    valueKey: "Id",
                    valueModel: "Media.FileName"
                }
            }
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBase, {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(Id) {
            var deferred = $q.defer();

            $http.get(serviceBase + Id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(category) {
            var deferred = $q.defer();

            $http.post(serviceBase, category)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(category) {
            var deferred = $q.defer();

            $http.put(serviceBase + category.Id, category)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(category) {
            var deferred = $q.defer();

            $http.delete(serviceBase + category.Id, category)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();