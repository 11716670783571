(function () {
    'use strict';

    angular
        .module('app.markettemplates')
        .factory('MarketTemplatesService', MarketTemplatesService);

    MarketTemplatesService.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config', 'ProductsService', 'ProductsGeneralService', 'GeneralService'];

    function MarketTemplatesService($http, $q, ngAuthSettings, logger, config, ProductsService, ProductsGeneralService,GeneralService) {
        var service = {


            getShipmentTemplateFields: getShipmentTemplateFields,
            getAllTemplate: getAllTemplate,
            addShipmentTemplate: addShipmentTemplate,
            refreshList: refreshList,
            getShipmentTemplate: getShipmentTemplate

        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/templatesIntegration/';




        ///////////////////////////////////////////
        function getShipmentTemplateFields() {
            return ShipmentTemplateFields;
        }
        var ShipmentTemplateFields = [
            {
                key: 'Name',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Şablon Adı',
                    resource: 'form.template.name',
                    placeholder: 'Adı',
                    class: 'col-md-6'
                }
            },
            {
                key: 'CargoPriceType',
                type: 'floatSelect',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Kargo Ödeme Yöntemi',
                    resource: 'form.cargopricetype',
                    placeholder: 'Kargo Ödeme Yöntemi',
                    class: 'col-md-6',
                    options: [
                        {
                            Name: 'Alıcı Öder',
                            Id: '1'
                        },
                        {
                            Name: 'Satıcı Öder',
                            Id: '2'
                        },
                        {
                            Name: 'Şartlı Kargo',
                            Id: '3'
                        }
                    ]
                }
            },
            {
                key: 'SetupInfo',
                type: 'floatTextarea',
                templateOptions: {
                    type: 'text',
                    label: 'Kurulum Bilgileri',
                    resource: 'form.setupinfo',
                    placeholder: 'Setup Bilgileri',
                    class: 'col-md-6'
                }
            },
            {
                key: 'ChangeInfo',
                type: 'floatTextarea',
                templateOptions: {
                    type: 'text',
                    label: 'Değişim Bilgileri',
                    resource: 'form.changeinfo',
                    placeholder: 'Değişim Bilgileri',
                    class: 'col-md-6'
                }
            },
            {
                key: 'DeliveryInfo',
                type: 'floatTextarea',
                templateOptions: {
                    type: 'text',
                    label: 'Teslimat Bilgileri',
                    resource: 'form.deliveryinfo',
                    placeholder: 'Teslimat Bilgileri',
                    class: 'col-md-6'
                }
            },
            {
                key: 'PrivateDeliveries',
                type: 'floatSelect',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Özel Teslimat',
                    resource: 'form.privateDeliveries',
                    placeholder: 'Özel Teslimat',
                    class: 'col-md-6',
                    options: [
                        {
                            Name: 'Evet',
                            Id: 'true'
                        },
                        {
                            Name: 'Hayır',
                            Id: 'false'
                        }
                    ]
                }
            },
            {
                key: 'DeliveryMethod',
                type: 'floatSelect',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Teslimat Metodu',
                    resource: 'form.deliverymethod',
                    placeholder: 'Teslimat Metodu',
                    class: 'col-md-6',
                    options: [
                        {
                            Name: 'Evet',
                            Id: '1'
                        },
                        {
                            Name: 'Hayır',
                            Id: '1'
                        }
                    ]
                }
            },
            {
                key: 'StoreTitle',
                type: 'floatInput',
                templateOptions: {
                    type: 'text',
                    label: 'Depo Adres Başlığı',
                    resource: 'form.storetitle',
                    placeholder: 'Depo Adres Başlığı',
                    class: 'col-md-6'
                }
            },
            {
                key: 'ReturnStoreTitle',
                type: 'floatInput',
                templateOptions: {
                    type: 'text',
                    label: 'İade Depo Adres Başlığı',
                    resource: 'form.returnstoretitle',
                    placeholder: 'İade Depo Adres Başlığı',
                    class: 'col-md-6'
                }
            },
            {
                key: 'StoreAddressInfo',
                type: 'floatTextarea',
                templateOptions: {
                    type: 'text',
                    label: 'Depo Adres Bilgilesi',
                    resource: 'form.store.address.info',
                    placeholder: 'Depo Adres Bilgisi',
                    class: 'col-md-6'
                }
            },
            {
                key: 'ReturnStoreAddressInfo',
                type: 'floatTextarea',
                templateOptions: {
                    type: 'text',
                    label: 'İade Depo Adres Bilgilesi',
                    resource: 'form.return.store.address.info',
                    placeholder: 'İade Depo Adres Bilgisi',
                    class: 'col-md-6'
                }
            },
            {
                key: 'StateProvinceId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'İl',
                    resource: 'form.state-province',
                    required: true,
                    options: [{ Name: 'Önce Ülke Seçiniz...' }],
                    class: 'col-md-6'
                },
                controller: ['$scope', function ($scope) {
                    $scope.$watch('model.CountryId', function (newValue, oldValue) {
                        var CountryType = 1;
                        if (CountryType === 1) {
                           
                                GeneralService.getStateProvince("D8060256-D952-4895-BCD7-2908ED9CC6BD").then(function (data) {
                                    $scope.to.options = data.sort(function (a, b) {
                                        return a.Name.localeCompare(b.Name);
                                    });
                                });
                           
                        } else {
                            GeneralService.getStateProvince()
                                .then(function (data) {
                                    $scope.to.options = data.sort(function (a, b) {
                                        return a.Name.localeCompare(b.Name);
                                    });
                                });
                        }
                    });
                }],
                expressionProperties: { 'templateOptions.required': 'model.AddressSettings.IsStateProvinceRequired' }
            },
            {
                key: 'ReturnStateProvinceId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'İl',
                    resource: 'form.return.state-province',
                    required: true,
                    options: [{ Name: 'Önce Ülke Seçiniz...' }],
                    class: 'col-md-6'
                },
                controller: ['$scope', function ($scope) {
                    $scope.$watch('model.CountryId', function (newValue, oldValue) {
                        var CountryType = 1;
                        if (CountryType === 1) {

                            GeneralService.getStateProvince("D8060256-D952-4895-BCD7-2908ED9CC6BD").then(function (data) {
                                $scope.to.options = data.sort(function (a, b) {
                                    return a.Name.localeCompare(b.Name);
                                });
                            });

                        } else {
                            GeneralService.getStateProvince()
                                .then(function (data) {
                                    $scope.to.options = data.sort(function (a, b) {
                                        return a.Name.localeCompare(b.Name);
                                    });
                                });
                        }
                    });
                }],
                expressionProperties: { 'templateOptions.required': 'model.AddressSettings.IsStateProvinceRequired' }
            },
            {
                key: 'DistrictId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'İlçe',
                    resource: 'form.district',
                    required: true,
                    options: [{ Name: 'Önce İl Seçiniz...' }],
                    class: 'col-md-6'
                },
                controller: ['$scope', function ($scope) {
                    $scope.$watch('model.StateProvinceId', function (newValue, oldValue) {
                        var StateProvinceType = 1;
                        if (StateProvinceType === 1) {
                            if (newValue) {
                                GeneralService.getDistrict($scope.model.StateProvinceId)
                                    .then(function (data) {
                                        $scope.to.options = data.sort(function (a, b) {
                                            return a.Name.localeCompare(b.Name);
                                        });
                                    });
                            } else {
                                $scope.to.options = [{ Name: 'Önce İl Seçiniz...' }];
                            }
                        }
                        else {
                            GeneralService.getDistrict()
                                .then(function (data) {
                                    $scope.to.options = data.sort(function (a, b) {
                                        return a.Name.localeCompare(b.Name);
                                    });
                                });
                        }
                    });
                }],
                expressionProperties: { 'templateOptions.required': 'model.AddressSettings.IsDistrictRequired' }
            },
            {
                key: 'ReturnDistrictId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'İlçe',
                    resource: 'form.returndistrict',
                    required: true,
                    options: [{ Name: 'Önce İl Seçiniz...' }],
                    class: 'col-md-6'
                },
                controller: ['$scope', function ($scope) {
                    $scope.$watch('model.ReturnStateProvinceId', function (newValue, oldValue) {
                        var StateProvinceType = 1;
                        if (StateProvinceType === 1) {
                            if (newValue) {
                                GeneralService.getDistrict($scope.model.ReturnStateProvinceId)
                                    .then(function (data) {
                                        $scope.to.options = data.sort(function (a, b) {
                                            return a.Name.localeCompare(b.Name);
                                        });
                                    });
                            } else {
                                $scope.to.options = [{ Name: 'Önce İl Seçiniz...' }];
                            }
                        }
                        else {
                            GeneralService.getDistrict()
                                .then(function (data) {
                                    $scope.to.options = data.sort(function (a, b) {
                                        return a.Name.localeCompare(b.Name);
                                    });
                                });
                        }
                    });
                }],
                expressionProperties: { 'templateOptions.required': 'model.AddressSettings.IsDistrictRequired' }
            },
            {
                key: 'StoreAddressZipCode',
                type: 'floatInput',
                templateOptions: {
                    type: 'text',
                    label: 'Depo Adres Posta Kodu',
                    resource: 'form.storeaddresszipcode',
                    placeholder: 'Depo Adres Posta Kodu',
                    class: 'col-md-6'
                }
            },
            {
                key: 'ReturnStoreAddressZipCode',
                type: 'floatInput',
                templateOptions: {
                    type: 'text',
                    label: 'İade Depo Adres Posta Kodu',
                    resource: 'form.returnstoreaddresszipcode',
                    placeholder: 'İade Depo Adres Posta Kodu',
                    class: 'col-md-6'
                }
            },
            {
                key: 'CargoCompanyName',
                type: 'floatSelect',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Kargo Şirket Adı',
                    resource: 'form.deliverymethod',
                    placeholder: 'Kargo Şirket Adı',
                    class: 'col-md-6',
                    options: [
                        {
                            Name: 'Aras Kurye',
                            Id: 'ARS2'
                        },
                        {
                            Name: 'Sürat',
                            Id: 'SRT'
                        },
                        {
                            Name: 'Yurtiçi',
                            Id: 'YK'
                        },
                        {
                            Name: 'Aras',
                            Id: 'ARAS'
                        },
                        {
                            Name: 'MNG Kargo',
                            Id: 'MNG'
                        },
                        {
                            Name: 'UPS',
                            Id: 'UP'
                        },
                        {
                            Name: 'PTT Kargo',
                            Id: 'PTT'
                        },
                        {
                            Name: 'Ceva Lojistik',
                            Id: 'CEVA'
                        },
                        {
                            Name: 'Horoz Lojistik',
                            Id: 'HLZ'
                        },
                        {
                            Name: 'DHL Kargo',
                            Id: 'DHL'
                        },
                        {
                            Name: 'TNT Kargo',
                            Id: 'TNT'
                        }
                    ]
                }
            },
            {
                key: 'ReturnCargoCompanyName',
                type: 'floatSelect',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'İade Kargo Şirket Adı',
                    resource: 'form.returndeliverymethod',
                    placeholder: 'İade Kargo Şirket Adı',
                    class: 'col-md-6',
                    options: [
                        {
                            Name: 'Aras Kurye',
                            Id: 'ARS2'
                        },
                        {
                            Name: 'Sürat',
                            Id: 'SRT'
                        },
                        {
                            Name: 'Yurtiçi',
                            Id: 'YK'
                        },
                        {
                            Name: 'Aras',
                            Id: 'ARAS'
                        },
                        {
                            Name: 'MNG Kargo',
                            Id: 'MNG'
                        },
                        {
                            Name: 'UPS',
                            Id: 'UP'
                        },
                        {
                            Name: 'PTT Kargo',
                            Id: 'PTT'
                        },
                        {
                            Name: 'Ceva Lojistik',
                            Id: 'CEVA'
                        },
                        {
                            Name: 'Horoz Lojistik',
                            Id: 'HLZ'
                        },
                        {
                            Name: 'DHL Kargo',
                            Id: 'DHL'
                        },
                        {
                            Name: 'TNT Kargo',
                            Id: 'TNT'
                        }
                    ]
                }
            },
            {
                key: 'IntegrationCargo',
                type: 'floatSelect',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Entegrasyonlu Kargo Kullanımı',
                    resource: 'form.integration.cargo',
                    placeholder: 'Entegrasyonlu Kargo Kullanımı',
                    class: 'col-md-6',
                    options: [
                        {
                            Name: 'Kullan',
                            Id: '1'
                        },
                        {
                            Name: 'Kullanma',
                            Id: '0'
                        }
                    ]
                }
            }



        ];



        return service;

        /////////////////////////////////////////
        function getAllTemplate(filterParams) {
            var deferred = $q.defer();
            $http.get(serviceBase + "shipmentTemplate", {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
        function addShipmentTemplate(template) {
            var deferred = $q.defer();

            $http.post(serviceBase + "AddShipmentTemplate", template)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
        function refreshList() {
            var deferred = $q.defer();

            $http.get(serviceBase + "AddShipmentTemplate")
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
        function getShipmentTemplate(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "Shipmenttemplate/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
        function delShipmentTemplate(template) {
            var deferred = $q.defer();

            $http.delete(serviceBase + "template/" + template.Id)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

    }
})();