(function () {
    'use strict';

    angular
        .module('app.vendors')
        .factory('OrdersService', OrdersService);

    OrdersService.$inject = [
        '$http',
        '$q',
        'ngAuthSettings',
        'logger',
        'config',
        '$timeout',
        'CustomerAddressService'];

    function OrdersService(
        $http,
        $q,
        ngAuthSettings,
        logger,
        config,
        $timeout, 
        CustomerAddressService) {
        var service = {
            getOrders: getOrders,
            getPayments: getPayments,
            getNewOrders: getNewOrders,
            getMarketOrders: getMarketOrders,
            getProcessingOrders: getProcessingOrders,
            getCustomerOrders: getCustomerOrders,
            getCustomerDebts: getCustomerDebts,
            getCustomerDirects: getCustomerDirects,
            getOrdersProduct: getOrdersProduct,
            getOrderStatuses: getOrderStatuses,
            getPaymentStatuses: getPaymentStatuses,
            getShipmentProviders: getShipmentProviders,
            getOrder: getOrder,
            addOrder: addOrder,
            editOrder: editOrder,
            deleteOrder: deleteOrder,
            changeStatus: changeStatus,
            changePaymentStatus: changePaymentStatus,
            setShipmentCode: setShipmentCode,
            setOrderProductShipmentCode: setOrderProductShipmentCode,
            setInvoiceNumber: setInvoiceNumber,
            getOrderFields: getOrderFields,
            getPaymentFields: getPaymentFields,
            getDebtFields: getDebtFields,
            accounting: accounting,
            getTotalPrice: getTotalPrice,
            sendSms: sendSms,
            sendWhatsapp: sendWhatsapp,
            sendMail: sendMail,
            getDebtsDashboard: getDebtsDashboard,
            getDirectsDashboard: getDirectsDashboard,
            insertWithCart: insertWithCart,
            OrderAccept: OrderAccept,
            OrderReject: OrderReject,
            CreateCargoNumber: CreateCargoNumber,
            CargoProductChange: CargoProductChange,
            CargoStatus: CargoStatus,
            CargoStatusAndMail: CargoStatusAndMail,
            NetsimAccounting: NetsimAccounting,
            ShipmentProviderCreateCargoNumber: ShipmentProviderCreateCargoNumber,
            payment: payment,
            CreateCargoNumberSelected: CreateCargoNumberSelected
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri;

        var orderfields = [{
                key: 'Code',
                type: 'floatInput',
                templateOptions: {
                    disabled: 'disabled',
                    type: 'text',
                    resource: 'form.order-code',
                    label: 'Sipariş No'
                }
            },
            {
                key: 'StatusId',
                type: 'floatSelect',
                templateOptions: {
                    required: true,
                    resource: 'form.order-status',
                    label: 'Sipariş Durumu',
                    options: [{
                        Name: '...'
                    }]
                },
                controller: ['$scope', function ($scope) {
                    getOrderStatuses().then(function (response) {
                        $scope.to.options = response;
                    });
                }]
            },
            {
                key: 'OrderDate',
                type: 'floatDatepicker',
                templateOptions: {
                    label: 'Sipariş Tarihi',
                    resource: 'form.order-date',
                    placeholder: 'Sipariş Tarihi',
                    readonly: true,
                    datepickerOptions: {
                        locale: 'tr',
                        format: 'dd.MM.yyyy HH:mm'
                    }
                }
            },
            {
                key: 'DeliveryAddressId',
                type: 'floatSelect',
                templateOptions: {
                    required: true,
                    resource: 'form.delivery-address',
                    label: 'Teslimat Adresi',
                    options: [{
                        Name: '...'
                    }]
                },
                controller: ['$scope', function ($scope) {
                    $scope.$watch('model.CustomerId', function (newValue, oldValue) {
                        if (newValue) {
                            CustomerAddressService.getCustomerAddresses(newValue).then(function (data) {
                                $scope.to.options = data.map(function (select) {
                                    $scope.model.DeliveryAddress = select;
                                    return {
                                        Id: select.Id,
                                        Name: (select.Title ? select.Title + " / " : "") +
                                            (select.Country ? select.Country + "-" : "") + (select.CountryName ? select.CountryName + "-" : "") +
                                            (select.StateProvince ? select.StateProvince + "-" : "") + (select.StateProvinceName ? select.StateProvinceName + "-" : "") +
                                            (select.District ? select.District + "-" : "") + (select.DistrictName ? select.DistrictName + "-" : "") +
                                            (select.City ? select.City + "-" : "") + (select.CityName ? select.CityName + "-" : "") +
                                            (select.Street1 ? select.Street1 + "-" : "") + (select.ApartmentNo ? select.ApartmentNo + "-" : "") +
                                            (select.ZipPostalCode ? select.ZipPostalCode + "-" : "") + (select.Description || "")
                                    };
                                });
                            });
                        }
                    });
                }]
            },
            {
                key: 'InvoiceAddressId',
                type: 'floatSelect',
                templateOptions: {
                    required: true,
                    resource: 'form.invoice-address',
                    label: 'Fatura Adresi',
                    options: [{
                        Name: '...'
                    }]
                },
                controller: ['$scope', function ($scope) {
                    $scope.$watch('model.CustomerId', function (newValue, oldValue) {
                        if (newValue) {
                            CustomerAddressService.getCustomerAddresses(newValue).then(function (data) {
                                $scope.to.options = data.map(function (select) {
                                    $scope.model.InvoiceAddress = select;
                                    return {
                                        Id: select.Id,
                                        Name: (select.Title ? select.Title + " / " : "") +
                                            (select.Country ? select.Country + "-" : "") + (select.CountryName ? select.CountryName + "-" : "") +
                                            (select.StateProvince ? select.StateProvince + "-" : "") + (select.StateProvinceName ? select.StateProvinceName + "-" : "") +
                                            (select.District ? select.District + "-" : "") + (select.DistrictName ? select.DistrictName + "-" : "") +
                                            (select.City ? select.City + "-" : "") + (select.CityName ? select.CityName + "-" : "") +
                                            (select.Street1 ? select.Street1 + "-" : "") + (select.ApartmentNo ? select.ApartmentNo + "-" : "") +
                                            (select.ZipPostalCode ? select.ZipPostalCode + "-" : "") + (select.Description || "")
                                    };
                                });
                            });
                        }
                    });
                }]
            },
            {
                key: 'PaymentMethodId',
                type: 'floatSelect',
                templateOptions: {
                    required: true,
                    resource: 'form.payment-method',
                    label: 'Ödeme Yöntemi',
                    options: [{
                        Name: '...'
                    }]
                },
                controller: ['$scope', function ($scope) {
                    getPaymentMethods().then(function (response) {
                        $scope.to.options = response;
                    });
                }]
            },
            {
                key: 'ShipmentProviderId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Kargo Firması',
                    resource: 'form.shipment-provider',
                    options: [{
                        Name: '...'
                    }]
                },
                controller: ['$scope', function ($scope) {
                    getShipmentProviders().then(function (response) {
                        $scope.to.options = response;
                    });
                }]
            },
            {
                key: 'SalesPointId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Bayi Adı',
                    resource: 'form.salespoint-name',
                    options: [{
                        Name: '...'
                    }]
                },
                controller: ['$scope', function ($scope) {
                    getSalesPoints().then(function (response) {
                        $scope.to.options = response;
                    });
                }]
            },
            {
                key: 'ShipmentCode',
                type: 'floatInput',
                templateOptions: {
                    label: 'Kargo No',
                    resource: 'form.shipment-code',
                    placeholder: 'Kargo No',
                    readonly: true
                }
            },
            {
                key: 'BankId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Banka',
                    resource: 'form.bank',
                    options: [{
                        Name: 'Yükleniyor...'
                    }]
                },
                controller: ['$scope', function ($scope) {
                    getBanks().then(function (response) {
                        $scope.to.options = response.Data;
                    });
                }]
            },
            {
                key: 'TransferEFTBankId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Havale/EFT Bankası',
                    resource: 'form.transfereftbank',
                    options: [{
                        Name: 'Yükleniyor...'
                    }]
                },
                controller: ['$scope', function ($scope) {
                    getBanks().then(function (response) {
                        $scope.to.options = response.Data;
                    });
                }]
            },
            {
                key: 'PayAtDoorPaymentTypeId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Kapıda Ödeme Tipi',
                    resource: 'form.payatdoor-payment-type',
                    options: [{
                        Name: ''
                    }, {
                        Id: 'f3260048-7ea7-4d5a-8cc2-9b3f1f5dfbd4',
                        Name: 'Kredi Kartı'
                    }, {
                        Id: '1bb93105-f97b-474a-9119-3079145a8385',
                        Name: 'Nakit'
                    }]
                }
            },
            {
                key: 'Description',
                type: 'floatTextarea',
                templateOptions: {
                    type: 'text',
                    label: 'Açıklama',
                    resource: 'form.description',
                    placeholder: 'Açıklama'
                }
            },
            {
                key: 'OrderNote',
                type: 'floatTextarea',
                templateOptions: {
                    type: 'text',
                    label: 'Sipariş Notu',
                    resource: 'form.order-note',
                    placeholder: 'Sipariş Notu'
                }
            },
            {
                key: 'RefundRequestDescription',
                type: 'floatTextarea',
                templateOptions: {
                    type: 'text',
                    label: 'İade Notu',
                    resource: 'form.refund-request-description',
                    placeholder: 'İade Notu'
                }
            }
        ];

        var debtfields = [{
                key: 'Total',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    resource: 'form.price',
                    label: 'Fiyat',
                    placeholder: 'Fiyat ',
                    numberMask: 2
                }
            },
            {
                key: 'Description',
                type: 'floatTextarea',
                templateOptions: {
                    //label: 'Açıklama',
                    resource: 'form.description',
                    placeholder: 'Açıklama'
                }
            },
            {
                key: 'SendByLinkEmail',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Email ile Yolla',
                    resource: 'form.sendtoemail',
                    class: 'col-xs-6'
                }
            },
            {
                key: 'SendByLinkSms',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'SMS ile Yolla',
                    resource: 'form.sendtosms',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'SendByLinkWhatsapp',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Whatsapp ile Yolla',
                    resource: 'form.sendtowhatsapp',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'Email',
                type: 'floatEmail',
                templateOptions: {
                    resource: 'form.email',
                    label: 'Eposta',
                    class: 'col-xs-6'
                }
            },

            {
                key: "PhoneNumber",
                type: "floatPhone",
                templateOptions: {
                    label: 'Telefon',
                    resource: 'form.phone',
                    class: 'col-xs-6'
                }
            }
        ];

        var paymentfields = [{
                key: 'BankId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Banka',
                    resource: 'form.bank',
                    options: [{
                        Name: 'Yükleniyor...'
                    }]
                },
                controller: ['$scope', function ($scope) {
                    getBanks().then(function (response) {
                        $scope.to.options = response.Data;
                    });
                }]
            },
            {
                key: 'Total',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    resource: 'form.price',
                    label: 'Fiyat',
                    placeholder: 'Fiyat ',
                    numberMask: 2
                }
            },
            {
                key: 'ExpectedTotal',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    resource: 'form.expected-total',
                    label: 'Fiyat',
                    placeholder: 'Fiyat ',
                    numberMask: 2
                }
            },
            {
                key: 'PaidTotal',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    resource: 'form.paid-total',
                    label: 'Fiyat',
                    placeholder: 'Fiyat ',
                    numberMask: 2
                }
            },
            {
                key: 'CardholderName',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    resource: 'form.cardholder-name',
                    label: 'Fiyat',
                    placeholder: 'Fiyat '
                }
            },
            {
                key: 'MaskedCreditCard',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    resource: 'form.masked-creditCard',
                    label: 'Fiyat',
                    placeholder: 'Fiyat '
                }
            },
            {
                key: 'Installment',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    resource: 'form.installment',
                    label: 'Fiyat',
                    placeholder: 'Fiyat '
                }
            },
            {
                key: 'InstallmentRate',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    resource: 'form.installment-rate',
                    label: 'Fiyat',
                    placeholder: 'Fiyat '
                }
            },
            {
                key: 'PaymentStatusId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Ödeme Durumu',
                    resource: 'form.payment-status',
                    options: [{
                        Name: 'Yükleniyor...'
                    }]
                },
                controller: ['$scope', function ($scope) {
                    getPaymentStatuses().then(function (response) {
                        $scope.to.options = response;
                    });
                }]
            }
        ];

        return service;

        ///////////////////////////////////

        function getOrderFields() {
            return orderfields;
        }

        function getPaymentFields() {
            return paymentfields;
        }

        function getDebtFields() {
            return debtfields;
        }


        function OrderAccept(OrderId) {
            var deferred = $q.defer();

            $http.post(serviceBase + 'api/SalesOrder/OrderAccept/', OrderId)
                .then(getOrdersComplete)
                .catch(getOrdersFailed);

            function getOrdersComplete(response) {
                deferred.resolve(response.data);
            }

            function getOrdersFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function OrderReject(OrderId) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/SalesOrder/OrderReject/' + OrderId)
                .then(getOrdersComplete)
                .catch(getOrdersFailed);

            function getOrdersComplete(response) {
                deferred.resolve(response.data);
            }

            function getOrdersFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }


        function getOrders(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/SalesOrder/', {
                    params: filterParams
                })
                .then(getOrdersComplete)
                .catch(getOrdersFailed);

            function getOrdersComplete(response) {
                deferred.resolve(response.data);
            }

            function getOrdersFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getPayments(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/Payments/', {
                    params: filterParams
                })
                .then(getOrdersComplete)
                .catch(getOrdersFailed);

            function getOrdersComplete(response) {
                deferred.resolve(response.data);
            }

            function getOrdersFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getMarketOrders() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/MarketPlace/MarketOrders')
                .then(getOrdersComplete)
                .catch(getOrdersFailed);

            function getOrdersComplete(response) {
                deferred.resolve(response.data);
            }

            function getOrdersFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getNewOrders() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/SalesOrder/NewOrders')
                .then(getOrdersComplete)
                .catch(getOrdersFailed);

            function getOrdersComplete(response) {
                deferred.resolve(response.data);
            }

            function getOrdersFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getProcessingOrders() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/SalesOrder/ProcessingOrders')
                .then(getOrdersComplete)
                .catch(getOrdersFailed);

            function getOrdersComplete(response) {
                deferred.resolve(response.data);
            }

            function getOrdersFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCustomerOrders(CustomerId) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/SalesOrder/Customer/' + CustomerId)
                .then(getOrdersComplete)
                .catch(getOrdersFailed);

            function getOrdersComplete(response) {
                deferred.resolve(response.data);
            }

            function getOrdersFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCustomerDebts(CustomerId) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/SalesOrder/Customer/' + CustomerId + '/Debts')
                .then(getOrdersComplete)
                .catch(getOrdersFailed);

            function getOrdersComplete(response) {
                deferred.resolve(response.data);
            }

            function getOrdersFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCustomerDirects(CustomerId) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/SalesOrder/Customer/' + CustomerId + '/Directs')
                .then(getOrdersComplete)
                .catch(getOrdersFailed);

            function getOrdersComplete(response) {
                deferred.resolve(response.data);
            }

            function getOrdersFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getOrdersProduct(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/SalesOrder/GetOrder', {
                    params: filterParams
                })
                .then(getOrdersComplete)
                .catch(getOrdersFailed);

            function getOrdersComplete(response) {
                deferred.resolve(response.data);
            }

            function getOrdersFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getOrder(OrderId) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/SalesOrder/' + OrderId)
                .then(getOrderComplete)
                .catch(getOrderFailed);

            function getOrderComplete(response) {
                deferred.resolve(response.data);
            }

            function getOrderFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getTotalPrice() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/SalesOrder/TotalPrice')
                .then(getOrderComplete)
                .catch(getOrderFailed);

            function getOrderComplete(response) {
                deferred.resolve(response.data);
            }

            function getOrderFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function addOrder(order) {
            var deferred = $q.defer();

            $http.post(serviceBase + 'api/SalesOrder', order)
                .then(addOrderComplete)
                .catch(addOrderFailed);

            function addOrderComplete(response) {
                deferred.resolve(response.data);
            }

            function addOrderFailed(error) {
                deferred.reject(error.data);
            }
            return deferred.promise;
        }

        function editOrder(order) {
            var deferred = $q.defer();

            $http.put(serviceBase + 'api/SalesOrder', order)
                .then(addOrderComplete)
                .catch(addOrderFailed);

            function addOrderComplete(response) {
                deferred.resolve(response.data);
            }

            function addOrderFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getOrderStatuses(order) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/SalesOrderStatus')
                .then(getOrderStatusesComplete)
                .catch(getOrderStatusesFailed);

            function getOrderStatusesComplete(response) {
                deferred.resolve(response.data);
            }

            function getOrderStatusesFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getPaymentStatuses(order) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/Payments/Status')
                .then(getOrderStatusesComplete)
                .catch(getOrderStatusesFailed);

            function getOrderStatusesComplete(response) {
                deferred.resolve(response.data);
            }

            function getOrderStatusesFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getPaymentMethods() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/PaymentMethod')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getShipmentProviders() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/ShipmentProvider')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data.Data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getSalesPoints() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/SalesPoints')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data.Data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getBanks() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/Bank')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function deleteOrder(order) {
            var deferred = $q.defer();

            $http.delete(serviceBase + 'api/SalesOrder/' + order.Id)
                .then(deleteOrderComplete)
                .catch(deleteOrderFailed);

            function deleteOrderComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteOrderFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function changeStatus(orderId, StatusId) {
            var deferred = $q.defer();

            $http.put(serviceBase + 'api/SalesOrder/' + orderId + '/Status', {
                    StatusId: StatusId
                })
                .then(addOrderComplete)
                .catch(addOrderFailed);

            function addOrderComplete(response) {
                deferred.resolve(response.data);
            }

            function addOrderFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function changePaymentStatus(paymentId, PaymentStatusId) {
            var deferred = $q.defer();

            $http.put(serviceBase + 'api/Payments/' + paymentId + '/Status', {
                    Id: paymentId,
                    PaymentStatusId: PaymentStatusId
                })
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function setShipmentCode(orderId, ShipmentCode) {
            var deferred = $q.defer();

            $http.put(serviceBase + 'api/SalesOrder/' + orderId + '/ShipmentCode', {
                    ShipmentCode: ShipmentCode
                })
                .then(addOrderComplete)
                .catch(addOrderFailed);

            function addOrderComplete(response) {
                deferred.resolve(response.data);
            }

            function addOrderFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function setOrderProductShipmentCode(orderProductId, ShipmentCode) {
            var deferred = $q.defer();

            $http.put(serviceBase + 'api/SalesOrder/' + orderProductId + '/OrderProductShipmentCode', {
                    ShipmentCode: ShipmentCode
                })
                .then(addOrderComplete)
                .catch(addOrderFailed);

            function addOrderComplete(response) {
                deferred.resolve(response.data);
            }

            function addOrderFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function CargoProductChange(orderProductId, Shipmentcompany) {
            var deferred = $q.defer();
            $http.post(serviceBase + 'api/SalesOrder/CargoProductChange/' + orderProductId, {
                    Shipmentcompany: Shipmentcompany
                })
                .then(addOrderComplete)
                .catch(addOrderFailed);

            function addOrderComplete(response) {
                deferred.resolve(response.data);
            }

            function addOrderFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function setInvoiceNumber(orderId, InvoiceSerial, InvoiceNumber) {
            var deferred = $q.defer();

            $http.put(serviceBase + 'api/SalesOrder/' + orderId + '/InvoiceNumber', {
                    InvoiceSerial: InvoiceSerial,
                    InvoiceNumber: InvoiceNumber
                })
                .then(addOrderComplete)
                .catch(addOrderFailed);

            function addOrderComplete(response) {
                deferred.resolve(response.data);
            }

            function addOrderFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function accounting(orderId) {
            var deferred = $q.defer();

            $http.put(serviceBase + 'api/SalesOrder/' + orderId + '/accounting')
                .then(addOrderComplete)
                .catch(addOrderFailed);

            function addOrderComplete(response) {
                deferred.resolve(response.data);
            }

            function addOrderFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function NetsimAccounting(salesorderId) {
            var deferred = $q.defer();
            $http.get(serviceBase + 'api/Netsim/Accounting/'+salesorderId )
                .then(addOrderComplete)
                .catch(addOrderFailed);

            function addOrderComplete(response) {
                deferred.resolve(response.data);
            }

            function addOrderFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function sendSms(dept) {
            var deferred = $q.defer();

            $http.post(serviceBase + 'api/SalesOrder/SendBySms', dept)
                .then(sendSmsComplete)
                .catch(sendSmsFailed);

            function sendSmsComplete(response) {
                deferred.resolve(response.data);
            }

            function sendSmsFailed(error) {
                deferred.reject(error.data);
            }
            return deferred.promise;
        }

        function sendWhatsapp(dept) {
            var deferred = $q.defer();

            $http.post(serviceBase + 'api/SalesOrder/SendByWhatsappMessage', dept)
                .then(sendWhatsappComplete)
                .catch(sendWhatsappFailed);

            function sendWhatsappComplete(response) {
                deferred.resolve(response.data);
            }

            function sendWhatsappFailed(error) {
                deferred.reject(error.data);
            }
            return deferred.promise;
        }

        function sendMail(dept) {
            var deferred = $q.defer();

            $http.post(serviceBase + 'api/SalesOrder/SendByMail', dept)
                .then(sendMailComplete)
                .catch(sendMailFailed);

            function sendMailComplete(response) {
                deferred.resolve(response.data);
            }

            function sendMailFailed(error) {
                deferred.reject(error.data);
            }
            return deferred.promise;
        }

        function getDebtsDashboard() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/SalesOrder/DebtsDashboard')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getDirectsDashboard() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/SalesOrder/DirectsDashboard')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function insertWithCart(cartId, salesOrder) {
            var deferred = $q.defer();

            $http.post(serviceBase + 'api/SalesOrder/InsertWithCart/' + cartId, salesOrder)
                .then(complete)
                .catch(failed);

            function complete(response) {
                deferred.resolve(response.data);
            }

            function failed(error) {
                deferred.reject(error.data);
            }
            return deferred.promise;
        }

  
        function CreateCargoNumber(orderId) {
            var deferred = $q.defer();
            $http.get(serviceBase + 'api/Cargo/CargoCreateNumber/'+orderId)
                .then(complete)
                .catch(failed);

            function complete(response) {
                deferred.resolve(response.data);
            }

            function failed(error) {
                deferred.reject(error.data);
            }
            return deferred.promise;
        }

        function CreateCargoNumberSelected(orders) {
            var deferred = $q.defer();
            $http.post(serviceBase + 'api/Cargo/CreateCargoNumberSelected', orders)
                .then(complete)
                .catch(failed);

            function complete(response) {
                deferred.resolve(response.data);
            }

            function failed(error) {
                deferred.reject(error.data);
            }
            return deferred.promise;
        }

        function ShipmentProviderCreateCargoNumber(order,CargoName) {
            var deferred = $q.defer();
            $http.post(serviceBase + 'api/Cargo/ShipmentProviderCreateCargoNumber/' + CargoName, order)
                .then(complete)
                .catch(failed);

            function complete(response) {
                deferred.resolve(response.data);
            }

            function failed(error) {
                deferred.reject(error.data);
            }
            return deferred.promise;
        }

        function CargoStatus(order) {
            var deferred = $q.defer();

            $http.post(serviceBase + 'api/Cargo/CargoStatus', order)
                .then(complete)
                .catch(failed);

            function complete(response) {
                deferred.resolve(response.data);
            }

            function failed(error) {
                deferred.reject(error.data);
            }
            return deferred.promise;
        }

        function CargoStatusAndMail(order) {
            var deferred = $q.defer();

            $http.post(serviceBase + 'api/Cargo/CargoStatusAndMail', order)
                .then(complete)
                .catch(failed);

            function complete(response) {
                deferred.resolve(response.data);
            }

            function failed(error) {
                deferred.reject(error.data);
            }
            return deferred.promise;
        }

       

        function payment(cartId, salesOrder,creditCard,user) {
            var deferred = $q.defer();
            $http.post(serviceBase + 'api/SalesOrder/Payment/' + cartId, { SalesOrder: salesOrder, CreditCard: creditCard, User: user, Bank: salesOrder.Bank })
                .then(complete)
                .catch(failed);

            function complete(response) {
                deferred.resolve(response.data);
            }

            function failed(error) {
                deferred.reject(error.data);
            }
            return deferred.promise;
        }

    }
})();