(function () {
    'use strict';

    angular
        .module('app.hbIntegration')
        .controller('HBMatchCategoryFilterValueListController', HBMatchCategoryFilterValueListController);

    HBMatchCategoryFilterValueListController.$inject = ['$location', '$stateParams', 'HBIntegrationService', 'NgTableParams', '$uibModal'];

    function HBMatchCategoryFilterValueListController($location, $stateParams, HBIntegrationService, NgTableParams, $uibModal) {
        /* jshint validthis:true */
        var vm = this;
        vm.settings = globalSettings;
        activate();
        console.log("asdasdasdasd");
        function activate() {
            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 10 }, $location.search()),
                {
                    getData: function () {
                        return HBIntegrationService.hbgetMatchCategoryFilterValueList($stateParams.Id).then(function (data) {
                            return data;
                        });
                    }
                });
        }
    }
})();