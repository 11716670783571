(function () {
    'use strict';

    angular
        .module('app.carts')
        .controller('CartsListController', ListController);

    ListController.$inject = ['$location', 'CartsService', 'NgTableParams'];

    function ListController($location, Service, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;

        activate();

        function activate() {

            vm.CustomerFilter = {
                IsGuest: { id: 'ng-table/headers/select-is-guest.html', placeholder: 'Üye mi?' },
                CustomerName: { id: 'text', placeholder: 'Üye Ad Soyad' }
            };
            vm.ActiveFilter = {
                Active: { id: 'ng-table/headers/select-is-active.html', placeholder: 'Aktif mi?' }
            };

            vm.CartTypes = Service.getCartTypes().then(function (data) {
                return [{ id: '', title: 'Seçiniz...' }].concat(data.map(function (item) { return { id: item.Id, title: item.Name }; }));
            });

            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({
                    page: 1,
                    count: 10,
                    sorting: {
                        InsertDate: "desc"
                    }
                }, $location.search()), {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {};
                        filterData.PageIndex = params.page();
                        filterData.PageSize = params.count();
                        filterData.SortProperty = Object.keys(params.sorting())[0];
                        filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        //filterData.IsGuest = false;
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return Service.getAll(filterData).then(function (data) {
                            params.total(data.FilteredCount);
                            return data.Data;
                        });
                    }
                });
        }

        vm.refresh = function () {
            vm.loading = true;
            vm.tableParams.reload();
            vm.loading = false;
        };
    }
})();