(function () {
    'use strict';

    angular
        .module('app.getProducts')
        .controller('XmlProductController', XmlProductController);

    XmlProductController.$inject = [
        '$scope',
        '$stateParams',
        '$location',
        "ProductsService",
        'GetProductsService',
        'NgTableParams',
        '$uibModal'];

    function XmlProductController(
        $scope,
        $stateParams,
        $location,
        ProductsService,
        GetProductsService,
        NgTableParams,
        $uibModal) {
       
        var vm = this;
        vm.settings = globalSettings;
        activate();
        function activate() {

            GetProductsService.GetXmlDataUpdate($stateParams.Id).then(function (response) {

                vm.allData = response;
            });

        }
        vm.RangeDatas = [];
        vm.range = function (data) {
            vm.RangeDatas = data.XmlAnalysisProductViewModels.slice(data.Min, (data.Max+1));
        }
        vm.filteredProductList = [];
        vm.refreshFilterProduct = function (search) {
            var params = {
                SearchKey: search,
                PageSize: 18,
                PageIndex: 1,
                SortProperty: 'Name',
                SortType: 'asc'
            };
            vm.filteredProductList = [{
                Id: '',
                Name: 'Yükleniyor...'
            }];
            return ProductsService.getProducts(params)
                .then(function (response) {
                    if (response.Data.length > 0) {
                        vm.filteredProductList = response.Data;
                    } else {
                        vm.filteredProductList = [{
                            Id: '',
                            Name: 'Bulunamadı (' + search + ')'
                        }];
                    }
                });
        }


        vm.SupplyProductAllUpdate = function (data) {
            console.log("data", vm.RangeDatas);

            GetProductsService.RangeUpdate(vm.RangeDatas).then(function (response) {
                console.log(response);
            });
        }

    }

})();