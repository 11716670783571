(function () {
    'use strict';

    angular.module('app.marketing',
        [
            'treeControl',
            'ui.bootstrap',
            'ui.grid',
            'ui.grid.pagination',
            'ui.mask', 'formly',
            'formlyBootstrap',
            'ngMap',
            'ui.select',
            'ui.tinymce',
            'ui.tree'
        ]);
})();