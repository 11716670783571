(function () {
    'use strict';

    angular
        .module('app.deliveries')
        .controller('DeliveriesListController', DeliveriesListController);

    DeliveriesListController.$inject = ['$scope', '$location', '$timeout', '$stateParams', 'DeliveriesService', 'NgTableParams']

    function DeliveriesListController($scope, $location, $timeout, $stateParams, DeliveriesService, NgTableParams) {
        var vm = this;

        vm.title = 'Teslimat Listesi';

        activate();

        function activate() {
            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                { page: 1, count: 18, group: "OrderCode" },
                {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {}; // params.filter();
                        filterData.PageIndex = params.page(),
                            filterData.PageSize = params.count(),
                            filterData.SortProperty = Object.keys(params.sorting())[0],
                            filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]]
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return DeliveriesService.getDeliveries(filterData).then(function (data) {
                            params.total(data.FilteredCount);
                            //$defer.resolve(data.Data);
                            return data.Data;
                        });
                    },
                });
            //vm.changeFilter = changeFilter;
            //vm.applyGlobalSearch = applyGlobalSearch;

            //function applyGlobalSearch() {
            //    var term = vm.globalSearchTerm;
            //    if (vm.isInvertedSearch) {
            //        term = "!" + term;
            //    }
            //    vm.tableParams.filter({ $: term });
            //}

            //function changeFilter(field, value) {
            //    var filter = {};
            //    filter[field] = value;
            //    angular.extend(vm.tableParams.filter(), filter);
            //}
        }
        vm.DateFilter = {
            DeliveryDateMin: { id: 'ng-table/date.html', placeholder: 'Minimum Tarih' },
            DeliveryDateMax: { id: 'ng-table/date.html', placeholder: 'Maximum Tarih' }
        }
    }
})();