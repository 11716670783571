(function () {
    'use strict';

    angular
        .module('app.carts')
        .controller('CartsDetailController', DetailController);

    DetailController.$inject = ['$location', '$stateParams', 'NgTableParams', 'CartsService'];

    function DetailController($location, $stateParams, NgTableParams, Service) {
        /* jshint validthis:true */
        var vm = this;

        Service.get($stateParams.CartsId)
            .then(function (data) {
                vm.entity = data;
            });

        vm.fields = Service.getFields();

        vm.delete = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                Service.del(vm.entity)
                    .then(function (data) {
                        $location.path('/carts/list');
                    });
            }
        };

    }
})();