
(function () {
    'use strict';

    angular
      .module('app.errors')
      .factory('ErrorsService', ErrorsService);

    ErrorsService.$inject = ['config'];

    function ErrorsService(config) {
        var service = {
            geterrors: geterrors,
            geterror: geterror
        };

        var errors = [
            { id: 1, Adi: "Kumaş Yırtığı", Kodu: "26658893288622", Tipi: "Yırtık" },
            { id: 2, Adi: "Kumaş Yırtığı", Kodu: "26658893288622", Tipi: "Yırtık" },
            { id: 3, Adi: "Kumaş Yırtığı", Kodu: "26658893288622", Tipi: "Yırtık" },
            { id: 4, Adi: "Kumaş Yırtığı", Kodu: "26658893288622", Tipi: "Yırtık" },
            { id: 5, Adi: "Kumaş Yırtığı", Kodu: "26658893288622", Tipi: "Yırtık" },
            { id: 6, Adi: "Kumaş Yırtığı", Kodu: "26658893288622", Tipi: "Yırtık" },
            { id: 7, Adi: "Kumaş Yırtığı", Kodu: "26658893288622", Tipi: "Yırtık" },
            { id: 8, Adi: "Kumaş Yırtığı", Kodu: "26658893288622", Tipi: "Yırtık" },
            { id: 9, Adi: "Kumaş Yırtığı", Kodu: "26658893288622", Tipi: "Yırtık" },
            { id: 10, Adi: "Kumaş Yırtığı", Kodu: "26658893288622", Tipi: "Yırtık" },
            { id: 11, Adi: "Kumaş Yırtığı", Kodu: "26658893288622", Tipi: "Yırtık" },
            { id: 12, Adi: "Kumaş Yırtığı", Kodu: "26658893288622", Tipi: "Yırtık" },
            { id: 13, Adi: "Kumaş Yırtığı", Kodu: "26658893288622", Tipi: "Yırtık" },
            { id: 14, Adi: "Kumaş Yırtığı", Kodu: "26658893288622", Tipi: "Yırtık" }

        ];

        function geterrors() {
            return errors;
        }

        function geterror(id) {
            return errors.find(function (c) { return c.id >= id; });
        }

        return service;
    }
})();
