(function () {
    'use strict';

    angular
        .module('app.account')
        .controller('AccountDeleteController', AccountDeleteController);

    AccountDeleteController.$inject = ['$scope', '$stateParams', '$location', '$timeout', 'AccountService'];

    function AccountDeleteController($scope, $stateParams, $location, $timeout, AccountService) {
        var vm = this;

        vm.loading = true;
        vm.title = '';

        AccountService.getUser($stateParams.id).then(function (data) {
            vm.deleteuser = data;
            vm.loading = false;
        });

        vm.userFields = AccountService.getUserFields();

        vm.userDelete = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                AccountService.removeUser(vm.deleteuser).then(function () {
                    vm.loading = false;
                    $location.path('/account/list');
                });
            }
        };

        vm.Cancel = function () {
            $location.path('/account/' + vm.deleteuser.Id);
        };
    }
})();