(function() {
    "use strict";

    angular.module("app.customers").controller("CustomerOrderDetailController", CustomerOrderDetailController);

    CustomerOrderDetailController.$inject = ["$stateParams", "OrdersService", "Settings"];

    function CustomerOrderDetailController($stateParams, OrdersService, Settings) {
        var vm = this;

        vm.order = {};
        vm.settings = Settings;
        activate();

        function activate() {
            OrdersService.getOrder($stateParams.orderid).then(function(data) {
                vm.order = data;
            });
        }

        vm.copyLink = function() {
            var copyText = document.getElementById("link-input");
            copyText.disabled = false;
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            document.execCommand("copy");
            copyText.disabled = true;
            alert("Link kopyalandı: " + copyText.value);
        };
    }
})();
