(function () {
    'use strict';

    angular
        .module('app.sellers')
        .controller('SellersEditController', SellersEditController);

    SellersEditController.$inject = ['$scope', '$stateParams', '$location', 'logger', 'SellersService'];

    function SellersEditController($scope, $stateParams, $location, logger, SellersService) {
        var vm = this;

        vm.loading = true;

        SellersService.get($stateParams.Id).then(function (data) {
            vm.entity = data;
            vm.loading = false;
        });

        vm.fields = SellersService.getFields();

        vm.edit = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                SellersService.edit(vm.entity).then(function (data) {
                    vm.loading = false;
                    $location.path('/sellers/detail/' + vm.entity.Id);
                });
            }
        };

        vm.cancel = function () {
            $location.path('/sellers/detail/' + vm.entity.Id);
        };
    }
})();