(function () {
    'use strict';

    angular
        .module('app.vendors')
        .controller('VendorsEditController', EditController);

    EditController.$inject = ['$location', '$stateParams', 'VendorsService', 'Upload'];

    function EditController($location, $stateParams, Service, Upload) {
        /* jshint validthis:true */
        var vm = this;

        vm.loading = true;
        vm.title = '';

        Service.get($stateParams.VendorId).then(function (data) {
            vm.entity = data;
            vm.loading = false;
        });

        vm.fields = Service.getFields();

        vm.edit = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                vm.entity.VendorTypeId = '3909332A-2A59-4FDB-BC69-69AD5D0ABCDC';
                Service.edit(vm.entity).then(function () {
                    vm.loading = false;
                    $location.path('/vendors/detail/' + vm.entity.Id);
                });
            }
        };
    }
})();