(function () {
    'use strict';

    angular
        .module('app.relatedProductType')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'relatedProductType',
                config: {
                    url: '/relatedProductType',
                    title: 'Markalar',
                    abstract: true,
                    templateUrl: 'app/relatedProductTypes/relatedProductType.html'
                }
            },
            {
                state: 'relatedProductType.list',
                config: {
                    templateUrl: 'app/relatedProductTypes/relatedProductType.list.html',
                    controller: 'RelatedProductTypeListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Marka Listesi',
                    settings: {
                        nav: 4,
                        name: 'relatedProductType.list',
                        topName: 'products',
                        content: 'Marka Listesi',
                        resource: 'menu.relatedProductType.list'
                    }
                }
            }
        ];
    }
})();