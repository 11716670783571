(function () {
    'use strict';

    angular
        .module('app.n11Integration')
        .controller('EditMatchCategoryFilterController', EditMatchCategoryFilterController);

    EditMatchCategoryFilterController.$inject = ['$scope', '$stateParams','$location', 'N11IntegrationService', 'NgTableParams'];

    function EditMatchCategoryFilterController($scope, $stateParams,$location, N11IntegrationService, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;

        vm.settings = globalSettings;
        activate();

        function activate() {
            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 10 }, $location.search()),
                {
                    getData: function () {
                        return N11IntegrationService.getMatchCategory($stateParams.Id)
                            .then(function (data) {
                                return N11IntegrationService.getCategoryFilter(data.IntegrationCategoryId)
                                    .then(function (result) {
                                        return result;
                                    });
                            });
                    }
                });

            N11IntegrationService.getOption().then(function (data) {
               
                vm.entity = getTreeData(data, null);

                vm.entity.refresh = true;
                vm.loading = false;
            });

            //N11IntegrationService.getMatchCategoryFilterListByCategory($stateParams.Id).then(function (data) {

            //});

            vm.MatchCategoryId = $stateParams.Id;
        }

        function getTreeData(options, topProp) {
            var data = options.filter(obj => obj["IconId"] === topProp);
            if (data.length <= 0) {
                return [];
            }
            return data
                .map(obj => {
                    var rObj = {};
                    rObj.id = obj["Id"];
                    rObj.name = obj["Name"];
                    rObj.children = getTreeData(options, obj.Id) || [];
                    return rObj;
                });
        }

        vm.selectFilter = function (filter) {
            vm.loading = true;
            vm.catAttr = {};
            vm.catAttr.OptionId = vm.item[0].id;
            vm.catAttr.MatchCategoryId = $stateParams.Id;
            vm.catAttr.IntegrationCategoryAttrId = filter.Id;
            vm.catAttr.IntegrationCategoryAttrName = filter.Name;
            N11IntegrationService.editMatchCategoryFilter(vm.catAttr).then(function (data) {
                vm.loading = false;
                $location.path('/n11Integration/editmatchcategoryfiltervalue/' + data.Id);
            });
        };
    }
})();