(function () {
    'use strict';

    angular
        .module('app.hbIntegration')
        .factory('HBIntegrationService', HBIntegrationService);

    HBIntegrationService.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config', 'ProductsService', 'ProductsGeneralService'];

    function HBIntegrationService($http, $q, ngAuthSettings, logger, config, ProductsService, ProductsGeneralService) {
        var service = {

            //Product

            getProductFields: getProductFields,
            getProductAll: getProductAll,
            TransferProduct: TransferProduct,
            TransferAllProduct: TransferAllProduct,
            hbgetAllMatchCategory: hbgetAllMatchCategory,
            hbgetMatchCategoryFields: hbgetMatchCategoryFields,
            hbaddMatchCategory: hbaddMatchCategory,
            hbgetMatchCategory: hbgetMatchCategory,
            getOption: getOption,
            getCategoryFilter: getCategoryFilter,
            hbeditMatchCategoryFilter: hbeditMatchCategoryFilter,
            hbeditMatchCategoryFilterValue: hbeditMatchCategoryFilterValue,
            hbgetMatchCategoryFilter: hbgetMatchCategoryFilter,
            getCategoryFilterValue: getCategoryFilterValue,
            getOptionValue: getOptionValue,
            hbgetMatchCategoryFilterListByCategory: hbgetMatchCategoryFilterListByCategory,
            hbgetMatchCategoryFilterValueList: hbgetMatchCategoryFilterValueList,
            hbtransferCategory: hbtransferCategory,
            hbtransferAllCategory: hbtransferAllCategory,
            hbgetMatchCategoryFilterValueList: hbgetMatchCategoryFilterValueList,
            hbeditMatchCategoryDelete: hbeditMatchCategoryDelete,
            hbeditMatchCategory: hbeditMatchCategory
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/hbIntegration/';

        var templateFields = [
            {
                key: 'Name',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Adı',
                    resource: 'form.name',
                    placeholder: 'Adı'
                }
            },
            {
                key: 'SalesPrice',
                type: 'floatSelect',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Satış Fiyatı',
                    resource: 'form.sales-price',
                    placeholder: 'Satış Fiyatı',
                    class: 'col-md-2',
                    options: [
                        {
                            Name: 'Ürün Fiyatı',
                            Id: 'Ürün Fiyatı'
                        },
                        {
                            Name: 'N11 Fiyatı',
                            Id: 'N11 Fiyatı'
                        }
                    ]
                }
            },
            {
                key: 'SalesPriceCalcType',
                type: 'floatSelect',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Satış Fiyatı Hesap Biçimi',
                    class: 'col-md-2',
                    options: [
                        {
                            Name: 'X',
                            Id: 'X',
                        },
                        {
                            Name: '/',
                            Id: '/',
                        },
                        {
                            Name: '+',
                            Id: '+',
                        }

                    ]
                }
            },
            {
                key: 'SalesPriceQuantity',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Satış Fiyat Ayarlama Miktarı',
                    placeholder: 'Satış Fiyat Ayarlama Miktarı',
                    class: 'col-md-2',
                    numberMask: 2
                }
            },
            {
                key: 'DiscountRate',
                type: 'floatInput',
                templateOptions: {
                    type: 'text',
                    label: '% İndirim Oranı',
                    resource: 'form.discount-rate',
                    placeholder: '% İndirim Oranı'
                }
            },
            {
                key: 'SalesStartDate',
                type: 'floatDatepicker',
                templateOptions: {
                    type: 'text',
                    label: 'Satışa Başlama Tarihi',
                    resource: 'form.sales-start-date',
                    placeholder: 'Satışa Başlama Tarihi'
                }
            },
            {
                key: 'SalesEndDate',
                type: 'floatDatepicker',
                templateOptions: {
                    type: 'text',
                    label: 'Satışın Bitiş Tarihi',
                    resource: 'form.sales-end-date',
                    placeholder: 'Satışın Bitiş Tarihi'
                }
            },
            {
                key: 'SubTitle',
                type: 'floatInput',
                templateOptions: {
                    type: 'text',
                    label: 'Alt Başlık',
                    resource: 'form.subtitle',
                    placeholder: 'Alt Başlık'
                }
            },
            {
                key: 'CargoTime',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'number',
                    label: 'Kargo Süresi',
                    resource: 'form.cargo-time',
                    placeholder: 'Kargo Süresi'
                }
            },
            {
                key: 'IntegrationShipmentTemplateName',
                type: 'floatSelect',
                templateOptions: {
                    type: 'text',
                    label: 'Teslimat Şablonu',
                    resource: 'form.deliveries-template',
                    placeholder: 'Teslimat Şablonu'
                },
                controller: ['$scope', function ($scope) {
                    getShipmentTemplate()
                        .then(function (response) {
                            var templates = response.map(x => {
                                var obj = {};
                                obj["Id"] = x.Name;
                                obj["Name"] = x.Name;
                                return obj;
                            });
                            $scope.to.options = templates;
                        });
                }]
            },
        ];

        var ProductsFields = [
            {
                key: 'IntegrationTemplateId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Şablonlar',
                    resource: 'form.templates',
                    required: true,
                    placeholder: 'Şablon Seçiniz ',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    ProductsGeneralService.getIntegrationTemplates()
                        .then(function (response) {
                            $scope.to.options = response;
                        });
                }]
            },
            {
                key: 'HepsiburadaSKU',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Hepsiburada Stok Kodu',
                    resource: 'form.hepsiburadaSku',
                    placeholder: 'Hepsiburada Stok Kodu'
                },
                controller: ['$scope', function ($scope) {
                    ProductsGeneralService.getCategories()
                        .then(function (response) {
                            $scope.to.options = response;
                        });
                }]
            },
        ];
        var transferFields = [];

        var matchcategoryFields = [
            {
                key: 'IntegrationTemplateId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Şablonlar',
                    resource: 'form.templates',
                    required: true,
                    placeholder: 'Şablon Seçiniz ',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    ProductsGeneralService.getIntegrationTemplates()
                        .then(function (response) {
                            $scope.to.options = response;
                        });
                }]
            },
            {
                key: 'EComCategoryId',
                type: 'floatTreeSelect',
                templateOptions: {
                    label: 'E-Ticaret Kategorileri',
                    resource: 'form.ecommerce-categories',
                    required: true,
                    placeholder: 'Kategori Seçiniz ',
                    topProp: 'TopCategoryId',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    ProductsGeneralService.getCategories()
                        .then(function (response) {
                            $scope.to.options = response;
                        });
                }]
            },
            {
                key: 'HBCategories',
                type: 'floatTreeSelect',
                templateOptions: {
                    label: 'Hepsiburada Kategorileri',
                    resource: 'form.Hb-topcategories',
                    required: true,
                    placeholder: 'Hepsiburada Kategorisi Seçiniz ',
                    class: 'col-md-6',
                    valueProp: 'categoryId',
                    labelProp: 'name',
                    options: [{ name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    hbgetTopCategories()
                        .then(function (data) {

                            $scope.to.options = data;
                        });
                }]
            }
        ];


        return service;

        /////////////////////////////////////////

        //Match Category
        function hbgetMatchCategoryFields() {
            return matchcategoryFields;
        }

        // Get product

        function getProductFields() {
            return ProductsFields;
        }



        function getProductAll() {
            return product;
        }

        //Transfer Product


        function transferFields() {
            return transferFields;
        }



        function TransferProduct(filterParams) {
            var deferred = $q.defer();
            $http.get(serviceBase + "TransferProduct", {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function TransferAllProduct(filterParams) {
            var deferred = $q.defer();
            $http.get(serviceBase + "TransferAllProduct", {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }


        function hbgetAllMatchCategory(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBase + "matchcategory", {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        //Transfer Category

        function hbgetTopCategories() {
            var deferred = $q.defer();
            $http.get(serviceBase + "HBTopCategories")
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }


        function hbaddMatchCategory(matchcategory) {
            var deferred = $q.defer();

            $http.post(serviceBase + "MatchCategory/add", matchcategory)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }


        function hbgetMatchCategory(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "matchcategory/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function hbeditMatchCategory(matchcategory) {
            var deferred = $q.defer();
            console.log("match category", matchcategory);
            $http.put(serviceBase + "MatchCategory/" + matchcategory.Id, matchcategory)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }


        function hbeditMatchCategoryFilter(matchcategory) {
            var deferred = $q.defer();

            $http.put(serviceBase + "matchcategoryfilter/" + matchcategory.Id, matchcategory)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }


        function hbeditMatchCategoryDelete(matchcategory) {
            var deferred = $q.defer();

            $http.get(serviceBase + "MatchCategoryDelete/" + matchcategory.Id)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function hbtransferCategory(matchcategory) {
            var deferred = $q.defer();

            $http.post(serviceBase + "TransferCategory/", matchcategory)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function hbtransferAllCategory() {
            var deferred = $q.defer();

            $http.post(serviceBase + "TransferAllCategory/")
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCategoryFilterValue(CatCode, FilterName) {
            var deferred = $q.defer();
            $http.get(serviceBase + "CategoryFilterValue/" + CatCode + "/" + FilterName)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
        function hbeditMatchCategoryFilterValue(matchcategory) {
            var deferred = $q.defer();

            $http.put(serviceBase + "matchcategoryfiltervalue/" + matchcategory.Id, matchcategory)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function hbgetMatchCategoryFilterListByCategory(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "matchcategoryfilterlistbycategory/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function hbgetMatchCategoryFilterValueList(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "matchcategoryfiltervaluelist/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function hbgetMatchCategoryFilter(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "hbmatchcategoryfilter/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        //Option

        function getOption() {
            var deferred = $q.defer();

            $http.get(serviceBase + "option")
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getOptionValue(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "optionvalue/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCategoryFilter(CatId) {
            var deferred = $q.defer();
            $http.get(serviceBase + "CategoryFilter/" + CatId)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
        function hbgetMatchCategoryFilterValueList(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "matchcategoryfiltervaluelist/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

    }
})();