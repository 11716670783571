(function () {
    'use strict';

    angular
        .module('app.settings')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'settings',
                config: {
                    url: '/settings',
                    title: 'Ayarlar',
                    abstract: true,
                    templateUrl: 'app/settings/setting.html'
                }
            },
            {
                state: 'settings.list',
                config: {
                    templateUrl: 'app/settings/setting.list.html',
                    controller: 'SettingsController',
                    controllerAs: 'vm',
                    url: '/settings',
                    title: 'Ayarlar',
                    settings: {
                        nav: 4,
                        name: 'settings.list',
                        topName: 'system',
                        content: 'Ayarlar',
                        resource: 'menu.settings'
                    }
                }
            },
            {
                state: 'settings.einvoice',
                config: {
                    templateUrl: 'app/settings/setting.eInvoice.html',
                    controller: 'SettingsEinvoiceController',
                    controllerAs: 'vm',
                    url: '/einvoice',
                    title: 'Fatura Ayarları',
                    settings: {
                        nav: 4,
                        name: 'settings.einvoice',
                        topName: 'system',
                        content: 'Fatura Ayarları',
                        resource: 'menu.settings.einvoice'
                    }
                }
            },
            {
                state: 'settings.einvoiceApply',
                config: {
                    templateUrl: 'app/settings/setting.eInvoiceApply.html',
                    controller: 'SettingsEinvoiceApplyController',
                    controllerAs: 'vm',
                    url: '/einvoiceApply',
                    title: 'eFatura Başvuru İşlemleri',
                    settings: {
                        nav: 4,
                        name: 'settings.einvoiceApply',
                        topName: 'system',
                        content: 'eFatura Başvuru İşlemleri',
                        resource: 'menu.settings.einvoiceApply'
                    }
                }
            }

        ];
    }
})();