(function () {
    'use strict';

    angular
        .module('app.blogPost')
        .factory('BlogPostService', BlogPostService);

    BlogPostService.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config', 'Settings', 'ProductsService','BlogPostCategoryService', 'MediaService'];

    function BlogPostService($http, $q, ngAuthSettings, logger, config, Settings, ProductsService, BlogPostCategoryService, MediaService) {
        var service = {
            getPosts: getPosts,
            getPost: getPost,
            addPost: addPost,
            editPost: editPost,
            deletePost: deletePost,
            getPostFields: getPostFields,
            getSeoFields: getSeoFields,
            getAllPostMedia: getAllPostMedia,
            addPostMedia: addPostMedia,
            removePostMedia: removePostMedia
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri;

        var postFields = [
            {
                wrapper: 'accordion',
                templateOptions: {
                    label: 'Genel Özellikler',
                    open: true,
                    resource: 'form.general-options'
                },
                fieldGroup: [
                    {
                        key: 'Name',
                        type: 'horizontalInput',
                        templateOptions: {
                            required: true,
                            type: 'text',
                            label: 'Adı',
                            placeholder: 'Adı',
                            resource: 'form.name'
                        }
                    },
                    {
                        key: 'PostCategoryId',
                        type: 'horizontalSelect',
                        templateOptions: {
                            label: 'Kategori',
                            resource: 'form.category',
                            required: true,
                            placeholder: 'Kategori Seçiniz ',
                            options: [{ Name: '...' }]
                        },
                        controller: ['$scope', function ($scope) {
                            BlogPostCategoryService.getCategories({ PageSize: -1 })
                                .then(function (response) {
                                    console.log("response PostCategoryId", response);
                                    $scope.to.options = response.Data;
                                });
                        }]
                    },
                    {
                        key: 'ProductId',
                        type: 'horizontalSelect',
                        templateOptions: {
                            label: 'Bu Postun Bağlı Olduğu Ürün',
                            resource: 'form.product',
                            placeholder: 'Ürün Seçiniz ',
                            options: [{ Name: '...' }]
                        },
                        controller: ['$scope', function ($scope) {
                            ProductsService.getProducts({ PageSize: -1 }).then(function (response) {
                                console.log("response ProductId", response);
                                $scope.to.options = response.Data;
                            });
                        }]
                    },
                    {
                        key: 'ShortDescription',
                        type: 'horizontalTinyMCE',
                        templateOptions: {
                            label: 'Kısa İçerik',
                            placeholder: 'Kısa İçerik',
                            resource: 'form.short-description'
                        }
                    },
                    {
                        key: 'Description',
                        type: 'horizontalTinyMCE',
                        templateOptions: {
                            label: 'İçerik',
                            placeholder: 'İçerik',
                            resource: 'form.description'
                        }
                    },
                    {
                        key: 'DisplayOrder',
                        type: 'horizontalInput',
                        templateOptions: {
                            type: 'number',
                            resource: 'form.display-order',
                            label: 'Sıra',
                            placeholder: 'Sıra'
                        }
                    },
                    {
                        key: 'isShowCase',
                        type: 'horizontalCheckbox',
                        defaultValue: true,
                        templateOptions: {
                            label: 'Vitrinde Görünsün Mü?',
                            resource: 'form.isShowCase',
                            placeholder: 'Vitrinde Görünsün Mü?'
                        }
                    },
                    {
                        key: 'Active',
                        type: 'horizontalCheckbox',
                        defaultValue: true,
                        templateOptions: {
                            label: 'Yayında',
                            resource: 'form.active',
                            placeholder: 'Yayında'
                        }
                    }
                ]
            }
        ];

        var seoFields = [
            {
                key: 'MetaKeyword',
                type: 'horizontalInput',
                templateOptions: {
                    type: 'text',
                    resource: 'form.meta-keyword',
                    label: 'Seo keywords'
                }
            },
            {
                key: 'MetaDescription',
                type: 'horizontalTextarea',
                templateOptions: {
                    label: 'Seo description',
                    resource: 'form.meta-description',
                    maxlength: 160
                }
            },
            {
                key: 'MetaTitle',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Seo title',
                    resource: 'form.meta-title',
                    type: 'text'
                }
            },
            {
                key: 'NormalizedName',
                type: 'horizontalInput',
                templateOptions: {
                    label: 'Arama Motoru Sayfa Adı',
                    resource: 'form.normalized-name',
                    type: 'text'
                }
            }
        ];

        return service;

        /////////////////////////////////////////

        function getPostFields() {
            return postFields;
        }

        function getSeoFields() {
            return seoFields;
        }

        function getPosts(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/Post/', {
                params: filterParams
            })
                .then(getPostsComplete)
                .catch(getPostsFailed);

            function getPostsComplete(response) {
                deferred.resolve(response.data);
            }

            function getPostsFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getPost(Id) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/Post/' + Id)
                .then(getPostComplete)
                .catch(getPostFailed);

            function getPostComplete(response) {
                deferred.resolve(response.data);
            }

            function getPostFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function addPost(post) {
            var deferred = $q.defer();

            $http.post(serviceBase + 'api/Post', post)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function editPost(entity) {
            var deferred = $q.defer();

            $http.put(serviceBase + 'api/Post/' + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function deletePost(entity) {
            var deferred = $q.defer();

            $http.delete(serviceBase + 'api/Post/' + entity.Id, entity)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

       
        function getAllPostMedia(Id) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/Post/' + Id + "/Media")
                .then(getPostComplete)
                .catch(getPostFailed);

            function getPostComplete(response) {
                deferred.resolve(response.data);
            }

            function getPostFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function addPostMedia(postId, MediaId) {
            var deferred = $q.defer();

            $http.post(serviceBase + 'api/Post/' + postId + "/" + MediaId)
                .then(complete)
                .catch(failed);

            function complete(response) {
                deferred.resolve(response.data);
            }

            function failed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function removePostMedia(postId, MediaId) {
            var deferred = $q.defer();

            $http.delete(serviceBase + 'api/Post/' + postId + "/Media/" + MediaId)
                .then(removePostMediaComplete)
                .catch(removePostMediaFailed);

            function removePostMediaComplete(response) {
                deferred.resolve(response.data);
            }

            function removePostMediaFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
     
    }
}) ();
