(function () {
    'use strict';

    angular
        .module('app.production')
        .factory('StockQuantityHistoryService', Service);

    Service.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config', 'Settings', 'RolesService'];

    function Service($http, $q, ngAuthSettings, logger, config, Settings, RolesService) {
        var service = {
            getAll: getAll,
            add: add,
            getFields: getFields,
            getProductStockFields: getProductStockFields
        };
        var baseUrl = ngAuthSettings.apiServiceBaseUri;

        var serviceBaseUrl = baseUrl + 'api/StockQuantityHistories/';

        var productStockFields = [
            {
                key: 'TrackStockQuantity',
                type: 'floatCheckbox',
                defaultValue: true,
                templateOptions: {
                    label: 'Stok Takip Et',
                    resource: 'form.track-stock-quantity',
                    placeholder: 'Stok Takip Et'
                }
            },
            {
                key: 'ClosedForSaleIfOutOfStock',
                type: 'floatCheckbox',
                defaultValue: true,
                templateOptions: {
                    label: 'Tükenince Satışa Kapat',
                    resource: 'form.closed-for-sale-if-out-of-stock',
                    placeholder: 'Tükenince Satışa Kapat'
                },
                hideExpression: '!model.TrackStockQuantity'
            },
            {
                key: "NotifyForStockQuantityBelow",
                type: "floatInput",
                templateOptions: {
                    label: "Düşünce Haber ver",
                    resource: "form.notify-for-stock-quantity-below",
                    placeholder: "Düşünce Haber ver",
                    numberMask: 2,
                    class: "col-xs-12"
                },
                hideExpression: '!model.TrackStockQuantity'
            },
            {
                key: "ProductStockTrackingTypeId",
                type: "floatSelect",
                templateOptions: {
                    label: "Stok Takip Şekli",
                    resource: "form.stock-tracking-type",
                    placeholder: "Stok Takip Şekli",
                    class: "col-xs-12",
                    options: [
                        { Id: "981ebfa4-4c44-48c3-929a-2b11058d99fa", Name: "" },
                        { Id: "766b9c7a-4ecb-470c-b867-7be65b6070ea", Name: "Seri Numarası" },
                        { Id: "3a0b57e6-2c84-42ee-b92c-aa308970054c", Name: "Lot Numarası" }
                    ]
                },
                hideExpression: '!model.TrackStockQuantity'
            },
        ];

        var fields = [
            {
                key: 'AddedStockQuantity',
                type: 'horizontalInput',
                templateOptions: {
                    required: true,
                    label: 'Eklenen Stok Miktar',
                    resource: 'form.addedStockQuantity',
                    placeholder: 'Eklenen Stok Miktar ',
                    numberMask: 2,
                    uiNegativeNumber: 'true'
                }
            },
            {
                key: 'Description',
                type: 'horizontalTextarea',
                templateOptions: {
                    label: 'Açıklama',
                    placeholder: 'Açıklama',
                    resource: 'form.description'
                }
            }
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getProductStockFields() {
            return productStockFields;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();
            $http.get(serviceBaseUrl, {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();
            $http.post(serviceBaseUrl, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();