(function () {
    'use strict';

    angular
        .module('app.consulting')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'consulting',
                config: {
                    url: '/consulting',
                    title: 'Danışmanlık',
                    abstract: true,
                    templateUrl: 'app/consulting/consulting.html'
                }
            },
            {
                state: 'consulting.expertise',
                config: {
                    url: '/expertise',
                    title: 'Danışmanlık',
                    abstract: true,
                    templateUrl: 'app/consulting/consulting.html'
                }
            },
            {
                state: 'consulting.expertise.list',
                config: {
                    templateUrl: 'app/consulting/expertise/expertise.list.html',
                    controller: 'ExpertiseListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Uzmanlık Listesi',
                    settings: {
                        nav: 4,
                        name: 'expertise.list',
                        topName: 'consulting',
                        content: 'Uzmanlık Listesi',
                        resource: 'menu.expertise.list'
                    }
                }
            },
            {
                state: 'consulting.consultant',
                config: {
                    url: '/consultants',
                    title: 'Danışman',
                    abstract: true,
                    templateUrl: 'app/consulting/consulting.html'
                }
            },
            {
                state: 'consulting.consultant.list',
                config: {
                    templateUrl: 'app/consulting/consultants/consultants.list.html',
                    controller: 'ConsultantsListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Danışman Listesi',
                    settings: {
                        nav: 4,
                        name: 'consultant.list',
                        topName: 'consulting',
                        content: 'Danışman Listesi',
                        resource: 'menu.consultant.list'
                    }
                }
            },
            {
                state: 'consulting.consultant.add',
                config: {
                    templateUrl: 'app/consulting/consultants/consultants.add.html',
                    controller: 'ConsultantsAddController',
                    controllerAs: 'vm',
                    url: '/add',
                    title: 'Danışman Ekle',
                    settings: {
                        nav: 4,
                        name: 'consultant.add',
                        topName: 'consulting.consultant.list',
                        content: 'Danışman Ekle',
                        resource: 'menu.consultant.add'
                    }
                }
            },
            {
                state: 'consulting.consultant.edit',
                config: {
                    templateUrl: 'app/consulting/consultants/consultants.edit.html',
                    controller: 'ConsultantsEditController',
                    controllerAs: 'vm',
                    url: '/edit/:Id',
                    title: 'Danışman Düzenle',
                    settings: {
                        nav: 4,
                        name: 'consultant.edit',
                        topName: 'consulting.consultant.list',
                        content: 'Danışman Düzenle',
                        resource: 'menu.consultant.edit'
                    }
                }
            },
            {
                state: 'consulting.appointment',
                config: {
                    url: '/appointments',
                    title: 'Danışman',
                    abstract: true,
                    templateUrl: 'app/consulting/consulting.html'
                }
            },
            {
                state: 'consulting.appointment.list',
                config: {
                    templateUrl: 'app/consulting/appointments/appointments.list.html',
                    controller: 'AppointmentsListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Danışman Listesi',
                    settings: {
                        nav: 4,
                        name: 'appointment.list',
                        topName: 'consulting',
                        content: 'Danışman Listesi',
                        resource: 'menu.appointment.list'
                    }
                }
            },
            {
                state: 'consulting.appointment.edit',
                config: {
                    templateUrl: 'app/consulting/appointments/appointments.edit.html',
                    controller: 'AppointmentsEditController',
                    controllerAs: 'vm',
                    url: '/edit/:Id',
                    title: 'Randevu Düzenle',
                    settings: {
                        nav: 4,
                        name: 'appointment.edit',
                        topName: 'consulting.appointment.list',
                        content: 'Randevu Düzenle',
                        resource: 'menu.appointment.edit'
                    }
                }
            }
        ];
    }
})();