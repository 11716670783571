(function () {
    'use strict';

    angular
        .module('app.vendors')
        .controller('VendorsDetailController', DetailController);

    DetailController.$inject = ['$location', '$stateParams', '$http', 'VendorsService', 'FileUploader', 'localStorageService', 'Settings'];

    function DetailController($location, $stateParams, $http, Service, FileUploader, localStorageService, Settings) {
        /* jshint validthis:true */
        var vm = this;

        vm.Settings = Settings;
        
        Service.get($stateParams.VendorId).then(function (data) {
            vm.entity = data;
        });
        
        vm.fields = Service.getFields();

        vm.delete = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                Service.del(vm.entity).then(function (data) {
                    $location.path('/vendors/list');
                });
            }
        };
    }
})();
