(function () {
    'use strict';

    angular
        .module('app.errors')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'errors',
                config: {
                    url: '/errors',
                    title: 'Hatalar',
                    abstract: true,
                    templateUrl: 'app/errors/errors.html'
                }
            },
                {
                    state: 'errors.list',
                    config: {
                        templateUrl: 'app/errors/errors.list.html',
                        controller: 'ErrorsListController',
                        controllerAs: 'vm',
                        url: '/list',
                        title: 'Hata Listesi',
                        settings: {
                            nav: 12,
                            name: 'errors.list',
                            topName:'services',
                            content: 'Hata Listesi'
                        }
                    }
                }                   
        ];
    }
})();

