(function () {
    'use strict';

    angular
        .module('app.excel')
        .factory('ExcelService', ExcelService);

    ExcelService.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config', 'Settings', 'CategoriesService', 'GeneralService', 'TagsService', 'ProductsGeneralService', "OptionValuesService", "ProductsService",
        "WarehousesService"];

    function ExcelService($http, $q, ngAuthSettings, logger, config, Settings, CategoriesService, GeneralService, TagsService, ProductsGeneralService, OptionValuesService,ProductsService,
        WarehousesService) {
        var service = {

            //Product

            getExcelExportFields: getExcelExportFields,
            getExcelImportFields: getExcelImportFields,
            getExcelFilterFields: getExcelFilterFields,
            getExcelFtpFields: getExcelFtpFields,
            getExcelAddImageFields: getExcelAddImageFields,
            exportFile: exportFile,
            importFile: importFile,
            analysisFile: analysisFile,
            addImageByFtp: addImageByFtp,
            addImageByFileManager: addImageByFileManager,

            //Category

            getExcelCategoryExportFields: getExcelCategoryExportFields,
            getExcelCategoryImportFields: getExcelCategoryImportFields,
            getExcelCategoryFilterFields: getExcelCategoryFilterFields,
            exportCategoryFile: exportCategoryFile,
            importCategoryFile: importCategoryFile,
            analysisCategoryFile: analysisCategoryFile,

            //Sales Point

            getExcelSalesPointExportFields: getExcelSalesPointExportFields,
            getExcelSalesPointImportFields: getExcelSalesPointImportFields,
            getExcelSalesPointFilterFields: getExcelSalesPointFilterFields,
            exportSalesPointFile: exportSalesPointFile,
            importSalesPointFile: importSalesPointFile,
            analysisSalesPointFile: analysisSalesPointFile,

            //Stock

            getExcelStockExportFields: getExcelStockExportFields,
            getExcelStockImportFields: getExcelStockImportFields,
            getExcelStockFilterFields: getExcelStockFilterFields,
            exportStockFile: exportStockFile,
            importStockFile: importStockFile,
            analysisStockFile: analysisStockFile
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri;
        var endpoint = serviceBase + 'api/excel/';

        //Product

        var excelExportFields = [
            {
                key: "ExcelType",
                type: "floatRadio",
                className: "boxRadio",
                "defaultValue": "excel-price",
                templateOptions: {
                    options: [
                        {
                            "Name": "Ürün Fiyatı Güncelle",
                            "Id": "excel-price"
                        },
                        {
                            "Name": "Ürün Adı Güncelle",
                            "Id": "excel-name"
                        },
                        {
                            "Name": "Ürünleri Pasife-Aktife Al",
                            "Id": "excel-status"
                        },
                        {
                            "Name": "Ürünleri Satışa Kapat-Aç",
                            "Id": "excel-sales-status"
                        },
                        {
                            "Name": "Ürünlerin Fiyatlarını Kapat-Aç",
                            "Id": "excel-price-status"
                        },
                        {
                            "Name": "Ürün Vergi Güncelle",
                            "Id": "excel-tax"
                        },
                        {
                            "Name": "Fotoğraf Güncelle",
                            "Id": "excel-image"
                        },
                        {
                            "Name": "Diğer Güncellemeler",
                            "Id": "excel-spesific"
                        }
                    ]
                },
                expressionProperties: {
                    'model': function ($viewValue, $modelValue, scope) {
                        if (scope.model.ExcelType === "excel-price") {
                            scope.model.propCategory = '';
                            scope.model.propName = true;
                            scope.model.propShortDescription = '';
                            scope.model.propDescription = '';
                            scope.model.propDescription2 = '';
                            scope.model.propPrice = true;
                            scope.model.propPriceWithDiscount = true;
                            scope.model.propPriceUnit = true;
                            scope.model.propUsePercentage = true;
                            scope.model.propBarcode = '';
                            scope.model.propBarcode2 = '';
                            scope.model.propTax = '';
                            scope.model.propTaxIncluded = '';
                            scope.model.propBrand = '';
                            scope.model.propTag = '';
                            scope.model.propActive = '';
                            scope.model.propDisplayOrder = '';
                            scope.model.propDepth = '';
                            scope.model.propHeight = '';
                            scope.model.propWeight = '';
                            scope.model.propWidth = '';
                            scope.model.propMetaKeyword = '';
                            scope.model.propMetaDescription = '';
                            scope.model.propMetaTitle = '';
                            scope.model.propNormalizedName = '';
                            scope.model.propStockQuantity = '';
                            scope.model.propClosedForSaleIfOutOfStock = '';
                            scope.model.propTrackStockQuantity = '';
                            scope.model.propNotifyForStockQuantityBelow = '';
                            scope.model.propProductStockTrackingType = '';
                            scope.model.propShowOldReview = '';
                            scope.model.propClosedForSale = '';
                            scope.model.propHidePrice = '';
                            scope.model.propFoto1 = '';
                            scope.model.propFoto2 = '';
                            scope.model.propFoto3 = '';
                            scope.model.propFoto4 = '';
                            scope.model.propFoto5 = '';
                            scope.model.propFoto6 = '';
                            scope.model.propFoto7 = '';
                            scope.model.propFoto8 = '';
                            scope.model.propFoto9 = '';
                            scope.model.propFoto10 = '';
                            scope.model.propN11Price = '';
                            scope.model.propN11Transfer = '';
                            scope.model.propXmlProductCode = '';
                            scope.model.propXmlProductCode2 = '';
                            scope.model.propXmlProductCode3 = '';
                            scope.model.propOption = '';
                            scope.model.propVariant = '';
                            scope.model.propGroup = '';
                            scope.model.propTP = '';
                        }
                        if (scope.model.ExcelType === "excel-name") {
                            scope.model.propCategory = '';
                            scope.model.propName = true;
                            scope.model.propShortDescription = '';
                            scope.model.propDescription = '';
                            scope.model.propDescription2 = '';
                            scope.model.propPrice = '';
                            scope.model.propPriceWithDiscount = '';
                            scope.model.propPriceUnit = '';
                            scope.model.propUsePercentage = '';
                            scope.model.propBarcode = '';
                            scope.model.propBarcode2 = '';
                            scope.model.propTax = '';
                            scope.model.propTaxIncluded = '';
                            scope.model.propBrand = '';
                            scope.model.propTag = '';
                            scope.model.propActive = '';
                            scope.model.propDisplayOrder = '';
                            scope.model.propDepth = '';
                            scope.model.propHeight = '';
                            scope.model.propWeight = '';
                            scope.model.propWidth = '';
                            scope.model.propMetaKeyword = '';
                            scope.model.propMetaDescription = '';
                            scope.model.propMetaTitle = '';
                            scope.model.propNormalizedName = '';
                            scope.model.propStockQuantity = '';
                            scope.model.propClosedForSaleIfOutOfStock = '';
                            scope.model.propTrackStockQuantity = '';
                            scope.model.propNotifyForStockQuantityBelow = '';
                            scope.model.propProductStockTrackingType = '';
                            scope.model.propShowOldReview = '';
                            scope.model.propClosedForSale = '';
                            scope.model.propHidePrice = '';
                            scope.model.propFoto1 = '';
                            scope.model.propFoto2 = '';
                            scope.model.propFoto3 = '';
                            scope.model.propFoto4 = '';
                            scope.model.propFoto5 = '';
                            scope.model.propFoto6 = '';
                            scope.model.propFoto7 = '';
                            scope.model.propFoto8 = '';
                            scope.model.propFoto9 = '';
                            scope.model.propFoto10 = '';
                            scope.model.propN11Price = '';
                            scope.model.propN11Transfer = '';
                            scope.model.propXmlProductCode = '';
                            scope.model.propXmlProductCode2 = '';
                            scope.model.propXmlProductCode3 = '';
                            scope.model.propOption = '';
                            scope.model.propVariant = '';
                            scope.model.propGroup = '';
                            scope.model.propTP = '';
                        }
                        if (scope.model.ExcelType === "excel-status") {
                            scope.model.propCategory = '';
                            scope.model.propName = '';
                            scope.model.propShortDescription = '';
                            scope.model.propDescription = '';
                            scope.model.propDescription2 = '';
                            scope.model.propPrice = '';
                            scope.model.propPriceWithDiscount = '';
                            scope.model.propPriceUnit = '';
                            scope.model.propUsePercentage = '';
                            scope.model.propBarcode = '';
                            scope.model.propBarcode2 = '';
                            scope.model.propTax = '';
                            scope.model.propTaxIncluded = '';
                            scope.model.propBrand = '';
                            scope.model.propTag = '';
                            scope.model.propActive = true;
                            scope.model.propDisplayOrder = '';
                            scope.model.propDepth = '';
                            scope.model.propHeight = '';
                            scope.model.propWeight = '';
                            scope.model.propWidth = '';
                            scope.model.propMetaKeyword = '';
                            scope.model.propMetaDescription = '';
                            scope.model.propMetaTitle = '';
                            scope.model.propNormalizedName = '';
                            scope.model.propStockQuantity = '';
                            scope.model.propClosedForSaleIfOutOfStock = '';
                            scope.model.propTrackStockQuantity = '';
                            scope.model.propNotifyForStockQuantityBelow = '';
                            scope.model.propProductStockTrackingType = '';
                            scope.model.propShowOldReview = '';
                            scope.model.propClosedForSale = '';
                            scope.model.propHidePrice = '';
                            scope.model.propFoto1 = '';
                            scope.model.propFoto2 = '';
                            scope.model.propFoto3 = '';
                            scope.model.propFoto4 = '';
                            scope.model.propFoto5 = '';
                            scope.model.propFoto6 = '';
                            scope.model.propFoto7 = '';
                            scope.model.propFoto8 = '';
                            scope.model.propFoto9 = '';
                            scope.model.propFoto10 = '';
                            scope.model.propN11Price = '';
                            scope.model.propN11Transfer = '';
                            scope.model.propXmlProductCode = '';
                            scope.model.propXmlProductCode2 = '';
                            scope.model.propXmlProductCode3 = '';
                            scope.model.propOption = '';
                            scope.model.propVariant = '';
                            scope.model.propGroup = '';
                            scope.model.propTP = '';
                        }
                        if (scope.model.ExcelType === "excel-sales-status") {
                            scope.model.propCategory = '';
                            scope.model.propName = '';
                            scope.model.propShortDescription = '';
                            scope.model.propDescription = '';
                            scope.model.propDescription2 = '';
                            scope.model.propPrice = '';
                            scope.model.propPriceWithDiscount = '';
                            scope.model.propPriceUnit = '';
                            scope.model.propUsePercentage = '';
                            scope.model.propBarcode = '';
                            scope.model.propBarcode2 = '';
                            scope.model.propTax = '';
                            scope.model.propTaxIncluded = '';
                            scope.model.propBrand = '';
                            scope.model.propTag = '';
                            scope.model.propActive = '';
                            scope.model.propDisplayOrder = '';
                            scope.model.propDepth = '';
                            scope.model.propHeight = '';
                            scope.model.propWeight = '';
                            scope.model.propWidth = '';
                            scope.model.propMetaKeyword = '';
                            scope.model.propMetaDescription = '';
                            scope.model.propMetaTitle = '';
                            scope.model.propNormalizedName = '';
                            scope.model.propStockQuantity = '';
                            scope.model.propClosedForSaleIfOutOfStock = '';
                            scope.model.propTrackStockQuantity = '';
                            scope.model.propNotifyForStockQuantityBelow = '';
                            scope.model.propProductStockTrackingType = '';
                            scope.model.propShowOldReview = '';
                            scope.model.propClosedForSale = true;
                            scope.model.propHidePrice = '';
                            scope.model.propFoto1 = '';
                            scope.model.propFoto2 = '';
                            scope.model.propFoto3 = '';
                            scope.model.propFoto4 = '';
                            scope.model.propFoto5 = '';
                            scope.model.propFoto6 = '';
                            scope.model.propFoto7 = '';
                            scope.model.propFoto8 = '';
                            scope.model.propFoto9 = '';
                            scope.model.propFoto10 = '';
                            scope.model.propN11Price = '';
                            scope.model.propN11Transfer = '';
                            scope.model.propXmlProductCode = '';
                            scope.model.propXmlProductCode2 = '';
                            scope.model.propXmlProductCode3 = '';
                            scope.model.propOption = '';
                            scope.model.propVariant = '';
                            scope.model.propGroup = '';
                            scope.model.propTP = '';
                        }
                        if (scope.model.ExcelType === "excel-price-status") {
                            scope.model.propCategory = '';
                            scope.model.propName = '';
                            scope.model.propShortDescription = '';
                            scope.model.propDescription = '';
                            scope.model.propDescription2 = '';
                            scope.model.propPrice = '';
                            scope.model.propPriceWithDiscount = '';
                            scope.model.propPriceUnit = '';
                            scope.model.propUsePercentage = '';
                            scope.model.propBarcode = '';
                            scope.model.propBarcode2 = '';
                            scope.model.propTax = '';
                            scope.model.propTaxIncluded = '';
                            scope.model.propBrand = '';
                            scope.model.propTag = '';
                            scope.model.propActive = '';
                            scope.model.propDisplayOrder = '';
                            scope.model.propDepth = '';
                            scope.model.propHeight = '';
                            scope.model.propWeight = '';
                            scope.model.propWidth = '';
                            scope.model.propMetaKeyword = '';
                            scope.model.propMetaDescription = '';
                            scope.model.propMetaTitle = '';
                            scope.model.propNormalizedName = '';
                            scope.model.propStockQuantity = '';
                            scope.model.propClosedForSaleIfOutOfStock = '';
                            scope.model.propTrackStockQuantity = '';
                            scope.model.propNotifyForStockQuantityBelow = '';
                            scope.model.propProductStockTrackingType = '';
                            scope.model.propShowOldReview = '';
                            scope.model.propClosedForSale = '';
                            scope.model.propHidePrice = true;
                            scope.model.propFoto1 = '';
                            scope.model.propFoto2 = '';
                            scope.model.propFoto3 = '';
                            scope.model.propFoto4 = '';
                            scope.model.propFoto5 = '';
                            scope.model.propFoto6 = '';
                            scope.model.propFoto7 = '';
                            scope.model.propFoto8 = '';
                            scope.model.propFoto9 = '';
                            scope.model.propFoto10 = '';
                            scope.model.propN11Price = '';
                            scope.model.propN11Transfer = '';
                            scope.model.propXmlProductCode = '';
                            scope.model.propXmlProductCode2 = '';
                            scope.model.propXmlProductCode3 = '';
                            scope.model.propOption = '';
                            scope.model.propVariant = '';
                            scope.model.propGroup = '';
                            scope.model.propTP = '';
                        }
                        if (scope.model.ExcelType === "excel-image") {
                            scope.model.propCategory = '';
                            scope.model.propName = true;
                            scope.model.propShortDescription = '';
                            scope.model.propDescription = '';
                            scope.model.propDescription2 = '';
                            scope.model.propPrice = '';
                            scope.model.propPriceWithDiscount = '';
                            scope.model.propPriceUnit = '';
                            scope.model.propUsePercentage = '';
                            scope.model.propBarcode = '';
                            scope.model.propBarcode2 = '';
                            scope.model.propTax = '';
                            scope.model.propTaxIncluded = '';
                            scope.model.propBrand = '';
                            scope.model.propTag = '';
                            scope.model.propActive = '';
                            scope.model.propDisplayOrder = '';
                            scope.model.propDepth = '';
                            scope.model.propHeight = '';
                            scope.model.propWeight = '';
                            scope.model.propWidth = '';
                            scope.model.propMetaKeyword = '';
                            scope.model.propMetaDescription = '';
                            scope.model.propMetaTitle = '';
                            scope.model.propNormalizedName = '';
                            scope.model.propStockQuantity = '';
                            scope.model.propClosedForSaleIfOutOfStock = '';
                            scope.model.propTrackStockQuantity = '';
                            scope.model.propNotifyForStockQuantityBelow = '';
                            scope.model.propProductStockTrackingType = '';
                            scope.model.propShowOldReview = '';
                            scope.model.propClosedForSale = '';
                            scope.model.propHidePrice = '';
                            scope.model.propPhoto = true;
                            scope.model.propN11Price = '';
                            scope.model.propN11Transfer = '';
                            scope.model.propXmlProductCode = '';
                            scope.model.propXmlProductCode2 = '';
                            scope.model.propXmlProductCode3 = '';
                            scope.model.propOption = '';
                            scope.model.propVariant = '';
                            scope.model.propGroup = '';
                            scope.model.propTP = '';
                        }
                        if (scope.model.ExcelType === "excel-tax") {
                            scope.model.propCategory = '';
                            scope.model.propName = '';
                            scope.model.propShortDescription = '';
                            scope.model.propDescription = '';
                            scope.model.propDescription2 = '';
                            scope.model.propPrice = '';
                            scope.model.propPriceWithDiscount = '';
                            scope.model.propPriceUnit = '';
                            scope.model.propUsePercentage = '';
                            scope.model.propBarcode = '';
                            scope.model.propBarcode2 = '';
                            scope.model.propTax = true;
                            scope.model.propTaxIncluded = '';
                            scope.model.propBrand = '';
                            scope.model.propTag = '';
                            scope.model.propActive = '';
                            scope.model.propDisplayOrder = '';
                            scope.model.propDepth = '';
                            scope.model.propHeight = '';
                            scope.model.propWeight = '';
                            scope.model.propWidth = '';
                            scope.model.propMetaKeyword = '';
                            scope.model.propMetaDescription = '';
                            scope.model.propMetaTitle = '';
                            scope.model.propNormalizedName = '';
                            scope.model.propStockQuantity = '';
                            scope.model.propClosedForSaleIfOutOfStock = '';
                            scope.model.propTrackStockQuantity = '';
                            scope.model.propNotifyForStockQuantityBelow = '';
                            scope.model.propProductStockTrackingType = '';
                            scope.model.propShowOldReview = '';
                            scope.model.propClosedForSale = '';
                            scope.model.propHidePrice = '';
                            scope.model.propFoto1 = '';
                            scope.model.propFoto2 = '';
                            scope.model.propFoto3 = '';
                            scope.model.propFoto4 = '';
                            scope.model.propFoto5 = '';
                            scope.model.propFoto6 = '';
                            scope.model.propFoto7 = '';
                            scope.model.propFoto8 = '';
                            scope.model.propFoto9 = '';
                            scope.model.propFoto10 = '';
                            scope.model.propN11Price = '';
                            scope.model.propN11Transfer = '';
                            scope.model.propXmlProductCode = '';
                            scope.model.propXmlProductCode2 = '';
                            scope.model.propXmlProductCode3 = '';
                            scope.model.propOption = '';
                            scope.model.propVariant = '';
                            scope.model.propGroup = '';
                            scope.model.propTP = '';
                        }
                        if (scope.model.ExcelType === "excel-spesific") {
                            scope.model.propPhoto = '';
                        }
                    }
                }
            },
            {
                key: 'propId',
                "defaultValue": true,
                hideExpression: 'model.ExcelType =="excel-price" || model.ExcelType =="excel-name" || model.ExcelType =="excel-status" || model.ExcelType =="excel-sales-status" || model.ExcelType =="excel-price-status" || model.ExcelType =="excel-tax" || model.ExcelType =="excel-image" || model.ExcelType =="excel-spesific"',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Ürün Id',
                    resource: 'form.id',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propCode',
                "defaultValue": true,
                hideExpression: 'model.ExcelType =="excel-price" || model.ExcelType =="excel-name" || model.ExcelType =="excel-status" || model.ExcelType =="excel-sales-status" || model.ExcelType =="excel-price-status" || model.ExcelType =="excel-tax" || model.ExcelType =="excel-image" || model.ExcelType =="excel-spesific"',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Ürün Kodu',
                    resource: 'form.code' ,
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propCategory',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Kategori',
                    resource: 'form.category',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propName',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Ürün Adı',
                    resource: 'form.name',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propShortDescription',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Kısa Açıklama',
                    resource: 'form.shortdescription',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propDescription',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Açıklama',
                    resource: 'form.description',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propDescription2',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Açıklama 2',
                    resource: 'form.description2',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propPrice',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Ürün Fiyatı',
                    resource: 'form.price',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propPriceWithDiscount',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Ürün Fiyatı',
                    resource: 'form.pricewithdiscount',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propPriceUnit',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Fiyat Birimi',
                    resource: 'form.priceunit',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propUsePercentage',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Yüzde Kullan',
                    resource: 'form.usepercentage',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propBarcode',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Barkod No',
                    resource: 'form.barcode',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propBarcode2',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Barkod No 2',
                    resource: 'form.barcode2',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propTax',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Vergi',
                    resource: 'form.tax',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propTaxIncluded',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Vergiler Dahil',
                    resource: 'form.tax-include',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propBrand',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Ürün Markası',
                    resource: 'form.brand',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propTag',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Ürün Etiketi',
                    resource: 'form.inputTag',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propActive',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Durumu',
                    resource: 'form.active',
                    placeholder: 'Durumu',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propDisplayOrder',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Sıra',
                    resource: 'form.display-order',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propCulture',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Dil',
                    resource: 'form.culture',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propDepth',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Derinlik',
                    resource: 'form.depth',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propHeight',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Yükseklik',
                    resource: 'form.height',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propWeight',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Ağırlık',
                    resource: 'form.weight',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propWidth',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Genişlik',
                    resource: 'form.width',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propStockQuantity',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Stok Adeti',
                    resource: 'form.stock-quantity',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propClosedForSale',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Satış Kapat',
                    resource: 'form.closed-for-sale',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propHidePrice',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Fiyatı Gizle',
                    resource: 'form.hide-price',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propMetaKeyword',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    resource: 'form.meta-keyword',
                    label: 'Seo keywords',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propMetaDescription',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Seo description',
                    resource: 'form.meta-description',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propMetaTitle',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Seo title',
                    resource: 'form.meta-title',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propNormalizedName',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Arama Motoru Sayfa Adı',
                    resource: 'form.normalized-name',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propClosedForSaleIfOutOfStock',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Stok Bitince Satışa Kapat',
                    resource: 'form.closed-for-sale-ıf-out-of-stock',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propTrackStockQuantity',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Stok Takibi Yap',
                    resource: 'form.track-stock-quantity',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propNotifyForStockQuantityBelow',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Miktarın Altına Düşünce Uyar',
                    resource: 'form.notify-for-stock-quantity-below',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propProductStockTrackingType',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Stok Takip Şekli',
                    resource: 'form.product-stock-tracking-type',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propShowOldReview',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Önceki Yorumları Göster',
                    resource: 'form.show-old-review',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propN11Price',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'N11 Fiyatı',
                    resource: 'form.n11-price',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propN11Transfer',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'N11 Transfer',
                    resource: 'form.n11-transfer',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propGGPrice',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'GittiGidiyor Fiyatı',
                    resource: 'form.gg-price',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propGGTransfer',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Gittigidiyor Transfer',
                    resource: 'form.gg-transfer',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propHBSKU',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Hepsiburada Stok Kodu',
                    resource: 'form.hb-SKU',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propHBPrice',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Hepsiburada Fiyatı',
                    resource: 'form.hb-price',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propHBTransfer',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Hepsiburada Transfer',
                    resource: 'form.hb-transfer',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propoem',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'OEM Numarası',
                    resource: 'form.oem',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propXmlProductCode',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Xml Kod',
                    resource: 'form.xmlproductcode',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propXmlProductCode2',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Xml Kod 2',
                    resource: 'form.xmlproductcode2',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propXmlProductCode3',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Xml Kod 3',
                    resource: 'form.xmlproductcode3',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propOption',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Ürün Seçenekleri',
                    resource: 'form.option',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propVariant',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Ürün Varyantları',
                    resource: 'form.variant',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propGroup',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Grup Ürünler',
                    resource: 'form.groupproduct',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propTP',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Ürün Özel Fiyatı',
                    resource: 'form.tierprice',
                    class: 'col-xs-3'
                }
            }
        ];

        var excelFilterFields = [
            {
                key: 'Category',
                type: 'floatTreeMultiSelect',
                templateOptions: {
                    label: 'Kategori',
                    resource: 'form.category',
                    topProp: 'TopCategoryId',
                    options: [{ Name: '...' }]
                },
                controller: ['$scope', function ($scope) {
                    CategoriesService.getCategories({ PageSize: -1 })
                        .then(function (response) {
                            $scope.to.options = response.Data;
                        });
                }]
            },
            {
                key: 'CategoryMapFilter',
                type: 'floatTreeMultiSelect',
                "hide": true,
                templateOptions: {
                    label: 'Kategori',
                    resource: 'form.category',
                    topProp: 'TopCategoryId',
                    options: [{ Name: '...' }]
                }
            },
        
            {
                key: 'Product',
                type: 'floatInput',
                templateOptions: {
                    label: 'Ürün Adı',
                    resource: 'form.product-contain',
                    placeholder: 'Kelimeyi İçeren Tüm Ürünleri Getirir'
                }
            },
            {
                key: 'Code',
                type: 'floatInput',
                templateOptions: {
                    label: 'Ürün Kodu',
                    resource: 'form.product-code',
                    placeholder: 'Kodu İçeren Tüm Ürünleri Getirir'
                }
            },
            {
                key: 'MinPrice',
                type: 'floatInput',
                templateOptions: {
                    label: 'En Düşük Fiyat',
                    resource: 'form.minimum-amount',
                    numberMask: 2,
                    class: 'col-xs-6'
                }
            },
            {
                key: 'MaxPrice',
                type: 'floatInput',
                templateOptions: {
                    label: 'En Yüksek Fiyat',
                    resource: 'form.maximum-amount',
                    numberMask: 2,
                    class: 'col-xs-6'
                }
            },
            {
                key: 'MinStock',
                type: 'floatInput',
                templateOptions: {
                    label: 'En Az Stok Sayısı',
                    resource: 'form.minimum-stock',
                    class: 'col-xs-6'
                }
            },
            {
                key: 'MaxStock',
                type: 'floatInput',
                templateOptions: {
                    label: 'En Çok Stok Sayısı',
                    resource: 'form.maximum-stock',
                    class: 'col-xs-6'
                }
            },
            {
                key: 'ProductStatus',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Ürün Durumu',
                    resource: 'form.status',
                    options: [
                        {
                            Name: 'Tümü',
                            Id: null
                        },
                        {
                            Name: 'Aktif',
                            Id: true
                        },
                        {
                            Name: 'Pasif',
                            Id: false
                        }
                    ]
                }
            },
            {
                key: 'SalesStatus',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Satış Durumu',
                    resource: 'form.salesstatus',
                    options: [
                        {
                            Name: 'Tümü',
                            Id: null
                        },
                        {
                            Name: 'Açık',
                            Id: true
                        },
                        {
                            Name: 'Kapalı',
                            Id: false
                        }
                    ]
                }
            },
            {
                key: 'ReviewType',
                type: 'float-async-ui-select-multiple',
                templateOptions: {
                    label: 'ReviewType',
                    resource: 'form.review-type',
                    labelProp: 'Name',
                    options: [],
                    placeholder: 'Yorum Tipi Seç veya Arama yap',
                    valueProp: 'Id',
                    refresh: function (text, options, model) {
                        var params = { Type: text, PageSize: 5 };
                        if (model) {
                            params.Id = model.Id;
                        }
                        return ProductsGeneralService.getReviewTypes(params)
                            .then(function (response) {
                                var reviewTypes = response.map(x => {
                                    var obj = {};
                                    obj["Id"] = x.Id;
                                    obj["Name"] = x.Type;
                                    return obj;
                                });
                                options.templateOptions.options = reviewTypes;
                            });
                    },
                    refreshDelay: 2
                }
            },
            {
                key: 'Brand',
                type: 'float-async-ui-select-multiple',
                templateOptions: {
                    label: 'Brand',
                    resource: 'form.brand',
                    labelProp: 'Name',
                    valueProp: 'Id',
                    class: 'col-xs-6',
                    options: [],
                    placeholder: 'Marka Seç veya Arama yap',
                    refresh: function (text, options, model) {
                        var params = { Name: text, PageSize: 5 };
                        if (model) {
                            params.Id = model.Id;
                        }
                        return GeneralService.getBrands(params)
                            .then(function (response) {
                                options.templateOptions.options = response;
                            });
                    },
                    refreshDelay: 2
                }
            },
            {
                key: 'Tag',
                type: 'float-async-ui-select-multiple',
                templateOptions: {
                    label: 'Tag',
                    resource: 'form.tag',
                    labelProp: 'Name',
                    class: 'col-xs-6',
                    options: [],
                    placeholder: 'Etiket Seç veya Arama yap',
                    valueProp: 'Id',
                    refresh: function (text, options, model) {
                        var params = { Name: text, PageSize: 5 };
                        if (model) {
                            params.Id = model.Id;
                        }
                        return TagsService.getAll(params)
                            .then(function (response) {
                                console.log(response.Data);
                                options.templateOptions.options = response.Data;
                            });
                    },
                    refreshDelay: 2
                }
            },
            {
                key: "OptionId",
                type: "floatSelect",
                templateOptions: {
                    label: "Özellik",
                    resource: "form.option",
                    options: [{ Name: "..." }],
                    class: 'col-xs-6'
                },
                controller: [
                    "$scope",
                    function ($scope) {
                            ProductsGeneralService.getOptions().then(function (response) {
                                $scope.to.options = response;
                            });
                        
                    }
                ]
            },
            {
                key: "OptionValueId",
                type: "float-async-ui-select-multiple",
                templateOptions: {
                    label: "Değer",
                    resource: "form.option-value",
                    labelProp: "Value",
                    options: [{ Value: "Önce Özellik Seçmelisiniz..." }],
                    class: 'col-xs-6',
                    valueProp: 'Id',
                    refresh: function (text, options, model) {
                        var params = { Name: text, PageSize: 5 };
                        if (model) {
                            params.Id = model.Id;
                        }
                        return ;
                    },
                    refreshDelay: 2
                },
                controller: [
                    "$scope",
                    function ($scope) {
                        $scope.$watch("model.OptionId", function (newValue, oldValue) {
                           // console.log("optionId");
                            if (newValue != oldValue) {

                                $scope.viewValue = null;
                                console.log("değiş");
                                OptionValuesService.getOptionValue(newValue).then(function (response) {
                                  //  console.log("response", response);
                                    $scope.to.options = response;
                                });
                            }
                        });
                    }
                ]
            }

        ];

        var excelImportFields = [
            //{
            //    key: 'File',
            //    type: 'horizontalFileUpload',
            //    templateOptions: {
            //        type: 'file',
            //        label: 'Excel Yükle',
            //        required: true
            //    }
            //}
            {
                key: "File",
                type: "horizontalSingleMedia",
                templateOptions: {
                    label: "Excel Yükle",
                    placeholder: "Excel Yükle",
                    valueKey: "Id"
                }
            }
        ];

        var excelFtpFields = [
            {
                key: 'FtpInput',
                type: 'horizontalInput',
                className: "FileRecources",
                templateOptions: {
                    type: 'text',
                    label: 'Yüklenecek Fotoğraf Yolu : uploads/newimages/',
                    placeholder : 'uploads/newimages/'
                }
            }
            //{
            //    key: "File",
            //    type: "horizontalSingleMedia",
            //    templateOptions: {
            //        label: "Excel Yükle",
            //        placeholder: "Excel Yükle",
            //        valueKey: "Id"
            //    }
            //},
        ];

        var excelAddImageFields = [
            {
                key: "File",
                type: "horizontalSingleMedia",
                templateOptions: {
                    label: "Fotoğraf Yükle",
                    placeholder: "Fotoğraf Yükle",
                    valueKey: "Id"
                }
            }
        ];

        //Category

        var excelCategoryExportFields = [
            {
                key: "ExcelType",
                type: "floatRadio",
                className: "boxCategoryRadio",
                "defaultValue": "excel-general",
                templateOptions: {
                    options: [
                        {
                            "Name": "Genel Liste",
                            "Id": "excel-general"
                        },
                        {
                            "Name": "Diğer Güncellemeler",
                            "Id": "excel-spesific"
                        }
                    ]
                },
                expressionProperties: {
                    'model': function ($viewValue, $modelValue, scope) {
                        if (scope.model.ExcelType === "excel-general") {
                            scope.model.propCategory = true;
                            scope.model.propTopCategory = true;
                            scope.model.propCulture = '';
                            scope.model.propDescription = '';
                            scope.model.propShowMenu = '';
                            scope.model.propDisplayOrder = '';
                            scope.model.propActive = '';
                            scope.model.propMetaDescription = '';
                            scope.model.propMetaKeyword = '';
                            scope.model.propMetaTitle = '';
                        }
                    }
                }
            },
            {
                key: 'propId',
                "defaultValue": true,
                hideExpression: 'model.ExcelType =="excel-general" || model.ExcelType =="excel-spesific"',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Kategori Id',
                    resource: 'form.category-id',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propCategory',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Kategori Adı',
                    resource: 'form.category',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propTopCategory',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Üst Kategori Adı',
                    resource: 'form.top-category',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propCulture',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Dil',
                    resource: 'form.culture',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propShowMenu',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Menüde Göster',
                    resource: 'form.show-menu',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propDescription',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Açıklama',
                    resource: 'form.description',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propDisplayOrder',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Sıra',
                    resource: 'form.display-order',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propActive',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Aktiflik',
                    resource: 'form.active',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propMetaDescription',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Meta Description',
                    resource: 'form.meta-description',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propMetaKeyword',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Meta Keyword',
                    resource: 'form.meta-keyword',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propMetaTitle',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Meta Title',
                    resource: 'form.meta-title',
                    class: 'col-xs-3'
                }
            },

        ];
                 
        var excelCategoryFilterFields = [
            {
                key: 'Category',
                type: 'floatInput',
                templateOptions: {
                    label: 'Kategori Adı',
                    resource: 'form.category-contain',
                    placeholder: 'Kelimeyi İçeren Tüm Kategorileri Getirir'
                }
            },
            {
                key: 'Code',
                type: 'floatInput',
                templateOptions: {
                    label: 'Kategori Kodu',
                    resource: 'form.category-code',
                    placeholder: 'Kodu İçeren Tüm Kategorileri Getirir'
                }
            },
            {
                key: 'ShowMenuStatus',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Menüde Gösterme Durumu',
                    resource: 'form.showmenu-status',
                    options: [
                        {
                            Name: 'Tümü',
                            Id: null
                        },
                        {
                            Name: 'Göster',
                            Id: true
                        },
                        {
                            Name: 'Gösterme',
                            Id: false
                        }
                    ]
                }
            },
            {
                key: 'CategoryStatus',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Kategori Durumu',
                    resource: 'form.category-status',
                    options: [
                        {
                            Name: 'Tümü',
                            Id: null
                        },
                        {
                            Name: 'Aktif',
                            Id: true
                        },
                        {
                            Name: 'Pasif',
                            Id: false
                        }
                    ]
                }
            },
        ];
                
        var excelCategoryImportFields = [
            {
                key: "File",
                type: "horizontalSingleMedia",
                templateOptions: {
                    label: "Excel Yükle",
                    placeholder: "Excel Yükle",
                    valueKey: "Id"
                }
            }
        ];

        //Sales Point

        var excelSalesPointExportFields = [
            {
                key: "ExcelType",
                type: "floatRadio",
                className: "boxVendorRadio",
                "defaultValue": "excel-name",
                templateOptions: {
                    options: [
                        {
                            "Name": "Bayi Adı Güncelle",
                            "Id": "excel-name"
                        },
                        {
                            "Name": "Bayi Telefon Güncelle",
                            "Id": "excel-phone"
                        },
                        {
                            "Name": "Bayi E-Mail Güncelle",
                            "Id": "excel-mail"
                        },
                        {
                            "Name": "Bayileri Aktife-Pasife Al",
                            "Id": "excel-status"
                        },
                        {
                            "Name": "Excel Alanlarını Seç",
                            "Id": "excel-spesific"
                        }
                    ]
                },
                expressionProperties: {
                    'model': function ($viewValue, $modelValue, scope) {
                        if (scope.model.ExcelType === "excel-name") {
                            scope.model.propName = true;
                            scope.model.propEmail = '';
                            scope.model.propDescription = '';
                            scope.model.propShowLocationList = '';
                            scope.model.propPhone = '';
                            scope.model.propDisplayOrder = '';
                            scope.model.propBayiDurum = '';
                            scope.model.propYetkili = '';
                            scope.model.propCariAd = '';
                            scope.model.propCariKod = '';
                            scope.model.propCity = '';
                            scope.model.propDistrict = '';
                            scope.model.propEgitimPortaliKullaniciAdi = '';
                            scope.model.propEgitimPortaliSifre = '';
                            scope.model.propMobilePhone = '';
                            scope.model.propStatu = '';
                            scope.model.propInsertDurum = '';
                            scope.model.propInsertMiktar = '';
                            scope.model.propPerakendeListeDurum = '';
                            scope.model.propPerakendeListeMiktar = '';
                            scope.model.propToptanListeDurum = '';
                            scope.model.propToptanListeMiktar = '';
                            scope.model.propBayiBilgiFoyuDurum = '';
                            scope.model.propBayiBilgiFoyuMiktar = '';
                            scope.model.propToplamM2 = '';
                            scope.model.propBaseM2 = '';
                            scope.model.propAcilisTarihi = '';
                            scope.model.propPasiflikTarihi = '';
                            scope.model.propPlaka = '';
                            scope.model.propStatus = '';
                            scope.model.propMailPassword = '';
                            scope.model.propMailNewPassword = '';
                            scope.model.propKarmaMunhasir = '';
                            scope.model.propFaks = '';
                        }
                        if (scope.model.ExcelType === "excel-phone") {
                            scope.model.propName = '';
                            scope.model.propEmail = '';
                            scope.model.propDescription = '';
                            scope.model.propShowLocationList = '';
                            scope.model.propPhone = true;
                            scope.model.propDisplayOrder = '';
                            scope.model.propBayiDurum = '';
                            scope.model.propYetkili = '';
                            scope.model.propCariAd = '';
                            scope.model.propCariKod = '';
                            scope.model.propCity = '';
                            scope.model.propDistrict = '';
                            scope.model.propEgitimPortaliKullaniciAdi = '';
                            scope.model.propEgitimPortaliSifre = '';
                            scope.model.propMobilePhone = '';
                            scope.model.propStatu = '';
                            scope.model.propInsertDurum = '';
                            scope.model.propInsertMiktar = '';
                            scope.model.propPerakendeListeDurum = '';
                            scope.model.propPerakendeListeMiktar = '';
                            scope.model.propToptanListeDurum = '';
                            scope.model.propToptanListeMiktar = '';
                            scope.model.propBayiBilgiFoyuDurum = '';
                            scope.model.propBayiBilgiFoyuMiktar = '';
                            scope.model.propToplamM2 = '';
                            scope.model.propBaseM2 = '';
                            scope.model.propAcilisTarihi = '';
                            scope.model.propPasiflikTarihi = '';
                            scope.model.propPlaka = '';
                            scope.model.propStatus = '';
                            scope.model.propMailPassword = '';
                            scope.model.propMailNewPassword = '';
                            scope.model.propKarmaMunhasir = '';
                            scope.model.propFaks = '';
                        }
                        if (scope.model.ExcelType === "excel-mail") {
                            scope.model.propName = '';
                            scope.model.propEmail = true;
                            scope.model.propDescription = '';
                            scope.model.propShowLocationList = '';
                            scope.model.propPhone = '';
                            scope.model.propDisplayOrder = '';
                            scope.model.propBayiDurum = '';
                            scope.model.propYetkili = '';
                            scope.model.propCariAd = '';
                            scope.model.propCariKod = '';
                            scope.model.propCity = '';
                            scope.model.propDistrict = '';
                            scope.model.propEgitimPortaliKullaniciAdi = '';
                            scope.model.propEgitimPortaliSifre = '';
                            scope.model.propMobilePhone = '';
                            scope.model.propStatu = '';
                            scope.model.propInsertDurum = '';
                            scope.model.propInsertMiktar = '';
                            scope.model.propPerakendeListeDurum = '';
                            scope.model.propPerakendeListeMiktar = '';
                            scope.model.propToptanListeDurum = '';
                            scope.model.propToptanListeMiktar = '';
                            scope.model.propBayiBilgiFoyuDurum = '';
                            scope.model.propBayiBilgiFoyuMiktar = '';
                            scope.model.propToplamM2 = '';
                            scope.model.propBaseM2 = '';
                            scope.model.propAcilisTarihi = '';
                            scope.model.propPasiflikTarihi = '';
                            scope.model.propPlaka = '';
                            scope.model.propStatus = '';
                            scope.model.propMailPassword = '';
                            scope.model.propMailNewPassword = '';
                            scope.model.propKarmaMunhasir = '';
                            scope.model.propFaks = '';
                        }
                        if (scope.model.ExcelType === "excel-status") {
                            scope.model.propName = '';
                            scope.model.propEmail = '';
                            scope.model.propDescription = '';
                            scope.model.propShowLocationList = '';
                            scope.model.propPhone = '';
                            scope.model.propDisplayOrder = '';
                            scope.model.propBayiDurum = '';
                            scope.model.propYetkili = '';
                            scope.model.propCariAd = '';
                            scope.model.propCariKod = '';
                            scope.model.propCity = '';
                            scope.model.propDistrict = '';
                            scope.model.propEgitimPortaliKullaniciAdi = '';
                            scope.model.propEgitimPortaliSifre = '';
                            scope.model.propMobilePhone = '';
                            scope.model.propStatu = '';
                            scope.model.propInsertDurum = '';
                            scope.model.propInsertMiktar = '';
                            scope.model.propPerakendeListeDurum = '';
                            scope.model.propPerakendeListeMiktar = '';
                            scope.model.propToptanListeDurum = '';
                            scope.model.propToptanListeMiktar = '';
                            scope.model.propBayiBilgiFoyuDurum = '';
                            scope.model.propBayiBilgiFoyuMiktar = '';
                            scope.model.propToplamM2 = '';
                            scope.model.propBaseM2 = '';
                            scope.model.propAcilisTarihi = '';
                            scope.model.propPasiflikTarihi = '';
                            scope.model.propPlaka = '';
                            scope.model.propStatus = true;
                            scope.model.propMailPassword = '';
                            scope.model.propMailNewPassword = '';
                            scope.model.propKarmaMunhasir = '';
                            scope.model.propFaks = '';
                        }
                    }
                }
            },
            {
                key: 'propId',
                "defaultValue": true,
                hideExpression: 'model.ExcelType =="excel-name" || model.ExcelType =="excel-phone" || model.ExcelType =="excel-mail" || model.ExcelType =="excel-status" || model.ExcelType =="excel-spesific"',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Bayi Id',
                    resource: 'form.salespoint-id',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propName',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Bayi Adı',
                    resource: 'form.salespoint-name',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propEmail',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'E-Mail',
                    resource: 'form.salespoint-mail',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propPhone',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Telefon',
                    resource: 'form.phone',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propDescription',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Açıklama',
                    resource: 'form.description',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propShowLocationList',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Konum Listesini Göster',
                    resource: 'form.show-location-list',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propDisplayOrder',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Sıra',
                    resource: 'form.display-order',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propBayiDurum',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Bayi Durum',
                    resource: 'form.bayi-durum',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propYetkili',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Yetkili',
                    resource: 'form.yetkili',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propCariAd',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Cari Adı',
                    resource: 'form.cari-ad',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propCariKod',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Cari Kodu',
                    resource: 'form.cari-kodu',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propCountry',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Ülke',
                    resource: 'form.country-name',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propStateProvince',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'İl',
                    resource: 'form.state-province-name',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propDistrict',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'İlçe',
                    resource: 'form.district',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propAddressDescription',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Adres',
                    resource: 'form.address',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propLocation',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Konum',
                    resource: 'form.location',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propMobilePhone',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Cep Telefonu',
                    resource: 'form.mobile-phone',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propStatu',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Statu',
                    resource: 'form.statu',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propInsertDurum',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Insert Durum',
                    resource: 'form.insert-durum',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propInsertMiktar',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Insert Miktar',
                    resource: 'form.insert-miktar',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propEgitimPortaliKullaniciAdi',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Eğitim Portalı Kullanıcı Adı',
                    resource: 'form.egitim-portali-kullanici-adi',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propEgitimPortaliSifre',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Eğitim Portalı Şifre',
                    resource: 'form.egitim-portali-sifre',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propBayiBilgiFoyuDurum',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Bayi Bilgi Foyu Durum',
                    resource: 'form.bayi-bilgi-foyu-durum',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propBayiBilgiFoyuMiktar',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Bayi Bilgi Foyu Miktar',
                    resource: 'form.bayi-bilgi-foyu-miktar',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propPerakendeListeDurum',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Perakende Liste Durum',
                    resource: 'form.perakende-liste-durum',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propPerakendeListeMiktar',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Perakende Liste Miktar',
                    resource: 'form.perakende-liste-miktar',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propToptanListeDurum',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    resource: 'form.toptan-liste-durum',
                    label: 'Toptan Liste Durum',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propToptanListeMiktar',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Toptan Liste Miktar',
                    resource: 'form.toptan-liste-miktar',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propToplamM2',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Toplam M2',
                    resource: 'form.toplam-m2',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propBaseM2',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Base M2',
                    resource: 'form.base-m2',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propAcilisTarihi',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Açılış Tarihi',
                    resource: 'form.acilis-tarihi',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propPasiflikTarihi',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Pasiflik Tarihi',
                    resource: 'form.pasiflik-tarihi',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propPlaka',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Plaka',
                    resource: 'form.plaka',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propStatus',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Durum',
                    resource: 'form.status',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propMailPassword',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Mail Password',
                    resource: 'form.mail-password',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propMailNewPassword',   
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Mail New Password',
                    resource: 'form.mail-new-password',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propKarmaMunhasir',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Karma Münhasır',
                    resource: 'form.karma-munhasir',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propFaks',
                type: 'floatCheckbox',
                hideExpression: 'model.ExcelType !="excel-spesific"',
                templateOptions: {
                    label: 'Fax',
                    resource: 'form.fax-number',
                    class: 'col-xs-3'
                }
            }
        ];

        var excelSalesPointFilterFields = [
            {
                key: 'Name',
                type: 'floatInput',
                templateOptions: {
                    label: 'Bayi Adı',
                    resource: 'form.salespoint-contain',
                    placeholder: 'Kelimeyi İçeren Tüm Bayi Adlarını Getirir'
                }
            },
            {
                key: 'CariKod',
                type: 'floatInput',
                templateOptions: {
                    label: 'Cari Kod',
                    resource: 'form.cari-kod-contain',
                    placeholder: 'Kelimeyi İçeren Tüm Cari Kodlarını Getirir'
                }
            },
            {
                key: 'CariAd',
                type: 'floatInput',
                templateOptions: {
                    label: 'Cari Ad',
                    resource: 'form.cari-ad-contain',
                    placeholder: 'Kelimeyi İçeren Tüm Cari Adlarını Getirir'
                }
            },
            {
                key: 'Status',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Bayi Durumu',
                    resource: 'form.salespoint-status',
                    options: [
                        {
                            Name: 'Tümü',
                            Id: null
                        },
                        {
                            Name: 'Aktif',
                            Id: true
                        },
                        {
                            Name: 'Pasif',
                            Id: false
                        }
                    ]
                }
            }
            //{
            //    key: 'KarmaMunhasir',
            //    type: 'float-async-ui-select-multiple',
            //    templateOptions: {
            //        label: 'KarmaMunhasir',
            //        resource: 'form.karma-munhasir',
            //        labelProp: 'KarmaMunhasir',
            //        options: [],
            //        placeholder: '...',
            //        valueProp: 'Id',
            //        refresh: function (text, options, model) {
            //            var params = { Name: text, PageSize: 5 };
            //            if (model) {
            //                params.Id = model.Id;
            //            }
            //            return GeneralService.getBrands(params)
            //                .then(function (response) {
            //                    options.templateOptions.options = response;
            //                });
            //        },
            //        refreshDelay: 2
            //    }
            //}           
        ];

        var excelSalesPointImportFields = [
            {
                key: 'File',
                type: 'horizontalSingleMedia',
                templateOptions: {
                    label: 'Excel Yükle',
                    placeholder: "Excel Yükle",
                    valueKey: "Id"
                }
            }
        ];


        //Stock

        var excelStockExportFields = [
            {
                key: "ExcelType",
                type: "floatRadio",
                className: "boxStockRadio",
                "defaultValue": "excel-stock-history",
                templateOptions: {
                    options: [
                        {
                            "Name": "Stok Hareketleri Listesi",
                            "Id": "excel-stock-history"
                        },
                        {
                            "Name": "Stok Elemanları Listesi",
                            "Id": "excel-stock-item"
                        }
                    ]
                },
                expressionProperties: {
                    'model': function ($viewValue, $modelValue, scope) {
                        if (scope.model.ExcelType === "excel-stock-history") {
                            scope.model.propProduct = true;
                            scope.model.propWareHouse = true;
                            scope.model.propQuantity = true;
                            scope.model.propStockHistoryType = true;
                            scope.model.propRecommendedPrice = true;
                            scope.model.propCode = '';
                            scope.model.propCost = '';
                            scope.model.propWareHouseLocation = '';
                            scope.model.propStockItemStatus = '';
                            scope.model.IsStockItem = false;
                            scope.model.propAutoGenerateStockItem = true;
                        }
                        if (scope.model.ExcelType === "excel-stock-item") {
                            scope.model.propProduct = true;
                            scope.model.propWareHouse = true;
                            scope.model.propQuantity = true;
                            scope.model.propStockHistoryType = '';
                            scope.model.propRecommendedPrice = true;
                            scope.model.propCode = true;
                            scope.model.propCost = true;
                            scope.model.propWareHouseLocation = true;
                            scope.model.propStockItemStatus = true;
                            scope.model.IsStockItem = true;
                            scope.model.propAutoGenerateStockItem = '';
                        }                       
                    }
                }
            },
            {
                key: 'propId',
                "defaultValue": true,
                hideExpression: 'model.ExcelType == "excel-stock-history" || model.ExcelType == "excel-stock-item"',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Stock Id',
                    resource: 'form.stock-id',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propProduct',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Ürün Adı',
                    resource: 'form.stock-product-name',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propWareHouse',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Depo Adı',
                    resource: 'form.stock-ware-house',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propQuantity',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Adet',
                    resource: 'form.stock-quantity',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propStockHistoryType',
                hideExpression: 'model.ExcelType == "excel-stock-item"',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Stok Tipi',
                    resource: 'form.stock-history-type',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propRecommendedPrice',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Önerilen Fiyat',
                    resource: 'form.stock-recommended-price',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propCode',
                hideExpression: 'model.ExcelType =="excel-stock-history"',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Kod',
                    resource: 'form.stock-code',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propCost',
                hideExpression: 'model.ExcelType =="excel-stock-history"',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Sisteme Satış Fiyatı',
                    resource: 'form.stockitem-cost',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propWareHouseLocation',
                hideExpression: 'model.ExcelType =="excel-stock-history"',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Depo Konumu',
                    resource: 'form.stock-ware-house-location',
                    class: 'col-xs-3'
                }
            },
            {
                key: 'propStockItemStatus',
                hideExpression: 'model.ExcelType =="excel-stock-history"',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Stok Ürünü Durumu',
                    resource: 'form.stock-item-status',
                    class: 'col-xs-3'
                }
            },
        ];  

        var excelStockFilterFields = [
            {
                key: 'ProductId',
                type: 'float-async-ui-select',
                templateOptions: {
                    label: 'Ürün',
                    resource: 'form.product',
                    options: [],
                    placeholder: '...',
                    valueProp: 'Id',
                    labelProp: 'Name',
                    refresh: function (text, options, model) {
                        var params = {
                            SelectFields: "Id,Name"
                        };
                        if (model.Product != null) {
                            params.Id = model.Product.Id;
                        } else {
                            params.SearchKey = (text || (model.Product != null ? model.Product.Name : ''));
                            params.TrackStockQuantity = true;
                        }
                        return ProductsService.getProducts(params)
                            .then(function (response) {
                                if (response.Data.length > 0) {
                                    options.templateOptions.options = response.Data;
                                } else {
                                    options.templateOptions.options = [{
                                        Id: '',
                                        Name: 'Bulunamadı (' + text + ')'
                                    }];
                                }
                            });
                    },
                    refreshDelay: 2
                }
            },
            {
                key: 'WarehouseId',
                type: 'float-async-ui-select',
                templateOptions: {
                    label: 'Depo',
                    resource: 'form.warehouse',
                    options: [],
                    placeholder: '...',
                    valueProp: 'Id',
                    labelProp: 'Name',
                    refresh: function (text, options, model) {
                        var params = {
                            Name: (text || (model.Warehouse != null ? model.Warehouse.Name : '')),
                            SelectFields: "Id,Name"
                        };
                        return WarehousesService.getAll(params)
                            .then(function (response) {
                                if (response.Data.length > 0) {
                                    options.templateOptions.options = response.Data;
                                } else {
                                    options.templateOptions.options = [{
                                        Id: '',
                                        Name: 'Bulunamadı (' + text + ')'
                                    }];
                                }
                            });
                    },
                    refreshDelay: 2
                }
            },
            {
                key: "StockHistoryTypeId",
                type: "floatSelect",
                templateOptions: {
                    label: 'Stok Tipi',
                    resource: 'form.stock-history-type',
                    options: [{
                        Name: ''
                    }, {
                        Id: '9509be17-9671-4c98-8c89-36ca7b525e28',
                        Name: 'Giriş'
                    }, {
                        Id: '4a812f3b-ab27-4c59-a4d2-b6cbfec9a23a',
                        Name: 'Çıkış'
                    }]
                }
            },
        ]; 

        var excelStockImportFields = [
            {
                key: 'File',
                type: 'horizontalSingleMedia',
                templateOptions: {
                    label: 'Excel Yükle',
                    placeholder: "Excel Yükle",
                    valueKey: "Id"
                }
            }
        ];

        return service;

        /////////////////////////////////////////

        //Product

        function getExcelExportFields() {
            return excelExportFields;
        }

        function getExcelImportFields() {
            return excelImportFields;
        }

        function getExcelFilterFields() {
            return excelFilterFields;
        }

        function getExcelFtpFields() {
            return excelFtpFields;
        }

        function getExcelAddImageFields() {
            return excelAddImageFields;
        }

        function exportFile(excelExport) {
            var deferred = $q.defer();
            console.log("excelExport", excelExport);
            $http.post(serviceBase + 'api/excel/Export', excelExport)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function importFile(file, filedelete) {

            var deferred = $q.defer();
            console.log("vm.file, vm.filedelete", file, filedelete);
            $http.post(serviceBase + 'api/excel/Import/'+ file + "/" + filedelete)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function analysisFile(fileId) {

            var deferred = $q.defer();

            $http.post(serviceBase + 'api/excel/Analysis/' + fileId)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function addImageByFtp(ftp) {
            var deferred = $q.defer();
            console.log("ftp", ftp);
            $http.post(serviceBase + 'api/excel/UploadImageByFtp' , ftp)
                .then(addImageByFtpComplete)
                .catch(addImageByFtpFailed);

            function addImageByFtpComplete(response) {
                deferred.resolve(response.data);
            }

            function addImageByFtpFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function addImageByFileManager() {
            var deferred = $q.defer();

            $http.post(serviceBase + 'api/excel/UploadImageByFileManager')
                .then(addImageByFileManagerComplete)
                .catch(addImageByFileManagerFailed);

            function addImageByFileManagerComplete(response) {
                deferred.resolve(response.data);
            }

            function addImageByFileManagerFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        //Category

        function getExcelCategoryExportFields() {
            return excelCategoryExportFields;
        }

        function getExcelCategoryImportFields() {
            return excelCategoryImportFields;
        }

        function getExcelCategoryFilterFields() {
            return excelCategoryFilterFields;
        }

        function exportCategoryFile(excelCategoryExport) {
            var deferred = $q.defer();
            console.log("excelCategoryExport", excelCategoryExport);
            $http.post(serviceBase + 'api/excel/CategoryExport', excelCategoryExport)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function importCategoryFile(file, filedelete) {

            var deferred = $q.defer();
            console.log("vm.file, vm.filedelete", file, filedelete);
            $http.post(serviceBase + 'api/excel/CategoryImport/' + file + "/" + filedelete)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function analysisCategoryFile(fileId) {

            var deferred = $q.defer();

            $http.post(serviceBase + 'api/excel/CategoryAnalysis/' + fileId)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        //Sales Point

        function getExcelSalesPointExportFields() {
            return excelSalesPointExportFields;
        }

        function getExcelSalesPointImportFields() {
            return excelSalesPointImportFields;
        }

        function getExcelSalesPointFilterFields() {
            return excelSalesPointFilterFields;
        }

        function exportSalesPointFile(excelSalesPointExport) {
            var deferred = $q.defer();
            console.log("excelSalesPointExport", excelSalesPointExport);
            $http.post(serviceBase + 'api/excel/SalesPointExport', excelSalesPointExport)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function importSalesPointFile(file, filedelete) {

            var deferred = $q.defer();
            console.log("vm.file, vm.filedelete", file, filedelete);
            $http.post(serviceBase + 'api/excel/SalesPointImport/' + file + "/" + filedelete)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function analysisSalesPointFile(fileId) {

            var deferred = $q.defer();

            $http.post(serviceBase + 'api/excel/SalesPointAnalysis/' + fileId)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        //Stock

        function getExcelStockExportFields() {
            return excelStockExportFields;
        }

        function getExcelStockImportFields() {
            return excelStockImportFields;
        }

        function getExcelStockFilterFields() {
            return excelStockFilterFields;
        }

        function exportStockFile(excelStockExport) {
            var deferred = $q.defer();
            console.log("excelStockExport", excelStockExport);
            $http.post(serviceBase + 'api/excel/StockExport', excelStockExport)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function importStockFile(file, filedelete) {

            var deferred = $q.defer();
            console.log("vm.file, vm.filedelete", file, filedelete);
            $http.post(serviceBase + 'api/excel/StockImport/' + file + "/" + filedelete)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function analysisStockFile(fileId) {

            var deferred = $q.defer();

            $http.post(serviceBase + 'api/excel/StockAnalysis/' + fileId)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();
