(function () {
    'use strict';

    angular
        .module('app.customers')
        .controller('CustomerOrderListController', Controller);

    Controller.$inject = ['$stateParams', 'OrdersService'];

    function Controller($stateparams, OrdersService) {
        var vm = this;

        vm.customerOrders = [{ Name: "Yükleniyor..." }];
        vm.UserId = $stateparams.UserId;

        activate();

        function activate() {
            OrdersService.getCustomerOrders(vm.UserId)
                .then(function (data) {
                    vm.customerOrders = data;
                });
        }
    }
})();