(function () {
    'use strict';

    angular
        .module('app.orders')
        .controller('ProductsAddController', ProductsAddController);

    ProductsAddController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'ProductsService'];

    function ProductsAddController($scope, $stateParams, $filter, $location, logger, ProductsService) {
        var vm = this;

        activate();

        function activate() {
            vm.newProduct = {};

            logger.showToasts = true;

            vm.productFields = ProductsService.getProductFields();
            vm.newProduct = {};
            vm.newProduct.Categories = [];
        }

        vm.saveProduct = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                vm.newProduct.BaseTypeId = "8db4aba4-2145-48d2-9bdc-1e07db7cfc91";
                vm.newProduct.IsVisibleIndividually = true;
                vm.newProduct.TaxIncluded = true;
                vm.newProduct.DefaultIncrement = 1;
                vm.newProduct.DefaultQuantity = 1;
                vm.newProduct.Categories = vm.newProduct.Categories || [];
                vm.newProduct.ProductCategory = vm.newProduct.MainCategoryId.map(function (item) {
                    return { CategoryId: item.id };
                });
                vm.newProduct.Categories = null;
                vm.newProduct.MainCategoryId = vm.newProduct.MainCategoryId[0].id;
                ProductsService.addProduct(vm.newProduct).then(function (data) {
                    vm.loading = false;
                    $location.path('/products/edit/' + data.Id);
                },
                    function (data) {
                        vm.loading = false;
                    });
                vm.loading = false;
            }
        };

        vm.addSeo = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                ProductsService.addSeo(vm.seo).then(function () {
                    vm.loading = false;
                    $location.path('/products/detail/' + vm.seo.Id);
                });
            }
        };
    }
})();