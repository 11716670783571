 (function () {
    'use strict';

    angular.module('app.n11Integration')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'n11Integration',
                config: {
                    url: '/n11Integration',
                    title: 'N11 Entegrasyonu',
                    abstract: true,
                    templateUrl: 'app/n11Integration/n11Integration.html',
                    settings: {
                        name: 'integration',
                        nav: 200,
                        icon: 'fa fa-cogs',
                        resource: 'menu.integration',
                        content: 'Entegrasyonlar'
                    }
                }
            },
            {
                state: 'n11Integration.addtemplate',
                config: {
                    templateUrl: 'app/n11Integration/n11Integration.addtemplate.html',
                    controller: 'AddTemplateController',
                    controllerAs: 'vm',
                    url: '/addtemplate',
                    title: 'Şablon Ekle',
                    settings: {
                        nav: 10,
                        name: 'n11Integration.addtemplate',
                        content: 'Şablon Ekle',
                        resource: 'menu.n11Integration.addtemplate'
                    }
                }
            },
            {
                state: 'n11Integration.edittemplate',
                config: {
                    templateUrl: 'app/n11Integration/n11Integration.edittemplate.html',
                    controller: 'EditTemplateController',
                    controllerAs: 'vm',
                    url: '/edittemplate/:Id',
                    title: 'Şablon Düzenle',
                    settings: {
                        nav: 10,
                        name: 'n11Integration.edittemplate',
                        content: 'Şablon Düzenle',
                        resource: 'menu.n11Integration.edittemplate'
                    }
                }
            },
            {
                state: 'n11Integration.templatelist',
                config: {
                    templateUrl: 'app/n11Integration/n11Integration.templatelist.html',
                    controller: 'TemplateListController',
                    controllerAs: 'vm',
                    url: '/templatelist',
                    title: 'Şablonlar Listesi',
                    settings: {
                        nav: 10,
                        name: 'n11Integration.templatelist',
                        content: 'Şablonlar Listesi',
                        resource: 'menu.n11Integration.templatelist'
                    }
                }
            },
            {
                state: 'n11Integration.addmatchcategory',
                config: {
                    templateUrl: 'app/n11Integration/n11Integration.addmatchcategory.html',
                    controller: 'AddMatchCategoryController',
                    controllerAs: 'vm',
                    url: '/addmatchcategory',
                    title: 'Kategori Eşleştir',
                    settings: {
                        nav: 10,
                        name: 'n11Integration.addmatchcategory',
                        content: 'Kategori Eşleştir',
                        resource: 'menu.n11Integration.addmatchcategory'
                    }
                }
            },
            {
                state: 'n11Integration.editmatchcategory',
                config: {
                    templateUrl: 'app/n11Integration/n11Integration.editmatchcategory.html',
                    controller: 'EditMatchCategoryController',
                    controllerAs: 'vm',
                    url: '/editmatchcategory/:Id',
                    title: 'Eşleşmiş Kategori Düzenle',
                    settings: {
                        nav: 10,
                        name: 'n11Integration.editmatchcategory',
                        content: 'Eşleşmiş Kategori Düzenle',
                        resource: 'menu.n11Integration.editmatchcategory'
                    }
                }
            },
            {
                state: 'n11Integration.matchcategorylist',
                config: {
                    templateUrl: 'app/n11Integration/n11Integration.matchcategorylist.html',
                    controller: 'MatchCategoryListController',
                    controllerAs: 'vm',
                    url: '/matchcategorylist',
                    title: 'Eşleşmiş Kategori Listesi',
                    settings: {
                        nav: 10,
                        name: 'n11Integration.matchcategorylist',
                        content: 'Eşleşmiş Kategori Listesi',
                        resource: 'menu.n11Integration.matchcategorylist'
                    }
                }
            },
            {
                state: 'n11Integration.editmatchcategoryfilter',
                config: {
                    templateUrl: 'app/n11Integration/n11Integration.editmatchcategoryfilter.html',
                    controller: 'EditMatchCategoryFilterController',
                    controllerAs: 'vm',
                    url: '/editmatchcategoryfilter/:Id',
                    title: 'Eşleşmiş Kategori Filtre Düzenle',
                    settings: {
                        nav: 10,
                        name: 'n11Integration.editmatchcategoryfilter',
                        content: 'Eşleşmiş Kategori Filtre Düzenle',
                        resource: 'menu.n11Integration.editmatchcategoryfilter'
                    }
                }
            },
            {
                state: 'n11Integration.matchcategoryfilterlist',
                config: {
                    templateUrl: 'app/n11Integration/n11Integration.matchcategoryfilterlist.html',
                    controller: 'MatchCategoryFilterListController',
                    controllerAs: 'vm',
                    url: '/matchcategoryfilterlist/:Id',
                    title: 'Eşleşmiş Kategori Filtre Listesi',
                    settings: {
                        nav: 10,
                        name: 'n11Integration.matchcategoryfilterlist',
                        content: 'Eşleşmiş Kategori Filtre Listesi',
                        resource: 'menu.n11Integration.matchcategoryfilterlist'
                    }
                }
            },
            {
                state: 'n11Integration.editmatchcategoryfiltervalue',
                config: {
                    templateUrl: 'app/n11Integration/n11Integration.editmatchcategoryfiltervalue.html',
                    controller: 'EditMatchCategoryFilterValueController',
                    controllerAs: 'vm',
                    url: '/editmatchcategoryfiltervalue/:Id',
                    title: 'Eşleşmiş Kategori Filtre Değeri Düzenle',
                    settings: {
                        nav: 10,
                        name: 'n11Integration.editmatchcategoryfiltervalue',
                        content: 'Eşleşmiş Kategori Filtre Değeri Düzenle',
                        resource: 'menu.n11Integration.editmatchcategoryfiltervalue'
                    }
                }
            },
            {
                state: 'n11Integration.matchcategoryfiltervaluelist',
                config: {
                    templateUrl: 'app/n11Integration/n11Integration.matchcategoryfiltervaluelist.html',
                    controller: 'MatchCategoryFilterValueListController',
                    controllerAs: 'vm',
                    url: '/matchcategoryfiltervaluelist/:Id',
                    title: 'Eşleşmiş Kategori Filtre Değeri Listesi',
                    settings: {
                        nav: 10,
                        name: 'n11Integration.matchcategoryfiltervaluelist',
                        content: 'Eşleşmiş Kategori Filtre Değeri Listesi',
                        resource: 'menu.n11Integration.matchcategoryfiltervaluelist'
                    }
                }
            }
        ];
    }
})();