(function () {
    'use strict';

    angular
        .module('app.customers')
        .factory('CustomersService', CustomersService);

    CustomersService.$inject = ['$http', '$q', 'ngAuthSettings','CustomerGeneralService', 'RolesService'];

    function CustomersService($http, $q, ngAuthSettings, CustomerGeneralService, RolesService) {
        var service = {
            getCustomers: getCustomers,
            getCustomer: getCustomer,
            getCustomerByAddressId: getCustomerByAddressId,
            addCustomer: addCustomer,
            editCustomer: editCustomer,
            deleteCustomer: deleteCustomer,
            getCustomerFields: getCustomerFields,
            getCustomerByOrderId: getCustomerByOrderId
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri;

        var customerFields = [{
                key: "UserType",
                type: "floatRadio",
                "defaultValue": "INDIVIDUAL",
                templateOptions: {
                    label: "Müşteri Tipi",
                    resource: 'form.user-type',
                    options: [{
                            "Name": "Bireysel",
                            "Id": "INDIVIDUAL"
                        },
                        {
                            "Name": "Kurumsal",
                            "Id": "CORPORATE"
                        }
                    ]
                },
                expressionProperties: {
                    'model': function ($viewValue, $modelValue, scope) {
                        if (scope.model.UserType === "INDIVIDUAL") {
                            scope.model.AuthorizedPerson = '';
                            scope.model.TaxOffice = '';
                            scope.model.TaxNumber = '';
                        } else {
                            scope.model.Surname = '';
                            scope.model.TcNo = '';
                            scope.model.GenderId = '';
                            scope.model.BirthDate = '';
                            scope.model.MaritalStatusId = '';
                            scope.model.EducationalStatusId = '';
                            scope.model.JobId = '';
                        }
                    }
                }
            },
            {
                key: 'Code',
                type: 'floatInput',
                templateOptions: {
                    type: 'text',
                    resource: 'form.code',
                    label: 'Müşteri No'
                }
            },
            {
                key: 'Name',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Adı',
                    resource: 'form.name',
                    placeholder: 'Adı'
                }
            },
            {
                key: 'Surname',
                type: 'floatInput',
                hideExpression: 'model.UserType=="CORPORATE"',
                templateOptions: {
                    type: 'text',
                    label: 'Soyadı',
                    resource: 'form.surname',
                    placeholder: 'Soyadı'
                }
            },
            {
                key: 'Email',
                type: 'floatEmail',
                templateOptions: {
                    resource: 'form.email',
                    label: 'Eposta',
                    required: true
                }
            },
            {
                key: 'Password',
                type: 'floatInput',
                templateOptions: {
                    type: 'password',
                    resource: 'form.password',
                    label: 'Şifre'
                }
            },
            {
                key: 'TcNo',
                type: 'floatInput',
                hideExpression: 'model.UserType=="CORPORATE"',
                templateOptions: {
                    type: 'text',
                    label: 'TC No',
                    resource: 'form.customer-code',
                    mask: '99999999999'
                }
            },
            {
                key: "PhoneNumber",
                type: "floatPhone",
                templateOptions: {
                    label: 'Telefon',
                    resource: 'form.phone',
                    required: false
                }
            },
            {
                key: "TickAmount",
                type: "floatInput",
                templateOptions: {
                    resource: "form.tick-amount",
                    label: "Veresiye Bakiye",
                    placeholder: "Veresiye Bakiye ",
                    numberMask: 2
                }
            },
            {
                key: "TickPaymentDay",
                type: "floatInput",
                templateOptions: {
                    resource: "form.tick-payment-day",
                    label: "Veresiye Ödeme Günü",
                    placeholder: "Veresiye Ödeme Günü",
                    type: 'number',
                    min: 0
                }
            },
            {
                key: 'Active',
                "defaultValue": true,
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Aktif',
                    resource: 'form.active',
                    placeholder: 'Aktif'
                }
            },
            {
                key: 'EmailConfirmed',
                "defaultValue": true,
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Email Onayı',
                    resource: 'form.email-confirmed',
                    placeholder: 'Email Onayı'
                }
            },
            {
                key: 'RoleId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Role',
                    resource: 'form.role',
                    options: [{
                        Name: '...'
                    }]
                },
                controller: ['$scope', function ($scope) {
                    RolesService.getAll({
                            IsBase: false
                        })
                        .then(function (response) {
                            $scope.to.options = response.Data;
                        });
                }]
            },
            {
                key: 'TypeId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Kullanıcı Tipi',
                    resource: 'form.user-type',
                    options: [{
                        Name: '...'
                    }]
                },
                controller: ['$scope', function ($scope) {
                    CustomerGeneralService.getUserTypes()
                        .then(function (response) {
                            $scope.to.options = response;
                        });
                }]
            },
            {
                key: 'AuthorizedPerson',
                type: 'floatInput',
                hideExpression: 'model.UserType=="INDIVIDUAL"',
                templateOptions: {
                    type: 'text',
                    label: 'Yetkili Adı',
                    resource: 'form.authorized-person',
                    placeholder: 'Yetkili Adı'
                }
            },
            {
                key: 'NetsimUserCode',
                type: 'floatInput',
                hideExpression: 'model.UserType=="CORPORATE"',
                templateOptions: {
                    type: 'text',
                    label: 'Netsim Cari No',
                    resource: 'form.NetsimUserCode'
                }
            },
            {
                key: 'TaxOffice',
                type: 'floatInput',
                hideExpression: 'model.UserType=="INDIVIDUAL"',
                templateOptions: {
                    type: 'text',
                    label: 'Vergi Dairesi',
                    resource: 'form.tax-office',
                    placeholder: 'Vergi Dairesi'
                }
            },
            {
                key: 'TaxNumber',
                type: 'floatInput',
                hideExpression: 'model.UserType=="INDIVIDUAL"',
                templateOptions: {
                    type: 'number',
                    label: 'Vergi No',
                    resource: 'form.tax-number',
                    placeholder: 'Vergi No'
                }
            },
            {
                key: 'SendEmailAfterRegister',
                "defaultValue": true,
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Üyelik sonrası Email Yolla',
                    resource: 'form.send-email-after-register',
                    placeholder: 'Üyelik sonrası Email Yolla'
                }
            },
        ];

        return service;

        ///////////////////////////////

        function getCustomers(filterParams) {
            var deferred = $q.defer();
            $http.get(serviceBase + 'api/customer/', {
                    params: filterParams
                })
                .then(getCustomerComplete)
                .catch(getCustomerFailed);

            function getCustomerComplete(response) {
                deferred.resolve(response.data);
            }

            function getCustomerFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCustomer(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/customer/' + id)
                .then(getCustomerComplete)
                .catch(getCustomerFailed);

            function getCustomerComplete(response) {
                deferred.resolve(response.data);
            }

            function getCustomerFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCustomerByAddressId(addressid) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/customer/' + addressid)
                .then(getCustomerByAddressIdComplete)
                .catch(getCustomerByAddressIdFailed);

            function getCustomerByAddressIdComplete(response) {
                deferred.resolve(response.data);
            }

            function getCustomerByAddressIdFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCustomerByOrderId(orderid) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/customer/' + orderid)
                .then(getCustomerByOrderIdComplete)
                .catch(getCustomerByOrderIdFailed);

            function getCustomerByOrderIdComplete(response) {
                deferred.resolve(response.data);
            }

            function getCustomerByOrderIdFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function addCustomer(customer) {
            var deferred = $q.defer();

            $http.post(serviceBase + 'api/customer', customer)
                .then(addCustomerComplete)
                .catch(addCustomerFailed);

            function addCustomerComplete(response) {
                deferred.resolve(response.data);
            }

            function addCustomerFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function editCustomer(customer) {
            var deferred = $q.defer();

            $http.put(serviceBase + 'api/customer/' + customer.Id, customer)
                .then(editCustomerComplete)
                .catch(editCustomerFailed);

            function editCustomerComplete(response) {
                deferred.resolve(response.data);
            }

            function editCustomerFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function deleteCustomer(customer) {
            var deferred = $q.defer();

            $http.delete(serviceBase + 'api/customer/' + customer.Id, customer)
                .then(deleteCustomerComplete)
                .catch(deleteCustomerFailed);

            function deleteCustomerComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteCustomerFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCustomerFields() {
            return customerFields;
        }
    }
})();