(function () {
    'use strict';

    angular
        .module('app.getProducts')
        .controller('SupplyListController', SupplyListController);

    SupplyListController.$inject = ['$location', 'GetProductsService', 'NgTableParams', '$uibModal'];

    function SupplyListController($location, GetProductsService, NgTableParams, $uibModal) {
       
        var vm = this;
        vm.settings = globalSettings;
        activate();
        function activate() {
            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 10 }, $location.search()),
                {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {};
                        filterData.PageIndex = params.page(),
                            filterData.PageSize = params.count(),
                            filterData.SortProperty = Object.keys(params.sorting())[0],
                            filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }

                        return GetProductsService.GetSupplyService(filterData).then(function (data) {
                            params.total(data.FilteredCount);
                            return data.Data;
                        });

                    }
                });

        }

        vm.UpdateService = function (data) {
            GetProductsService.GetXmlProductUpdate(data).then(function (response) {
                vm.openModal(response);
            });

        }

        vm.delete = function (row) {

            GetProductsService.deleteXmlSupply(row.Id).then(function (response) {

                vm.openModal(response);

            });

        }
    



        // Modal

        vm.modalTitle = "Tedarikçi Ürün Güncelleme";
        vm.modalInstance = null;
        vm.openModal = function (data) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'ModalContent.html',
                controller: 'ModalController',
                controllerAs: 'vm',
                resolve: {
                    items: function () {
                        return {
                            data: data
                        };
                    }
                }
            });
        }


    }
    angular
        .module('app.getProducts')
        .controller('ModalController', ModalController);

    ModalController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'GetProductsService', '$uibModal', '$uibModalStack', '$uibModalInstance', 'items'];

    function ModalController($scope, $stateParams, $filter, $location, logger, GetProductsService, $uibModal, $uibModalStack, $uibModalInstance, items) {

        var vm = this;
        vm.content = items;
        activate();
        function activate() {
            vm.data = vm.content.data;
        }
        vm.XmlRangeUpdate = function (data) {

            GetProductsService.RangeUpdate(data).then(function (response) {
                if (response.length >= 1) {

                    var message = response[response.length - 1].ProductCount + " Adet Ürün Güncellendi.";

                    toastr.success(message, "Güncelleme");
                } else {
                    var message = "0 Adet Ürün Güncellendi.";

                    toastr.success(message, "Güncelleme");
                }
            });

        }

    }
})();