(function () {
    'use strict';

    angular
        .module('app.productBrand')
        .controller('ProductBrandListController', ProductBrandListController);

    ProductBrandListController.$inject = ['Settings', '$location', 'ProductBrandService', 'NgTableParams', '$uibModal'];

    function ProductBrandListController(Settings, $location, ProductBrandService, NgTableParams, $uibModal) {
        var vm = this;
        vm.settings = Settings;

        activate();

        function activate() {
            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({
                    page: 1,
                    count: 10
                }, $location.search()), {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {};
                        filterData.PageIndex = params.page(),
                            filterData.PageSize = params.count(),
                            filterData.SortProperty = Object.keys(params.sorting())[0],
                            filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return ProductBrandService.getAll(filterData).then(function (data) {
                            params.total(data.FilteredCount);
                            return data.Data;
                        });
                    }
                });
        }

        vm.cancel = cancel;
        vm.del = del;
        vm.save = save;
        vm.add = add;

        ///////////////////////////////////

        function cancel(row, rowForm) {
            vm.tableParams.reload();
        }

        function del(row) {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                ProductBrandService.del(row).then(function (data) {
                    vm.tableParams.reload();
                });
            }
        }

        function save(row, rowForm) {
            row.Media = null;
            if (row.Id) {
                ProductBrandService.edit(row).then(function (data) {
                    vm.tableParams.reload();
                });
            } else {
                ProductBrandService.add(row).then(function (data) {
                    vm.tableParams.reload();
                });
            }
        }

        function add() {
            vm.tableParams.data.unshift({
                Name: "",
                isEditing: true
            });
        }

        ////////////////////

        var $uibModalInstance;
        vm.selectFile = function (row) {
            $uibModalInstance = $uibModal.open({
                windowClass: "filemanager-modal",
                template: '<div class="modal-body" id="modal-body-{{name}}">' +
                    "<angular-filemanager></angular-filemanager>" +
                    "</div>"
            });

            function pickItem(evt) {
                var item = evt.detail.item;
                if (!Array.isArray(item)) {
                    var name = item.path.join("/") + "/" + item.name;
                    row.Media = {};
                    row.Media.FullPath = name;
                    row.MediaId = item.content;
                }
                $uibModalInstance.dismiss();
                window.removeEventListener("onPickItem", pickItem);
            }
            window.addEventListener("onPickItem", pickItem, false);
        };

        vm.cancelImage = function (row) {
            row.MediaId = null;
            row.Media = null;
        };

        vm.getProperty = function (object, propertyName) {
            if (!object) {
                return "";
            }
            if (!propertyName) {
                return "";
            }
            var parts = propertyName.split("."),
                length = parts.length,
                i,
                property = object || this;
            for (i = 0; i < length; i++) {
                if (property) {
                    property = property[parts[i]];
                }
            }
            return property;
        };
    }
})();