(function () {
    'use strict';

    angular
        .module('app.orders')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'orders',
                config: {
                    url: '/orders',
                    title: 'Siparişler',
                    abstract: true,
                    templateUrl: 'app/sales/orders.html',
                    settings: {
                        nav: 5,
                        name: 'sales',
                        icon: 'fa fa-cart-plus',
                        content: 'Satış',
                        resource: 'menu.orders'
                    }
                }
            },
            {
                state: 'orders.list',
                config: {
                    templateUrl: 'app/sales/orders.list.html',
                    controller: 'OrdersListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Sipariş Listesi',
                    settings: {
                        nav: 5,
                        name: 'orders.list',
                        topName: 'sales',
                        content: 'Sipariş Listesi',
                        resource: 'menu.orders.list'
                    }
                }
            },
            {
                state: 'orders.payments',
                config: {
                    templateUrl: 'app/sales/payments.list.html',
                    controller: 'PaymentsListController',
                    controllerAs: 'vm',
                    url: '/payments',
                    title: 'Ödeme Listesi',
                    settings: {
                        nav: 5,
                        name: 'payments.list',
                        topName: 'sales',
                        content: 'Ödeme Listesi',
                        resource: 'menu.payments.list'
                    }
                }
            },
            {
                state: 'orders.product-list',
                config: {
                    templateUrl: 'app/sales/orders.product-list.html',
                    controller: 'OrderProductListController',
                    controllerAs: 'vm',
                    url: '/product-list',
                    title: 'Sipariş Kalemleri Listesi',
                    settings: {
                        nav: 5,
                        name: 'orders.product-list',
                        topName: 'sales',
                        content: 'Sipariş Kalemleri Listesi',
                        resource: 'menu.orders.product-list'
                    }
                }
            },
            {
                state: 'orders.directs',
                config: {
                    templateUrl: 'app/sales/orders.directs.html',
                    controller: 'DirectsListController',
                    controllerAs: 'vm',
                    url: '/directs',
                    title: 'Direkt Ödeme Listesi',
                    settings: {
                        nav: 5,
                        name: 'orders.directs',
                        topName: 'sales',
                        content: 'Direkt Ödeme Listesi',
                        resource: 'menu.orders.directs'
                    }
                }
            },
            {
                state: 'orders.debts',
                config: {
                    templateUrl: 'app/sales/orders.debts.html',
                    controller: 'DebtsListController',
                    controllerAs: 'vm',
                    url: '/debts',
                    title: 'Borç Listesi',
                    settings: {
                        nav: 5,
                        name: 'orders.debts',
                        topName: 'sales',
                        content: 'Borç Listesi',
                        resource: 'menu.orders.debts'
                    }
                }
            },
            {
                state: 'orders.deliveries',
                config: {
                    templateUrl: 'app/sales/orders.deliveries.html',
                    controller: 'DeliveriesController',
                    controllerAs: 'vm',
                    url: '/deliveries',
                    title: 'Teslimat Listesi',
                    settings: {
                        nav: 5,
                        name: 'orders.deliveries',
                        topName: 'sales',
                        content: 'Teslimat Listesi',
                        resource: 'menu.orders.deliveries'
                    }
                }
            },
            {
                state: 'orders.add',
                config: {
                    templateUrl: 'app/sales/orders.add.html',
                    controller: 'OrdersAddController',
                    controllerAs: 'vm',
                    url: '/add/:UserId',
                    title: 'Sipariş Ekle',
                    settings: {
                        topName: 'orders.list',
                        resource: 'menu.orders.add'
                    }
                }
            },
            {
                state: 'orders.detail',
                config: {
                    templateUrl: 'app/sales/orders.detail.html',
                    controller: 'OrdersDetailController',
                    controllerAs: 'vm',
                    url: '/:OrderId',
                    title: 'Sipariş Detayı',
                    settings: {
                        topName: 'orders.list',
                        resource: 'menu.orders.detail'
                    }
                }
            },
            {
                state: 'orders.edit',
                config: {
                    templateUrl: 'app/sales/orders.edit.html',
                    controller: 'OrdersEditController',
                    controllerAs: 'vm',
                    url: '/edit/:OrderId',
                    title: 'Sipariş Düzenle',
                    settings: {
                        topName: 'orders.list',
                        resource: 'menu.orders.edit'
                    }
                }
            }

        ];
    }
})();