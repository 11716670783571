(function () {
    'use strict';

    angular
        .module('app.filter')
        .filter('priceformat', priceformat);

    function priceformat() {
        return priceformatFilter;

        function priceformatFilter(input, format) {
            input = input || 0;
            format = format || '<ins>{0}<span>,{1}</span> ₺</ins>';
            var inputString = format.toString();
            var priceInt = (input.toFixed(2) + '').split('.')[0];
            var priceDecimal = ((input.toFixed(2) + '').split('.')[1]) || '00';
            inputString = inputString.replace("{0}", priceInt);
            inputString = inputString.replace("{1}", priceDecimal);
            return '<span class="money">' + inputString + '</span>';
        }
    }
})();

