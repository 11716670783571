(function () {
    'use strict';

    angular
        .module('app.galleries')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'galleries',
                config: {
                    url: '/galleries',
                    title: 'Galeriler',
                    abstract: true,
                    templateUrl: 'app/galleries/galleries.html'
                }
            },
            {
                state: 'galleries.list',
                config: {
                    templateUrl: 'app/galleries/galleries.list.html',
                    controller: 'GalleriesListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Galeri Listesi',
                    settings: {
                        nav: 4,
                        name: 'galleries.list',
                        topName: 'products',
                        content: 'Galeri Listesi',
                        resource: 'menu.galleries.list'
                    }
                }
            },
            {
                state: 'galleries.detail',
                config: {
                    templateUrl: 'app/galleries/galleries.detail.html',
                    controller: 'GalleriesDetailController',
                    controllerAs: 'vm',
                    url: '/detail/:GalleryId',
                    title: 'Galeri Detay',
                    //settings: {
                    //    name: 'galleries.detail',
                    //    topName: 'galleries.list',
                    //    content: 'Galeri Detay',
                    //    resource: 'menu.galleries.detail'
                    //}
                }
            }
        ];
    }
})();