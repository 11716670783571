(function () {
    'use strict';

    angular
      .module('app.deliveries')
      .controller('DeliveriesDeleteController', DeliveriesDeleteController);

    DeliveriesDeleteController.$iinject=['$scope', '$stateParams', 'DeliveriesService', 'uiGridConstants', 'uiGridGroupingConstants', 'FileUploader', '$location', '$anchorScroll'];

    function DeliveriesDeleteController($scope, $stateParams, DeliveriesService, uiGridConstants, uiGridGroupingConstants, FileUploader, $location, $anchorScroll) {
        var vm = this;

        vm.title = 'Teslimat Ekle';

        vm.deleteDelivery = {
            Code: 'TS478562',
            DeliveryStatus: 'Hazırlanıyor'
        };

        vm.deliveryFields = DeliveriesService.getDeliveryFields();

        vm.deliveryProduct = [];

        var uploader = vm.uploader = new FileUploader({
            url: 'upload.php'
        });

        uploader.filters.push({
            name: 'imageFilter',
            fn: function (item /*{File|FileLikeObject}*/, options) {
                var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
            }
        });

        uploader.onWhenAddingFileFailed = function (item /*{File|FileLikeObject}*/, filter, options) {
            console.info('onWhenAddingFileFailed', item, filter, options);
        };
        uploader.onAfterAddingFile = function (fileItem) {
            console.info('onAfterAddingFile', fileItem);
        };
        uploader.onAfterAddingAll = function (addedFileItems) {
            console.info('onAfterAddingAll', addedFileItems);
        };
        uploader.onBeforeUploadItem = function (item) {
            console.info('onBeforeUploadItem', item);
        };
        uploader.onProgressItem = function (fileItem, progress) {
            console.info('onProgressItem', fileItem, progress);
        };
        uploader.onProgressAll = function (progress) {
            console.info('onProgressAll', progress);
        };
        uploader.onSuccessItem = function (fileItem, response, status, headers) {
            console.info('onSuccessItem', fileItem, response, status, headers);
        };
        uploader.onErrorItem = function (fileItem, response, status, headers) {
            console.info('onErrorItem', fileItem, response, status, headers);
        };
        uploader.onCancelItem = function (fileItem, response, status, headers) {
            console.info('onCancelItem', fileItem, response, status, headers);
        };
        uploader.onCompleteItem = function (fileItem, response, status, headers) {
            console.info('onCompleteItem', fileItem, response, status, headers);
        };
        uploader.onCompleteAll = function () {
            console.info('onCompleteAll');
        };

        vm.mapoptions = {
            map: {
                center: new google.maps.LatLng(38.7133632, 35.5326646),
                zoom: 17,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            },
            marker: function (house) {
                return {
                    clickable: false,
                    draggable: true,
                    title: house.name
                }
            }
        };

        vm.setHouseLocation = function (house, marker) {
            var position = marker.getPosition();
            house.lat = position.lat();
            house.lng = position.lng();
        };

        vm.houses = [
            {
                id: 0,
                name: 'House A',
                lat: 38.7133632,
                lng: 35.5326646
            }
        ];        


        //vm.gridDeliveryGroupsOptions = {
        //    data: vm.deliveryProduct,
        //    rowHeight: 39,
        //    enableVerticalScrollbar: 2,
        //    enableHorizontalScrollbar: 2,
        //    enableGrouping: true,
        //    onRegisterApi: function (gridApi) {
        //        vm.gridDeliveryGroupsApi = gridApi;
        //        vm.gridDeliveryGroupsApi.grid.registerDataChangeCallback(function () {
        //            vm.gridDeliveryGroupsApi.treeBase.expandAllRows();
        //        });
        //    },
        //    columnDefs: [
        //       {
        //           name: 'GrupKod', displayName: 'Grup Kodu',
        //           grouping: {
        //               groupPriority: 0,
        //           },
        //           sort: {
        //               priority: 0, direction: 'asc'
        //           },
        //       },
        //       { name: 'OrderCode', displayName: 'Sipariş Kodu' },
        //       { name: 'Name', displayName: 'Ürün Adı' },
        //       { name: 'Quantity', displayName: 'Adet' },
        //       {
        //           name: 'DeliveryQuantity', displayName: 'Teslimat Adet',
        //           cellTemplate: '<input style="padding:10px!important;" ng-disabled="true" type="number" min="1" max="{{row.entity.Quantity}}" name="input" ng-model="row.entity.DeliveryQuantity">'
        //       }
                
        //    ]         
        //}

        vm.getTableHeight = function () {
            var rowHeight = 65; // your row height 
            var headerHeight = 39; // your header height
            console.log("------------------");
            console.log((vm.gridProductsOptions.data.length + 4) * rowHeight + headerHeight);
            return {
                height: (vm.gridProductsOptions.data.length * rowHeight + headerHeight) + "px"
            };
        };

        activate();

        function activate() {
        }

        DeliveriesService.getDelivery($stateParams.id).then(function (data) {
            vm.customer = data.Customer;
            vm.deleteDelivery = data;
            vm.loading = false;
        });

        vm.removeDelivery = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                vm.loading = true;
                vm.deleteDelivery.Products = vm.deliveryProduct.map(function (select) {
                    return {
                        ProductId: select.Id,
                        DeliveryQuantity: select.DeliveryQuantity,
                        Code: select.Code,
                        Name: select.Name
                    }
                });
                DeliveriesService.removeDelivery(vm.deleteDelivery).then(function () {
                    vm.loading = false;
                    $location.path('/deliveries/list');
                });
            }
            }
            vm.Cancel = function () {
                $location.path('/deliveries/list');
            };

       
    }
})();