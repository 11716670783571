(function () {
    'use strict';

    angular
        .module('app.trendyolIntegration')
        .controller('TrendyolEditMatchCategoryController', TrendyolEditMatchCategoryController);

    TrendyolEditMatchCategoryController.$inject = ['$scope', '$stateParams', '$location', 'TrendyolIntegrationService', 'NgTableParams'];

    function TrendyolEditMatchCategoryController($scope, $stateParams, $location, TrendyolIntegrationService, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;
        vm.settings = globalSettings;
        activate();

        function activate() {
            vm.fields = TrendyolIntegrationService.getMatchCategoryFields();
            TrendyolIntegrationService.getMatchCategory($stateParams.Id)
                .then(function (data) {
                    vm.entity = data;
                    vm.entity.refresh = true;
                    vm.loading = false;
                });
        }

        vm.editMatchCategory = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                TrendyolIntegrationService.editMatchCategory(vm.entity).then(function () {
                    vm.loading = false;
                    activate();
                });
            }
        };

    }
})();