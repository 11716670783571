(function () {
    'use strict';

    angular
        .module('app.production')
        .factory('ProductGroupService', Service);

    Service.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config', 'Settings', 'ProductsService', 'ProductsGeneralService'];

    function Service($http, $q, ngAuthSettings, logger, config, Settings, ProductsService, ProductsGeneralService) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            getFields: getFields
        };
        var baseUrl = ngAuthSettings.apiServiceBaseUri;
        var serviceBaseUrl = baseUrl + 'api/ProductGroups/';

        var fields = [{
                key: 'SubProductId',
                type: 'async-ui-select',
                templateOptions: {
                    required: true,
                    label: 'Ürün',
                    resource: 'form.product',
                    options: [],
                    placeholder: '...',
                    valueProp: 'Id',
                    labelProp: 'Name',
                    refresh: function (text, options) {
                        var params = {
                            Name: text,
                            PageSize: 10,
                            PageIndex: 1,
                            SortProperty: 'Name',
                            SortType: 'asc'
                        };
                        return ProductsService.getProducts(params)
                            .then(function (response) {
                                if (response.Data.length > 0) {
                                    options.templateOptions.options = response.Data;
                                } else {
                                    options.templateOptions.options = [{
                                        Id: '',
                                        Name: 'Bulunamadı (' + text + ')'
                                    }];
                                }
                            });
                    },
                    refreshDelay: 2
                }
            },
            {
                key: 'DefaultGroupQuantity',
                type: 'horizontalInput',
                templateOptions: {
                    type: 'number',
                    resource: 'form.default-group-quantity',
                    label: 'Varsayılan Grup Adedi',
                    placeholder: 'Varsayılan Grup Adedi'
                }
            }
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBaseUrl, {
                    params: filterParams
                })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(entity) {
            var deferred = $q.defer();

            $http.get(serviceBaseUrl + entity.Id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();

            $http.post(serviceBaseUrl, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http.put(serviceBaseUrl + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(entity) {
            var deferred = $q.defer();

            $http.delete(serviceBaseUrl + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();