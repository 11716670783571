(function () {
    'use strict';

    angular.module('app.simplemailing')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'simplemailing',
                config: {
                    url: '/simplemailing',
                    title: 'Basit Mailing',
                    abstract: true,
                    templateUrl: 'app/mailing/simplemailing.html'
                }
            },
            {
                state: 'simplemailing.send',
                config: {
                    templateUrl: 'app/mailing/simplemailing.send.html',
                    controller: 'SendSimplemailing',
                    controllerAs: 'vm',
                    url: '/send',
                    title: 'Basit Mailing',
                    settings: {
                        nav: 10,
                        name: 'simplemailing.send',
                        topName: 'sales',
                        content: 'Basit Mailing',
                        resource: 'menu.simplemailing.send'
                    }
                }
            }
        ];
    }
})();