(function () {
    'use strict';

    angular
        .module('app.orders')
        .controller('ProductsEditController', ProductsEditController);

    ProductsEditController.$inject = [
        '$scope',
        '$stateParams',
        'RelatedProductService',
        '$location',
        'Settings',
        'ProductsService',
        'ProductMediasService',
        'DiscountsService',
        'ProductGroupService',
        'ProductOptionValuesService',
        'TierPricesService',
        'StockQuantityHistoryService',
        'ProductTagsService',
        'StockItemsService',
        'NgTableParams',
        'TrendyolIntegrationService',
        '$uibModal'
    ];

    function ProductsEditController(
        $scope,
        $stateParams,
        RelatedProductService,
        $location,
        Settings,
        ProductsService,
        ProductMediasService,
        DiscountsService,
        ProductGroupService,
        ProductOptionValuesService,
        TierPricesService,
        StockQuantityHistoryService,
        ProductTagsService,
        StockItemsService,
        NgTableParams,
        TrendyolIntegrationService,
        $uibModal) {
        var vm = this;
        activate();

        function activate() {

            vm.Settings = Settings;
            vm.loading = true;
            vm.entity = {
                Categories: []
            };

            vm.tableProductAttributeParams = new NgTableParams({
                page: 1,
                count: 10
            }, {
                getData: function (params) {
                    if (!vm.firstLoad) {
                        $location.search(params.url());
                    }
                    vm.firstLoad = false;
                    var filterData2 = params.filter();
                    var filterData = {};
                    filterData.PageIndex = params.page();
                    filterData.PageSize = params.count();
                    filterData.SortProperty = Object.keys(params.sorting())[0];
                    filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                    var keys = Object.keys(filterData2);
                    for (var i = 0; i < keys.length; i++) {
                        filterData[keys1[i]] = filterData2[keys[i]];
                    }
                    return ProductOptionValuesService
                        .getAll($stateParams.ProductId, true)
                        .then(function (data) {
                            return data;
                        });
                },
                counts: []
            });

            vm.tableStockQuantityHistoryParams = new NgTableParams({
                page: 1,
                count: 10
            }, {
                getData: function (params) {
                    if (!vm.firstLoad) {
                        $location.search(params.url());
                    }
                    vm.firstLoad = false;
                    var filterData2 = params.filter();
                    var filterData = {};
                    filterData.PageIndex = params.page(),
                        filterData.PageSize = params.count(),
                        filterData.SortProperty = 'InsertDate'; //Object.keys(params.sorting())[0],
                    filterData.SortType = 'desc'; // params.sorting()[Object.keys(params.sorting())[0]];
                    var keys = Object.keys(filterData2);
                    for (var i = 0; i < keys.length; i++) {
                        filterData[keys[i]] = filterData2[keys[i]];
                    }
                    filterData.ProductId = $stateParams.ProductId;
                    return StockQuantityHistoryService.getAll(filterData).then(function (data) {
                        params.total(data.FilteredCount);
                        return data.Data;
                    });
                },
                counts: []
            });

            vm.tableProductOptionValueParams = new NgTableParams({
                page: 1,
                count: 10,
                group: "Option.Name"
            }, {
                getData: function (params) {
                    if (!vm.firstLoad) {
                        $location.search(params.url());
                    }
                    vm.firstLoad = false;
                    var filterData2 = params.filter();
                    var filterData = {};
                    filterData.PageIndex = params.page();
                    filterData.PageSize = params.count();
                    filterData.SortProperty = Object.keys(params.sorting())[0];
                    filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                    var keys = Object.keys(filterData2);
                    for (var i = 0; i < keys.length; i++) {
                        filterData[keys[i]] = filterData2[keys[i]];
                    }
                    return ProductOptionValuesService.getAll($stateParams.ProductId, false)
                        .then(function (data) {
                            vm.entity.ProductOptionValue = data;
                            vm.entity.GroupedProductOptionValue = vm.groupBy(data, 'OptionId');
                            return data;
                        });
                },
                counts: []
            });

            vm.tableRelatedProductParams = new NgTableParams({
                page: 1,
                count: 10,
                group: "RelatedProductType.Name"
            }, {
                getData: function (params) {
                    if (!vm.firstLoad) {
                        $location.search(params.url());
                    }
                    vm.firstLoad = false;
                    var filterData2 = params.filter();
                    var filterData = {};
                    filterData.ProductId = $stateParams.ProductId;
                    filterData.PageIndex = params.page();
                    filterData.PageSize = -1;
                    filterData.SortProperty = Object.keys(params.sorting())[0];
                    filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                    var keys = Object.keys(filterData2);
                    for (var i = 0; i < keys.length; i++) {
                        filterData[keys[i]] = filterData2[keys[i]];
                    }
                    return RelatedProductService.getAll(filterData)
                        .then(function (data) {
                            //vm.entity.RelatedProductValue = data;
                            //vm.entity.GroupedRelatedProductValue = vm.groupBy(data, 'RelatedProductTypeId');
                            return data.Data;
                        });
                },
                counts: []
            });

            vm.n11Fields = ProductsService.getN11Fields();
            vm.ggFields = ProductsService.getGGFields();
            vm.hbFields = ProductsService.getHBFields();
            vm.trFields = ProductsService.getTRFields();

            ProductsService.getProduct($stateParams.ProductId)
                .then(function (data) {
                    vm.entity = data;
                    vm.entity.ProductCategory = vm.entity.ProductCategory || [];
                    vm.entity.Categories = vm.entity.ProductCategory.map(function (item) {
                        return item.CategoryId;
                    });
                    vm.entity.ProductShipmentProvider = vm.entity.ProductShipmentProvider || [];
                    vm.entity.ShipmentProviders = vm.entity.ProductShipmentProvider.map(function (item) {
                        return item.ShipmentProvider.Id;
                    });
                    vm.entity.ProductRoles = vm.entity.ProductRoles || [];
                    vm.entity.Roles = vm.entity.ProductRoles.map(function (item) {
                        return item.Role.Id;
                    });

                    vm.entity.ProductListProduct = vm.entity.ProductListProduct || [];
                    vm.entity.ProductLists = vm.entity.ProductListProduct.map(function (item) {
                        return item.ProductList.Id;
                    });

                    vm.entity.refresh = true;
                    vm.getProductGroups(vm.entity);
                    vm.getAllVariant();
                    vm.getProductMedia();
                    vm.getProductDiscount();
                    vm.getTags(data);
                    vm.getMarketData(data);

                    ProductsService.getProducts({
                            pageSize: -1,
                            Code: vm.entity.Code
                        })
                        .then(function (data) {
                            vm.otherCultureProduct = data.Data;
                        });

                    vm.loading = false;
                });

            vm.fields = ProductsService.getProductFields();

            vm.otherFields = ProductsService.getProductOtherFields();

            vm.seofields = ProductsService.getSeoFields();

            vm.attrfields = ProductsService.getAttrFields();

            vm.productMediaFields = ProductMediasService.getFields();


        }
        vm.marketOpen = function () {

            var categoryCount = vm.entity.Category.length;
            if (vm.entity.Categories[0].id != undefined) {
                ProductsService.getMatchCategory(vm.entity.Categories[categoryCount - 1].id).then(function (data) {
                    if (data = "false") {

                    }
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].marketPlaceName == "N11") {
                            vm.entityn11.n11Commision = data[i].Commision;

                        } else if (data[i].marketPlaceName == "GG") {
                            vm.entitygg.ggCommision = data[i].Commision;
                        } else if (data[i].marketPlaceName == "HB") {
                            vm.entityhb.hbCommision = data[i].Commision;
                        } else if (data[i].marketPlaceName == "TR") {
                            vm.entitytr.trCommision = data[i].Commision;
                        }
                    }
                });

            }

            vm.entityn11.n11Cost = vm.entity.Cost;
            vm.entitygg.ggCost = vm.entity.Cost;
            vm.entityhb.hbCost = vm.entity.Cost;
            vm.entitytr.trCost = vm.entity.Cost;
        }

        vm.getMarketData = function (entity) {
            vm.entityn11.N11Transfer = entity.N11Transfer;
            vm.entityn11.N11Description = entity.N11Description;
            vm.entityn11.N11Price = entity.N11Price;
            if (vm.entity.N11TransferResult == null) {
                vm.n11AlertType = "alert-danger";
                vm.n11Message = "Ürün N11'e Aktarılmamıştır.";
            }


            vm.entitygg.GGTransfer = entity.GGTransfer;
            vm.entitygg.GGDescription = entity.GGDescription;
            vm.entitygg.GGPrice = entity.GGPrice;
            if (vm.entity.GGtransferResult == null) {
                vm.ggAlertType = "alert-danger";
                vm.ggMessage = "Ürün GittiGidiyor'a Aktarılmamıştır.";
            }

            vm.entityhb.HBTransfer = entity.HBTransfer;
            vm.entityhb.HBDescription = entity.HBDescription;
            vm.entityhb.HBPrice = entity.HBPrice;
            vm.entityhb.HBSKU = entity.HBSKU;
            if (vm.entity.HepsiburadaTrackingId == null) {
                vm.hbAlertType = "alert-danger";
                vm.hbMessage = "Ürün HepsiBurada'ya Aktarılmamıştır.";
            }


            vm.entitytr.TrendyolTransfer = entity.TrendyolTransfer;
            vm.entitytr.TRDescription = entity.TRDescription;
            vm.entitytr.TrendyolPrice = entity.TrendyolPrice;
            if (vm.entity.TrendyolBatchId == null) {
                vm.trAlertType = "alert-danger";
                vm.trMessage = "Ürün Trendyol'1 Aktarılmamıştır.";
            } else if (vm.entity.TrendyolBatchId == "Başarılı") {
                vm.trAlertType = "alert-success";
                vm.trMessage = "Ürün Trendyol'a Aktarılmıştır.";
            } else if (vm.entity.TrendyolBatchId != null && vm.entity.TrendyolBatchId != "Başarılı") {
                vm.trAlertType = "alert-warning";
                vm.TrendyolQuery = true;
                vm.trMessage = "Ürün Trendyol'a Aktarılmıştır. İşlem Sonucunu Kontrol Ediniz.";
            }
        }


        vm.marketQuery = function (entity, market) {
            if (market == "trendyol") {
                TrendyolIntegrationService.ProductQuery(entity).then(function (data) {
                    vm.loading = false;
                    vm.openModal(data);
                }, function () {
                    vm.loading = false;
                    alert("Bir hata oluştu. Daha sonra tekrar deneyin.");

                });
            }
        }
        vm.marketSave = function (entity, market) {

            if (market == "trendyol") {
                vm.entity.TrendyolPrice = entity.TrendyolPrice;
                vm.entity.TRDescription = entity.TRDescription;
                vm.entity.TrendyolTransfer = entity.TrendyolTransfer;
                ProductsService.editProduct(vm.entity).then(function () {
                    vm.loading = false;
                    toastr.info("Ürün Başarıyla Güncellendi!", "Mazaka Eticaret");
                    activate();
                }, function () {
                    vm.loading = false;
                    alert("Bir hata oluştu. Daha sonra tekrar deneyin.");
                    activate();
                });
            }

            else if (market == "HB") {
                vm.entity.HBPrice = entity.HBPrice;
                vm.entity.HBDescription = entity.HBDescription;
                vm.entity.HBTransfer = entity.HBTransfer;
                ProductsService.editProduct(vm.entity).then(function () {
                    vm.loading = false;
                    toastr.info("Ürün Başarıyla Güncellendi!", "Mazaka Eticaret");
                    activate();
                }, function () {
                    vm.loading = false;
                    alert("Bir hata oluştu. Daha sonra tekrar deneyin.");
                    activate();
                });
            }

        }


        vm.marketSend = function (entity) {


            if (entity.TrendyolTransfer != null) {
                vm.entity.TrendyolPrice = entity.TrendyolPrice;
                vm.entity.TRDescription = entity.TRDescription;
                vm.entity.TrendyolTransfer = entity.TrendyolTransfer;
                ProductsService.editProduct(vm.entity).then(function () {
                    vm.loading = false;
                    TrendyolIntegrationService.ProductSender(vm.entity).then(function (data) {
                        vm.loading = false;
                        vm.openModal(data);
                    }, function () {
                        vm.loading = false;
                        alert("Bir hata oluştu. Daha sonra tekrar deneyin.");

                    });
                    activate();
                }, function () {
                    vm.loading = false;
                    alert("Bir hata oluştu. Daha sonra tekrar deneyin.");
                    activate();
                });

            }

        }

      

        vm.edit = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                if (vm.entity.Categories) {
                    vm.entity.ProductCategory = vm.entity.Categories.map(function (item) {
                        if (item.id) {
                            return {
                                CategoryId: item.id
                            };
                        } else {
                            return {
                                CategoryId: item
                            };
                        }
                    });
                    vm.entity.Categories = null;
                    vm.entity.ProductShipmentProvider = vm.entity.ShipmentProviders.map(function (item) {
                        if (item.id) {
                            return {
                                ShipmentProviderId: item.id
                            };
                        } else {
                            return {
                                ShipmentProviderId: item
                            };
                        }
                    });
                    vm.entity.ProductRoles = vm.entity.Roles.map(function (item) {
                        if (item.id) {
                            return {
                                RoleId: item.id
                            };
                        } else {
                            return {
                                RoleId: item
                            };
                        }
                    });
                    vm.entity.Roles = null;
                    vm.entity.ProductListProduct = vm.entity.ProductLists.map(function (item) {
                        if (item.id) {
                            return {
                                ProductListId: item.id
                            };
                        } else {
                            return {
                                ProductListId: item
                            };
                        }
                    });
                    vm.entity.ProductLists = null;
                    vm.entity.ShipmentProviders = null;
                    vm.entity.ProductMedia = null;
                    vm.entity.MainCategoryId = vm.entity.MainCategoryId[0].id;
                    ProductsService.editProduct(vm.entity).then(function () {
                        vm.loading = false;
                        activate();
                    }, function () {
                        vm.loading = false;
                        alert("Bir hata oluştu. Daha sonra tekrar deneyin.");
                        activate();
                    });
                } else {
                    vm.loading = false;
                    alert("Kaydetmeden önce en az bir kategori seçmelisiniz.");
                }
            }
        };

        vm.delete = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                ProductsService.deleteProduct(vm.entity).then(function (data) {
                    $location.path('/products/list');
                });
            }
        };

        vm.getProductMedia = function () {
            ProductMediasService.getAll(vm.entity.Id)
                .then(function (data) {
                    vm.productMedia = data;
                });
        };

        vm.editMedia = function (productMedia) {
            vm.productMediaEntity = productMedia;
        };

        vm.addMedia = function () {
            vm.productMediaEntity = {
                ProductId: vm.entity.Id
            };
        };

        vm.productMediaSubmit = function () {
            if (vm.productMediaForm.$valid) {
                if (Array.isArray(vm.productMediaEntity.MediaList)) {
                    var orjinalEntity = angular.copy(vm.productMediaEntity);
                    for (var media of orjinalEntity.MediaList) {
                        var entity = angular.copy(orjinalEntity);
                        entity.MediaList = null;
                        entity.MediaId = media.id;
                        ProductMediasService.add(entity)
                            .then(function () {
                                vm.loading = false;
                                vm.productMediaEntity = null;
                                vm.getProductMedia();
                            });
                    }
                } else if (vm.productMediaEntity.Id) {
                    ProductMediasService.edit(vm.productMediaEntity)
                        .then(function () {
                            vm.loading = false;
                            vm.productMediaEntity = null;
                            vm.getProductMedia();
                        });
                } else {
                    ProductMediasService.add(vm.productMediaEntity)
                        .then(function () {
                            vm.loading = false;
                            vm.productMediaEntity = null;
                            vm.getProductMedia();
                        });
                }
            }
        };

        vm.productMediaCancel = function () {
            vm.productMediaEntity = null;
        };

        vm.productVariants = [];
        vm.getAllVariant = function () {
            ProductsService.getProductVariants(vm.entity.Id)
                .then(function (data) {
                    vm.productVariants = data;
                    vm.loading = false;
                })
                .catch(function () {
                    vm.loading = false;
                });
        };

        vm.regenerateVariants = function () {
            vm.loading = true;
            ProductsService.regenerateAllVariants(vm.entity.Id)
                .then(function () {
                    vm.getAllVariant();
                })
                .catch(function () {
                    vm.loading = false;
                });
        };

        vm.newVariantProductEntity = null;
        vm.newVariantProduct = function () {
            vm.newVariantProductEntity = [];
        };

        vm.newVariantProductPost = function () {
            vm.loading = true;
            ProductsService.addVariantProduct(vm.entity.Id, vm.newVariantProductEntity)
                .then(function () {
                    vm.getAllVariant();
                })
                .catch(function () {
                    vm.loading = false;
                });
        };

        vm.OptionList = [];
        vm.addAttr = function (item) {
            vm.loading = true;
            vm.optionEntity.ProductId = vm.entity.Id;
            vm.optionEntity.IsRequired = false;
            vm.optionEntity.IsSpesific = true;
            vm.optionEntity.SaveTopOptions = true;
            ProductOptionValuesService.add(vm.optionEntity)
                .then(data => {
                    vm.tableProductAttributeParams.reload();
                    vm.loading = false;
                });
        };

        vm.editAttr = function (productOption) {
            vm.optionEntity = angular.copy(productOption);
        };

        vm.deleteAttr = function (productOption) {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                ProductOptionValuesService.del(productOption)
                    .then(function (data) {
                        vm.tableProductAttributeParams.reload();
                        vm.loading = false;
                    });
            }
        };

        vm.removeFile = function (item) {
            ProductsService.removeProductMedia(vm.entity.Id, item.MediaId).then(function (data) {
                vm.getProductMedia();
            });
        };

        vm.cancel = function () {
            $location.path('/products/detail/' + vm.entity.Id);
        };

        vm.varyantValue = function (item) {
            return item && item.ProductOptionValue && item.ProductOptionValue.Value ? item.ProductOptionValue.Value : '';
        };
        ///////////////////////////////// Group Product

        vm.groupProductFields = ProductGroupService.getFields();

        vm.groupProductEntity = null;

        vm.getProductGroups = function (Product) {
            ProductGroupService.getAll({
                    TopProductId: Product.Id
                })
                .then(function (data) {
                    vm.ProductGroups = data.Data;
                });
        };

        vm.addProductGroup = function () {
            vm.groupProductEntity = {
                TopProductId: vm.entity.Id
            };
        };

        vm.editProductGroup = function (groupProduct) {
            vm.groupProductEntity = angular.copy(groupProduct);
        };

        vm.deleteProductGroup = function (groupProduct) {
            if (confirm("Silmek istediğinize emin misiniz??")) {
                ProductGroupService.del(groupProduct).then(function (data) {
                    vm.getProductGroups(vm.entity);
                });
            }
        };

        vm.groupProductCancel = function () {
            vm.groupProductEntity = null;
        };

        vm.groupProductSubmit = function () {
            vm.loading = true;
            vm.groupProductEntity.TopProductId = vm.entity.Id;
            vm.groupProductEntity.SubProduct = null;
            if (vm.groupProductEntity.Id) {
                ProductGroupService.edit(vm.groupProductEntity)
                    .then(function () {
                        vm.getProductGroups(vm.entity);
                        vm.groupProductEntity = null;
                        vm.loading = false;
                    })
                    .catch(function () {
                        vm.loading = false;
                    });
            } else {
                ProductGroupService.add(vm.groupProductEntity)
                    .then(function () {
                        vm.getProductGroups(vm.entity);
                        vm.groupProductEntity = null;
                        vm.loading = false;
                    })
                    .catch(function () {
                        vm.loading = false;
                    });
            }
        };

        //////////////////////////////////Option Value

        vm.productOptionValueFields = ProductOptionValuesService.getFields();
        vm.productOptionValueEntity = {};
        vm.productOptionValueFormOptions = {};

        vm.editProductOptionValue = function (productOptionValue) {
            vm.productOptionValueEntity = angular.copy(productOptionValue);
        };

        vm.deleteProductOptionValue = function (productOptionValue) {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                ProductOptionValuesService.del(productOptionValue)
                    .then(function (data) {
                        vm.tableProductOptionValueParams.reload();
                        vm.loading = false;
                    });
            }
        };

        vm.productOptionValueSubmit = function (productOption) {
            vm.loading = true;
            if (vm.productOptionValueEntity.Id) {
                vm.productOptionValueEntity.ProductId = vm.entity.Id;
                vm.productOptionValueEntity.ProductOption = null;
                vm.productOptionValueEntity.Settings = JSON.stringify(vm.productOptionValueEntitySettings);
                ProductOptionValuesService.edit(vm.productOptionValueEntity)
                    .then(function () {
                        vm.tableProductOptionValueParams.reload();
                        vm.productOptionValueEntity = {
                            UseForSubVariantProduct: true
                        };
                        vm.productOptionValueEntitySettings = null;
                        vm.loading = false;
                    })
                    .catch(function () {
                        vm.loading = false;
                    });
            } else {
                vm.productOptionValueEntity.ProductId = vm.entity.Id;
                vm.productOptionValueEntity.Settings = JSON.stringify(vm.productOptionValueEntitySettings);
                ProductOptionValuesService.add(vm.productOptionValueEntity)
                    .then(function () {
                        vm.tableProductOptionValueParams.reload();
                        vm.productOptionValueEntity = {};
                        vm.productOptionValueEntitySettings = null;
                        vm.loading = false;
                    })
                    .catch(function () {
                        vm.loading = false;
                    });
            }
        };

        vm.groupBy = function (xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        };

        vm.productOptionValueCancel = function () {
            vm.productOptionValueEntity = {};
        };

        //////////////////////////////// Tier Price

        vm.tierPriceFields = TierPricesService.getFields();
        vm.tierPriceEntity = {};

        vm.getTierPrices = function (Product) {
            TierPricesService.getAll({
                    ProductId: Product.Id,
                    pageSize: -1
                })
                .then(function (data) {
                    vm.entity.TierPrice = data.Data;
                    vm.tierPriceEntity = null;
                    vm.loading = false;
                });
        };

        vm.addTierPrice = function (product) {
            vm.tierPriceEntity = {
                ProductId: product.Id
            };
        };

        vm.editTierPrice = function (tierPrice) {
            vm.tierPriceEntity = angular.copy(tierPrice);
        };

        vm.deleteTierPrice = function (tierPrice) {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                vm.loading = true;
                TierPricesService.del(tierPrice).then(function (data) {
                    vm.getTierPrices(vm.entity);
                });
            }
        };

        vm.tierPriceCancel = function () {
            vm.tierPriceEntity = null;
        };

        vm.tierPriceSubmit = function (tierPrice) {
            vm.loading = true;
            if (vm.tierPriceEntity.Id) {
                vm.tierPriceEntity.Role = null;
                TierPricesService.edit(vm.tierPriceEntity)
                    .then(function () {
                        vm.loading = false;
                        vm.getTierPrices(vm.entity);

                    })
                    .catch(function () {
                        vm.getTierPrices(vm.entity);
                    });
            } else {
                TierPricesService.add(vm.tierPriceEntity)
                    .then(function () {
                        vm.getTierPrices(vm.entity);
                    })
                    .catch(function () {
                        vm.getTierPrices(vm.entity);
                    });
            }
        };

        //////////////////////////////// ProductTag

        vm.tagFields = ProductTagsService.getFields();
        vm.tagEntity = {};

        vm.getTags = function (Product) {
            ProductTagsService.getAll({
                    ProductId: Product.Id,
                    pageSize: -1
                })
                .then(function (data) {
                    vm.entity.ProductTag = data.Data;
                    vm.tagEntity = null;
                    vm.loading = false;
                });
        };

        vm.addTag = function (product) {
            vm.tagEntity = {
                ProductId: product.Id
            };
        };

        vm.editTag = function (tag) {
            vm.tagEntity = angular.copy(tag);
        };

        vm.deleteTag = function (tag) {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                vm.loading = true;
                ProductTagsService.del(tag).then(function (data) {
                    vm.getTags(vm.entity);
                });
            }
        };

        vm.tagCancel = function () {
            vm.tagEntity = null;
        };

        vm.tagSubmit = function (tag) {
            vm.loading = true;
            if (vm.tagEntity.Id) {
                ProductTagsService.edit(vm.tagEntity)
                    .then(function () {
                        vm.getTags(vm.entity);
                    })
                    .catch(function () {
                        vm.getTags(vm.entity);
                    });
            } else {
                ProductTagsService.add(vm.tagEntity)
                    .then(function () {
                        vm.getTags(vm.entity);
                    })
                    .catch(function () {
                        vm.getTags(vm.entity);
                    });
            }
        };

        //////////////////////////////// Stock Quantity History

        vm.stockQuantityHistoryFields = StockQuantityHistoryService.getFields();
        vm.productStockFields = StockQuantityHistoryService.getProductStockFields();
        StockItemsService.calculateStockItemAverage($stateParams.ProductId)
            .then(function (data) {
                vm.stockItemAverage = data;
            });
        vm.stockQuantityHistoryEntity = {};

        vm.addStockQuantityHistory = function (product) {
            vm.stockQuantityHistoryEntity = {
                ProductId: product.Id
            };
        };

        vm.stockQuantityHistoryCancel = function () {
            vm.stockQuantityHistoryEntity = null;
        };

        vm.stockQuantityHistorySubmit = function () {
            vm.loading = true;

            StockQuantityHistoryService.add(vm.stockQuantityHistoryEntity)
                .then(function () {
                    vm.tableStockQuantityHistoryParams.reload();
                    vm.loading = true;
                })
                .catch(function () {
                    vm.tableStockQuantityHistoryParams.reload();
                    vm.loading = true;
                });
        };

        /////////////////////////////////// Discount

        vm.productDiscountFields = DiscountsService.getProductDiscountFields();
        vm.productDiscountEntity = {};

        vm.getProductDiscount = function () {
            vm.loading = true;
            DiscountsService.getProductDiscount(vm.entity.Id)
                .then(function (data) {
                    if (data && data.length > 0) {
                        vm.productDiscountEntity = data[0];
                        if (data[0]) {
                            vm.productDiscountEntity.StartDate = moment(data[0].StartDate).toDate();
                            vm.productDiscountEntity.EndDate = moment(data[0].EndDate).toDate();
                        }
                    } else {
                        vm.productDiscountEntity = {};
                    }
                    vm.loading = false;
                });
        };

        vm.productDiscountSubmit = function () {
            vm.loading = true;
            vm.productDiscountEntity.ProductId = vm.entity.Id;
            vm.productDiscountEntity.DiscountTypeId = "0b99e190-dfb9-4a41-9b93-d3c32c2a7337";
            if (vm.productDiscountEntity.Id) {
                DiscountsService.edit(vm.productDiscountEntity)
                    .then(function (data) {
                        data.PriceWithDiscountTemp = vm.productDiscountEntity.PriceWithDiscountTemp;
                        // vm.productDiscountEntity = data;
                        vm.getProductDiscount();
                        vm.loading = false;
                    });
            } else {
                vm.productDiscountEntity.Name = "Discount-" + vm.entity.Code;
                DiscountsService.add(vm.productDiscountEntity)
                    .then(function () {
                        vm.loading = false;
                        vm.getProductDiscount();
                    });
            }
        };

        vm.productDiscountDelete = function () {
            if (vm.productDiscountEntity.Id) {
                vm.loading = true;
                DiscountsService.del(vm.productDiscountEntity)
                    .then(function () {
                        vm.loading = false;
                        vm.getProductDiscount();
                    });
            }
        };
        vm.productDiscountEntity.changeType = "";
        $scope.$watch('vm.productDiscountEntity.PriceWithDiscountTemp', function (newValue, oldValue) {
            if (newValue && vm.entity.Price > newValue) {
                vm.productDiscountEntity.Active = true;
                vm.productDiscountEntity.UsePercentage = false;
                vm.productDiscountEntity.Amount = vm.entity.Price - newValue;
            } else {
                vm.productDiscountEntity.Active = false;
                vm.productDiscountEntity.UsePercentage = false;
                vm.productDiscountEntity.Amount = null;
            }
        });

        function trimChar(string, charToRemove) {
            while (string.charAt(0) == charToRemove) {
                string = string.substring(1);
            }

            while (string.charAt(string.length - 1) == charToRemove) {
                string = string.substring(0, string.length - 1);
            }

            return string;
        }

        //////////////////////////////////Related Product

        vm.relatedProductFields = RelatedProductService.getFields();
        vm.relatedProductEntity = {};
        vm.relatedProductFormOptions = {};

        vm.editRelatedProduct = function (relatedProduct) {
            vm.relatedProductEntity = angular.copy(relatedProduct);
        };

        vm.deleteRelatedProduct = function (relatedProduct) {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                RelatedProductService.del(relatedProduct)
                    .then(function (data) {
                        vm.tableRelatedProductParams.reload();
                        vm.loading = false;
                    });
            }
        };

        vm.relatedProductSubmit = function (productOption) {
            vm.loading = true;
            vm.relatedProductEntity.Related = null;
            vm.relatedProductEntity.Product = null;
            vm.relatedProductEntity.RelatedProductType = null;
            if (vm.relatedProductEntity.Id) {
                vm.relatedProductEntity.ProductId = vm.entity.Id;
                RelatedProductService.edit(vm.relatedProductEntity)
                    .then(function () {
                        vm.tableRelatedProductParams.reload();
                        vm.relatedProductEntity = {};
                        vm.relatedProductEntitySettings = null;
                        vm.loading = false;
                    })
                    .catch(function () {
                        vm.loading = false;
                    });
            } else {
                vm.relatedProductEntity.ProductId = vm.entity.Id;
                RelatedProductService.add(vm.relatedProductEntity)
                    .then(function () {
                        vm.tableRelatedProductParams.reload();
                        vm.relatedProductEntity = {};
                        vm.loading = false;
                    })
                    .catch(function () {
                        vm.loading = false;
                    });
            }
        };

        vm.groupBy = function (xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        };

        vm.relatedProductCancel = function () {
            vm.relatedProductEntity = {};
        };



        // Modal

        vm.modalTitle = "Pazaryeri";
        vm.modalInstance = null;
        vm.openModal = function (data) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'ModalContentResult.html',
                controller: 'ModalMarketController',
                controllerAs: 'vm',
                resolve: {
                    items: function () {
                        return {
                            data: data
                        };
                    }
                }
            });
        }

    }


    angular
        .module('app.excel')
        .controller('ModalMarketController', ModalMarketController);

    ModalMarketController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'ExcelService', '$uibModal', '$uibModalStack', '$uibModalInstance', 'items'];

    function ModalMarketController($scope, $stateParams, $filter, $location, logger, ExcelService, $uibModal, $uibModalStack, $uibModalInstance, items) {

        var vm = this;
        vm.content = items;
        activate();

        function activate() {
            vm.dataResult = vm.content.data;
        }
    }


})();