(function () {
    'use strict';

    angular.module('app.core')
        .factory('CustomResourceLoader', CustomResourceLoader);

    CustomResourceLoader.$inject = ['$q', '$http'];

    function CustomResourceLoader($q, $http) {
        return function (options) {
            var deferred = $q.defer();

            $http.get('/api/resources/' + options.key)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                var resources = {};
                for (var i of response.data) {
                    resources[i.Key] = i.Value;
                }
                deferred.resolve(resources);
            }

            function getFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        };
    }

    angular.module('app.core')
        .factory('CustomTranslateHandlerFactory', CustomTranslateHandlerFactory);

    CustomTranslateHandlerFactory.$inject = ['$timeout'];

    function CustomTranslateHandlerFactory($timeout) {

        var start = false;
        $timeout(function () {
            start = true;
        }, 1500)
        return function (translationID) {
            if (start) {
                alert('Resource bulunamadı -> ' + translationID);
            }
        };
    }
})();