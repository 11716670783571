(function () {
    'use strict';

    angular
        .module('app.currency')
        .factory('CurrencyService', CurrencyService);

    CurrencyService.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config' ];

    function CurrencyService($http, $q, ngAuthSettings, logger, config) {
        var service = {

            GetAllCurrency: GetAllCurrency,
            UpdateCurrency: UpdateCurrency,
            DeleteCurrency: DeleteCurrency,
            SaveCurrency: SaveCurrency,
            getCurrencyFields: getCurrencyFields,
            addCurrency: addCurrency
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/Currency/';

        var CurrencyFields = [
            {
                key: 'Name',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Adı',
                    resource: 'form.name',
                    placeholder: 'Adı'
                }
            }, {
                key: 'ShortName',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Kısa Adı',
                    resource: 'form.shortname',
                    placeholder: 'Kısa Adı'
                }
            },{
                key: 'Symbol',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Sembol',
                    resource: 'form.symbol',
                    placeholder: 'Sembol'
                }
            }, 
            {
                key: "Default",
                type: "floatCheckbox",
                defaultValue: false,
                templateOptions: {
                    label: "Default",
                    resource: "form.Default",
                    placeholder: "Varsayılan?",
                    class: "col-xs-4"
                }
            },
            {
                key: 'Format',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Format',
                    resource: 'form.CurrencyFormat',
                    placeholder: 'Format'
                }
            },
            {
                key: "DisplayOrder",
                type: "floatInput",
                defaultValue: 99,
                templateOptions: {
                    type: "number",
                    resource: "form.display-order",
                    label: "Sıra",
                    placeholder: "Sıra",
                    class: "col-xs-3"
                }
            }, {
                key: 'Rate',
                type: 'floatInput',
                templateOptions: {
                    type: 'number',
                    label: 'Rate',
                    resource: 'form.currency-rate',
                    placeholder: 'Rate',
                    numberMask: 2
                }
            }
        ];

  


        return service;

        /////////////////////////////////////////

      

        // Get product

        function getCurrencyFields() {
            return CurrencyFields;
        }





        function GetAllCurrency(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBase + "GetAllCurrency", {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }


        function UpdateCurrency() {
            var deferred = $q.defer();

            $http.get(serviceBase + "UpdateCurrency")
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }



        function DeleteCurrency(data) {
            var deferred = $q.defer();

            $http.post(serviceBase + "DeleteCurrency/",data)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
1
        function addCurrency(currency) {
            var deferred = $q.defer();

            $http.post(serviceBase + "addCurrency", currency)
                .then(getComplete)
                .catch(getFailed);


            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
        function SaveCurrency(currency) {
            var deferred = $q.defer();

            $http.post(serviceBase + "SaveCurrency", currency)
                .then(getComplete)
                .catch(getFailed);


            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

    }
})();