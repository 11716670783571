(function () {
    'use strict';

    angular
        .module('app.discounts')
        .factory('DiscountsService', Service);

    Service.$inject = ['$http', '$q', 'ngAuthSettings', 'RolesService', 'config', 'ProductsGeneralService', 'ProductsService'];

    function Service($http, $q, ngAuthSettings, RolesService, config, ProductsGeneralService, ProductsService) {
        var service = {
            getAll: getAll,
            getProductDiscount: getProductDiscount,
            get: get,
            add: add,
            edit: edit,
            del: del,
            getFields: getFields,
            getProductDiscountFields: getProductDiscountFields,
            getDiscountType: getDiscountType
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/discounts/';

        var fields = [{
                key: 'Active',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Aktif',
                    resource: 'form.active',
                    placeholder: 'Aktif',
                    class: "col-xs-4"
                }
            },
            {
                key: 'Name',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Adı',
                    resource: 'form.name',
                    placeholder: 'Adı',
                    class: "col-xs-8"
                }
            },
            {
                key: 'DiscountTypeId',
                type: 'floatSelect',
                templateOptions: {
                    required: true,
                    label: 'İndirim Tipi',
                    resource: 'form.discount-type',
                    options: [{
                        Name: 'Yükleniyor...'
                    }]
                },
                controller: ['$scope', function ($scope) {
                    service.getDiscountType().then(function (response) {
                        var opt = [{
                            Name: ''
                        }];
                        $scope.to.options = opt.concat(response);
                    });
                }]
            },
            {
                key: 'UsePercentage',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Yüzde Kullan',
                    resource: 'form.use-percentage',
                    placeholder: 'Yüzde Kullan',
                    class: "col-xs-4"
                }
            },
            {
                key: 'Amount',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'İndirim Miktarı',
                    resource: 'form.amount',
                    placeholder: 'İndirim Miktarı',
                    numberMask: 2,
                    class: "col-xs-8"
                }
            },
            {
                key: 'StartDate',
                type: 'floatDatepicker',
                templateOptions: {
                    label: 'Başlangıç Tarihi',
                    resource: 'form.start-date',
                    placeholder: 'Başlangıç Tarihi',
                    class: "col-xs-6"
                }
            },
            {
                key: 'EndDate',
                type: 'floatDatepicker',
                templateOptions: {
                    label: 'Bitiş Tarihi',
                    resource: 'form.end-date',
                    placeholder: 'Bitiş Tarihi',
                    class: "col-xs-6"
                }
            },
            {
                key: 'UseCouponCode',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Kupon Kullan',
                    resource: 'form.use-coupon-code',
                    placeholder: 'Kupon Kullan',
                    class: "col-xs-4"
                }
            },
            {
                key: 'CouponCode',
                type: 'floatInput',
                templateOptions: {
                    type: 'text',
                    label: 'Kupon kodu',
                    resource: 'form.coupon-code',
                    placeholder: 'Kupon kodu',
                    class: "col-xs-8"
                }
            },
            {
                key: 'MinimumAmount',
                type: 'floatInput',
                defaultValue: "0",
                templateOptions: {
                    type: 'text',
                    label: 'Minimum Tutar',
                    resource: 'form.minimum-amount',
                    placeholder: 'Minimum Tutar',
                    numberMask: 2,
                    class: "col-xs-6"
                }
            },
            {
                key: 'MaximumAmount',
                type: 'floatInput',
                templateOptions: {
                    type: 'text',
                    label: 'Maximum Tutar',
                    resource: 'form.maximum-amount',
                    placeholder: 'Maximum Tutar',
                    numberMask: 2,
                    class: "col-xs-6"
                }
            }, {
                key: 'RoleId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Role',
                    resource: 'form.role',
                    options: [{
                        Name: '...'
                    }]
                },
                controller: ['$scope', function ($scope) {
                    RolesService.getAll({
                            IsBase: false
                        })
                        .then(function (response) {
                            $scope.to.options = response.Data;
                        });
                }]
            },
            {
                key: 'Categories',
                type: 'floatTreeMultiSelect',
                templateOptions: {
                    label: 'Kategori',
                    resource: 'form.categories',
                    options: [{
                        Name: 'Yükleniyor...'
                    }]
                },
                controller: ['$scope', function ($scope) {
                    ProductsGeneralService.getCategories()
                        .then(function (response) {
                            $scope.model.DiscountCategory = $scope.model.DiscountCategory || [];
                            $scope.model.Categories = $scope.model.DiscountCategory.map(function (item) {
                                return item.CategoryId;
                            });
                            $scope.to.options = response;
                        });
                }]
            },
            // {
            //     key: 'ProductId',
            //     type: 'float-async-ui-select',
            //     templateOptions: {
            //         label: 'Ürün',
            //         resource: 'form.product',
            //         options: [],
            //         placeholder: '...',
            //         valueProp: 'Id',
            //         labelProp: 'Name',
            //         refresh: function (text, options) {
            //             var params = {
            //                 Name: text,
            //                 PageSize: 5
            //             };
            //             if (options.formControl.$modelValue && !text) {
            //                 params.Id = options.formControl.$modelValue;
            //             }
            //             return ProductsService.getProducts(params)
            //                 .then(function (response) {
            //                     options.templateOptions.options = response.Data;
            //                 });
            //         },
            //         refreshDelay: 2
            //     }
            // },
            {
                key: 'Products',
                type: 'float-async-ui-select-multiple',
                templateOptions: {
                    label: 'Ürünler',
                    resource: 'form.products',
                    options: [],
                    placeholder: '...',
                    valueProp: 'Id',
                    labelProp: 'Name',
                    refresh: function (text, options, model) {
                        options.templateOptions.options = [{
                            Name: 'Yükleniyor...'
                        }]
                        var params = {
                            SearchKey: text,
                            PageSize: 20
                        };
                        if (model.DiscountProduct && !model.Products) {
                            model.Products = model.DiscountProduct.map(k => k.ProductId);
                            params.Id = model.Products;
                            return ProductsService.getProducts({
                                    PageSize: -1,
                                    Id: model.Product
                                })
                                .then(function (productResponse) {
                                    return ProductsService.getProducts(params)
                                        .then(function (response) {
                                            var concat = productResponse.Data.concat(response.Data);
                                            options.templateOptions.options = concat;
                                        });
                                });
                        }
                        return ProductsService.getProducts(params)
                            .then(function (response) {
                                options.templateOptions.options = response.Data;
                            });
                    },
                    refreshDelay: 2
                }
            }
        ];

        var productDiscountFields = [{
                key: 'Active',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Aktif',
                    resource: 'form.active',
                    placeholder: 'Aktif'
                }
            },
            {
                key: 'UsePercentage',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Yüzde Kullan',
                    resource: 'form.use-percentage',
                    placeholder: 'Yüzde Kullan'
                }
            },
            {
                key: 'Amount',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'İndirim Miktarı',
                    resource: 'form.amount',
                    placeholder: 'İndirim Miktarı',
                    numberMask: 2
                }
            },
            {
                key: 'StartDate',
                type: 'floatDatepicker',
                templateOptions: {
                    label: 'Başlangıç Tarihi',
                    resource: 'form.start-date',
                    placeholder: 'Başlangıç Tarihi'
                }
            },
            {
                key: 'EndDate',
                type: 'floatDatepicker',
                templateOptions: {
                    label: 'Bitiş Tarihi',
                    resource: 'form.end-date',
                    placeholder: 'Bitiş Tarihi'
                }
            }
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getProductDiscountFields() {
            return productDiscountFields;
        }

        function getDiscountType() {
            var deferred = $q.defer();

            $http.get(serviceBase + "discountTypes")
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data.filter(k => k.Id !== "0b99e190-dfb9-4a41-9b93-d3c32c2a7337"));
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBase, {
                    params: filterParams
                })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getProductDiscount(Id) {
            var deferred = $q.defer();

            $http.get(serviceBase + 'ProductDiscount/' + Id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(Id) {
            var deferred = $q.defer();

            $http.get(serviceBase + Id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();

            $http.post(serviceBase, entity)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http.put(serviceBase + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function del(entity) {
            var deferred = $q.defer();

            $http.delete(serviceBase + entity.Id, entity)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();