(function () {
    'use strict';

    angular.module('app.currency')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'currency',
                config: {
                    url: '/currency',
                    title: 'Para Birimleri',
                    abstract: true,
                    templateUrl: 'app/currency/currency.html',
                    settings: {
                        name: 'integration',
                        nav: 200,
                        icon: 'fa fa-cogs',
                        resource: 'menu.currency',
                        content: 'Para Birimleri'
                    }
                }
            },
            {
                state: 'currency.currencylist',
                config: {
                    templateUrl: 'app/currency/currency.currencylist.html',
                    controller: 'CurrencyListController',
                    controllerAs: 'vm',
                    url: '/currencylist',
                    title: 'Para Birimleri Listesi',
                    settings: {
                        nav: 10,
                        name: 'currency.currencylist',
                        content: 'Para Birimleri Listesi',
                        resource: 'menu.currency.currencylist'
                    }
                }
            },
            {
                state: 'currency.currencyadd',
                config: {
                    templateUrl: 'app/currency/currency.currencyadd.html',
                    controller: 'AddCurrencyController',
                    controllerAs: 'vm',
                    url: '/currencyadd',
                    title: 'Para Birimi Ekle',
                    settings: {
                        nav: 10,
                        name: 'currency.currencyadd',
                        content: 'Para Birimi Ekle',
                        resource: 'menu.currency.currencyadd'
                    }
                }
            }
        ];
    }
})();