(function () {
    'use strict';

    angular
        .module('app.production')
        .factory('ProductTagsService', Service);

    Service.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config', 'Settings', 'RolesService', 'TagsService'];

    function Service($http, $q, ngAuthSettings, logger, config, Settings, RolesService, TagsService) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            getFields: getFields
        };
        var baseUrl = ngAuthSettings.apiServiceBaseUri;

        var serviceBaseUrl = baseUrl + 'api/ProductTags/';

        var fields = [
            {
                key: 'TagId',
                type: 'floatSelect',
                templateOptions: {
                    required: true,
                    label: 'Etiket',
                    resource: 'form.tag',
                    options: [{ Name: '...' }],
                    class: "col-xs-6"
                },
                controller: ['$scope', function ($scope) {
                    TagsService.getAll({ PageSize: -1 })
                        .then(function (response) {
                            $scope.to.options = response.Data;
                        });
                }]
            },
            {
                key: 'DisplayOrder',
                type: 'floatInput',
                templateOptions: {
                    type: 'number',
                    resource: 'form.display-order',
                    label: 'Sıra',
                    placeholder: 'Sıra',
                    class: "col-xs-6"
                }
            }
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();
            $http.get(serviceBaseUrl, {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(Id) {
            var deferred = $q.defer();

            $http.get(serviceBaseUrl + Id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();
            $http.post(serviceBaseUrl, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http.put(serviceBaseUrl + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(entity) {
            var deferred = $q.defer();
            $http.delete(serviceBaseUrl + entity.ProductId + '/' + entity.TagId, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();