(function () {
    'use strict';

    angular.module('app.tags')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'tags',
                config: {
                    url: '/tags',
                    title: 'Etiketler',
                    abstract: true,
                    templateUrl: 'app/tags/tags.html'
                }
            },
            {
                state: 'tags.list',
                config: {
                    templateUrl: 'app/tags/tags.list.html',
                    controller: 'TagsListController',
                    controllerAs: 'vm',
                    url: '/list',
                    title: 'Etiket Listesi',
                    settings: {
                        nav: 10,
                        name: 'tags.list',
                        topName: 'products',
                        content: 'Etiket Listesi',
                        resource: 'menu.tags.list'
                    }
                }
            },
            {
                state: 'tags.add',
                config: {
                    templateUrl: 'app/tags/tags.add.html',
                    controller: 'TagsAddController',
                    controllerAs: 'vm',
                    url: '/add',
                    title: 'Etiket Ekle',
                    settings: {
                        topName: 'tags.list',
                        resource: 'menu.tags.add'
                    }
                }
            },
            {
                state: 'tags.edit',
                config: {
                    templateUrl: 'app/tags/tags.edit.html',
                    controller: 'TagsEditController',
                    controllerAs: 'vm',
                    url: '/edit/:Id',
                    title: 'Etiket Düzenle',
                    settings: {
                        topName: 'tags.list',
                        resource: 'menu.tags.edit'
                    }
                }
            },
            {
                state: 'tags.detail',
                config: {
                    templateUrl: 'app/tags/tags.detail.html',
                    controller: 'TagsDetailController',
                    controllerAs: 'vm',
                    url: '/detail/:Id',
                    title: 'Etiket Detay',
                    settings: {
                        topName: 'tags.list',
                        resource: 'menu.tags.detail'
                    }
                }
            }
        ];
    }
})();