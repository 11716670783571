(function () {
    'use strict';

    angular
        .module('app.trendyolIntegration')
        .controller('TrendyolEditMatchCategoryFilterController', TrendyolEditMatchCategoryFilterController);

    TrendyolEditMatchCategoryFilterController.$inject = ['$scope', '$stateParams', '$location', 'TrendyolIntegrationService', 'NgTableParams'];

    function TrendyolEditMatchCategoryFilterController($scope, $stateParams, $location, TrendyolIntegrationService, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;
        vm.settings = globalSettings;
        activate();

        function activate() {
            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({ page: 1, count: 10 }, $location.search()),
                {
                    getData: function () {
                        return TrendyolIntegrationService.getMatchCategory($stateParams.Id)
                            .then(function (data) {
                                return TrendyolIntegrationService.getCategoryFilter(data.IntegrationCategoryId)
                                    .then(function (result) {
                                        return result;
                                    });
                            });
                    }
                });

            TrendyolIntegrationService.getOption().then(function (data) {
                console.log("data", data);
                vm.entity = getTreeData(data.Data, null);
                vm.entity.refresh = true;
                vm.loading = false;
            });

            vm.MatchCategoryId = $stateParams.Id;
        }

        function getTreeData(options, topProp) {
            var data = options.filter(obj => obj["TopOptionId"] === topProp);
            if (data.length <= 0) {
                return [];
            }
            return data
                .map(obj => {
                    var rObj = {};
                    rObj.id = obj["Id"];
                    rObj.name = obj["Name"];
                    rObj.children = getTreeData(options, obj.Id) || [];
                    return rObj;
                });
        }

        vm.selectFilter = function (filter) {
            vm.loading = true;
            vm.catAttr = {};
            vm.catAttr.OptionId = vm.item[0].id;
            vm.catAttr.MatchCategoryId = $stateParams.Id;
            vm.catAttr.IntegrationCategoryAttrId = filter.attribute.id;
            vm.catAttr.IntegrationCategoryAttrName = filter.attribute.name;
            TrendyolIntegrationService.editMatchCategoryFilter(vm.catAttr).then(function (data) {
                vm.loading = false;
                $location.path('/trendyolIntegration/trendyoleditmatchcategoryfiltervalue/' + data.Id);
            });
        };
    }
})();