(function () {
    'use strict';

    angular.module('app.unitmeasures', [
        // Angular modules 
        'ngRoute'

        // Custom modules 

        // 3rd Party Modules
        
    ]);
})();