(function () {
        "use strict";

        angular.module("app", [
                "app.core",
                "app.widgets",
                "app.account",
                "app.roles",
                "app.layout",
                "app.dashboard",
                "app.general",
                "app.customers",
                "app.orders",
                "app.banks",
                "app.cultures",
                "app.sellers",
                "app.discounts",
                "app.shipmentProvider",
                "app.shipmentPrice",
                "app.deliveries",
                "app.errors",
                "app.production",
                "app.option",
                "app.reviews",
                "app.productBrand",
                "app.unitmeasures",
                "app.blogPost",
                "app.blogPostCategory",
                "app.blogPostTag",
                "app.categories",
                "app.designTheme",
                "app.designTemplate",
                "app.designMockup",
                "app.galleries",
                "app.collections",
                "app.settings",
                "app.services",
                "app.spares",
                "app.pages",
                "app.vendors",
                "app.vendortypes",
                "app.warehouses",
                "app.stockHistories",
                "app.redirectUrls",
                "app.messageTemplates",
                "angular-loading-bar",
                "app.filter",
                "app.directive",
                "app.resources",
                "app.subscriptions",
                "app.filemanager",
                "app.tags",
                "app.n11Integration",
                "app.hbIntegration",
                "app.ggIntegration",
                "app.excel",
                "app.contracts",
                "app.salespoints",
                "app.salespointTypes",
                "app.stockItems",
                "app.getProducts",
                "app.relatedProductType",
                "app.carts",
                "app.trendyolIntegration",
                "app.markettemplates",
                "app.simplemailing",
                "app.reporting",
                "app.currency",
                "app.consulting",
                "app.users",
                "app.invoices",
                "app.marketing",
                "app.productLists"
        ]);
})();