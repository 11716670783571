(function () {
    'use strict';

    angular
        .module('app.collections')
        .controller('CollectionsAddController', Collections);

    Collections.$inject = ['$location', 'CollectionsService'];

    function Collections($location, CollectionsService) {

        /* jshint validthis:true */
        var vm = this;

        vm.title = '';

        vm.collection = {};

        vm.collectionFields = CollectionsService.getCollectionFields();

        vm.create = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                CollectionsService.addCollection(vm.collection).then(function (data) {
                    vm.loading = false;
                    $location.path('/collections/detail/' + data.Id);
                });
            }
        };
    }
})();