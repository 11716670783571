(function () {
    'use strict';

    angular
        .module('app.trendyolIntegration')
        .factory('TrendyolIntegrationService', TrendyolIntegrationService);

    TrendyolIntegrationService.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config', 'ProductsService', 'ProductsGeneralService'];

    function TrendyolIntegrationService($http, $q, ngAuthSettings, logger, config, ProductsService, ProductsGeneralService) {
        var service = {

            //Option

            getOption: getOption,
            getOptionValue: getOptionValue,

            //Template

            getTemplateFields: getTemplateFields,
            getAllTemplate: getAllTemplate,
            getTemplate: getTemplate,
            addTemplate: addTemplate,
            editTemplate: editTemplate,
            delTemplate: delTemplate,

            //MatchCategory

            getMatchCategoryFields: getMatchCategoryFields,
            getAllMatchCategory: getAllMatchCategory,
            getMatchCategory: getMatchCategory,
            addMatchCategory: addMatchCategory,
            editMatchCategory: editMatchCategory,
            delMatchCategory: delMatchCategory,

            //MatchCategory Filter

            getMatchCategoryFilterFields: getMatchCategoryFilterFields,
            getMatchCategoryFilter: getMatchCategoryFilter,
            editMatchCategoryFilter: editMatchCategoryFilter,
            deleteFilter: deleteFilter,

            //MatchCategory Filter Value

            getMatchCategoryFilterValueFields: getMatchCategoryFilterValueFields,
            getMatchCategoryFilterValue: getMatchCategoryFilterValue,
            editMatchCategoryFilterValue: editMatchCategoryFilterValue,

            //trendyol Integration

            getShipmentTemplate: getShipmentTemplate,
            getTopCategories: getTopCategories,
            getSubCategories: getSubCategories,
            getCategoryFilter: getCategoryFilter,
            getCategoryFilterValue: getCategoryFilterValue,
            getMatchCategoryFilterListByCategory: getMatchCategoryFilterListByCategory,
            getMatchCategoryFilterValueList: getMatchCategoryFilterValueList,
            transferCategory: transferCategory,
            transferAllCategory: transferAllCategory,
            transferCategoryControl: transferCategoryControl,

            //Product Sender 

            ProductSender: ProductSender,
            ProductQuery: ProductQuery

        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/trendyolIntegration/';

        var templateFields = [
            {
                key: 'Name',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Adı',
                    resource: 'form.name',
                    placeholder: 'Adı'
                }
            },
            {
                key: 'SalesPrice',
                type: 'floatSelect',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Satış Fiyatı',
                    resource: 'form.sales-price',
                    placeholder: 'Satış Fiyatı',
                    class: 'col-md-2',
                    options: [
                        {
                            Name: 'Ürün Fiyatı',
                            Id: 'Ürün Fiyatı'
                        },
                        {
                            Name: 'N11 Fiyatı',
                            Id: 'N11 Fiyatı'
                        }
                    ]
                }
            },
            {
                key: 'SalesPriceCalcType',
                type: 'floatSelect',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Satış Fiyatı Hesap Biçimi',
                    class: 'col-md-2',
                    options: [
                        {
                            Name: 'X',
                            Id: 'X',
                        },
                        {
                            Name: '/',
                            Id: '/',
                        }
                    ]
                }
            },
            {
                key: 'SalesPriceQuantity',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Satış Fiyat Ayarlama Miktarı',
                    placeholder: 'Satış Fiyat Ayarlama Miktarı',
                    class: 'col-md-2',
                    numberMask: 2
                }
            },
            {
                key: 'DiscountRate',
                type: 'floatInput',
                templateOptions: {
                    type: 'text',
                    label: '% İndirim Oranı',
                    resource: 'form.discount-rate',
                    placeholder: '% İndirim Oranı'
                }
            },
            {
                key: 'SalesStartDate',
                type: 'floatDatepicker',
                templateOptions: {
                    type: 'text',
                    label: 'Satışa Başlama Tarihi',
                    resource: 'form.sales-start-date',
                    placeholder: 'Satışa Başlama Tarihi'
                }
            },
            {
                key: 'SalesEndDate',
                type: 'floatDatepicker',
                templateOptions: {
                    type: 'text',
                    label: 'Satışın Bitiş Tarihi',
                    resource: 'form.sales-end-date',
                    placeholder: 'Satışın Bitiş Tarihi'
                }
            },
            {
                key: 'SubTitle',
                type: 'floatInput',
                templateOptions: {
                    type: 'text',
                    label: 'Alt Başlık',
                    resource: 'form.subtitle',
                    placeholder: 'Alt Başlık'
                }
            },
            {
                key: 'CargoTime',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'number',
                    label: 'Kargo Süresi',
                    resource: 'form.cargo-time',
                    placeholder: 'Kargo Süresi'
                }
            },
            {
                key: 'IntegrationShipmentTemplateName',
                type: 'floatSelect',
                templateOptions: {
                    type: 'text',
                    label: 'Teslimat Şablonu',
                    resource: 'form.deliveries-template',
                    placeholder: 'Teslimat Şablonu'
                },
                controller: ['$scope', function ($scope) {
                    getShipmentTemplate()
                        .then(function (response) {
                            var templates = response.map(x => {
                                var obj = {};
                                obj["Id"] = x.Name;
                                obj["Name"] = x.Name;
                                return obj;
                            });
                            $scope.to.options = templates;
                        });
                }]
            },
        ];

        var matchcategoryFields = [
            {
                key: 'IntegrationTemplateId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Şablonlar',
                    resource: 'form.templates',
                    required: true,
                    placeholder: 'Şablon Seçiniz ',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    ProductsGeneralService.getIntegrationTemplates()
                        .then(function (response) {
                            $scope.to.options = response;
                        });
                }]
            },
            {
                key: 'ShipmentIntegrationTemplateId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Teslimat Şablonu Seçiniz',
                    resource: 'form.shipment.templates',
                    required: true,
                    placeholder: 'Şablon Seçiniz ',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    ProductsGeneralService.getShipmentTemplates()
                        .then(function (response) {
                            console.log("response", response);
                            $scope.to.options = response;
                        });
                }]
            },
            {
                key: 'EComCategoryId',
                type: 'floatTreeSelect',
                templateOptions: {
                    label: 'E-Ticaret Kategorileri',
                    resource: 'form.ecommerce-categories',
                    required: true,
                    placeholder: 'Kategori Seçiniz ',
                    topProp: 'TopCategoryId',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    ProductsGeneralService.getCategories()
                        .then(function (response) {
                            $scope.to.options = response;
                        });
                }]
            },
            {
                key: 'TRCategories1',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Trendyol Kategorileri',
                    resource: 'form.tr-topcategories',
                    required: true,
                    placeholder: 'Kategori Seçiniz ',
                    class: 'col-md-3',
                    labelProp: 'name',
                    valueProp: 'id',
                    options: [{ name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {

                    getTopCategories().then(function (data) {
                       
                        $scope.$watch('model.TRCategories1', function (newValue, oldValue) {
                            if (newValue) {
                                var found = $scope.to.options.find(function (data) {
                                    return data.id == newValue;
                                });

                                $scope.model.TRCategories1Name = found.name;
                                $scope.model.TRCategories1Id = found.id;
                            }
                        });
                        if (data.length > 0) {
                            $scope.model.showTRCategories2 = true
                        }
                        $scope.to.options = data;
                    });
                }]
            },
            {
                key: 'TRCategories2',
                hideExpression: '!(model.TRCategories1 && model.showTRCategories2)',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Trendyol Kategorileri',
                    resource: 'form.tr-subcategories',
                    required: true,
                    placeholder: 'Kategori Seçiniz ',
                    class: 'col-md-3',
                    labelProp: 'Item1',
                    valueProp: 'Item2',
                    options: [{ name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    var getData = null;
                    $scope.$watch('model.TRCategories1', function (newValue, oldValue) {

                        if (newValue) {

                            getSubCategories(newValue).then(function (data) {
                                if (data.length > 0) {
                                    $scope.model.showTRCategories3 = true
                                    $scope.to.options = data;
                                    getData = data;
                                }
                                else {
                                    $scope.model.showTRCategories2 = false;
                                }
                            });
                        }
                    });
                    $scope.$watch('model.TRCategories2', function (newValue, oldValue) {
                        if (newValue) {
                           // console.log("model ", vm);
                            console.log("cat2 data", getData);
                           // data = getData;
                            var found = $scope.to.options.find(function (getData) {
                                return getData.Item2 == newValue;
                            });

                           console.log("found2", found);
                            $scope.model.TRCategories2Name = found.Item1;
                            $scope.model.TRCategories2Id = newValue;
                            console.log('found2', $scope.model.TRCategories2Name);
                        }
                    });
                }]
            },
            {
                key: 'TRCategories3',
                hideExpression: '!(model.TRCategories2 && model.showTRCategories3)',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Trendyol Kategorileri',
                    resource: 'form.tr-subcategories',
                    required: true,
                    placeholder: 'Kategori Seçiniz ',
                    class: 'col-md-3',
                    labelProp: 'Item1',
                    valueProp: 'Item2',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    var getData = null;
                    $scope.$watch('model.TRCategories2', function (newValue, oldValue) {

                        if (newValue) {
                            var sendData = $scope.model.TRCategories1Id + "-" + $scope.model.TRCategories2Id + "-" + newValue;

                            getSubCategories2(sendData).then(function (data) {
                                if (data.length > 0) {
                                    $scope.model.showTRCategories4 = true
                                    $scope.to.options = data;
                                    getData = data;
                                }
                                else {
                                    $scope.model.showTRCategories3 = false;
                                }
                            });
                        }
                    });
                    $scope.$watch('model.TRCategories3', function (newValue, oldValue) {
                        if (newValue) {
                            var found = $scope.to.options.find(function (getData) {
                                return getData.Item2 == newValue;
                            });
                            $scope.model.TRCategories3Name = found.Item1;
                            console.log('found3', $scope.model.TRCategories3Name);
                            $scope.model.TRCategories3Id = newValue;
                        }
                    });
                }]
            },
            {
                key: 'TRCategories4',
                hideExpression: '!(model.TRCategories3 && model.showTRCategories4)',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Trendyol Kategorileri',
                    resource: 'form.tr-subcategories',
                    required: true,
                    placeholder: 'Kategori Seçiniz ',
                    class: 'col-md-3',
                    labelProp: 'Item1',
                    valueProp: 'Item2',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    var getData = null;
                    $scope.$watch('model.TRCategories3', function (newValue, oldValue) {
                        if (newValue) {
                            var sendData = $scope.model.TRCategories1Id + "-" + $scope.model.TRCategories2Id + "-" + $scope.model.TRCategories3Id + "-" + newValue;
                            getSubCategories3(sendData).then(function (data) {
                                if (data.length > 0) {
                                    $scope.model.showTRCategories5 = true
                                    $scope.to.options = data;
                                    getData = data;
                                }
                                else {
                                    $scope.model.showTRCategories4 = false;
                                }
                            });
                        }
                    });
                    $scope.$watch('model.TRCategories4', function (newValue, oldValue) {
                        if (newValue) {
                            var found = $scope.to.options.find(function (getData) {
                                return getData.Item2 == newValue;
                            });
                            $scope.model.TRCategories4Name = found.Item1;
                            console.log('found4', $scope.model.TRCategories4Name);

                            $scope.model.TRCategories4Id = newValue;
                        }
                    });
                }]
            },
            {
                key: 'TRCategories5',
                hideExpression: '!(model.TRCategories4 && model.showTRCategories5)',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Trendyol Kategorileri',
                    resource: 'form.tr-subcategories',
                    required: true,
                    placeholder: 'Kategori Seçiniz ',
                    class: 'col-md-3',
                    labelProp: 'Item1',
                    valueProp: 'Item2',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    var getData = null;
                    $scope.$watch('model.TRCategories4', function (newValue, oldValue) {
                        if (newValue) {

                            var sendData = $scope.model.TRCategories1Id + "-" + $scope.model.TRCategories2Id + "-" + $scope.model.TRCategories3Id + "-" + $scope.model.TRCategories4Id + "-" + newValue;
                            getSubCategories4(sendData).then(function (data) {
                                if (data.length > 0) {
                                    $scope.model.showTRCategories6 = true
                                    $scope.to.options = data;
                                    getData = data;

                                }
                                else {
                                    $scope.model.showTRCategories5 = false;
                                }
                            });
                        }
                    });
                    $scope.$watch('model.TRCategories5', function (newValue, oldValue) {
                        if (newValue) {
                            var found = $scope.to.options.find(function (getData) {
                                return getData.Item2 == newValue;
                            });
                            $scope.model.TRCategories5Name = found.Item1;
                            console.log('found5', $scope.model.TRCategories5Name);
                            $scope.model.TRCategories5Id = newValue;
                        }
                    });
                }]
            },
            {
                key: 'TRCategories6',
                hideExpression: '!(model.TRCategories5 && model.showTRCategories6)',
                type: 'floatSelect',
                templateOptions: {
                    label: 'Trendyol Kategorileri',
                    resource: 'form.tr-subcategories',
                    required: true,
                    placeholder: 'Kategori Seçiniz ',
                    class: 'col-md-3',
                    labelProp: 'Item1',
                    valueProp: 'Item2',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    var getData = null;
                    $scope.$watch('model.TRCategories5', function (newValue, oldValue) {
                        if (newValue) {
                            var sendData = $scope.model.TRCategories1Id + "-" + $scope.model.TRCategories2Id + "-" + $scope.model.TRCategories3Id + "-" + $scope.model.TRCategories4Id + "-" + $scope.model.TRCategories5Id + "-" + newValue;

                            getSubCategories5(sendData).then(function (data) {
                                if (data.length > 0) {
                                    $scope.to.options = data;
                                    getData = data;
                                }
                                else {
                                    $scope.model.showTRCategories6 = false;
                                }
                            });
                        }
                    });
                    $scope.$watch('model.TRCategories6', function (newValue, oldValue) {
                        if (newValue) {
                            var found = $scope.to.options.find(function (getData) {
                                return getData.Item2 == newValue;
                            });
                            $scope.model.TRCategories6Name = found.Item1;
                            console.log('found6', $scope.model.TRCategories6Name);

                            $scope.model.TRCategories5Id = newValue;
                        }
                    });
                }]
            },
        ];

        var matchcategoryFilterFields = [
            {
                key: 'EComCategoryFilterId',
                type: 'floatSelect',
                templateOptions: {
                    label: 'E-Ticaret Kategori Filtre',
                    resource: 'form.ecommerce-categories-filter',
                    required: true,
                    placeholder: 'Kategori Seçiniz ',
                    topProp: 'TopCategoryId',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    ProductsGeneralService.getCategories()
                        .then(function (response) {
                            $scope.to.options = response;
                        });
                }]
            },
            {
                key: 'N11CategoriesFilter',
                type: 'floatSelect',
                templateOptions: {
                    label: 'N11 Kategorileri',
                    resource: 'form.n11-topcategories',
                    required: true,
                    placeholder: 'Kategori Seçiniz ',
                    class: 'col-md-3',
                    options: [{ Name: 'Yükleniyor...' }]
                },
                controller: ['$scope', function ($scope) {
                    getTopCategories().then(function (data) {
                        $scope.$watch('model.N11Categories1', function (newValue, oldValue) {
                            console.log('N11Categories1', newValue, oldValue);
                            if (newValue) {
                                var found = $scope.to.options.find(function (data) {
                                    return data.Id == newValue;
                                });
                                $scope.model.N11Categories1Name = found.Name;
                                console.log('found', $scope.model.N11Categories1Name);
                            }
                        });
                        if (data.length > 0) {
                            $scope.model.showN11Categories2 = true
                        }
                        $scope.to.options = data;
                    });
                }]
            }
        ];

        return service;

        /////////////////////////////////////////


        //Option

        function getOption() {
            var deferred = $q.defer();

            $http.get(serviceBase + "option")
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getOptionValue(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "optionvalue/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        //

        //Template

        function getTemplateFields() {
            return templateFields;
        }

        function getAllTemplate(filterParams) {
            var deferred = $q.defer();
            $http.get(serviceBase + "template", {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getTemplate(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "template/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function addTemplate(template) {
            var deferred = $q.defer();

            $http.post(serviceBase + "template", template)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function editTemplate(template) {
            var deferred = $q.defer();

            $http.put(serviceBase + "template/" + template.Id, template)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function delTemplate(template) {
            var deferred = $q.defer();

            $http.delete(serviceBase + "template/" + template.Id)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        //

        //Match Category

        function getMatchCategoryFields() {
            return matchcategoryFields;
        }

        function getAllMatchCategory(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBase + "trmatchcategory", {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getMatchCategory(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "matchcategory/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function addMatchCategory(matchcategory) {
            console.log("matchcategory", matchcategory);
            var deferred = $q.defer();

            $http.post(serviceBase + "matchcategory/add", matchcategory)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function editMatchCategory(matchcategory) {
            var deferred = $q.defer();
            console.log("match category", matchcategory);
            $http.put(serviceBase + "matchcategory/" + matchcategory.Id, matchcategory)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function delMatchCategory(matchcategory) {
            var deferred = $q.defer();

            $http.delete(serviceBase + "matchcategory/" + matchcategory.Id)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        //

        //Match Category Filter

        function getMatchCategoryFilterFields() {
            return matchcategoryFilterFields;
        }

        function getMatchCategoryFilter(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "matchcategoryfilter/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getMatchCategoryFilterListByCategory(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "matchcategoryfilterlistbycategory/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function editMatchCategoryFilter(matchcategory) {
            var deferred = $q.defer();

            $http.put(serviceBase + "matchcategoryfilter/" + matchcategory.Id, matchcategory)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function deleteFilter(filter) {
            var deferred = $q.defer();

            $http.post(serviceBase + "deleteFilter",filter)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        //

        //Match Category Filter Value

        function getMatchCategoryFilterValueFields() {
            return matchcategoryFilterFields;
        }

        function getMatchCategoryFilterValue(id) {
            var deferred = $q.defer();
            console.log("getMatchCategoryFilterValue", id);
            $http.get(serviceBase + "matchcategoryfiltervalue/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getMatchCategoryFilterValueList(id) {
            var deferred = $q.defer();

            $http.get(serviceBase + "matchcategoryfiltervaluelist/" + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function editMatchCategoryFilterValue(matchcategory) {
            var deferred = $q.defer();

            $http.put(serviceBase + "matchcategoryfiltervalue/" + matchcategory.Id, matchcategory)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        //

        //trendyol Categories

        function getShipmentTemplate() {
            var deferred = $q.defer();

            $http.get(serviceBase + "N11TemplateList")
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getTopCategories() {
            var deferred = $q.defer();

            $http.get(serviceBase + "TrendyolTopCategories")
                .then(postComplete)
                .catch(postFailed);
            
            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }


        function getSubCategories(CatId) {
            var deferred = $q.defer();

            console.log("CatId 1", CatId);


            $http.get(serviceBase + "TrendyolSubCategories/" + CatId)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getSubCategories2(CatId) {
            var deferred = $q.defer();

            $http.get(serviceBase + "TrendyolSubCategories2/" + CatId)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
        function getSubCategories3(CatId) {
            var deferred = $q.defer();

            $http.get(serviceBase + "TrendyolSubCategories3/" + CatId)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
        function getSubCategories4(CatId) {
            var deferred = $q.defer();

            $http.get(serviceBase + "TrendyolSubCategories4/" + CatId)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
        function getSubCategories5(CatId) {
            var deferred = $q.defer();

            $http.get(serviceBase + "TrendyolSubCategories5/" + CatId)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCategoryFilter(CatId) {
            var deferred = $q.defer();
            $http.get(serviceBase + "CategoryFilter/" + CatId)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCategoryFilterValue(CatCode, FilterName) {
            var deferred = $q.defer();
            $http.get(serviceBase + "CategoryFilterValue/" + CatCode + "/" + FilterName)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function transferCategory(matchcategory) {
            var deferred = $q.defer();

            $http.post(serviceBase + "TransferCategory/", matchcategory)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function transferCategoryControl(matchcategory) {
            var deferred = $q.defer();

            $http.post(serviceBase + "TransferCategoryControl/", matchcategory)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function transferAllCategory() {
            var deferred = $q.defer();

            $http.post(serviceBase + "TransferAllCategory/")
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        

        //Product Sender

        
        function ProductSender(entity) {
            var deferred = $q.defer();

            $http.post(serviceBase + "ProductSend/",entity)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }


        function ProductQuery(entity) {
            var deferred = $q.defer();

            $http.post(serviceBase + "ProductQuery/", entity)
                .then(postComplete)
                .catch(postFailed);

            function postComplete(response) {
                deferred.resolve(response.data);
            }

            function postFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        //
    }
})();