(function () {
    'use strict';

    angular
        .module('app.designTheme')
        .factory('DesignThemeService', Service);

    Service.$inject = ['$http', '$q', 'ngAuthSettings', 'logger'];

    function Service($http, $q, ngAuthSettings, logger) {
        var service = {
            getData: getData,
            getData2: getData2
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri;

        return service;

        function getData() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/Test')
                .then(getDataComplete)
                .catch(getDataFailed);

            function getDataComplete(response) {
                deferred.resolve(response.data);
            }

            function getDataFailed(error) {
                logger.error('XHR Failed : ' + error);
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function getData2() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/Test/GetOther')
                .then(getDataComplete)
                .catch(getDataFailed);

            function getDataComplete(response) {
                deferred.resolve(response.data);
            }

            function getDataFailed(error) {
                logger.error('XHR Failed : ' + error);
                deferred.reject(error);
            }

            return deferred.promise;
        }
    }
})();