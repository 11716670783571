(function () {
    'use strict';

    angular
        .module('app.marketing')
        .factory('CampaignsService', Service);

    Service.$inject = ['$http', '$q', 'ngAuthSettings', 'RolesService'];

    function Service($http, $q, ngAuthSettings, RolesService) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            getFields: getFields,
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + 'api/campaigns/';

        var fields = [{
                key: 'Name',
                type: 'floatInput',
                templateOptions: {
                    required: true,
                    type: 'text',
                    label: 'Adı',
                    resource: 'form.name',
                    placeholder: 'Adı',
                    class: "col-xs-12"
                }
            },
            {
                key: 'Active',
                type: 'floatCheckbox',
                templateOptions: {
                    label: 'Aktif',
                    resource: 'form.active',
                    placeholder: 'Aktif',
                    class: "col-xs-6"
                }
            },
            {
                key: 'Priority',
                type: 'floatInput',
                defaultValue: 1,
                templateOptions: {
                    type: 'number',
                    required: true,
                    label: 'Öncelik',
                    resource: 'form.priority',
                    placeholder: 'Öncelik',
                    class: "col-xs-6"
                }
            },
            {
                key: 'StartDate',
                type: 'floatDatepicker',
                templateOptions: {
                    label: 'Başlangıç Tarihi',
                    resource: 'form.start-date',
                    placeholder: 'Başlangıç Tarihi',
                    class: "col-xs-6"
                }
            },
            {
                key: 'EndDate',
                type: 'floatDatepicker',
                templateOptions: {
                    label: 'Bitiş Tarihi',
                    resource: 'form.end-date',
                    placeholder: 'Bitiş Tarihi',
                    class: "col-xs-6"
                }
            },
            // {
            //     key: 'MaxUsageQuantity',
            //     type: 'floatInput',
            //     templateOptions: {
            //         type: 'number',
            //         label: 'Max. Kullanım Adedi',
            //         resource: 'form.max-usage-quantity',
            //         placeholder: 'Max. Kullanım Adedi',
            //         class: "col-xs-6"
            //     }
            // },
            // {
            //     key: 'MaxUsageQuantityForUser',
            //     type: 'floatInput',
            //     templateOptions: {
            //         type: 'number',
            //         label: 'Max. Kullanım Adedi(Kullanıcı)',
            //         resource: 'form.max-usage-quantity-for-user',
            //         placeholder: 'Max. Kullanım Adedi(Kullanıcı)',
            //         class: "col-xs-6"
            //     }
            // },
            // {
            //     key: 'CouponCode',
            //     type: 'floatInput',
            //     templateOptions: {
            //         type: 'text',
            //         label: 'Kupon kodu',
            //         resource: 'form.coupon-code',
            //         placeholder: 'Kupon kodu',
            //         class: "col-xs-6"
            //     }
            // },
            // {
            //     key: 'RoleId',
            //     type: 'floatSelect',
            //     templateOptions: {
            //         label: 'Role',
            //         resource: 'form.role',
            //         options: [{
            //             Name: '...'
            //         }],
            //         class: "col-xs-6"
            //     },
            //     controller: ['$scope', function ($scope) {
            //         RolesService.getAll({
            //                 IsBase: false
            //             })
            //             .then(function (response) {
            //                 $scope.to.options = response.Data;
            //             });
            //     }]
            // },
            {
                key: "MediaId",
                type: "floatSingleImage",
                templateOptions: {
                    label: "Kampanya Resmi",
                    placeholder: "Kampanya Resmi",
                    resource: "form.campign-image",
                    valueKey: "Id",
                    valueModel: "Media.FullPath",
                    class: "col-xs-6"
                }
            },
            {
                key: "Description",
                type: "floatTinyMCE",
                templateOptions: {
                    label: "İçerik",
                    placeholder: "İçerik",
                    resource: "form.description"
                }
            }
        ];

        return service;

        /////////////////////////////////////////

        function getFields() {
            return fields;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBase, {
                    params: filterParams
                })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(Id) {
            var deferred = $q.defer();

            $http.get(serviceBase + Id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {
            var deferred = $q.defer();

            $http.post(serviceBase, entity)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function edit(entity) {
            var deferred = $q.defer();

            $http.put(serviceBase + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function del(entity) {
            var deferred = $q.defer();

            $http.delete(serviceBase + entity.Id, entity)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();