(function () {
    'use strict';

    angular
        .module('app.blogPost')
        .controller('BlogPostDetailController', BlogPostDetailController);

    BlogPostDetailController.$inject = ['$scope', '$stateParams', '$filter', '$http', '$location', 'logger', 'Settings', 'BlogPostService', 'FileUploader', 'localStorageService', 'NgTableParams'];

    function BlogPostDetailController($scope, $stateParams, $filter, $http, $location, logger, Settings, BlogPostService, FileUploader, localStorageService, NgTableParams) {
        var vm = this;       

        vm.uploader = new FileUploader({});

        vm.Settings = Settings;

        vm.uploader.filters.push({
            name: 'imageFilter',
            fn: function (item, options) {
                var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
            }
        });

        vm.seofields = BlogPostService.getSeoFields();

        vm.uploader.onSuccessItem = function (fileItem, response) {
            fileItem.MediaId = response.Id;
        };
        vm.loading = true;
        BlogPostService.getPost($stateParams.Id).then(function (data) {
            vm.entity = data;

            vm.uploader.url = 'api/BlogPost/' + data.Id + '/Media';
            var authData = localStorageService.get('authorizationData');
            vm.uploader.headers["Authorization"] = 'Bearer ' + authData.token;
            for (var i = 0; i < data.PostMedia.length; i++) {
                var dummy = new FileUploader.FileItem(vm.uploader, {});
                dummy.progress = 100;
                dummy.isUploaded = true;
                dummy.isSuccess = true;
                vm.uploader.queue.push(dummy);
            }
            vm.loading = false;
        });

        vm.fields = BlogPostService.getPostFields();

        vm.delete = function () {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                BlogPostService.deletePost(vm.entity).then(function (data) {
                    $location.path('/blogpost/list');
                });
            }
        };

        vm.removeFile = function (item) {
            BlogPostService.removePostMedia(vm.entity.Id, item.MediaId).then(function (data) {
                vm.uploader.removeFromQueue(item);
            });
        };
    }
})();