(function () {
    'use strict';

    angular
        .module('app.production')
        .factory('ProductsGeneralService', ProductsGeneralService);

    ProductsGeneralService.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config'];

    function ProductsGeneralService($http, $q, ngAuthSettings, logger, config) {
        var service = {
            getBaseTypes: getBaseTypes,
            getCategories: getCategories,
            getModels: getModels,
            getTypes: getTypes,
            getProductBrands: getProductBrands,
            getUnitMeasures: getUnitMeasures,
            getOptions: getOptions,
            getOptionControlTypes: getOptionControlTypes,
            getReviewTypes: getReviewTypes,
            getIntegrationTemplates: getIntegrationTemplates,
            getShipmentTemplates: getShipmentTemplates 
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri;

        return service;

        /////////////////////////////////////////

        function getBaseTypes() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/ProductGeneral/BaseType')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCategories() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/ProductGeneral/Category')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getProductBrands() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/ProductGeneral/ProductBrand')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getModels() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/ProductGeneral/Model')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getTypes() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/ProductGeneral/Type')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getUnitMeasures() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/ProductGeneral/UnitMeasure')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getOptions() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/ProductGeneral/Option')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }


        function getOptionControlTypes() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/ProductGeneral/OptionControlType')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getReviewTypes() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/ProductGeneral/ReviewType')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getIntegrationTemplates() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/ProductGeneral/IntegrationTemplate')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
        function getShipmentTemplates() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/ProductGeneral/ShipmentTemplate')
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();