(function() {
    "use strict";

    angular.module("app.contracts").run(appRun);

    appRun.$inject = ["routerHelper"];

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: "contracts",
                config: {
                    url: "/contracts",
                    title: "Sözleşmeler",
                    abstract: true,
                    templateUrl: "app/contracts/contracts.html"
                }
            },
            {
                state: "contracts.list",
                config: {
                    templateUrl: "app/contracts/contracts.list.html",
                    controller: "ContractsListController",
                    controllerAs: "vm",
                    url: "/list",
                    title: "Sözleşme Listesi",
                    settings: {
                        nav: 10,
                        name: "contracts.list",
                        topName: "system",
                        content: "Sözleşme Listesi",
                        resource: "menu.contracts.list"
                    }
                }
            },
            {
                state: "contracts.add",
                config: {
                    templateUrl: "app/contracts/contracts.add.html",
                    controller: "ContractsAddController",
                    controllerAs: "vm",
                    url: "/add",
                    title: "Sözleşme Ekle",
                    settings: {
                        topName: "contracts.list",
                        resource: "menu.contracts.add"
                    }
                }
            },
            {
                state: "contracts.edit",
                config: {
                    templateUrl: "app/contracts/contracts.edit.html",
                    controller: "ContractsEditController",
                    controllerAs: "vm",
                    url: "/edit/:Id",
                    title: "Sözleşme Düzenle",
                    settings: {
                        topName: "contracts.list",
                        resource: "menu.contracts.edit"
                    }
                }
            },
            {
                state: "contracts.detail",
                config: {
                    templateUrl: "app/contracts/contracts.detail.html",
                    controller: "ContractsDetailController",
                    controllerAs: "vm",
                    url: "/detail/:Id",
                    title: "Sözleşme Detay",
                    settings: {
                        topName: "contracts.list",
                        resource: "menu.contracts.detail"
                    }
                }
            }
        ];
    }
})();
