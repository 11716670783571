(function () {
    'use strict';

    angular
        .module('app.ggIntegration')
        .controller('GGEditMatchCategoryController', GGEditMatchCategoryController);

    GGEditMatchCategoryController.$inject = ['$scope', '$stateParams', '$location', 'GGIntegrationService', 'NgTableParams'];

    function GGEditMatchCategoryController($scope, $stateParams, $location, GGIntegrationService, NgTableParams) {
        /* jshint validthis:true */
        var vm = this;

        vm.settings = globalSettings;
        activate();

        function activate() {
            vm.fields = GGIntegrationService.getMatchCategoryFields();
            console.log('vm.fields', vm.fields);

            GGIntegrationService.getMatchCategory($stateParams.Id)
                .then(function (data) {
                    vm.entity = data;
                    vm.entity.refresh = true;
                    vm.loading = false;
                });
        }

        vm.editMatchCategory = function () {
            if (vm.form.$valid) {
                vm.loading = true;
                N11IntegrationService.editMatchCategory(vm.entity).then(function () {
                    vm.loading = false;
                    activate();
                });
            }
        };

    }
})();